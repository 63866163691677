import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { tag } from "app/main/admin/contacts/model/tag.model";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MailchimpService {
  tagList: BehaviorSubject<any>;
  constructor(private http: HttpClient) {
    this.tagList = new BehaviorSubject([]);
  }

  public getMailchimpList(): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/mailingList`;
    return this.http.get(url);
  }

  refreshMailchimpList(guId): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/mailingList/${guId}`;
    return this.http.get(url)
  }

  getMailchimpData() : Observable<any>{
    let url = `${environment.apiBaseUrl}/rest/mailingList/`
    return this.http.get(url)
  }

  createMailchimpList (payload): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/mailingList`
    return this.http.post(url, payload)
  }

  updateMailchimpList ( payload): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/mailingList`
    return this.http.put(url, payload)
  }
  deleteMailchimpList (guId) : Observable<any>{
    let url = `${environment.apiBaseUrl}/rest/mailingList/${guId}`;
    return this.http.delete(url)
  }

  getMailchimpListStats(): Observable<any>{
    let url = `${environment.apiBaseUrl}/rest/mailingList/stats`
    return this.http.get(url)
  }

}
