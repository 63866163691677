import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NoticeComponent } from "app/main/shared/components/notice/notice.component";

@Component({
  selector: "app-notice-dlg",
  templateUrl: "./notice-dlg.component.html",
  styleUrls: ["./notice-dlg.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NoticeDlgComponent implements OnInit {
  notices = [];
  noticeCount: any = 0;
  quotes = [];
  isStudentRole: boolean;
  dialogRef: any;

  constructor(
    public _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public matDialogRef: MatDialogRef<NoticeDlgComponent>
  ) {}

  ngOnInit(): void {
    this.notices = this._data.notices;
    this.quotes = this._data.quotes;
    this.isStudentRole = this._data.isStudentRole;
  }

  manageNotices() {
    this.dialogRef = this._matDialog.open(NoticeComponent, {
      panelClass: "dlg-contct-div"
    });
    this.dialogRef.afterClosed().subscribe((response: any) => {
      this.matDialogRef.close(true);
    });
  }
}
