import { Component, OnInit } from "@angular/core";
import { MailchimpService } from "./mailchimp.service";
import { SubSink } from "subsink";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MailchimpListInfoDialogComponent } from "app/main/admin/app-settings/integration-settings/mailchimp/mailchimp-list-info-dialog/mailchimp-list-info-dialog.component";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-mailchimp-integration",
  templateUrl: "./mailchimp-integration.component.html",
  styleUrls: ["./mailchimp-integration.component.scss"],
  animations: fuseAnimations
})
export class MailchimpIntegrationComponent implements OnInit {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  mailChimpform: FormGroup;
  showAudienceGif: boolean = false;
  showApiKey: boolean = false;
  subs = new SubSink();
  mailchimpInfo: any;
  mailChimpData: any;
  isReSyncDisabled: any;
  accountDetail: any;
  completeStatus = "Complete";
  payload: any = {};
  status: any;
  issubmitting: boolean = false;
  isCreateMode: boolean = true;
  isloading: boolean = false;
  isReSync: boolean = false;
  isResyncStarted: boolean = false;
  data: any = {
    name: "",
    listId: "",
    apiKey: "",
    status: ""
  };

  tenant: any;
  isLoading: boolean = false;
  constructor(
    private mailchimpService: MailchimpService,
    private _formBuilder: FormBuilder,
    public toastr: ToastrService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _settingsService: AppSettingsService,
    public _matDialog: MatDialog,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this.accountDetail = { ...data };
        this.SpinnerService.hide();
      })
    );

    //this.getMailchimpData();
    this.mailChimpform = this._formBuilder.group({
      mcAudienceName: ["", Validators.required],
      mcAudienceId: ["", Validators.required],
      mcApiKey: ["", Validators.required],
      fromEmail: [""],
      fromName: [""]
    });

    this.getCampaignDetail();
  }

  resyncTags(): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      id: "dialog"
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      "Sync will sync all mailchimp contacts with wajooba contacts" +
      " " +
      "this process takes time based on the number of records";
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.isResyncStarted) {
          alert("resync is already in progress");
          return;
        }
        this.isResyncStarted = true;
        this.setStatus("in_progress");
        this.inintResyncMailchimp("");
      }
      this.confirmDialogRef = null;
    });
  }

  generateMailchimpName() {
    // Declare a string variable
    // which stores all string
    var string = "0123456789";
    var name = "Mailchimp-";
    // Find the length of string
    var len = string.length;
    for (var i = 0; i < 3; i++) {
      name += string[Math.floor(Math.random() * len)];
    }
    return name;
  }

  audienceId() {
    this.showAudienceGif = true;
  }
  apiKey() {
    this.showApiKey = true;
  }

  buildPayload() {
    var payload = {
      isActiveList: true
    };
    return payload;
  }

  onFormSubmit() {
    this.isLoading = true;
    let payload = this.mailChimpform.getRawValue();

    this.subs.add(
      this._settingsService.updateMailchimpTenant(payload).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.toastr.success("Mailchimp updated successfully");
        },
        errorResp => {
          this.isLoading = false;
          this.toastr.error(errorResp.error.errors.message);
        }
      )
    );
  }

  onSubmit() {
    let mailChimpInfo = this.mailChimpform.value;
    (this.payload.name = this.data.name ? this.data.name : this.generateMailchimpName()),
      (this.payload.listId = mailChimpInfo.listId),
      (this.payload.apiKey = mailChimpInfo.apiKey),
      (this.payload.fromEmail = mailChimpInfo.fromEmail ? mailChimpInfo.fromEmail : this.accountDetail.fromEmail),
      (this.payload.fromName = mailChimpInfo.fromName ? mailChimpInfo.fromName : this.accountDetail.fromEmailName);
    if (this.mailchimpInfo && this.mailchimpInfo.listId == "") {
      this.subs.add(
        this.mailchimpService.updateMailchimpList(this.payload).subscribe(result => {
          this.issubmitting = false;
          this.isCreateMode = false;
          this.setStatus(result.status);
          this.mailchimpInfo = result.data;
          this.toastr.success("Mailchimp added successfully");
          if (this.isReSync) {
            this.isReSyncDisabled = true;
            this.inintResyncMailchimp("");
          }
        })
      );
    } else {
      this.issubmitting = true;
      if (
        this.data.status == this.completeStatus &&
        (this.mailChimpData.listId != this.mailchimpInfo.listId ||
          this.mailChimpData.apiKey != this.mailchimpInfo.apiKey)
      ) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
          disableClose: false,
          id: "dialog"
        });
        this.confirmDialogRef.componentInstance.confirmMessage =
          "This will reset the existing mailchimp account and resync has to be done again, proceed?";
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.updateMailchimp(this.payload);
          }
          this.confirmDialogRef = null;
          this.issubmitting = false;
        });
      } else {
        this.issubmitting = false;
        this.updateMailchimp(this.payload);
      }
    }
  }

  setStatus = function(status) {
    switch (status) {
      case "not_started":
        this.data.status = "Not Started";
        break;
      case "in_progress":
        this.data.status = "In progress";
        break;
      case "complete":
        this.data.status = "Complete";
        break;
      case "failed":
        this.data.status = "Failed";
        break;
      default:
        this.data.status = "Not Started";
    }
  };

  getMailchimpListStats() {
    this.subs.add(
      this.mailchimpService.getMailchimpListStats().subscribe(result => {
        this.mailchimpInfo = result;
      })
    );
  }

  getCampaignDetail() {
    this.subs.add(
      this._settingsService.getCampaignDetail().subscribe((result: any) => {
        this.tenant = result.tenant;
        this.bindFormData();
      })
    );
  }

  public getMailchimpData(): any {
    this.subs.add(
      this.mailchimpService.getMailchimpData().subscribe(result => {
        this.mailChimpData = result;
        this.data.name = result.name;
        if (result.listId != "") {
          this.getMailchimpListStats();
        }
        console.log(result.status == "Complete", "g");
        if (result.status == "complete") {
          this.isReSync = true;
        }
        this.bindFormData();
        this.isCreateMode = result.listId != "" ? false : true;
        this.status = this.setStatus(result.status);
        this.isloading = false;
      })
    );
  }

  mailchimpDialog(): void {
    const dialogRef = this._matDialog.open(MailchimpListInfoDialogComponent, {
      disableClose: true,
      width: "550px",
      height: "325px",
      id: "dialog",
      data: {
        mailchimpInfo: this.mailchimpInfo,
        mailChimpData: this.mailChimpData
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {});
  }

  inintResyncMailchimp(isResyncCall) {
    this.subs.add(
      this.mailchimpService.createMailchimpList(this.buildPayload()).subscribe(result => {
        this.isCreateMode = false;
        this.setStatus("complete");
        this.mailchimpInfo = result;
        this.toastr.success("Resync was successfully initiated");
        this.isResyncStarted = false;
        this.isReSyncDisabled = false;
        if (isResyncCall) {
          this._router.navigate(["integration"], { relativeTo: this._activatedRoute });
        }
        return false;
      })
    );
  }

  updateMailchimp(payload) {
    this.SpinnerService.show();
    this.subs.add(
      this.mailchimpService.updateMailchimpList(payload).subscribe(result => {
        this.isCreateMode = false;
        this.setStatus(result.status);
        this.mailchimpInfo = result;
        this.SpinnerService.hide();
        this.toastr.success("Mailchimp updated successfully");
        if (this.isReSync) {
          this.isReSyncDisabled = true;
          this.SpinnerService.hide();
          this.inintResyncMailchimp(true);
        } else {
          this._router.navigate(["integration"], { relativeTo: this._activatedRoute });
        }
      })
    );
  }

  bindFormData(): void {
    // Reactive Form
    this.mailChimpform = this._formBuilder.group({
      mcAudienceName: [this.tenant.mcAudienceName],
      mcAudienceId: [this.tenant.mcAudienceId],
      mcApiKey: [this.tenant.mcApiKey],
      fromEmail: [this.tenant.fromEmail],
      fromName: [this.tenant.fromName]
    });
  }
}
