<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner> -->

<div class="nav" [ngClass]="{ horizontal: layout === 'horizontal', vertical: layout === 'vertical' }" style="width: 1366px; margin: 0 auto;">
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of navigation">
      <fuse-nav-vertical-group *ngIf="item.type == 'group'" [item]="item"></fuse-nav-vertical-group>
      <fuse-nav-vertical-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-vertical-collapsable>
      <fuse-nav-vertical-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-vertical-item>
    </ng-container>
  </ng-container>
  <!-- / Vertical Navigation Layout -->

  <!-- Horizontal Navigation Layout -->
  <ng-container *ngIf="layout === 'horizontal'">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" *ngIf="tenant">
        <img class="logo-icon" [src]="websiteLogo" [style.height]="tenant.org.logoHeight+'px'" (click)="openHomepage();closeNavbar()" />
      </div>
    </div>
    <div class="spacer"></div>
    <ng-container *ngFor="let item of navigation">
      <fuse-nav-horizontal-collapsable *ngIf="item.type == 'group'" [item]="item"></fuse-nav-horizontal-collapsable>
      <fuse-nav-horizontal-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-horizontal-collapsable>
      <fuse-nav-horizontal-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-horizontal-item> </ng-container
  ></ng-container>
</div>
