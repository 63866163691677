import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {TenantUserService} from '../../../../../core/services/tenantuser.service';
import {ToastrService} from 'ngx-toastr';
import {SubSink} from 'subsink';
import {AppSettingsService} from '../../services/app-settings.service';
import {MatDialog} from "@angular/material/dialog";
import {ConnectEmailComponent} from "../connect-email/connect-email.component";

@Component({
    selector: 'app-zoom-meeting',
    templateUrl: './connect-email-page.component.html',
    styleUrls: ['./connect-email-page.component.scss'],
    animations: fuseAnimations,
})
export class ConnectEmailPageComponent implements OnInit, OnDestroy {
    subs = new SubSink();
  isLoading:boolean = false;
    connectedEmail = [];
    constructor(private _formBuilder: FormBuilder,
                private _tenantUserService: TenantUserService,
                private _settingsService: AppSettingsService,
                public toastr: ToastrService,
                private _matDialog: MatDialog,) {
    }


    ngOnInit(): void {
        this.getTenantInfo();
    }
  openConnectEmailDialog(){
      let eventObj = {
        date: new Date()
      };
      const dialogRef = this._matDialog.open(ConnectEmailComponent, {
        panelClass: "connect-email-dialog",
        data: eventObj,
        disableClose: true,
        width: "780px"
      });
    }
    getTenantInfo() {
        const validEmailProviders = ['googlemail','zohomail','outlookmail'];
        this.subs.add(
            this._settingsService.getAccountDetail().subscribe((data) => {
                    data.oauthProviderList.forEach(item => {
                        if (validEmailProviders.includes(item.productName)){
                            this.connectedEmail.push(item);
                        }
                    });
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
