<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <div class="new-header">
    <div style="float: left; display: flex; align-items: center; justify-content: center">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> language </mat-icon>
      <span class="logo-text h1"> Website Settings </span>
    </div>
    <div style="float: right">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <div class="content">
    <div fxLayout="column" fxLayoutAlign="center">
      <div class="settings" fxLayout="row wrap" fxLayoutAlign="center">
        <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex="grow" fxLayout.gt-xs="row" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
          <div class="mb-24" style="width: 100%">
            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="center" fxFlex="90" fxFlex="grow" name="form" [formGroup]="form" fxFlexFill style="border-radius: 4px">
              <h4 class="settingTitle">Website Settings</h4>
              <mat-divider style="display: inline-block" fxFlex="100" style="padding-bottom: 20px"></mat-divider>

              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
                <span class="custom-control courseTitleClass" fxFlex="100"> Favorite Icon </span>
              </div>
              <div class="col-sm-2 img-wrapper" fxFlex="50">
                <img src="{{ this.favoriteIcon }}" alt="" class="p-8" />
              </div>
              <app-file-upload
                [photos]="favImage"
                (getFileObject)="favImage$.next($event)"
                [title]="''"
                [orgId]="orgId"
                [description]="'Add a favorite icon for your website'"
                [isFontApply]="true"
              ></app-file-upload>

              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
                <span class="custom-control courseTitleClass" fxFlex="100"> Organization Logo </span>
              </div>
              <div class="col-sm-2 img-wrapper" fxFlex="50">
                <img src="{{ this.websiteLogo }}" alt="" class="p-8" />
              </div>
              <app-file-upload [photos]="bannerImage" (getFileObject)="bannerImage$.next($event)" [title]="''" [orgId]="orgId" [description]="'Add a logo image for your website'" [isFontApply]="true">
              </app-file-upload>
<!-- 
              <div fxLayout="row" fxLayoutAlign="start" fxLayout="column" fxFlex="1 1 auto" style="margin-bottom: 13px">
                <mat-checkbox class="courseTitleClass" formControlName="isScaleLogo" fxFlex="100" name="isScaleLogo" (change)="showOptions($event)">Scale Logo </mat-checkbox>
                <h5 class="courseSubTitleClass" style="margin: 0px; margin-bottom: 8px; margin-left: 24px">When checked, logo height will be calculated based on the image proportionately</h5>
              </div> -->

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Logo Width</mat-label>
                  <input matInput formControlName="logoWidth" class="right-align" placeholder="Enter Width" type="number" />
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Logo Height (Recommended 75px)</mat-label>
                  <input
                    matInput
                    formControlName="logoHeight"
                    class="right-align"
                    placeholder="Enter Height"
                    type="number"
                  />
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">SEO Page Title</mat-label>
                  <input matInput formControlName="title" class="right-align" placeholder="Title" />
                  <mat-error>Title is required!</mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">SEO Page Description</mat-label>
                  <textarea matInput formControlName="seoDescription" class="right-align"></textarea>
                  <mat-error>Description is required!</mat-error>
                </mat-form-field>
              </div>
              
              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Page Script</mat-label>
                  <textarea matInput formControlName="pageScript" class="right-align"></textarea>
                  <mat-error>Page Script is required!</mat-error>
                </mat-form-field>
              </div>

              <!--              <h4 class="settingTitle">Website Settings</h4>-->
              <!--              <mat-divider style="display: inline-block;" fxFlex="100" style="padding-bottom: 20px;"></mat-divider>-->
              <!--              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                <span class="custom-control  courseTitleClass" fxFlex="100">-->
              <!--                  Title-->
              <!--                </span>-->
              <!--              </div>-->
              <!--              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                 <span class="custom-control secondary-text" fxFlex="100">-->
              <!--                          A SEO title key must contain your target key.-->
              <!--                        </span>-->
              <!--              </div>-->
              <!--              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>-->
              <!--                  <mat-label class="courseTitleClass">SEO Title</mat-label>-->
              <!--                  <textarea matInput formControlName="title" class="right-align"></textarea>-->
              <!--                  <mat-error>SEO Title is required!</mat-error>-->
              <!--                </mat-form-field>-->
              <!--              </div>-->

              <!--              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                <span class="custom-control  courseTitleClass" fxFlex="100">-->
              <!--                  SEO Description-->
              <!--                </span>-->
              <!--              </div>-->
              <!--              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                 <span class="custom-control secondary-text" fxFlex="100">-->
              <!--                          SEO Desc goes here-->
              <!--                        </span>-->
              <!--              </div>-->
              <!--              <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto" style="padding-top: 5px;">-->
              <!--                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>-->
              <!--                  <mat-label class="courseTitleClass">SEO Description</mat-label>-->
              <!--                  <textarea matInput formControlName="seoDescription" class="right-align"></textarea>-->
              <!--                  <mat-error>SEO Description is required!</mat-error>-->
              <!--                </mat-form-field>-->
              <!--              </div>-->

              <h4 class="settingTitle">Theme Settings</h4>
              <mat-divider style="display: inline-block" fxFlex="100" style="padding-bottom: 20px"></mat-divider>

              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
                <span class="custom-control courseTitleClass" fxFlex="100"> Top Bar / Footer Background </span>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
                <span class="custom-control secondary-text" fxFlex="100"> Appears as background on page header and footer. </span>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center" layout-align="center center" fxFlex="1 0 auto" class="title-header">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <input matInput formControlName="headerColor" [(colorPicker)]="headerColor" (colorPickerChange)="headerColorChanged($event)" /><span
                    style="width: 10px"
                    [style.background]="headerColor"
                  ></span>
                </mat-form-field>
              </div>

              <h4 class="settingTitle">Social Media</h4>
              <mat-divider style="display: inline-block" fxFlex="100" style="padding-bottom: 20px"></mat-divider>
              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Twitter</mat-label>
                  <input matInput formControlName="twitter" class="right-align" placeholder="Twitter Link" />
                  <mat-error>Twitter is required!</mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Facebook</mat-label>
                  <input matInput formControlName="facebook" class="right-align" placeholder="Facebook Link" />
                  <mat-error>Facebook is required!</mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Instagram</mat-label>
                  <input matInput formControlName="instagram" class="right-align" placeholder="Instagram Link" />
                  <mat-error>Instagram is required!</mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">LinkedIn</mat-label>
                  <input matInput formControlName="linkedin" class="right-align" placeholder="LinkedIn Link" />
                  <mat-error>LinkedIn is required!</mat-error>
                </mat-form-field>
              </div>
             <!-- Privacy Policy -->

              <h4 class="settingTitle">Website Footer</h4>
              <mat-divider style="display: inline-block" fxFlex="100" style="padding-bottom: 20px"></mat-divider>
              <!-- Want to show the footer tag here -->
              <quill-editor style="margin-bottom: 90px;" formControlName="footerInfo"></quill-editor>
              <!-- <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"> -->
                <!-- <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
                  <mat-label class="courseTitleClass">Footer Message</mat-label>
                  <input matInput formControlName="footerInfo" class="right-align" />
                  <mat-error>Footer Message is required!</mat-error>
                </mat-form-field> -->
              <!-- </div> -->
              <div fxLayout="row" fxLayoutAlign="start" fxLayout="column" fxFlex="1 1 auto" style="margin-bottom: 13px">
                <mat-checkbox class="courseTitleClass" formControlName="isShowFooter" fxFlex="100" name="isShowFooter">Hide Website footer </mat-checkbox>
                <h5 class="courseSubTitleClass" style="margin: 0px; margin-bottom: 8px; margin-left: 24px">footer will not shown.</h5>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
                <button type="button" mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>

                <button mat-raised-button type="button" color="accent" (click)="updateSettings()">
                  Submit
                  <mat-icon *ngIf="isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                  </mat-icon>
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="setting" *ngFor="let setting of settingsList" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33">
                  <div
                    class="setting-content bg-color-white"
                    fxLayout="column"
                    fxFlex="1 1 auto"
                    (click)="navigateTo(setting.url)"
                  >
                    <div *ngIf="setting.icon !== 'wysiwyg'" class="setting_icon mt-32 bg-color-whiteSmoke">
                      <mat-icon class="icon-size-10 txt-color-themeBlue">{{ setting.icon }}</mat-icon>
                    </div>
                    <div
                      *ngIf="setting.icon !== 'wysiwyg'"
                      mat-button
                      class="header"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <div class="h1 box" fxFlex>
                        {{ setting.title }}
                      </div>
                    </div>
                    <div
                      *ngIf="setting.icon === 'wysiwyg'"
                      mat-button
                      class="header"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <div class="h1 box" fxFlex>
                        {{ setting.title }}
                      </div>
                    </div>

                    <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
                      <div class="updated">{{ setting.description }}</div>
                    </div>
                  </div>
                </div>

                <div class="no-settings" *ngIf="settingsList.length === 0">
                  No Settings found!
                </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex="grow" fxLayout.gt-xs="row"
      fxFlex.gt-xs="50" fxFlex.gt-sm="33">
      <div class="mb-24">
        <div fxFlex="5"></div>
        <div class="card p-24 mr-24" fxLayout="column" fxLayoutAlign="center" fxFlex="grow"
          fxFlex="90" fxFlexFill>
          <div class="content bg-color-whiteSmoke p-24">


        </div>
        </div>
      </div>
    </div> -->
  <!-- / CONTENT -->
</div>
