import { Component, OnInit, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ExternalWebsiteService } from '../../../admin/app-settings/website-setting/external-website/externalwebsite.service';
import { ToastrService } from 'ngx-toastr';
import { AppLoadService } from '../../../../core/services/appload.service';
import { TenantUserService } from '../../../../core/services/tenantuser.service';

@Component({
  selector: 'standard-page-header-subheader',
  templateUrl: './standard-page-header-subheader.component.html',
  styleUrls: ['./standard-page-header-subheader.component.scss'],
  animations: fuseAnimations,
})
export class StandardPageHeaderSubheaderComponent implements OnInit {
  header: string;
  subHeader: string;
  isPageHeaderEdit: boolean;
  isPageSubHeaderEdit: boolean;
  isLoggedIn: boolean;
  isPreviewMode: boolean;
  pageId = '';
  tenant: any;
  @Input()
  pageName = '';

  constructor(private _externalWebsiteService: ExternalWebsiteService, public toastr: ToastrService, private _appLoadService: AppLoadService, private _tenantUserService: TenantUserService) {
    this.tenant = this._appLoadService.getTenantPing();
  }

  ngOnInit(): void {
    this.isLoggedIn = this._tenantUserService.getuser().isLoggedIn;
    this.isPreviewMode = this._tenantUserService.getuser().isPreviewMode;
    this.getInternalWebsitePageDetail();
  }

  private editInternalWebsitePageDetail() {
    let payload: any = {};
    payload = {
      header: this.header,
      subHeader: this.subHeader,
    };
    if (this.pageId == '') {
      this._externalWebsiteService.addInternalWebsitePage(this.pageName, payload).subscribe(
        result => {
          this.toastr.success(this.pageName + ' is added successfully.');
          this.getInternalWebsitePageDetail();
        },
        error => {
          this.toastr.error(error.message);
        },
      );
    } else {
      this._externalWebsiteService.updateInternalWebsitePage(this.pageId, payload).subscribe(
        result => {
          this.toastr.success(this.pageName + ' is updated successfully.');
          this.getInternalWebsitePageDetail();
        },
        error => {
          this.toastr.error(error.message);
        },
      );
    }
  }
  private getInternalWebsitePageDetail() {
    this._externalWebsiteService.getInternalWebsitePublicPage(this.pageName, this.tenant.tenantId).subscribe(
      (result: any) => {
        console.log("This is the data",result.subHeader
        );
        
        this.subHeader = result.subHeader;
        this.header = result.header;
        this.pageId = result._id;
      },
      error => {},
    );
  }
}
