import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { ToastrService } from "ngx-toastr";
import { AppSettingsService } from "../../../services/app-settings.service";
import { AppConstants } from "app/core/settings/appconstants";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-razorpay",
  templateUrl: "./razorpay.component.html",
  styleUrls: ["./razorpay.component.scss"],
  animations: fuseAnimations
})
export class RazorpayComponent implements OnInit {
  @Output() payment: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;

  subs = new SubSink();
  adminList;
  isOrganizePlan;
  isExpandPlan;
  isLeapPlan;
  isFreePlan;
  isAccountOwner: boolean = false;
  isShowError;
  issubmitting: boolean = false;
  isInstamojo = false;

  tenant: any;
  tenantKeys: any;
  isProviderIntegrated: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const self = this;
    this.form = this._formBuilder.group({
      secretKey: [""],
      apiKey: [""],
      isTestMode: [""],
      testApiKey: [""],
      testSecretKey: [""]
    });
    this.getTenantDetail();
  }

  getTenantDetail() {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getTenantDetail().subscribe(
        result => {
          let resultData: any = result;
          this.tenant = resultData.data;
          let providerObj = this.tenant.paymentKeys.find(item => {
            return item.provider == AppConstants.PAYMENT_TYPE.RAZORPAY_PAYMENT.toLowerCase();
          });
          if (providerObj != null && providerObj != undefined) {
            this.tenantKeys = providerObj;
            this.bindFormData();
          }
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        }
      )
    );
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      apiKey: [this.tenantKeys.apiKey],
      secretKey: [this.tenantKeys.secretKey],
      testApiKey: [this.tenantKeys.testApiKey],
      testSecretKey: [this.tenantKeys.testSecretKey],
      isTestMode: [this.tenantKeys.isTestMode]
    });
  }

  validateTestMode() {
    this.isShowError = "";
  }

  backToPaymentComponent(): void {
    this._router.navigate(["/settings/integrations"]);
    this.payment.emit(null);
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    payload.isProviderIntegrated = this.isProviderIntegrated;
    if (payload.apiKey == "" && payload.secretKey == "") {
      if (payload.testApiKey != "" && payload.testSecretKey != "") {
        if (payload.isTestMode == "") {
          this.isShowError = "Test mode should be on";
          return;
        }
      }
    }

    this.updatePayload(payload);
  }

  public openDialog(): void {
    const dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      data:{'dialogTitle': 'Disconnect Razorpay','content': 'Would you really like to disconnect Razorpay ?','isErrorDlg': false}
    });
    dialogRef.afterClosed().subscribe((result) =>{
      if(result == true){
        this.isProviderIntegrated = false;
        this.onSubmit();
      }
    })
  }
  
  public enableProvider(){
    this.isProviderIntegrated = true;
    this.onSubmit()
  }

  updatePayload(payload) {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.updateRazorpayProvider(payload).subscribe(
        result => {
          this.tenant = result;
          this.SpinnerService.hide();
          this.toastr.success("Successfully updated Settings.");
          this.backToPaymentComponent();
          // this._router.navigate(["/settings/integrations/paymentprovider"]);
          this.payment.emit(null);
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error(error);
        }
      )
    );
  }
}
