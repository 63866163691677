<mat-toolbar class="p-0 mat-elevation-z1" [ngClass]="{ 'student-nav': isStudentfRole || isStudentPreview }">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="{{_imagePrefixPath+_tenantConfig.org.logo}}" />
        </div>
      </div>

      <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <!-- <button
        *ngIf="!isStudentfRole"
        (click)="showCreateNoticeDialog()"
        mat-button
        fxHide
        fxShow.gt-xs
        class="language-button"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <span class="iso">Noticeboard ({{ notices?.length }})</span>
        </div>
      </button> 

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
      -->

      <button mat-button [matMenuTriggerFor]="franchiesMenu" class="user-button" *ngIf="isStaffRole && franchiseData && franchiseData.length > 0">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <span class="username mr-12" fxHide fxShow.gt-sm>Switch franchise</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <div *ngIf="isStaffRole && franchiseData && franchiseData.length > 0" class="toolbar-separator"></div>
      <mat-menu #franchiesMenu="matMenu" [overlapTrigger]="false">
        <button *ngFor="let franchise of franchiseData" mat-menu-item (click)="showFranchise(franchise)">
          <!-- <mat-icon>preview</mat-icon> -->
          <span>{{ franchise.orgId | titlecase }} ({{ franchise.tenantId }})</span>
        </button>
      </mat-menu>
      <button *ngIf="isShowMasterTenantMode" mat-button class="user-button" (click)="backToAdminMode()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <mat-icon class="s-16" fxHide.xs>keyboard_backspace</mat-icon>

          <span class="username mr-12" fxHide fxShow.gt-sm>Master View</span>
        </div>
      </button>

      <div *ngIf="isStaffRole" class="toolbar-separator" fxHide fxShow.gt-xs></div>

<!--      <a *ngIf="isStudentfRole" class="nav-link" (click)="myDonations()" style="cursor: pointer; font-size: 14px"-->
<!--        ><img _ngcontent-vhn-c487="" src="https://passets.wajooba.com/img/DonationIcon_02.png" style="width: 17px; height: 17px; margin-top: 7px; margin-right: 5px" /> Donate</a-->
<!--      >-->
<!--      <div *ngIf="!isStudentfRole" class="toolbar-separator" style="margin-left: 15px" fxHide fxShow.gt-xs></div>-->

      <button *ngIf="!isStudentPreview && !isStudentfRole" mat-button [matMenuTriggerFor]="previewMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <span class="username mr-12" fxHide fxShow.gt-sm>Preview</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <div class="toolbar-separator" *ngIf="isStaffRole"></div>
      <mat-menu #previewMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngIf="isStaffRole" (click)="webPreview()">
          <!-- <mat-icon>preview</mat-icon> -->
          <span>Web Preview</span>
        </button>

        <button *ngIf="isStaffRole"   mat-menu-item class="" (click)="showDialog()">
          <!-- <mat-icon>preview</mat-icon> -->
          <span>Student Preview</span>
        </button>
      </mat-menu>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="navigateToProfile()">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>

<!--        <button *ngIf="!isStudentfRole" mat-menu-item (click)="navigateToUserSettings()">-->
<!--          <mat-icon>settings</mat-icon>-->
<!--          <span>Settings</span>-->
<!--        </button>-->

        <button mat-menu-item class="" (click)="logoutUser()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <button *ngIf="isStudentPreview" mat-button class="user-button" (click)="backToAdminView()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <mat-icon class="s-16" fxHide.xs>keyboard_backspace</mat-icon>

          <span class="username mr-12" fxHide fxShow.gt-sm>Admin View</span>
        </div>
      </button>
      <div *ngIf="isStudentPreview" class="toolbar-separator"></div>

      <!-- <div class="toolbar-separator"></div>

      <fuse-search-bar (input)="search($event)"></fuse-search-bar>

      <div class="toolbar-separator"></div> -->

      <!-- <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/' + selectedLanguage.flag + '.png'" />
          <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
        </div>
      </button> -->
      <!-- 
      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->

      <!-- <button
        mat-icon-button
        class="quick-panel-toggle-button"
        (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel"
      >
        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
      </button> -->

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

<!--      <button *ngIf="!isStudentfRole" mat-icon-button (click)="navigateToUserSettings()">-->
<!--        <mat-icon matTooltip="Settings" aria-label="Settings" style="font-size: 22px; margin-right: 5px">settings</mat-icon>-->
<!--      </button>-->
      <!-- <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
           <span class="username mr-12" fxHide fxShow.gt-sm>{{ user.name }}</span>
          <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
        </div>
      </button> -->
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<!-- <div fxLayout="column" fxLayoutAlign="center center" class="mt-5">
  <div class="see-more" style="padding:2px 15px 2px 15px" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
    <a (click)="showFeedBack()" style="cursor: pointer;float: left;">
      <span *ngIf="isStudentfRole">Send Us a Feedback</span>
      <span *ngIf="!isStudentfRole">All Feedbacks</span>
    </a>
    <a (click)="showAllQuotes()" *ngIf="notices.length > 0" style="cursor: pointer;float: right;"
      >Active Notices ({{ notices.length }})</a
    >
  </div>
</div> -->
<!-- <div *ngIf="isShowAllNotes" fxLayout="column" fxLayoutAlign="center center" class="mt-5">
  <div *ngFor="let notice of notices; let i = index" class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
    <div class="notice">
      <mat-icon>notifications</mat-icon>
      <p [innerHTML]="notice.message"></p>
    </div>
  </div> -->
<!-- <div fxLayout="row" fxLayoutAlign="end end" class="see-more" *ngIf="notices.length > 2">
    <span *ngIf="!showMoreNotices" (click)="showMoreNotices = true">More Notices</span>
    <span *ngIf="showMoreNotices" (click)="showMoreNotices = false">Less Notices</span>
  </div> -->
<!-- </div> -->
<!-- <div *ngIf="isShowAllNotes" fxLayout="column" fxLayoutAlign="center center" class="mt-5">
  <div *ngFor="let quote of quotes; let i = index" class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
    <div class="notice">
      <p [innerHTML]="quote.message"></p>
    </div>
  </div> -->
<!-- <div fxLayout="row" fxLayoutAlign="end end" class="see-more" *ngIf="quotes.length > 1">
    <span *ngIf="!showMoreQuotes" (click)="showMoreQuotes = true">More Quotes</span>
    <span *ngIf="showMoreQuotes" (click)="showMoreQuotes = false">Less Quotes</span>
  </div> -->
<!-- </div> -->
