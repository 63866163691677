<div id="academy-courses" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex; align-items: center; justify-content: center">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> account_balance_wallet </mat-icon>
      <span class="logo-text h1"> Upgrade Plan </span>
      <div style="font-size: 14px; margin-left: 10px; margin-top: 3px">Your current plan, you may upgrade your plan.</div>
    </div>
    <div style="float: right">
      <button *ngIf="isPlanSelected" mat-icon-button (click)="backToPlan()" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button *ngIf="!isPlanSelected" mat-icon-button [routerLink]="['/settings/plan']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex.gt-xs="50">
    <div class="mb-24 membershipDetails" fxLayout="row" fxFlex="100">
      <div id="plan" class="course" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100" style="margin: 5px; margin-right: 0px">
        <div *ngIf="isPlanSelected">
          <!-- <h2 style="margin: 5px;margin-left: 0px;font-weight: 600">
            Plan - {{ currentPlanName | titlecase }} - {{ _tenantConfig.csymbol }}{{ currentPlan.price }}
            {{ currentPlan.period | titlecase }}
          </h2> -->
          <!--    Payment Information-->
          <div class="payment" style="margin: 3rem; width: 100%">
            <div class="paymentItem" style="margin-top: 0rem">
              <mat-card class="example-card">
                <mat-card-header style="border-bottom: 1px solid #cccccc">
                  <mat-card-title> Plan - {{ currentPlanName | titlecase }} - {{ _tenantConfig.csymbol }}{{ currentPlan.price }} {{ currentPlan.period | titlecase }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <mat-tab-group *ngIf="paymentProvider == stripeType" animationDuration="0ms" mat-stretch-tabs (selectedTabChange)="onStripeTabChanged($event)">
                    <mat-tab>
                      <ng-template mat-tab-label style="float: left">
                        <mat-icon class="example-tab-icon" style="margin-right: 6px">credit_card</mat-icon>
                        Card
                      </ng-template>
                      <div fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 10px">
                        <div class="course" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="100" [@animate]="{ value: '*', params: { y: '100%' } }" style="margin-right: 14px">
                          <div class="payment-card" style="padding-bottom: 1rem; margin-left: 4rem; margin-right: 5rem">
                            <div class="payment-card-50 cart-items-header" style="padding-left: 0px">
                              <img class="card-img" src="https://passets.wajooba.com/img/SSL_01.png" />
                            </div>
                            <div class="payment-card-50 cart-items-header" style="display: flex; justify-content: flex-end">
                              <img class="card-img" src="https://passets.wajooba.com/img/visa.png" />
                              <img class="card-img" src="https://passets.wajooba.com/img/mastercard.png" />
                              <img class="card-img" src="https://passets.wajooba.com/img/discover.png" />
                              <img class="card-img" src="https://passets.wajooba.com/img/american.png" />
                            </div>
                          </div>

                          <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout" style="margin-left: 5rem; margin-right: 5rem">
                            <div class="form-row">
                              <div id="card-info" #cardInfo style="background-color: white; border-radius: 4px; padding: 10px; border: 1px solid #b5b5b5"></div>
                              <div id="card-errors" role="alert" *ngIf="error" style="color: #fa755a">{{ error }}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                              <div class="course" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100" [@animate]="{ value: '*', params: { y: '100%' } }" style="padding-top: 5px">
                                <button type="submit" hidden id="stripe"></button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>

                  <mat-tab-group *ngIf="paymentProvider == paymentspringType" animationDuration="0ms" mat-stretch-tabs (selectedTabChange)="onTabChanged($event)">
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon" style="margin-right: 6px">credit_card</mat-icon>
                        Card
                      </ng-template>
                      <form [formGroup]="cardForm">
                        <div style="margin-top: 30px">
                          <div>
                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-50 cart-items-header">
                                <img class="card-img" src="https://passets.wajooba.com/img/SSL_01.png" />
                              </div>
                              <div class="payment-card-50 cart-items-header" style="display: flex; justify-content: flex-end">
                                <img class="card-img" src="https://passets.wajooba.com/img/visa.png" />
                                <img class="card-img" src="https://passets.wajooba.com/img/mastercard.png" />
                                <img class="card-img" src="https://passets.wajooba.com/img/discover.png" />
                                <img class="card-img" src="https://passets.wajooba.com/img/american.png" />
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-100 cart-items-header" *ngIf="availableCards.length > 0">
                                <mat-radio-group [(ngModel)]="activeCard" [ngModelOptions]="{ standalone: true }" aria-labelledby="example-radio-group-label" class="example-radio-group">
                                  <mat-radio-button *ngFor="let card of availableCards" [value]="card.methodId" class="mb-12 w-100-p">
                                    <div style="display: inline-flex; align-items: center">
                                      <img *ngIf="card.prefCard.split(' ')[0] === 'visa' && card.methodId !== 'other'" class="card-img" src="https://passets.wajooba.com/img/visa.png" />
                                      <img *ngIf="card.prefCard.split(' ')[0] === 'mastercard' && card.methodId !== 'other'" class="card-img" src="https://passets.wajooba.com/img/mastercard.png" />
                                      <img *ngIf="card.prefCard.split(' ')[0] === 'discover' && card.methodId !== 'other'" class="card-img" src="https://passets.wajooba.com/img/discover.png" />
                                      <img *ngIf="card.prefCard.split(' ')[0] === 'american' && card.methodId !== 'other'" class="card-img" src="https://passets.wajooba.com/img/american.png" />
                                      <span *ngIf="card.methodId !== 'other'" style="margin: 1rem" class="card_number">{{ card.prefCard | titlecase }}</span>
                                      <span *ngIf="card.methodId !== 'other'" style="text-align: right; width: 40%"
                                        ><a class="nav-link" style="cursor: pointer" (click)="removeCard(card)">Remove Card</a></span
                                      >
                                      <span style="margin-left: 6px" *ngIf="card.methodId === 'other'">Other</span>
                                    </div>
                                  </mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-100">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Card Owner Name</mat-label>
                                  <input [readonly]="activeCard !== 'other'" formControlName="cardOwnerName" matInput required />
                                  <mat-icon class="secondary-text" matSuffix>account_circle</mat-icon>
                                  <mat-error>Name is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-100">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Card Number</mat-label>
                                  <input [readonly]="activeCard !== 'other'" [cleave]="creditCardOptions.creditCard" formControlName="cardNumber" matInput required />
                                  <mat-icon class="secondary-text" matSuffix>credit_card</mat-icon>
                                  <mat-error>Number is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-30">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Month</mat-label>
                                  <mat-select [disabled]="activeCard !== 'other'" required formControlName="expMonth">
                                    <mat-option *ngFor="let month of months" [value]="month.key">
                                      {{ month.value }}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error>Month is required!</mat-error>
                                </mat-form-field>
                              </div>

                              <div class="payment-card-30">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Year</mat-label>
                                  <mat-select [disabled]="activeCard !== 'other'" required formControlName="expYear">
                                    <mat-option *ngFor="let year of years" [value]="year.key">
                                      {{ year.value }}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error>Year is required!</mat-error>
                                </mat-form-field>
                              </div>

                              <div class="payment-card-30">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%">
                                  <mat-label>CVV</mat-label>
                                  <input type="password" [readonly]="activeCard !== 'other'" minlength="3" formControlName="cvv" matInput required />
                                  <mat-error>CVV is required!</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="payment-card-30">
                                <mat-form-field [class.decrease-opacity]="activeCard !== 'other'" appearance="outline" fxFlex="100" style="width: 100%">
                                  <mat-label>ZIP</mat-label>
                                  <input [readonly]="activeCard !== 'other'" formControlName="zip" matInput />
                                  <mat-error>ZIP is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-100">for subscrition payments customer credit card is saved on file</div>
                              <div class="payment-card-100">
                                <mat-checkbox class="example-margin" [disabled]="activeCard !== 'other'" formControlName="storeCard">Save for my next payment</mat-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="submit" hidden id="cardPayment"></button>
                      </form>
                    </mat-tab>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon" style="margin-right: 6px">account_balance</mat-icon>
                        Banking Draft
                      </ng-template>
                      <form [formGroup]="bankForm">
                        <div style="margin-top: 30px">
                          <div class="pb-12">
                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-50 cart-items-header">
                                <img class="card-img" src="https://passets.wajooba.com/img/SSL_01.png" />
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-50">
                                <mat-form-field appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>First Name</mat-label>
                                  <input formControlName="bankFirstName" matInput required />
                                  <mat-icon class="secondary-text" matSuffix>account_circle</mat-icon>
                                  <mat-error>First Name is required!</mat-error>
                                </mat-form-field>
                              </div>

                              <div class="payment-card-50">
                                <mat-form-field appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Last Name</mat-label>
                                  <input formControlName="bankLastName" matInput required />
                                  <mat-icon class="secondary-text" matSuffix>account_circle</mat-icon>
                                  <mat-error>Last Name is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-100">
                                <mat-form-field appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Bank Account Number</mat-label>
                                  <input formControlName="bankAccountNumber" matInput required />
                                  <mat-icon class="secondary-text" matSuffix>credit_card</mat-icon>
                                  <mat-error>Bank Account Number is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="payment-card" style="padding-bottom: 1rem; margin-left: 5rem; margin-right: 5rem">
                              <div class="payment-card-50">
                                <mat-form-field appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Bank Routing Number</mat-label>
                                  <input formControlName="bankRoutingNumber" matInput required />
                                  <mat-error>Bank Routing Number is required!</mat-error>
                                </mat-form-field>
                              </div>

                              <div class="payment-card-50">
                                <mat-form-field appearance="outline" fxFlex="100" style="width: 100%; margin-bottom: -3rem">
                                  <mat-label>Type</mat-label>
                                  <mat-select name="bankAccountType" formControlName="bankAccountType" requird>
                                    <mat-option *ngFor="let type of types" [value]="type.key">
                                      {{ type.value }}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error>Bank Account Type is required!</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="submit" hidden id="bankPayment"></button>
                      </form>
                    </mat-tab>
                  </mat-tab-group>
                </mat-card-content>
                <mat-card-actions>
                  <div class="phone ng-star-inserted" style="display: flex; width: 100%; padding: 2rem; padding-left: 0; padding-top: 0px">
                    <button
                      *ngIf="!isLoadingCheckout"
                      class="mat-focus-indicator mat-raised-button mat-button-base mat-primary"
                      color="primary"
                      mat-raised-button
                      type="submit"
                      style="width: 90%; margin-left: 5rem"
                      mat-raised-button
                      (click)="completeOrder()"
                    >
                      Complete Order
                    </button>

                    <button
                      *ngIf="isLoadingCheckout"
                      class="mat-focus-indicator mat-raised-button mat-button-base mat-primary"
                      color="primary"
                      mat-raised-button
                      type="button"
                      style="width: 90%; margin-left: 5rem"
                      mat-raised-button
                    >
                      Complete Order
                      <mat-icon>
                        <mat-spinner diameter="20"> </mat-spinner>
                      </mat-icon>
                    </button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <div *ngIf="!isPlanSelected">
          <h2 style="margin: 5px; margin-left: 0px; font-weight: 600">Plan - {{ subscription.planName | titlecase }}</h2>
          <section>
            <h2>
              <div class="type-bill" id="priceToggle" style="display: flex">
                <p
                  id="monthly"
                  style="padding-right: 10px; padding-top: 5px; font-weight: 600; margin: 0px; font-size: 20px"
                  [ngStyle]="{ color: isPlanTypeYearly ? 'rgba(0, 0, 0, 0.5)' : 'rgb(0, 0, 0)' }"
                >
                  Monthly
                </p>
                <label class="switch text-center">
                  <input id="check-bill" type="checkbox" (change)="planTypeChange()" [(ngModel)]="isPlanTypeYearly" />
                  <span class="slider round"></span>
                </label>
                <p
                  id="yearly"
                  style="padding-left: 10px; padding-top: 5px; font-weight: 600; margin: 0px; font-size: 20px"
                  [ngStyle]="{ color: isPlanTypeYearly ? 'rgba(0, 0, 0)' : 'rgb(0, 0, 0, 0.5)' }"
                >
                  Yearly
                </p>
              </div>
            </h2>
            <div class="pricing pricing-palden" *ngIf="allAvailablePlan.length > 0">
              <div class="pricing-item features-item ja-animate" data-animation="move-from-bottom" data-delay="item-0" style="min-height: 497px">
                <div class="pricing-deco">
                  <svg
                    class="pricing-deco-img"
                    enable-background="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xml:space="preserve"
                    y="0px"
                  >
                    <path
                      class="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    ></path>
                    <path
                      class="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                  <div class="pricing-price">
                    <span class="pricing-currency" *ngIf="_tenantConfig">{{ _tenantConfig.csymbol }}</span
                    >{{ allAvailablePlan[1].price }}
                    <span class="pricing-period" *ngIf="!isPlanTypeYearly">/ mo</span>
                    <span class="pricing-period" *ngIf="isPlanTypeYearly">/ year</span>
                  </div>
                  <h3 class="pricing-title">
                    <mat-icon *ngIf="allAvailablePlan[1].isCurrentPlan" style="color: green; font-size: 17px">check_circle_outline</mat-icon>
                    <span style="font-size: 17px">
                      {{ allAvailablePlan[1].name }}
                    </span>
                  </h3>
                </div>
                <ul class="pricing-feature-list">
                  <li class="pricing-feature">Basic Features +</li>
                  <li class="pricing-feature">100 Active Members</li>
                  <li class="pricing-feature">2000 Contacts</li>
                </ul>
                <button class="pricing-action" (click)="changePlan(allAvailablePlan[1])">Choose plan</button>
              </div>
              <div class="pricing-item features-item ja-animate pricing__item--featured" data-animation="move-from-bottom" data-delay="item-1" style="min-height: 497px">
                <div class="pricing-deco" style="background: linear-gradient(135deg, #a93bfe, #584efd)">
                  <svg
                    class="pricing-deco-img"
                    enable-background="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xml:space="preserve"
                    y="0px"
                  >
                    <path
                      class="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    ></path>
                    <path
                      class="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                  <div class="pricing-price">
                    <span class="pricing-currency" *ngIf="_tenantConfig">{{ _tenantConfig.csymbol }}</span
                    >{{ allAvailablePlan[2].price }}
                    <span class="pricing-period" *ngIf="!isPlanTypeYearly">/ mo</span>
                    <span class="pricing-period" *ngIf="isPlanTypeYearly">/ year</span>
                  </div>
                  <h3 class="pricing-title">
                    <mat-icon *ngIf="allAvailablePlan[2].isCurrentPlan" style="color: green; font-size: 17px">check_circle_outline</mat-icon>
                    <span style="font-size: 17px">
                      {{ allAvailablePlan[2].name }}
                    </span>
                  </h3>
                </div>
                <ul class="pricing-feature-list">
                  <li class="pricing-feature">Value Features +</li>
                  <li class="pricing-feature">250 Active Members</li>
                  <li class="pricing-feature">10K Contacts</li>
                  <li class="pricing-feature">Mobile App</li>
                  <li class="pricing-feature">Custom Domains</li>
                </ul>
                <button class="pricing-action" (click)="changePlan(allAvailablePlan[2])">Choose plan</button>
              </div>
              <div class="pricing-item features-item ja-animate" data-animation="move-from-bottom" data-delay="item-2" style="min-height: 497px">
                <div class="pricing-deco">
                  <svg
                    class="pricing-deco-img"
                    enable-background="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xml:space="preserve"
                    y="0px"
                  >
                    <path
                      class="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    ></path>
                    <path
                      class="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    ></path>
                    <path
                      class="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                  <div class="pricing-price">
                    <span class="pricing-currency" *ngIf="_tenantConfig">{{ _tenantConfig.csymbol }}</span
                    >{{ allAvailablePlan[3].price }}
                    <span class="pricing-period" *ngIf="!isPlanTypeYearly">/ mo</span>
                    <span class="pricing-period" *ngIf="isPlanTypeYearly">/ year</span>
                  </div>
                  <h3 class="pricing-title">
                    <mat-icon *ngIf="allAvailablePlan[3].isCurrentPlan" style="color: green; font-size: 17px">check_circle_outline</mat-icon>
                    <span style="font-size: 17px">
                      {{ allAvailablePlan[3].name }}
                    </span>
                  </h3>
                </div>
                <ul class="pricing-feature-list">
                  <li class="pricing-feature">Value Features +</li>
                  <li class="pricing-feature">Unlimited Members</li>
                  <li class="pricing-feature">50K Contacts</li>
                  <li class="pricing-feature">Custom Mobile App (IOS & Android)</li>
                  <li class="pricing-feature">Custom Domains</li>
                </ul>
                <button class="pricing-action" (click)="contactus()">Choose plan</button>
              </div>
            </div>
          </section>

          <h2 style="margin: 5px; margin-left: 0px; font-weight: 600">
            <a style="text-transform: initial" href="https://wajooba.com/pricing/" target="_blank">Click here for plan details</a>
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
