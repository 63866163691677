import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { fuseAnimations } from '@fuse/animations';


/// This is a boolean switch component an example can be found in user settings screen
/// It returns true if it is checked and false if it not.
@Component({
  selector: 'app-app-switch-card',
  templateUrl: './app-switch-card.component.html',
  styleUrls: ['./app-switch-card.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AppSwitchCardComponent implements OnInit {
  color: ThemePalette = 'accent';
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() value: any;
  slideToggle = new FormControl();
  @Output() getChildValue = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.slideToggle.setValue(this.value);
    this.slideToggle.valueChanges.subscribe(res => {
      this.getChildValue.emit(this.slideToggle.value);
    });
  }

}
