import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { FileObject } from "app/main/shared/components/file-upload/file-upload.component";
import { SubSink } from "subsink";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "app/main/admin/products/services/product.service";
import { UnlayerService } from "app/main/shared/services/unlayer.service";

@Component({
  selector: "external-website-add-template-dlg",
  templateUrl: "./external-website-add-template-dlg.component.html",
  styleUrls: ["./external-website-add-template-dlg.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ExternalWebsiteAddTemplateDlgComponent implements OnInit {
  templateImageUrl: string;
  templateForm: FormGroup;
  isLoading: boolean = false;
  templateImage: { id: number; url: string }[] = [];
  templateImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();
  orgId: string = "master";

  subs = new SubSink();
  /**
   * Constructor
   *
   * @param {MatDialogRef<ExternalWebsiteAddTemplateDlgComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<ExternalWebsiteAddTemplateDlgComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _productsService: ProductService,
    public unlayerService: UnlayerService
  ) {
    this.orgId = _data.orgId;
    this.templateForm = this.createTemplateForm();
    this.subs.add(
      this.templateImage$.subscribe(res => {
        this.templateImage = res;
        let list = this.templateImage[0].url.split("/");
        let length = list.length;
        // this.templateImageUrl = `${list[length - 2]}/${this.orgId}/${list[length - 1]}`;
        this.templateImageUrl = `${list[length - 2]}/${list[length - 1]}`;
        this.templateForm.get("thumbnail").setValue(this.templateImageUrl);
      })
    );
  }

  ngOnInit() {}

  /**
   * Create Template form
   *
   * @returns {FormGroup}
   */
  createTemplateForm(): FormGroup {
    return this._formBuilder.group({
      name: [""],
      templateImage: [this.templateImage],
      pageType: [this._data.pageType],
      thumbnail: [this.templateImageUrl],
      unhtml: [this._data.unhtml],
      unjson: [this._data.unjson]
    });
  }

  submit(response) {
    let self = this.matDialogRef;
    let formObject = this.templateForm;
    this.isLoading = true;
    let payload = {
      ...response.getRawValue()
    };
    this.matDialogRef.close(payload);
    // this.subs.add(
    //   this.unlayerService.addUntemplate(payload).subscribe((result: any) => {
    //     this.matDialogRef.close(true);
    //     console.log("addUntemplate---");
    //   })
    // );
    // this.subs.add(
    //   this._productsService.addUntemplate(payload).subscribe(
    //     (result: any) => {
    //       //this.toastr.success("Added Template successfully");
    //       // setTimeout(() => {
    //       //   self.close(formObject);
    //       // }, 100);
    //       this.isLoading = false;
    //       self.close(true);
    //       console.log("addUntemplate---");
    //     },
    //     error => {
    //       this.isLoading = false;
    //       self.close(true);
    //       this.toastr.error(error.message);
    //     }
    //   )
    // );
  }
}
