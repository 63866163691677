<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div class="container">
  <!-- HEADER -->
  <div fxLayout="row" fxLayoutGap="0.5rem" class="header">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="1rem">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> dvr </mat-icon>
      <span class="logo-text h1">
        <span>Templates</span>
      </span>
    </div>
    <button class="mat-elevation-z2" mat-mini-fab [routerLink]="['/settings/templates/add']">
      <mat-icon aria-hidden="true" data-mat-icon-type="font">add</mat-icon>
    </button>
  </div>
  <!-- HEADER END -->
  <!-- CONTENT -->
  <div class="table-container" *ngIf="dataSource?.data?.length > 0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" color="accent">
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef>Template Name</th>
        <td mat-cell class="m-card-title" *matCellDef="let element">
          <a (click)="gotoEditScreen(element._id)">{{ element.name }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Date Created</th>
        <td mat-cell class="m-card-sub-title" *matCellDef="let element" class="m-card-sub-title">{{ element.createdAt | date : 'mediumDate' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="template-item-row"></tr>
    </table>
    <mat-paginator #paginator [length]="pageLength" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" aria-label="Select page">
    </mat-paginator>
  </div>
  <div class="fallback-message p-16" *ngIf="!isLoading && dataSource?.data?.length == 0">❗No templates found</div>
  <!-- CONTENT END -->
</div>
