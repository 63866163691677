import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import {WebsiteBuilderSidebarComponent} from "./website-builder-sidebar.component";
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [WebsiteBuilderSidebarComponent],
  imports: [RouterModule, MatDividerModule, MatListModule, CommonModule, MatIconModule, DragDropModule],
  exports: [WebsiteBuilderSidebarComponent]
})
export class WebsiteBuilderSidebarModule {}
