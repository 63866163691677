import { Component, Input, OnInit } from "@angular/core";
import { SidebarService } from "./app.sidebar.service";
@Component({
  selector: "fuse-app-sidebar",
  templateUrl: "./app-sidebar.component.html",
  styleUrls: ["./app-sidebar.component.scss"]
})
export class AppSidebarComponent implements OnInit {
  @Input() type: any;
  @Input() sidebarList: any;
  @Input() title: any;
  @Input() selectedGuId: any;
  /**
   * Constructor
   */

  sidebarData: any = [];
  sidebarType: string;
  item: any = null;
  guId: any;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit() {
    this.sidebarData = this.sidebarList;
    this.sidebarType = this.type;
    this.guId = this.selectedGuId;
  }

  showDetailView(item) {
    this.item = item;
    this.item.sidebarType = this.sidebarType;
    this.guId = item.guId;
    this.sidebarService.setSidebarItem(this.item);
  }
}
