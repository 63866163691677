<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div *ngIf="!isLoading" id="user" class="fullwidth page-layout simple inner-sidebar inner-scroll right-sidebar">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">
    <!-- HEADER -->
    <div class="new-header">
      <div style="float: left; display: flex;  align-items: center; justify-content: center;">
        <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
          supervised_user_circle
        </mat-icon>
        <span class="logo-text h1">
          Roles Setting
        </span>
      </div>
      <div style="float: right;">
        <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>

    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content">
      <!-- / SIDEBAR -->
      <!-- CONTENT -->
      <div *ngIf="!isLoading" class="p-24" fxLayout="row wrap" fxLayoutAlign="center stretch" fxFlex>
        <div class="mb-24" style="width: 100%;">
          <form
            class="mat-card mat-elevation-z4 p-24 "
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="100"
            name="form"
            [formGroup]="form"
            (ngSubmit)="updateSettings()"
          >
            <div class="h1">Roles</div>
            <div class="h4">
              Control User Roles, Functionality/Screens will only be allowed based on permissions given.
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="column" fxLayoutAlign="start">
              <div
                class="courses"
                fxLayout="row wrap"
                fxLayoutAlign="space-around start"
                fxFlex="100"
                style="width: 100%;"
              >
                <div
                  class="role"
                  fxFlex="100"
                  fxFlex.gt-xs="50"
                  fxFlex.gt-sm="33"
                  [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                  <div class="course-content" fxLayout="column" fxFlex="1 0 auto" style="padding: 12px;">
                    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                      <div class="h1">Classes or Programs</div>
                      <div class="description">Manage Classes or Programs</div>
                    </div>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">Staff </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isAllowCreateClassesStaff"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                    <mat-divider fxLayout="row" style="display: inline-block;"></mat-divider>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">FrontDesk </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isAllowCreateClassesFrontdesk"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="role"
                  fxFlex="100"
                  fxFlex.gt-xs="50"
                  fxFlex.gt-sm="33"
                  [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                  <div class="course-content" fxLayout="column" fxFlex="1 0 auto" style="padding: 12px;">
                    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                      <div class="h1">Courses</div>
                      <div class="description">Manage Courses</div>
                    </div>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">Staff </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowCourseStaff"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                    <mat-divider fxLayout="row" style="display: inline-block;"></mat-divider>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">FrontDesk </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowCourseFrontdesk"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="role"
                  fxFlex="100"
                  fxFlex.gt-xs="50"
                  fxFlex.gt-sm="33"
                  [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                  <div class="course-content" fxLayout="column" fxFlex="1 0 auto" style="padding: 12px;">
                    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                      <div class="h1">Contacts</div>
                      <div class="description">Manage Contacts</div>
                    </div>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">Staff </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowContactsListStaff"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                    <mat-divider fxLayout="row" style="display: inline-block;"></mat-divider>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">FrontDesk </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowContactsListFrontdesk"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="courses"
                fxLayout="row wrap"
                fxLayoutAlign="start"
                fxFlex="100"
                style="width: 100%;"
                [@animateStagger]="{ value: '50' }"
              >
                <div
                  class="role"
                  fxFlex="100"
                  fxFlex.gt-xs="50"
                  fxFlex.gt-sm="33"
                  [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                  <div class="course-content" fxLayout="column" fxFlex="1 0 auto" style="padding: 12px;">
                    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
                      <div class="h1">Offers</div>
                      <div class="description">Manage Offers</div>
                    </div>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">Staff </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowOffersStaff"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                    <mat-divider fxLayout="row" style="display: inline-block;"></mat-divider>
                    <div class="header" fxLayout="row" fxLayoutAlign="center center">
                      <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%;">
                        <span style="font-weight: 600;">FrontDesk </span>
                        <mat-slide-toggle
                          style="float: right;"
                          formControlName="isShowOffersFrontdesk"
                          labelPosition="after"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end">
              <button mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>
              <button type="submit" mat-raised-button color="accent">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>
