import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoticeComponent } from "./notice.component";

import { FuseSharedModule } from "@fuse/shared.module";

import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

import { AppPipesModule } from "app/main/shared/pipes/apppipes.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import {QuillModule} from "ngx-quill";

@NgModule({
  declarations: [NoticeComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        NgxSkeletonLoaderModule,
        FuseSharedModule,
        AppPipesModule,
        MatDatepickerModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDividerModule,
        MatCheckboxModule,
        NgxSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        QuillModule
    ],
})
export class NoticeModule {}
