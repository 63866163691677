<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>

<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        share
      </mat-icon>
      <span class="logo-text h1"> Brevo </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings/integrations']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24" fxLayoutAlign="center center" >
      <form
        class="mat-card mat-elevation-z4 p-24"
        style="border-radius: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="100"
        fxFlex.gt-xs="90"
        fxFlex.gt-md="50%"
        name="form"
        [formGroup]="form"
        fxFlexFill
      >
        <div fxLayout="column" class="mb-24" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="center center"
            fxFlex="100"
            fxLayoutGap="10px"
          >
            <div
              fxFlex="35"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              style="height: 100%;"
            >
              <div style="height: 100%;">
                <div class="integration-title">
                  <img style="height: 30px" src="https://res.cloudinary.com/wajooba/image/upload/v1695184349/master/brevo-logo.png">
                </div>
              </div>
            </div>
            <div class="card-preview" fxFlex="65" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div>
                <div>
                  <div class="h3">
                    Enables to you send and recieve email notifications. Get started by registering on
                    <a href="https://www.brevo.com/" class="twilio">Brevo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider> -->
        <ng-container>
          <div class=" courseTitleClass" style="margin-bottom: 5px;">Brevo API Key*</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label> API Key</mat-label>
              <input type="tel" formControlName="sibApiKey" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>
          <div class=" courseTitleClass" style="margin-bottom: 5px;">From Name*</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>From Name</mat-label>
              <input type="tel" formControlName="fromName" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>
          <div class=" courseTitleClass">From Email*</div>
          <small style="margin: 0px 0px 15px 0px">Use email you have registered for Brevo</small>

          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>From Email</mat-label>
              <input type="tel" formControlName="fromEmail" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/settings/integrations']" class="mr-4" color="accent">
            Cancel
          </button>
          <button [disabled]="form.invalid" mat-raised-button color="accent" (click)="submit()">
            Submit
            <mat-icon *ngIf="isLoading">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  <!-- / CONTENT -->
</div>
