import { NgModule } from '@angular/core';
import { OnboardingComponent } from "./onboarding.component";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
 
 
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
 
import { FuseSharedModule } from "@fuse/shared.module";
 
import { MatInputModule } from "@angular/material/input";
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { PhoneNumberModule } from "app/main/shared/components/phone-number/phone-number.module";
import { FileuploadModule } from "app/main/shared/components/file-upload/file-upload.module";
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
 
const routes = [
  {
    path: "",
    component: OnboardingComponent,
  },
];
 
@NgModule({
  declarations: [OnboardingComponent],
  imports: [
    RouterModule.forChild(routes),
 
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
 
    FuseSharedModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    NgxMaskModule,
    PhoneNumberModule,
    FileuploadModule,
    MatStepperModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSlideToggleModule,
  ],
  exports: [OnboardingComponent],
})
export class OnboardingModule { }