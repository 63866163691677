<div *ngIf="!selectedPart" id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        share
      </mat-icon>
      <span class="logo-text h1"> Payment Providers </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/integrations']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->
  <!-- CONTENT -->
  <div
    class="content "
    style="padding: 16px;"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="100"
    fxFlex.gt-sm="50"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <div
        class="mat-card mat-elevation-z4 p-24 mr-24 "
        style="border-radius: 4px"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        fxFlexFill
      >
        <div class="h1 mb-16">Payment Provider</div>
        <mat-divider class="mb-24" fxFlex="100"></mat-divider>
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%;"
          >
            <div
              class="integration card-preview"
              fxFlex="100"
              fxFlex.gt-xs="100"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              (click)="selectedParts(1)"
            >
              <div class="fuse-card turquoise">
                <div class="integration-title">
                  <div class="h1">
                    <!-- <mat-icon *ngIf="isPspringIntegrated">grade</mat-icon> -->
                    <mat-icon *ngIf="isPspringIntegrated" style="color:green">check_circle_outline</mat-icon>
                    PaymentSpring
                  </div>
                </div>
              </div>
            </div>
            <div
              class="integration card-preview"
              fxFlex="100"
              fxFlex.gt-xs="100"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              (click)="selectedParts(2)"
            >
              <div class="fuse-card dark">
                <div class="integration-title">
                  <div class="h1">
                    <!-- <mat-icon *ngIf="isStripeIntegrated"
                      >grade</mat-icon
                    > -->
                    <mat-icon *ngIf="isStripeIntegrated" style="color:green">check_circle_outline</mat-icon>
                    Stripe
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              class="integration card-preview"
              fxFlex="100"
              fxFlex.gt-xs="100"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              (click)="selectedParts(4)"
            >
              <div class="fuse-card paytm">
                <div class="integration-title">
                  <div class="h1"> 
                    <mat-icon *ngIf="isPaytmIntegrated" style="color:green">check_circle_outline</mat-icon>
                    PayTm
                  </div>
                </div>
              </div>
            </div> -->

            <div
              class="integration card-preview"
              fxFlex="100"
              fxFlex.gt-xs="100"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              (click)="selectedParts(3)"
            >
              <div class="fuse-card purple">
                <div class="integration-title">
                  <div class="h1">
                    <mat-icon *ngIf="isRazorpayIntegrated" style="color:green">check_circle_outline</mat-icon>
                    Razorpay
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / CONTENT -->
</div>

<div *ngIf="selectedPart == 1">
  <app-payment-spring (payment)="MainPaymentComponent()"></app-payment-spring>
</div>
<app-stripe *ngIf="selectedPart == 2" (payment)="MainPaymentComponent()"></app-stripe>
<app-razorpay *ngIf="selectedPart == 3" (payment)="MainPaymentComponent()"></app-razorpay>
<app-phonepe *ngIf="selectedPart == 4" (payment)="MainPaymentComponent()"></app-phonepe>
<!-- <app-paytm *ngIf="this.selectedPart === 4" (payment)="MainPaymentComponent()"></app-paytm> -->
