import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlanComponent } from "./plan.component";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatGridListModule } from "@angular/material/grid-list";
import { LayoutModule } from "app/layout/layout.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { ConfirmationDialogModule } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.module";
import { FuseConfirmDialogModule } from "@fuse/components";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PhoneNumberModule } from "app/main/shared/components/phone-number/phone-number.module";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { PlanService } from "./plan.service";
import { UpgradeComponent } from "./upgrade/upgrade.component";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxMaskModule } from "ngx-mask";
import { NgxCleaveDirectiveModule } from "ngx-cleave-directive";
@NgModule({
  declarations: [PlanComponent, UpgradeComponent],
  imports: [
    CommonModule,
    RouterModule,
    FuseSharedModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatGridListModule,
    LayoutModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    NgxSkeletonLoaderModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FuseConfirmDialogModule,
    ConfirmationDialogModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneNumberModule,
    ClipboardModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    NgxMaskModule,
    NgxCleaveDirectiveModule
  ],
  providers: [PlanService]
})
export class PlanModule {}
