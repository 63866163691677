<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex; align-items: center; justify-content: center">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> share </mat-icon>
      <span class="logo-text h1"> MailChimp </span>
    </div>
    <div style="float: right">
      <button mat-icon-button [routerLink]="['/settings/integrations']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <form
        class="mat-card p-16 mr-24"
        style="border-radius: 4px"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        name="form"
        [formGroup]="mailChimpform"
        fxFlexFill
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%"
          >
            <div class="card-preview" fxFlex="30" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div class="fuse-card yellow">
                <div class="mailchimp-title">
                  <div class="h1">Mailchimp integration</div>
                </div>
              </div>
            </div>
            <div class="card-preview" fxFlex="70" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div style="padding: 19px 0px" class="fuse-card grey">
                <div class="mailchimp-description">
                  <div class="h3">
                    The integration enables two-way-sync between the Settings from Manage Audience Dropdown and Select
                    Audience Name and Defaults and copy the listId highlighted in red
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider>

        <!-- <div *ngIf="!isCreateMode" class="h2">
          Status - <span class="h2 text-grey">{{ data.status }}</span>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control" fxFlex="100" style="font-size: 15px"
            >Select audience and the Setting from Manage Audience Dropdown and Select Audience Name and copy the listID
            highlighted in red <a (click)="audienceId()" class="help-link">more help</a></span
          >
        </div>
        <br /> 
        <div fxLayout="row wrap" *ngIf="showAudienceGif" fxLayoutAlign="center" fxFlex="1 0 auto">
          <img fxFlex="80" src="https://passets.wajooba.com/img/hq_listId.gif" alt="mailchimp List id" />
        </div>-->

        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <div fxFlex="80" style="display: flex; justify-content: start; align-items: center">
            <div class="courseTitleClass" style="margin-bottom: -5px">Audience Name*</div>
            <!-- <span class="custom-control">Navigate to Dashboard to get the ACCOUNT SID</span> -->
          </div>
          <span
            class="custom-control"
            fxFlex="20"
            style="justify-content: flex-end; align-items: flex-end; display: flex"
          >
            <button type="button" (click)="mailchimpDialog()" mat-raised-button color="accent">List Info</button>
          </span>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Audience Name</mat-label>

            <input formControlName="mcAudienceName" matInput class="right-align" required />
            <mat-error *ngIf="mailChimpform.get('mcAudienceName').hasError('required')">
              Audience Name <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="courseTitleClass" style="margin-bottom: 5px">Audience ID*</div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Audience ID</mat-label>
            <input formControlName="mcAudienceId" matInput class="right-align" required />
            <!-- <span matSuffix>ce80e74632</span> -->
            <mat-error *ngIf="mailChimpform.get('mcAudienceId').hasError('required')">
              Audience ID is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control" fxFlex="100" style="font-size: 15px"
            >Click Profile on the right top, then on Account, and then on Extras. Click Create A Key and copy the key
            <a (click)="apiKey()" class="help-link">more help</a></span
          >
        </div> -->
        <div class="courseTitleClass" style="margin-bottom: 5px">API Key*</div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>API Key</mat-label>

            <input formControlName="mcApiKey" matInput class="right-align" required />
            <mat-error *ngIf="mailChimpform.get('mcApiKey').hasError('required')">
              API Key <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

 

        <div class="courseTitleClass" style="margin-bottom: 5px">From Name *</div>
        <div class="courseTitleClass" style="margin-bottom: 5px">
          This name will appear as the sender's name in the email.
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>From Name</mat-label>
            <input formControlName="fromName" matInput class="right-align" required />
            <!-- <span matSuffix>ce80e74632</span> -->
            <mat-error *ngIf="mailChimpform.get('fromName').hasError('required')">
              From Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="courseTitleClass" style="margin-bottom: 5px">From Email *</div>
        <div class="courseTitleClass" style="margin-bottom: 5px">
          This email must be the one registered with Mailchimp.
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Email</mat-label>
            <input formControlName="fromEmail" matInput class="right-align" required />
            <!-- <span matSuffix>ce80e74632</span> -->
            <mat-error *ngIf="mailChimpform.get('fromEmail').hasError('required')">
              From Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="tenant.mcStatus" fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <div class="courseTitleClass">
            Status - <span class="h2 text-grey" style="font-size: 17px">{{ tenant.mcStatus | titlecase }}</span>
          </div>
        </div>

        <!-- <div fxLayout="row wrap" *ngIf="showApiKey" fxLayoutAlign="center" fxFlex="1 0 auto">
          <img fxFlex="80" src="https://passets.wajooba.com/img/hq_apiKey.gif" alt="mailchimp API Key" />
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-checkbox
            [disabled]="isReSyncDisabled || isResyncStarted"
            formControlName="isReSync"
            fxFlex="100"
            style="font-size: 17px"
            >Turn on the ReSync</mat-checkbox
          >
        </div>
        <div *ngIf="!isCreateMode" fxLayout="column" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding: 12px 0px">
          <div class="h2">List Info</div>
          <div class="h3" style="font-size: 15px">Summary of the MailChimp List</div>
          <div *ngIf="mailchimpInfo && mailChimpData" fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <button type="submit" (click)="mailchimpDialog()" fxFlex="10" mat-raised-button color="accent">
              List Info
            </button>
          </div>
        </div> -->

        <!-- <div *ngIf="!isCreateMode" fxLayout="column" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding: 12px 0px">
          <div class="h2">Resync</div>
          <div class="h3" style="font-size: 15px">
            Start the sync process with Mailchimp. If the status is complete, resync is not needed.
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <button type="submit" (click)="resyncTags()" fxFlex="12" mat-raised-button color="accent">
              <mat-icon [ngStyle]="{ transform: isResyncStarted ? 'rotate(90deg)' : '' }" class="mr-8">sync</mat-icon>
              ReSync
            </button>
          </div>
        </div> -->

        <!-- <div *ngIf="!isCreateMode"> -->

        <!-- </div> -->
        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto" style="display: flex; justify-content: flex-end">
          <button mat-stroked-button [routerLink]="['/integrations']" class="mr-4" color="accent">Cancel</button>
          <button
            type="submit"
            [disabled]="!mailChimpform.valid"
            (click)="onFormSubmit()"
            mat-raised-button
            color="accent"
          >
            Submit
            <mat-icon *ngIf="isLoading">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
