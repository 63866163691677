import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { ConfirmationDialogComponent } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { AppSettingsService } from "../services/app-settings.service";
import { AddCustomFieldDlgComponent } from "./add-custom-field-dlg/add-custom-field-dlg.component";

@Component({
  selector: "app-custom-field-setting",
  templateUrl: "./custom-field-setting.component.html",
  styleUrls: ["./custom-field-setting.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class CustomFieldSettingComponent implements OnInit, OnDestroy {
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  isLoading: boolean = true;
  dialogRef: any;

  customFields = {
    text: {
      guId: "",
      name: "",
      text: "",
      placeholder: "",
      isMandatory: false
    },
    checkbox: {
      guId: "",
      name: "",
      text: "",
      placeholder: ""
    },
    radio: {
      guId: "",
      name: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      placeholder: ""
    },
    header: {
      guId: "",
      name: "",
      text: "",
      placeholder: ""
    },
    number: {
      guId: "",
      name: "",
      text: "",
      placeholder: "",
      isMandatory: false
    },
    textarea: {
      guId: "",
      name: "",
      text: "",
      placeholder: "",
      isMandatory: false
    },
    dropdown: {
      guId: "",
      name: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      placeholder: ""
    },
  };

  customFieldsData = [
    { name: "Text", data: [], type: "text" },
    { name: "Text Area", data: [], type: "textarea" },
    { name: "Number", data: [], type: "number" },
    { name: "Header", data: [], type: "header" },
    { name: "Checkbox", data: [], type: "checkbox" },
    { name: "Radio", data: [], type: "radio" },
    { name: "Dropdown", data: [], type: "dropdown" }
  ];

  subs = new SubSink();
  constructor(
    private _settingsService: AppSettingsService,
    private _formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    public _matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.getCustomSettings();
  }

  getCustomSettings() {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getCustomSettings().subscribe((result: any[]) => {
        this.customFieldsData.map(x => (x.data = result.filter(res => res.type == x.type)));
        this.isLoading = false;
        this.SpinnerService.hide();
      })
    );
  }

  addCustomField(type) {
    this.showAddDialog(type);
  }

  editCustomField(dataParam) {
    this.dialogRef = this._matDialog.open(AddCustomFieldDlgComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      height: "674px",
      width: "450px",
      data: {
        action: "edit",
        type: dataParam.type,
        customField: dataParam
      }
    });

    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this._settingsService.updateCustomField(dataParam.guId, response.getRawValue()).subscribe(result => {
            this.toastr.success("Updated custom field successfully");
            this.getCustomSettings();
          })
        );
      })
    );
  }
  showAddDialog(type): void {
    this.dialogRef = this._matDialog.open(AddCustomFieldDlgComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      height: "674px",
      width: "450px",
      data: {
        action: "new",
        type: type
      }
    });

    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this._settingsService.addCustomField(response.getRawValue()).subscribe(result => {
            this.toastr.success("Added custom field successfully");
            this.getCustomSettings();
          })
        );
      })
    );
  }

  deleteCustomField(guId) {
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      data: {
        dialogTitle: "Delete Custom Field!",
        content: "Are you sure you want to continue?"
      }
    });

    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: boolean) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this._settingsService.deleteCustomField(guId).subscribe(result => {
            this.toastr.success("Deleted custom field successfully");
            this.getCustomSettings();
          })
        );
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }
}
