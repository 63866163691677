import { Injectable, Inject, Injector } from "@angular/core";
import { PLATFORM_ID } from "@angular/core";
// import { isPlatformBrowser, isPlatformServer } from "@angular/common";

// import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: "root",
})
export class WindowRef {
  constructor(
    private injector: Injector, // 2. we need the injector to ..
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  public getHostName(): string {
    let requestUrl;
    requestUrl = this.nativeWindow.location.href;
    // if (isPlatformServer(this.platformId)) {
    //   const request = this.injector.get(REQUEST);
    //   requestUrl = request.headers["x-forwarded-host"] || request.hostname; // 3. ..retrieve the injected url
    //   console.log("server greets you via " + requestUrl);
    // } else {
    //   console.log("browser says hello from " + this.nativeWindow.location.href);
    //   requestUrl = this.nativeWindow.location.href;
    // }
    return requestUrl;
  }

  public getHostOrigin(): string {
    return this.nativeWindow.location.origin;
  }

  public getUrlInfo() {
    let href = this.getHostName();
    var match = href.match(
      /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
    );
    return (
      match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    );
  }

  public get nativeWindow(): any {
    return _window();
  }
}
