<div class="course" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
  <div class="course-content" fxLayout="column" fxFlex="1 1 auto">
    <div class="header" fxLayout="row" fxLayoutAlign="center center">
      <div  fxLayout="row" fxLayoutAlign="space-between center">
        <span style="font-weight: 600;">{{title}} </span>
        <mat-slide-toggle [color]="color" style="float: right;" [formControl]="slideToggle" labelPosition="after"></mat-slide-toggle>
      </div>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
      <div class="description">{{description}}</div>
    </div>
  </div>
</div>