<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>

<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        share
      </mat-icon>
      <span class="logo-text h1"> Twilio </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings/integrations']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <form
        class="mat-card mat-elevation-z4 p-24 mr-24"
        style="border-radius: 4px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        name="form"
        [formGroup]="form"
        fxFlexFill
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%"
          >
            <div
              class="card-preview"
              fxFlex="35"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              style="height: 100%;"
            >
              <div class="fuse-card green" style="height: 100%;">
                <div class="integration-title">
                  <div class="h1">Twilio</div>
                </div>
              </div>
            </div>
            <div class="card-preview" fxFlex="65" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div class="fuse-card grey">
                <div class="integration-description">
                  <div class="h3">
                    Enables to you send and recieve SMS messages and notifications right your phone. Get started by
                    registering a phone number on
                    <a href="https://www.twilio.com/" class="twilio">Twilio</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider>
        <ng-container *ngIf="!isSetting">
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <div fxFlex="90" style="display: flex; justify-content: start; align-items: center;">
              <div class=" courseTitleClass" style="margin-bottom: -5px;">
                Twilio Phone or Message Id*
              </div>
              <!-- <span class="custom-control">Navigate to Dashboard to get the ACCOUNT SID</span> -->
            </div>
            <span
              class="custom-control"
              fxFlex="10"
              style="justify-content: flex-end; align-items: flex-end; display: flex;"
            >
              <button
                mat-mini-fab
                (click)="showAdditionalInfo()"
                matTooltip="Additional Info"
                style="padding: 0 !important;min-width: 40px !important;"
              >
                <span class="material-icons">
                  settings
                </span>
              </button>
            </span>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Twilio Sender</mat-label>
              <input type="tel" formControlName="twilioPhone" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>

          <div class=" courseTitleClass" style="margin-bottom: 5px;">Account SID*</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Twilio Account SID Key</mat-label>
              <input formControlName="twilioSid" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>

          <div class=" courseTitleClass" style="margin-bottom: 5px;">Auth Token*</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <!-- <span class="custom-control" fxFlex="100">Navigate to Dashboard to get the AUTH Token</span> -->
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Twilio auth token</mat-label>
              <input formControlName="twilioToken" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>

          <div class=" courseTitleClass" style="margin-bottom: 5px;">Admin Phone</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Admin Phone</mat-label>
              <input type="tel" formControlName="adminPhone" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="h2" style="font-weight: normal;font-size: 18px;">Phone Number</div>

          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" *ngIf="loadPhoneCode">
            <app-phone-number fxFlex="100" (getPhoneNumber)="getPhoneNumber($event)" [phone]="phone" [heading]="true">
            </app-phone-number>
          </div> -->

          <!-- <div class=" courseTitleClass" style="margin-bottom: 5px;">Footer</div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Footer</mat-label>
              <input formControlName="footer" matInput class="right-align" />
              <mat-error></mat-error>
            </mat-form-field>
          </div> -->

          <br />
        </ng-container>
        <ng-container *ngIf="isSetting">
          <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
            <span
              class="custom-control"
              fxFlex="10"
              style="justify-content: flex-end; align-items: flex-end; display: flex;"
            >
              <button
                (click)="showMainInfo()"
                matTooltip="Main Info"
                mat-mini-fab
                style="padding: 0 !important;min-width: 40px !important;"
              >
                <span class="material-icons">
                  settings
                </span>
              </button>
            </span>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Twilio Page Size</mat-label>
              <input matInput formControlName="pageSize" class="right-align" />
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" matInput>
              <mat-label>Twilio Page Timer</mat-label>
              <input matInput formControlName="pageWaitTime" class="right-align" />
              <span matSuffix fxFlex="10">minutes</span>
            </mat-form-field>
          </div>
        </ng-container>

        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/integrations']" class="mr-4" color="accent">
            Cancel
          </button>
          <button *ngIf="!isSetting" [disabled]="form.invalid" mat-raised-button color="accent" (click)="submit()">
            Submit
            <mat-icon *ngIf="isLoading">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
          <button *ngIf="isSetting" mat-raised-button color="accent" (click)="submit()">
            Submit
            <mat-icon *ngIf="isLoading">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
