import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConnectEmailService {

  constructor(private http: HttpClient) { }
  submitEmailForConnectEmailAPI(email) {
    return this.http.get(`${environment.apiBaseUrl}/authmgr/oauth/mxname?email=${email}`);
  }
  confirmConnectEmailAPI(providerName) {
    return this.http.get(`${environment.apiBaseUrl}/authmgr/oauth/connect/${providerName}?isShareAccount=true`);
  }

}
