<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
      <span class="title dialog-title">Notices & Quotes</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content style="width: 100%; height: 100%;padding: 0px !important; margin: 0px !important;">
    <mat-tab-group>
      <mat-tab label="Notices">
        <ng-template matTabContent>
          <div style="padding: 10px;padding-top: 5px">
            <div
              *ngIf="!isStudentRole"
              fxLayout="column"
              fxLayoutAlign="end end"
              style="margin-right: 10px; margin-bottom: 5px;margin-top: 10px;"
            >
              <a (click)="manageNotices()" style="cursor: pointer;">
                Manage
              </a>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" class="mt-5">
              <ng-container *ngIf="notices.length > 0">
                <div
                  *ngFor="let notice of notices; let i = index"
                  class="w-100-p"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="notice">
                    <mat-icon>notifications</mat-icon>
                    <p [innerHTML]="notice.message"></p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="notices.length == 0">
                <h4>No Notices Found</h4>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Quotes">
        <ng-template matTabContent>
          <div style="padding: 10px;padding-top: 5px">
            <div
              *ngIf="!isStudentRole"
              fxLayout="column"
              fxLayoutAlign="end end"
              style="margin-right: 10px; margin-bottom: 5px;margin-top: 10px;"
            >
              <a (click)="manageNotices()" style="cursor: pointer;">
                Manage
              </a>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" class="mt-5">
              <ng-container *ngIf="quotes.length > 0">
                <div
                  *ngFor="let quote of quotes; let i = index"
                  class="w-100-p"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div class="notice">
                    <p [innerHTML]="quote.message"></p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="quotes.length == 0">
                <h4>No Quotes Found</h4>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float:right;margin-right: 10px;" fxLayoutAlign="end end">
    <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">cancel</button>
  </mat-dialog-actions>
</div>
