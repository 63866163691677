import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';

import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { HorizontalLayout2Module } from 'app/layout/horizontal/layout-2/layout-2.module';
import { NoUserSelectDialogComponent } from '../main/admin/store/no-user-select-dialog/no-user-select-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutBlankModule } from './vertical/blank/layout-blank.module';

@NgModule({
  imports: [VerticalLayout1Module, HorizontalLayout1Module,LayoutBlankModule, HorizontalLayout2Module, MatDialogModule, MatToolbarModule, MatIconModule, MatDividerModule],
  exports: [VerticalLayout1Module, HorizontalLayout1Module,LayoutBlankModule, HorizontalLayout2Module, NoUserSelectDialogComponent],
  declarations: [NoUserSelectDialogComponent],
})
export class LayoutModule {}
