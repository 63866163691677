<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
      <span class="title dialog-title">{{ dialogTitle }}</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <div class="content-align" style="padding-right: 2rem;">
      <form [formGroup]="contactForm">
        <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
          <mat-form-field appearance="outline" fxFlex style="width: 49%;" style="margin-right: 20px;">
            <mat-label>First Name</mat-label>
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <input name="firstName" formControlName="firstName" matInput required />
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex style="width: 50%;">
            <mat-label>Last Name</mat-label>
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <input name="lastName" formControlName="lastName" matInput required />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
          <mat-form-field appearance="outline" fxFlex style="width: 100%;">
            <mat-label>Email</mat-label>
            <mat-icon matSuffix class="secondary-text">email</mat-icon>
            <input name="email" formControlName="email" matInput required type="email" />
          </mat-form-field>
        </div>

        <mat-form-field
          *ngIf="tenant && tenant.isSupportGrn"
          appearance="outline"
          fxFlex="100"
          class="pl-4"
          style="width: 100%;"
        >
          <mat-label>GRN Number</mat-label>
          <input matInput formControlName="grnNumber" />
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <!-- <mat-error *ngIf="contactAddForm.get('grnNumber').hasError('required')">
              GRN Number is <strong>required</strong>
            </mat-error>  -->
        </mat-form-field>

        <!-- <mat-form-field
          *ngIf="tenant && tenant.isSupportGrn"
          appearance="outline"
          fxFlex="100"
          class="pl-4"
          style="width: 100%;"
        >
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" />
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
        </mat-form-field> -->

        <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
          <!-- <mat-form-field appearance="outline" fxFlex style="width: 100%;">
            <mat-label>Phone number</mat-label>
            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
            <input formControlName="phone" mask="000-000-0000" matInput />
          </mat-form-field> -->
          <app-phone-number
            fxFlex="100"
            style="width: 100%;"
            (getPhoneNumber)="getPhoneNumber($event)"
            [phone]="phone"
            [heading]="true"
          ></app-phone-number>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
          <mat-checkbox class="mt-10" formControlName="hasSignedWaiverForm" required>
            Waiver Form Signed
          </mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" style="width: 100%;">
          <mat-checkbox class="mt-20" formControlName="subscribeToMailingList" required>
            Subscribe to Mailing List
          </mat-checkbox>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float:right;margin-right: 25px;">
    <!-- <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">cancel</button> -->
    <button
      class="mat-accent m-0"
      mat-raised-button
      tabindex="1"
      (click)="submit()"
      [disabled]="contactForm.invalid"
      aria-label="Add"
    >
      Submit

      <mat-icon *ngIf="isLoading">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>
