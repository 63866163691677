<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div class="container">
  <!-- HEADER -->
  <div fxLayout="row" fxLayoutGap="0.5rem" class="header">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="1rem">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> dvr </mat-icon>
      <span class="logo-text h1">
        <span>Template Details</span>
      </span>
    </div>
    <button *ngIf="templateGuId" class="mat-elevation-z2" mat-mini-fab (click)="showDeleteDialog(templateGuId)">
      <mat-icon aria-hidden="true" data-mat-icon-type="font" style="color: #ff8845">delete</mat-icon>
    </button>
    <button class="mat-elevation-z2" mat-mini-fab [routerLink]="['/settings/templates']">
      <mat-icon aria-hidden="true" data-mat-icon-type="font">arrow_back</mat-icon>
    </button>
  </div>
  <!-- HEADER END -->
  <!-- CONTENT -->
  <div *ngIf="!isLoading" class="section-container">
    <h2>
      {{ templateGuId ? 'Edit Template' : 'Add Template' }}
    </h2>
    <div class="input-container mb-16">
      <div class="available-fields" fxLayout="row wrap" fxLayoutGap="1rem">
        <div class="custom-chip mb-8 orange" *ngFor="let field of standardFields" draggable="true" (dragstart)="onDragStart($event, field)">
          {{ field.name }}
        </div>
        <div class="custom-chip mb-8" *ngFor="let field of availableFields" draggable="true" (dragstart)="onDragStart($event, field)">
          {{ field.name }}
        </div>
        <div class="custom-chip mb-8 green" (click)="addCustomField()">&#10010;</div>
      </div>
    </div>
    <form [formGroup]="templateForm" fxLayout="column" (ngSubmit)="submit()">
      <div class="input-container">
        <h3 class="settingTitle">Name</h3>
        <mat-form-field appearance="outline">
          <mat-label>Template Name</mat-label>
          <input type="email" formControlName="name" placeholder="Template Name" matInput />
          <mat-error>Template Name is required!</mat-error>
        </mat-form-field>
        <h3 class="settingTitle">Subject</h3>
        <mat-form-field appearance="outline" class="mb-16">
          <mat-label>Subject</mat-label>
          <input type="text" formControlName="subject" placeholder="Subject" matInput />
          <mat-error>Subject is required!</mat-error>
          <mat-hint>Subject may contain tags</mat-hint>
        </mat-form-field>
        <quill-editor formControlName="template"></quill-editor>
        <button type="submit" mat-flat-button color="accent" class="submit-button mt-12">Submit</button>
      </div>
    </form>
  </div>
  <!-- CONTENT END -->
</div>
