import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FeedbackDlgService {
  constructor(private _httpClient: HttpClient) {}

  public saveFeedback(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/client/feedback`;
    return this._httpClient.post(apiUrl, payload);
  }

  public getFeedbackList(start, pageSize): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/client/feedback?offset=${pageSize}&max=${start}`;
    return this._httpClient.get(apiUrl);
  }
}
