<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div *ngIf="!isLoading" id="customfield" class="fullwidth page-layout simple inner-sidebar inner-scroll right-sidebar">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">
    <!-- HEADER -->

    <div class="new-header">
      <div style="float: left; display: flex;  align-items: center; justify-content: center;">
        <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
          text_fields
        </mat-icon>
        <span class="logo-text h1">
          Custom Fields
        </span>
      </div>
      <div style="float: right;">
        <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content">
      <!-- / SIDEBAR -->
      <!-- CONTENT -->
      <div *ngIf="!isLoading" class="p-24" fxLayout="row wrap" fxLayoutAlign="center stretch" fxFlex>
        <div class="mb-24" style="width: 100%;">
          <div fxFlex="5"></div>
          <div
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="90"
            name="form"
          >
            <div class="h1">Custom Fields</div>
            <div class="h4">Create custom fields of your contacts.</div>
            <mat-divider></mat-divider>

            <div
              fxLayout="column"
              fxLayoutAlign="center"
              fxFlex="1 0 auto"
              fxLayout.gt-xs="row wrap"
              fxLayoutAlign.gt-xs="center"
            >
              <div
                fxFlex="100"
                fxFlex.gt-xs="50"
                fxFlex.gt-sm="33"
                class="field-content"
                *ngFor="let customField of customFieldsData"
              >
                <mat-list role="list" class="mb-24" style="width: 100%; max-width:300px">
                  <mat-list-item class="header">
                    <span style="font-weight: 600;"> {{ customField.name }} </span>
                    <span style="margin-left:auto; cursor: pointer;">
                      <mat-icon color="accent" class="secondary-text" (click)="addCustomField(customField.type)"
                        >add_circle</mat-icon
                      >
                    </span>
                  </mat-list-item>
                  <mat-list-item
                    class="itemlist"
                    role="listitem"
                    *ngFor="let data of customField.data"
                    style="height: auto;"
                  >
                    <span
                      *ngIf="data.name"
                      style="font-weight: 600;color:#00C6D8;cursor: pointer;height: auto;"
                      (click)="editCustomField(data)"
                      >{{ data.name }}</span
                    >
                    <span style="margin-left:auto; cursor: pointer" (click)="deleteCustomField(data.guId)">
                      <mat-icon color="warn" class="secondary-text">cancel</mat-icon>
                    </span>
                  </mat-list-item>
                  <mat-list-item role="listitem" *ngIf="customField.data.length <= 0">
                    <span style="font-weight: 600;">Not Added</span>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>
