<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->

  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        view_module
      </mat-icon>
      <span class="logo-text h1"> Stripe </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['settings/integrations/paymentprovider']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content "
    style="padding: 16px;"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="100"
    fxFlex.gt-sm="100"
  >
    <div class="mb-24" style="width: 100%">
      <div fxFlex="5"></div>
      <form
        class="mat-card p-16 mr-24 "
        style="border-radius: 4px;"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        name="form"
        fxFlexFill
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%"
          >
            <div
              class="card-preview"
              fxFlex.gt-xs="100"
              fxFlex.gt-md="100"
              fxFlex.gt-sm="100"
              [@animate]="{ value: '*', params: { y: '100%' } }"
            >
              <div class=" black">
                <div class="mailchimp-title" *ngIf="!isLoading">
                  <div *ngIf="isConnected" class="h1" style="color: green">
                    Payment Provider - Stripe Integrated successfully
                  </div>
                  <div *ngIf="!isConnected" class="h1" style="color: red">
                    Payment Provider - Stripe Integration Fail!
                  </div>
                </div>

                <div *ngIf="isLoading" style="width: 100%;padding: 15px;background: white;">
                  <ngx-skeleton-loader
                    count="6"
                    appearance=""
                    [theme]="{
                      background: '#F5F5F5',
                      height: '30px'
                    }"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto"></div>
        <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider>
        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['settings/integrations/paymentprovider']" class="mr-4" color="accent">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
