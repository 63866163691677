import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Observable, of} from 'rxjs';
import {map, catchError, tap} from 'rxjs/operators';
import {TenantUserService} from 'app/core/services/tenantuser.service';

@Injectable({
    providedIn: 'root'
})

export class AppSearchContactService {
    constructor(private http: HttpClient, private tenantUserService: TenantUserService) {
    }

    valueSearch:any;

    contactSearch(search: any): Observable<any> {
        if (search == undefined) {
              this.valueSearch = '';
        }else{
        this.valueSearch = search;
        }
        if (typeof search !== 'object') {
            const url = `${environment.apiBaseUrl}/rest/contact/?autoComplete=true&q=${search}`;
            return this.http.get(url);
        }
    }



    getContactToken(guId: string): Observable<any> {
        const url = `${environment.apiBaseUrl}/rest/contact/${guId}/token`;
        return this.http.get(url).pipe(
            map((data: any) => {
                return data.access_token;
            })
        );
    }
}
