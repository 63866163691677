<mat-toolbar class="p-0 homepage" style="min-height: 36px;width: 1366px;margin: 0 auto;" >
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" [style.background]="tenant.org.headerColor">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" >

            <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text mobile_menu">menu</mat-icon>
            </button>


            <div class="toolbar-separator" fxHide.gt-md></div>
            
            <!-- <div fxLayout="row" fxLayoutAlign="start center">
                <div class="logo ml-16">
                    <img class="logo-icon cursor-pointer" (click)="onLogoClicked()" [src]="smallLogo" >
                </div>
            </div> -->

        </div>
        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="toolbar-separator"></div>
            <!-- <button mat-button class="user-button">
                <div fxLayout="row" style="filter: invert(1);" fxLayoutAlign="center center">
                    <a href="abd"> 
                         <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 97.75 97.75" style="enable-background:new 0 0 97.75 97.75;" xml:space="preserve">  <g>  <path d="M48.875,0C21.882,0,0,21.882,0,48.875S21.882,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.868,0,48.875,0z M67.521,24.89l-6.76,0.003c-5.301,0-6.326,2.519-6.326,6.215v8.15h12.641L67.07,52.023H54.436v32.758H41.251V52.023H30.229V39.258 h11.022v-9.414c0-10.925,6.675-16.875,16.42-16.875l9.851,0.015V24.89L67.521,24.89z"/>  </g><g> </g> <g> </g> <g> </g>  <g></g>  <g> </g> <g> </g> <g> </g> <g>  </g> <g> </g> <g> </g> <g> </g>     <g>   </g>  <g> </g>     <g>    </g>   <g> </g></svg>
                </a>
                  <a href="xyz" class="ml-8">
                    <svg height="13pt" viewBox="0 0 512 512" width="13pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm116.886719 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"/></svg>
                  </a>
             
                </div>
            </button> -->
            <div style="font-size: 13px;" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="none center">
                <!-- <span style="color: white">Contact us </span> -->
                <div ngStyle.gt-xs="line-height:2" style="line-height: 0;" *ngIf="tenant.phone">
                    <a href="tel:{{tenant.phone}}" style="text-transform: none;color: #fff;" >
                        <mat-icon style="font-size: 16px;line-height: 22px;text-align:center;vertical-align: middle;">phone</mat-icon>{{tenant.phone}}&nbsp;</a>
                </div>
                <div ngStyle.gt-xs="line-height:2" style="line-height: 0;">
                    <a style="text-transform: none;cursor: pointer;color: #fff;" href="mailto:{{tenant.email}}">
                        <mat-icon style="font-size: 16px;line-height: 22px;text-align:center;vertical-align: middle;">email</mat-icon>{{tenant.email}}</a>
                </div>
            </div>
            <button mat-button class="user-button p-0" (click)="logInUser()" >
                <div fxLayout="row" fxLayoutAlign="center center" style="border-left: 1px solid #fff;padding-left: 15px;margin-left: 15px;">
                    <!-- fxHide -->
                    <span class="login mr-12"    fxShow.gt-sm>Login</span>
                </div>
            </button>
     
        </div>
    </div>
</mat-toolbar>
