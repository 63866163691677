import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WidgetComponent } from "./widget.component";
import { WidgetService } from "./widget.service"
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { WidgetEditComponent } from './widget-edit/widget-edit.component'; 
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

 @NgModule({
  declarations: [WidgetComponent, WidgetEditComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    // TranslateModule,
    FuseSharedModule,
    MatPaginatorModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  exports: [],
  providers: [WidgetService]
})
export class WidgetModule {}
