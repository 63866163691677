import { NgModule } from "@angular/core";
import { epocToDatePipe } from "./epopToDate.pipe";
import { NameFilterPipe } from "./namefilter.pipe";
import { CategoryFilterPipe } from "./categoryfilter.pipe"
import { from } from 'rxjs';
import { SafeUrlPipe } from "./safeUrl.pipe";
@NgModule({
  declarations: [
    NameFilterPipe,
    epocToDatePipe,
    CategoryFilterPipe,
    SafeUrlPipe
  ],
  imports: [],
  exports: [
    NameFilterPipe,
    epocToDatePipe,
    CategoryFilterPipe,
    SafeUrlPipe
  ],
})
export class AppPipesModule {}
