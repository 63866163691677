import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { AppUtilsService } from "app/core/services/apputils.service";
import { AppLoadService } from "app/core/services/appload.service";

@Injectable({
  providedIn: "root"
})
export class CustomFormService {
  constructor(private http: HttpClient, private _appUtilsService: AppUtilsService, private _appLoadService: AppLoadService) {}

  getPublicCustomForm(tName, type): Observable<any> {
    let url = `${environment.apiBaseUrl}/public/customForm?tenant=${tName}&type=${type}`;
    return this.http.get(url);
  }
  getPublicCustomFormById(guId): Observable<any> {
    const tid = this._appLoadService.tenantConfig.tenantId;
    let url = `${environment.apiBaseUrl}/public/customForm/${guId}?tid=${tid}`;
    return this.http.get(url);
  }

  getRegistrationList(): Observable<any> {
    let type = "STUDENT_REGISTRATION";
    return this.http.get(`${environment.apiBaseUrl}/rest/customForm?type=${type}`);
  }
}
