<div id="academy-courses" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        business
      </mat-icon>
      <span class="logo-text h1"> Franchise </span>
      <div style="font-size: 14px; margin-left: 10px;margin-top: 3px;">
        Add or manage your franchises.
      </div>
    </div>
    <div style="float: right;">
      <button
        mat-mini-fab
        [routerLink]="['/settings/franchise/new']"
        matTooltip="Add Product "
        aria-label="Add Product"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- / HEADER -->
  <div fxLayout="row wrap" style="margin-left: 16px;margin-right: 16px;padding-top: 10px;padding-bottom: 2px;">
    <div class="course" fxFlex="100" [@animate]="{ value: '*', params: { y: '100%' } }">
      <div class="search-wrapper mx-32 bg-color-search mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="accent">search</mat-icon>
          <input [formControl]="tableSearch" placeholder="Search" style="text-align: left;" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="content "
    style="padding: 16px;padding-top: 5px;"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex.gt-xs="50"
  >
    <div fxLayout="row" fxFlex="100">
      <div
        fxLayout="column"
        fxLayoutAlign="center stretch"
        class="users-table mat-card"
        style="width: 100%;padding: 10px;border-radius: 4px;"
      >
        <table
          mat-table
          fxLayout="column"
          fxLayoutAlign="center"
          style="width: 100%;"
          fxFlex="1 1 0"
          #table
          [dataSource]="dataSource"
          matSort
          fusePerfectScrollbar
        >
          <!-- <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <p class="text-truncate" style="font-weight: 600;">{{row.name|titlecase}}</p>
                    </mat-cell>
                </ng-container> -->

          <ng-container matColumnDef="orgId">
            <mat-header-cell *matHeaderCellDef fxShow.gt-md>Org Id </mat-header-cell>
            <mat-cell *matCellDef="let row" fxShow.gt-md>
              <p class="category text-truncate">{{ row.orgId }} ({{ row.tenantId }})</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="dateCreatedStr">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>Created Date </mat-header-cell>
            <mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
              <p class="price text-truncate">
                {{ row.dateCreatedStr | date: "dd/MM/yyyy" }}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Action </mat-header-cell>
            <mat-cell *matCellDef="let row" fxShow.gt-xs>
              <p
                class="price text-truncate b-red pointer"
                (click)="$event.stopPropagation(); deleteFranchise(row.guid)"
              >
                <mat-icon>delete</mat-icon>
              </p>
            </mat-cell>
          </ng-container>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No matching records found</td>
          </tr>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="user pointer"
            matRipple
            [routerLink]="['/settings/franchise/edit', row.guid]"
          >
          </mat-row>
        </table>

        <div *ngIf="isLoading" style="width: 100%;padding: 15px;">
          <ngx-skeleton-loader
            count="6"
            appearance=""
            [theme]="{
              background: '#F5F5F5',
              height: '30px'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlex="100" class="mb-24" style="width: 100%;">
      <mat-paginator
        #paginator
        [length]="totalData"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        style="width: 100%;"
      >
      </mat-paginator>
    </div>
  </div>
  <!-- / CONTENT CARD -->
</div>
