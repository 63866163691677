import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { SubSink } from "subsink";

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AppSettingsService } from "../../../services/app-settings.service";
import { AppConstants } from "app/core/settings/appconstants";
import { fuseAnimations } from "@fuse/animations";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector:"app-phonepe",
  templateUrl:"./phonepe.component.html",
  styleUrls:["./phonepe.component.scss"],
  animations: fuseAnimations
})
export class PhonepeComponent implements OnInit{
    
  @Output() payment: EventEmitter<any> = new EventEmitter<any>();
  subs = new SubSink();
  form: FormGroup;
  tenant: any;
  tenantKeys: any;
  public isProviderIntegrated: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _settingsService: AppSettingsService,
    private _router: Router,
    private _matDialog: MatDialog,
    public toastr: ToastrService,
    private SpinnerService: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    const self = this;
    this.form = this._formBuilder.group({
        merchantId: [""],
        saltKey: [""],
        saltIndex: [""],
      });
    this.getTenantDetail();
  }
  backToPaymentComponent(): void {
    this._router.navigate(["/settings/integrations"]);
    this.payment.emit(null);
  }
  getTenantDetail() {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getTenantDetail().subscribe(
        result => {
          let resultData: any = result;
          this.tenant = resultData.data;
          let providerObj = this.tenant.paymentKeys.find(item => {
              return item.provider == AppConstants.PAYMENT_TYPE.PHONEPE_PAYMENT.toLowerCase();
            });
          if (providerObj != null && providerObj != undefined) {
            this.tenantKeys = providerObj;
            this.bindFormData();
          }
          this.SpinnerService.hide();
        },
        error => {
          this.SpinnerService.hide();
        }
      )
    );
  }

  public enableProvider(){
    this.isProviderIntegrated = true;
    this.onSubmit()
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      merchantId: [this.tenantKeys.apiKey],
      saltKey: [this.tenantKeys.secretKey],
      saltIndex: [this.tenantKeys.saltIndex],
    });
    this.isProviderIntegrated = this.tenantKeys.isProviderIntegrated;
  }
  onSubmit() {
    let payload = this.form.getRawValue();
    payload.isProviderIntegrated = this.isProviderIntegrated;
    if(this.form.valid){
      this.subs.add(
        this._settingsService.updatePhonepeProvider(payload).subscribe(
          result => {
            this.tenant = result;
            this.SpinnerService.hide();
            this.toastr.success("Successfully updated Settings.");
            this.backToPaymentComponent();
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error(error.error.errors.message);
          }
        )
      );
    }
  }
  
  public openDialog(): void {
    const dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      data:{'dialogTitle': 'Disconnect Phonepe','content': 'Would you really like to disconnect PhonePe ?','isErrorDlg': false}
    });
    dialogRef.afterClosed().subscribe((result) =>{
      if(result == true){
        this.isProviderIntegrated = false;
        this.onSubmit();
      }
    })
  }
}