import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   // translate: "NAV.CALENDAR.TITLE",
  //   type: "item",
  //   icon: "calendar_today",
  //   url: "/calendar"
  // },
  // {
  //   id: "courses",
  //   title: "Courses",
  //   // translate: "NAV.COURSES.TITLE",
  //   type: "item",
  //   icon: "category",
  //   url: "/courses",
  // }
  // {
  //   id: "donations",
  //   title: "donation",
  //   // translate: "NAV.CALENDAR.TITLE",
  //   type: "item",
  //   icon: "attach_money",
  //   url: "/donations"
  // },
];
