<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        account_circle
      </mat-icon>
      <span class="logo-text h1">
        Account Settings
      </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex="grow" fxLayout.gt-xs="row"
    fxFlex.gt-xs="100" fxFlex.gt-sm="100">
    <div class="mb-24" style="width: 100%;">
      <div fxFlex="5"></div>
      <div class="mat-elevation-z4 p-24 mr-24" style="width:100%;margin: 0 auto;">
        <form class="" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" fxFlex="90" name="form" [formGroup]="form"
          fxFlexFill (ngSubmit)="updateSettings()">
          <h4 class="settingTitle">Account Settings</h4>
          <mat-divider style="display: inline-block;" fxFlex="100" style="padding-bottom: 20px;"></mat-divider>
          <!-- <div class="h2">Organization</div> -->
          <!-- <mat-divider class="mb-24" style="display: inline-block;" fxFlex="100"></mat-divider> -->

          <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
            <label style="    font-size: 17px;
            display: flex;
            justify-content: center;
            padding-bottom: 15px;">Org Id: {{ _accountDetail.orgId }}
              <mat-icon matTooltip="Copy clipboard" aria-label="Copy clipboard" style="margin-left: 10px;
              font-size: 17px;cursor: pointer;
              margin-top: 3px;" class="secondary-text" title="Copy clipboard"
                [cdkCopyToClipboard]="_accountDetail.orgId">file_copy</mat-icon></label>
          </div>

          <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" class="pr-4" matInput>
              <mat-label>Organization Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error>Organization Name is required!</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matNativeControl>
              <mat-label>Account Owner</mat-label>
              <mat-select formControlName="accountOwner">
                <mat-option [value]="admin.id" *ngFor="let admin of adminList">
                  {{ admin.fullName }}
                </mat-option>
              </mat-select>
              <mat-error>Account Owner is required!</mat-error>
            </mat-form-field>
          </div>

          <div class="settingTitle ">Account Email & Phone</div>
          <mat-divider class="mb-24" style="display: inline-block;" fxFlex="100"></mat-divider>

         
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
              <mat-label>From Email Name</mat-label>
              <input matInput formControlName="fromEmailName" class="right-align" />
              <mat-error>Email Name is required!</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
              <mat-label>From Email Address</mat-label>
              <input matInput formControlName="fromEmail" class="right-align" />
              <mat-error>Email Address is required!</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <span class="custom-control courseSubTitleClass" fxFlex="100">Business Phone</span>
          </div>
          <app-phone-number fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" (getPhoneNumber)="getPhoneNumber($event)"
            [phone]="phone" [heading]="true">
          </app-phone-number>

          <div class="settingTitle ">Address</div>

          <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
            <mat-label>Street address </mat-label>
            <input matInput formControlName="address_line1" />
            <mat-icon matSuffix class="secondary-text">home</mat-icon>
            <!-- <mat-error> Name is required!</mat-error> -->
          </mat-form-field>
          <div fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
              <mat-label>City name </mat-label>
              <input matInput formControlName="address_city" />
              <!-- <mat-error> Name is required!</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
              <mat-label>State </mat-label>
              <input matInput formControlName="address_state" fxFlex="100" />
              <!-- <mat-error> Name is required!</mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
              <mat-label>Zip code </mat-label>
              <input matInput formControlName="address_zip" />
              <!-- <mat-error> Name is required!</mat-error> -->
            </mat-form-field>
          </div>

          <div class=" settingTitle">Application</div>
         

          <div fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matNativeControl>
              <mat-label>TimeZone</mat-label>
              <mat-select formControlName="timezone">
                <mat-option [value]="timeZone.value" *ngFor="let timeZone of timeZoneList">
                  {{ timeZone.text }}
                </mat-option>
              </mat-select>
              <mat-error>TimeZone is required!</mat-error>
            </mat-form-field>
          </div>


          <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
            <span class="custom-control courseSubTitleClass" fxFlex="100">Default expiry period when creating membership
              in months.</span>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
              <mat-label>Membership Expiry Period</mat-label>
              <input matInput formControlName="itemExpiresAfter" class="right-align" />
              <span matSuffix fxFlex="10">months</span>
              <mat-error>Membership Expiry Period is required!</mat-error>
            </mat-form-field>
          </div>

          <div class=" settingTitle">Custom Domain</div>
          <mat-divider style="display: inline-block;" fxFlex="100" class="mb-24"></mat-divider>

          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
              <mat-label>Custom Domain</mat-label>
              <input matInput formControlName="customDomain" class="right-align" placeholder="schedule.xyz.com" />
              <mat-error>Custom Domain is required!</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" matInput>
              <mat-label>API Key</mat-label>
              <input matInput formControlName="apiKey" />

              <mat-icon matSuffix class="secondary-text" (click)="generateKey()" matTooltip="Generate Key "
                aria-label="Generate Key" color="accent">refresh</mat-icon>
            </mat-form-field>
          </div>
          
          <div class=" settingTitle">About Organization</div>
          <mat-divider style="display: inline-block;" fxFlex="100" class="mb-24"></mat-divider>

          <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50" matInput>
              <input matInput formControlName="aboutOrg" class="right-align" placeholder="Add organization description"/>
              <mat-error>Organization description is required!</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
            <button mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>
            <button type="submit" mat-raised-button color="accent">
              Submit
              <mat-icon *ngIf="issubmitting">
                <mat-spinner diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- / CONTENT -->
</div>