<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<!-- startshere -->
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        view_module
      </mat-icon>
      <span class="logo-text h1"> Razorpay </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button (click)="backToPaymentComponent()" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
  >
    <div class="mb-24">
      <div fxFlex="5"></div>

      <form
        class="mat-card mat-elevation-z4 p-24 mr-24"
        style="border-radius: 4px;"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        (ngSubmit)="onSubmit()"
        fxFlex="90"
        [formGroup]="form"
        name="form"
        fxFlexFill
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div
            class="integrations"
            fxLayout="row wrap"
            fxLayoutAlign="space-around start"
            fxFlex="100"
            style="width: 100%"
          >
            <div
              class="card-preview"
              fxFlex.gt-xs="40"
              fxFlex.gt-md="30"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
            >
              <div class="fuse-card purple">
                <div class="mailchimp-title">
                  <div class="h1">Razorpay Payments</div>
                </div>
              </div>
            </div>
            <div
              class="card-preview"
              fxFlex.gt-xs="40"
              fxFlex.gt-md="70"
              fxFlex.gt-sm="50"
              [@animate]="{ value: '*', params: { y: '100%' } }"
            >
              <div class="fuse-card grey">
                <p>Accepting Payments in INR, Merchant fees from 2%</p>

                <p>Navigate to Account -> API Keys for following information</p>
              </div>
            </div>
          </div>
        </div>

        <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-checkbox [checked]="tenant?.paymentProvider == 'INSTAMOJO'" fxFlex="100"
            >Active Payment Provider</mat-checkbox
          >
        </div> -->
        <div
          class="h2"
          style="
                border-bottom: 1px solid #8080805e;
                padding-bottom: 10px;
            "
        >
          Production Mode
        </div>
        <br />

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Api Key*</mat-label>
            <input formControlName="apiKey" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Secret Key*</mat-label>
            <input formControlName="secretKey" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <!-- </div> -->

        <div class="h2" style=" border-bottom: 1px solid #8080805e; padding-bottom: 10px;  ">
          Test Mode
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-checkbox (change)="validateTestMode()" formControlName="isTestMode" fxFlex="100">
            <div class="h2">Switch To Test Mode</div>
          </mat-checkbox>
        </div>
        <div *ngIf="isShowError" class=" col-sm-12">
          <label class="col-md-2 col-xs-12"></label>
          <div class="col-md-8 col-xs-12 form-group-lg has-feedback has-error">
            <small style="margin-top: 0;margin-left: -15px;" class="help-block">{{ isShowError }} </small>
          </div>
        </div>
        <br />

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Test API Key*</mat-label>
            <input formControlName="testApiKey" matInput class="right-align" />
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Test Secret Key*</mat-label>
            <input formControlName="testSecretKey" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" [fxLayoutAlign]= "tenantKeys ? 'space-between center' : 'end center'">
          <button *ngIf="tenantKeys?.isProviderIntegrated" mat-raised-button style="background: #9C27B0; color: white" type="button" (click)="openDialog()">Disconnect</button>
          <button *ngIf="tenantKeys?.isProviderIntegrated == false" mat-raised-button style="background: #9C27B0; color: white" type="button" (click)="enableProvider()">Reconnect</button>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button (click)="backToPaymentComponent()" matTooltip="Back" aria-label="Back">Cancel</button>
            <button mat-raised-button color="accent" type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
