import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { AppConstants } from "app/core/settings/appconstants";

@Injectable({
  providedIn: "root"
})
export class InvoiceService {
  baseUrl = environment.apiBaseUrl;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private http: HttpClient) {}

  getInvoiceList(start, pageSize, isShowAll, operations) {
    let url = `${environment.apiBaseUrl}/item/ireport?offset=${start}&max=${pageSize}&showArchive=${isShowAll}`
    url += operations.sortBy ? `&sort=${operations.sortBy}` : ""
    url += operations.search ? `&search=${operations.search}` : ""
    url += operations.order ? `&order=${operations.order}` : ""
    return this.http.get(url);
  }

  getMembershipUpcomingInvoiceList(start, pageSize, isShowAll, operations) {
    let url = `${environment.apiBaseUrl}/item/membership/subs/upcoming?offset=${start}&max=${pageSize}&showArchive=${isShowAll}`
    url += operations.sortBy ? `&sort=${operations.sortBy}` : ""
    url += operations.search ? `&search=${operations.search}` : ""
    url += operations.order ? `&order=${operations.order}` : ""
    return this.http.get(url);
  }

  getDownloadPdf(id: any) {
    return this.http.get(`${environment.apiBaseUrl}/cform/pdf/ireport/${id}`);
  }

  getInvoiceDetail(id: any) {
    return this.http.get(`${environment.apiBaseUrl}/item/invoice/${id}`);
  }

  voidInvoice(id: any, payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/invoice/${id}/void`, payload);
  }

  sendInvoiceEmailNotification(id: any,data: any = {}) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice/${id}/email`, data);
  }

  saveInvoice(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice`, payload);
  }

  updateInvoice(id: any, payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/invoice/${id}`, payload);
  }

  deleteInvoice(id: any) {
    return this.http.delete(`${environment.apiBaseUrl}/item/invoice/${id}`);
  }

  invoicePayment(id: any, payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice/${id}/payment`, payload);
  }

  invoicePublicPayment(id: any, payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice/${id}/publicpayment`, payload);
  }

  invoiceApplyPayment(id: any, payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice/${id}/applypayment`, payload);
  }

  checkoutPayment(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/checkout`, payload);
  }

  productCheckout(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/snode/product/usercheckout`, payload);
  }

  getPaymentIntent(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/payment/paymentintent`, payload);
  }

  setPaymentIntent(id: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/payment/setupintent/${id}`, {});
  }

  validationCheckoutPayload(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/checkout/validate`, payload);
  }

  refundInvoicePayment(id: any, payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/invoice/${id}/refund`, payload);
  }

  static getCurrency(currency) {
    if (currency == "USD" || currency == "usd") {
      return "$";
    } else if (currency == "INR" || currency == "inr") {
      return "₹";
    } else {
      return "$";
    }
  }

  static defaultSelectedCurrency() {
    return [{ code: "USD", text: "United States Dollars – USD" }, { code: "INR", text: "India Rupees – INR" }];
  }

  static getCurrencyList() {
    return [
      { code: "AFN", text: "Afghanistan Afghanis – AFN" },
      { code: "ALL", text: "Albania Leke – ALL" },
      { code: "DZD", text: "Algeria Dinars – DZD" },
      { code: "ARS", text: "Argentina Pesos – ARS" },
      { code: "AUD", text: "Australia Dollars – AUD" },
      { code: "ATS", text: "Austria Schillings – ATS" },
      { code: "BSD", text: "Bahamas Dollars – BSD" },
      { code: "BHD", text: "Bahrain Dinars – BHD" },
      { code: "BDT", text: "Bangladesh Taka – BDT" },
      { code: "BBD", text: "Barbados Dollars – BBD" },
      { code: "BEF", text: "Belgium Francs – BEF" },
      { code: "BMD", text: "Bermuda Dollars – BMD" },
      { code: "BRL", text: "Brazil Reais – BRL" },
      { code: "BGN", text: "Bulgaria Leva – BGN" },
      { code: "CAD", text: "Canada Dollars – CAD" },
      { code: "XOF", text: "CFA BCEAO Francs – XOF" },
      { code: "XAF", text: "CFA BEAC Francs – XAF" },
      { code: "CLP", text: "Chile Pesos – CLP" },
      { code: "CNY", text: "China Yuan Renminbi – CNY" },
      { code: "COP", text: "Colombia Pesos – COP" },
      { code: "XPF", text: "CFP Francs – XPF" },
      { code: "CRC", text: "Costa Rica Colones – CRC" },
      { code: "HRK", text: "Croatia Kuna – HRK" },
      { code: "CYP", text: "Cyprus Pounds – CYP" },
      { code: "CZK", text: "Czech Republic Koruny – CZK" },
      { code: "DKK", text: "Denmark Kroner – DKK" },
      { code: "DEM", text: "Deutsche (Germany) Marks – DEM" },
      { code: "DOP", text: "Dominican Republic Pesos – DOP" },
      { code: "NLG", text: "Dutch (Netherlands) Guilders - NLG" },
      { code: "XCD", text: "Eastern Caribbean Dollars – XCD" },
      { code: "EGP", text: "Egypt Pounds – EGP" },
      { code: "EEK", text: "Estonia Krooni – EEK" },
      { code: "EUR", text: "Euro – EUR" },
      { code: "FJD", text: "Fiji Dollars – FJD" },
      { code: "FIM", text: "Finland Markkaa – FIM" },
      { code: "FRF", text: "France Francs – FRF" },
      { code: "DEM", text: "Germany Deutsche Marks – DEM" },
      { code: "XAU", text: "Gold Ounces – XAU" },
      { code: "GRD", text: "Greece Drachmae – GRD" },
      { code: "GTQ", text: "Guatemalan Quetzal – GTQ" },
      { code: "NLG", text: "Holland (Netherlands) Guilders – NLG" },
      { code: "HKD", text: "Hong Kong Dollars – HKD" },
      { code: "HUF", text: "Hungary Forint – HUF" },
      { code: "ISK", text: "Iceland Kronur – ISK" },
      { code: "XDR", text: "IMF Special Drawing Right – XDR" },
      { code: "INR", text: "India Rupees – INR" },
      { code: "IDR", text: "Indonesia Rupiahs – IDR" },
      { code: "IRR", text: "Iran Rials – IRR" },
      { code: "IQD", text: "Iraq Dinars – IQD" },
      { code: "IEP", text: "Ireland Pounds – IEP" },
      { code: "ILS", text: "Israel New Shekels – ILS" },
      { code: "ITL", text: "Italy Lire – ITL" },
      { code: "JMD", text: "Jamaica Dollars – JMD" },
      { code: "JPY", text: "Japan Yen – JPY" },
      { code: "JOD", text: "Jordan Dinars – JOD" },
      { code: "KES", text: "Kenya Shillings – KES" },
      { code: "KRW", text: "Korea (South) Won – KRW" },
      { code: "KWD", text: "Kuwait Dinars – KWD" },
      { code: "LBP", text: "Lebanon Pounds – LBP" },
      { code: "LUF", text: "Luxembourg Francs – LUF" },
      { code: "MYR", text: "Malaysia Ringgits – MYR" },
      { code: "MTL", text: "Malta Liri – MTL" },
      { code: "MUR", text: "Mauritius Rupees – MUR" },
      { code: "MXN", text: "Mexico Pesos – MXN" },
      { code: "MAD", text: "Morocco Dirhams – MAD" },
      { code: "NLG", text: "Netherlands Guilders – NLG" },
      { code: "NZD", text: "New Zealand Dollars – NZD" },
      { code: "NOK", text: "Norway Kroner – NOK" },
      { code: "OMR", text: "Oman Rials – OMR" },
      { code: "PKR", text: "Pakistan Rupees – PKR" },
      { code: "XPD", text: "Palladium Ounces – XPD" },
      { code: "PEN", text: "Peru Nuevos Soles – PEN" },
      { code: "PHP", text: "Philippines Pesos – PHP" },
      { code: "XPT", text: "Platinum Ounces – XPT" },
      { code: "PLN", text: "Poland Zlotych – PLN" },
      { code: "PTE", text: "Portugal Escudos – PTE" },
      { code: "QAR", text: "Qatar Riyals – QAR" },
      { code: "RON", text: "Romania New Lei – RON" },
      { code: "ROL", text: "Romania Lei – ROL" },
      { code: "RUB", text: "Russia Rubles – RUB" },
      { code: "SAR", text: "Saudi Arabia Riyals – SAR" },
      { code: "XAG", text: "Silver Ounces – XAG" },
      { code: "SGD", text: "Singapore Dollars – SGD" },
      { code: "SKK", text: "Slovakia Koruny – SKK" },
      { code: "SIT", text: "Slovenia Tolars – SIT" },
      { code: "ZAR", text: "South Africa Rand – ZAR" },
      { code: "KRW", text: "South Korea Won – KRW" },
      { code: "ESP", text: "Spain Pesetas – ESP" },
      { code: "XDR", text: "Special Drawing Rights (IMF) – XDR" },
      { code: "LKR", text: "Sri Lanka Rupees – LKR" },
      { code: "SDD", text: "Sudan Dinars – SDD" },
      { code: "SEK", text: "Sweden Kronor – SEK" },
      { code: "CHF", text: "Switzerland Francs – CHF" },
      { code: "TWD", text: "Taiwan New Dollars – TWD" },
      { code: "THB", text: "Thailand Baht – THB" },
      { code: "TTD", text: "Trinidad and Tobago Dollars – TTD" },
      { code: "TND", text: "Tunisia Dinars – TND" },
      { code: "TRY", text: "Turkey New Lira – TRY" },
      { code: "AED", text: "United Arab Emirates Dirhams – AED" },
      { code: "GBP", text: "United Kingdom Pounds – GBP" },
      { code: "USD", text: "United States Dollars – USD" },
      { code: "VEB", text: "Venezuela Bolivares – VEB" },
      { code: "VND", text: "Vietnam Dong – VND" },
      { code: "ZMK", text: "Zambia Kwacha – ZMK" }
    ];
  }

  static getPercentageVal(base: any, pct: any): any {
    let percentageVal = ((base * pct) / 100).toFixed(2);
    return +percentageVal;
    //return Math.floor((base * pct) / 100);
  }

  static makeRandomStr() {
    let possible = "1234567890";
    const lengthOfCode = 5;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static billingFreqs() {
    return [
      { key: "daily", value: "Daily" },
      { key: "weekly", value: "Weekly" },
      { key: "monthly", value: "Monthly" },
      { key: "quarterly", value: "Quarterly" },
      { key: "halfyearly", value: "Half Yearly" },
      { key: "yearly", value: "Yearly" }
    ];
  }

  static validPeriodTypes() {
    return [{ key: "days", value: "days" }, { key: "months", value: "months" }, { key: "years", value: "years" }];
  }

  getTemplateId(status) {
    let templateId;
    switch (status) {
      case AppConstants.PaymentStatus.PENDING:
        templateId = AppConstants.emailTemplateId.PENDING;
        break;
      case AppConstants.PaymentStatus.PARTIAL:
        templateId = AppConstants.emailTemplateId.PENDING;
        break;
      case AppConstants.PaymentStatus.FAILED:
        templateId = AppConstants.emailTemplateId.FAILED;
        break;
      case AppConstants.PaymentStatus.COMPLETE:
        templateId = AppConstants.emailTemplateId.COMPLETE;
        break;
      case AppConstants.PaymentStatus.REMINDER:
        templateId = AppConstants.emailTemplateId.REMINDER;
        break;
      default:
    }
    return templateId;
  }

  getEmailTemplatePayload(invoice) {
    const items = [];
    invoice.items.forEach((item: any) => {
      let newItem: any = {};
      newItem.currency = item.currency || invoice.currency;
      newItem.qty = item.qty;
      newItem.itemName = item.itemName;
      newItem.itemType = item.itemType;
      newItem.subscriptionAmount = item.itemTotal;
      items.push(newItem);
    });

    const payload = {
      templateId: this.getTemplateId(invoice.status),
      tenantName: invoice.tenantName,
      tenantEmail: invoice.tenantEmail,
      tenantAddress: invoice.tenantAddress == null ? "" : invoice.tenantAddress,
      invoiceDate: invoice.createdAt,
      invoiceId: invoice.invoiceId,
      contactEmail: invoice.contactEmail,
      contactPhone: invoice.contactPhone == null ? "" : invoice.contactPhone,
      firstName: invoice.firstName,
      lastName: invoice.lastName,
      salesPersonName: invoice.salesPersonName,
      salesPersonEmail: invoice.salesPersonEmail,
      total: invoice.total,
      subTotal: invoice.subTotal,
      cardFees: invoice.cardFees,
      tax: invoice.tax,
      balance: invoice.balance,
      currency: invoice.currency,
      items: items
    };
    return payload;
  }
}
