import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { FeedbackDlgService } from "./feedback-dlg.service";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-feedback-dlg",
  templateUrl: "./feedback-dlg.component.html",
  styleUrls: ["./feedback-dlg.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FeedbackDlgComponent implements OnInit {
  isLoading: boolean = false;
  basicInfoForm: FormGroup;
  subs = new SubSink();
  isStudentRole: boolean = false;

  selection = new SelectionModel<any>(true, []);
  totalRecords: number;
  pageIndex: number;
  select: {};
  start: number = 0;
  pageSize: number = 50;
  dataSource: any;
  pageEvent: PageEvent;
  displayedColumns: string[] = ["name", "email", "message", "updatedAt"];
  pageSizeOptions: any[] = [50, 100, 150];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  title: any = "Feedback";
  dataList: any = [];
  constructor(
    private myfeedbackService: FeedbackDlgService,
    private _formBuilder: FormBuilder,
    public toastr: ToastrService,
    public matDialogRef: MatDialogRef<FeedbackDlgComponent>,
    private _router: Router,
    private _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {}

  ngOnInit(): void {
    this.basicInfoForm = this._formBuilder.group({
      message: ["", Validators.required]
    });
    this.isStudentRole = this._data.isStudentRole;
    if (!this.isStudentRole) {
      this.getFeedbackList(null);
    }
  }

  getFeedbackList(event: PageEvent) {
    this.isLoading = true;
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
    }
    this.isLoading = true;
    this.subs.add(
      this.myfeedbackService.getFeedbackList(this.pageSize, this.start).subscribe(
        (result: any) => {
          this.title = "Feedbacks " + "(" + result.total + ")";

          this.dataList = result.data;
          this.totalRecords = result.total;
          this.dataSource = new MatTableDataSource<any>(this.dataList);
          this.isLoading = false;
        },
        (errorResp: any) => {
          this.isLoading = false;
          this.toastr.error(errorResp.error.errors.message);
        }
      )
    );
  }
  submitForm() {
    this.isLoading = true;
    let payload = {
      message: this.basicInfoForm.get("message").value
    };
    this.subs.add(
      this.myfeedbackService.saveFeedback(payload).subscribe(
        (res: any) => {
          this.matDialogRef.close(this.basicInfoForm);
          this.toastr.success("Feedback submited successfully");
          this.isLoading = false;
          this.clearForm();
        },
        (errorResp: any) => {
          this.isLoading = false;
          this.matDialogRef.close(this.basicInfoForm);
          this.toastr.error(errorResp.error.errors.message);
          console.log("errorResp", errorResp);
        }
      )
    );
  }
  clearForm() {
    this.basicInfoForm.get("message").setValue("");
  }
}
