<div class="main-div">
  <div class="accent px-8" style="min-height: 60px;" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="secondary-text s-24">assignment</mat-icon>
      <h2 class="m-0 ml-8" style="font-weight: 600;">Manage Notice And Quote</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button
        class="m-8"
        mat-mini-fab
        mat-dialog-close
        matTooltip="Close"
        (click)="isEditNotice = true"
        aria-label="Close"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="courses bg-color-white pb-16">
    <div style="width: 100%;padding: 15px;">
      <ngx-skeleton-loader
        appearance=""
        count="3"
        [theme]="{
          background: '#ededed',
          height: '18px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        appearance=""
        count="3"
        [theme]="{
          background: '#ededed',
          height: '18px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div *ngIf="!isLoading" class="content-div m-16">
    <!-- View notices -->
    <div *ngIf="!isAddNotice && !isEditNotice">
      <div class="p-8 border-bottom" fxLayout="row" fxLayoutAlign="end end" style="margin-top: -10px;">
        <!-- <div>
                    <h2><b>Manage Notice And Quote</b></h2>
                </div> -->
        <button mat-mini-fab matTooltip="Add Notice" (click)="showAddNotice()" aria-label="Add Notice">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="h-48 font-weight-800 border-bottom border-left border-right"
        >
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex="60" class="pl-16">
            Name
          </div>
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex="20">
            Type
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="20"></div>
        </div>
      </div>
      <div class="data-section">
        <div
          *ngFor="let notice of notices; let i = index"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="border-bottom border-left border-right pl-16"
        >
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex="60">
            <div class="position-relative message-div">
              <span class="approve-status" [ngStyle]="{ 'background-color': notice.isActive ? 'green' : 'red' }"></span>
              <div fxLayout="row">
                <p class="message" [innerHTML]="notice.message"></p>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex="20">
            {{ notice?.type | titlecase }}
          </div>
          <div fxLayout="row" fxLayoutAlign="end start" fxFlex="20" class="pr-4">
            <mat-icon class="cursor-pointer" (click)="edit(notice)">edit </mat-icon>
            <mat-icon class="ml-8 cursor-pointer" (click)="delete(notice)">delete</mat-icon>
          </div>
        </div>
      </div>

      <div *ngIf="notices.length === 0" class="h-40" fxLayout="row" fxLayoutAlign="center center">
        No notices available
      </div>
    </div>

    <!-- Add notice  -->
    <div *ngIf="isAddNotice && !isEditNotice" class="p-8">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="px-8">
        <div>
          <h2><b>Add notice</b></h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-slide-toggle [(ngModel)]="isActive"></mat-slide-toggle>
          <span class="ml-4" *ngIf="!isActive">Private</span>
          <span class="ml-4" *ngIf="isActive">Published</span>
        </div>
      </div>
      <div class="p-8" fxLayout="row" fxLayoutAlign="start center">
<!--        <ckeditor class="w-100-p" [editor]="Editor" required [(ngModel)]="message" (ready)="onReady($event)">-->
<!--        </ckeditor>-->
        <quill-editor required [(ngModel)]="message" ></quill-editor>

      </div>

      <div class="p-8" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field
            appearance="outline"
            style="width:100px;    margin: 0px !important;  padding: 0px !important;"
          >
            <mat-label>Select type</mat-label>
            <mat-select [(ngModel)]="type">
              <mat-option value="notice">Notice</mat-option>
              <mat-option value="quote">Quote</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button
            mat-stroked-button
            (click)="isAddNotice = false"
            class="mr-16 w-80"
            style="border: 1px solid #00C6D8;"
            color="accent"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            color="accent"
            class="mr-16 w-80"
            color="accent"
            (click)="addNotice()"
            [disabled]="message.trim() === '' || !type"
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <!-- Edit notice -->
    <div *ngIf="!isAddNotice && isEditNotice">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="px-8">
        <div>
          <h2><b>Edit notice</b></h2>
        </div>
        <div *ngIf="selectedNotice" fxLayout="row" fxLayoutAlign="center center">
          <mat-slide-toggle [(ngModel)]="selectedNotice.isActive"></mat-slide-toggle>
          <span class="ml-4" *ngIf="!selectedNotice.isActive">Private</span>
          <span class="ml-4" *ngIf="selectedNotice.isActive">Published</span>
        </div>
      </div>

      <div *ngIf="selectedNotice" class="p-8" fxLayout="row" fxLayoutAlign="start center">
        <form class="w-100-p" name="basicInfoForm" [formGroup]="basicInfoForm">
<!--          <ckeditor class="w-100-p" [editor]="Editor" required formControlName="message" (ready)="onReady($event)">-->
<!--          </ckeditor>-->
          <quill-editor required formControlName="message"></quill-editor>

        </form>
      </div>

      <div *ngIf="selectedNotice" class="p-8" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-form-field
            appearance="outline"
            style="width:100px;    margin: 0px !important;  padding: 0px !important;"
          >
            <mat-label>Select type</mat-label>
            <mat-select [(ngModel)]="selectedNotice.type">
              <mat-option value="notice">Notice</mat-option>
              <mat-option value="quote">Quote</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button
            mat-stroked-button
            (click)="isEditNotice = false"
            class="mr-16 w-80"
            style="border: 1px solid #00C6D8;"
            color="accent"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            color="accent"
            class="mr-16 w-80"
            color="accent"
            (click)="editNotice()"
            [disabled]="selectedNotice.message.trim() === '' || !selectedNotice?.type"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
