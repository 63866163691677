import { Component, OnInit } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { AppSettingsService } from "../../services/app-settings.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { AppConstants } from "app/core/settings/appconstants";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
  animations: fuseAnimations
})
export class PaymentsComponent implements OnInit {
  paymentSpringOrStripe = [{ value: 1, name: "PaymentSpring" }, { value: 2, name: " Stripe" }];
  selectedPart = 0;
  subs = new SubSink();

  tenant: any;
  isPspringIntegrated: boolean = false;
  isStripeIntegrated: boolean = false;
  isPaytmIntegrated: boolean = false;
  isRazorpayIntegrated: boolean = false;
  isPhonepeIntegrated: boolean = false;

  provider: any = 1;
  constructor(
    private _settingsService: AppSettingsService,
    private _tenantUserService: TenantUserService,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTenantInfo();
    this.updateTenantStripeCode();

    this.route.queryParams.subscribe(params => {
      this.provider = params["provider"];
      this.selectedParts(this.provider);
    });
  }
  
  // tslint:disable-next-line:typedef
  selectedParts(selectedPart: number) {
    console.log("selectedPart", selectedPart);
    this.selectedPart = selectedPart;
  }
  // tslint:disable-next-line:typedef
  MainPaymentComponent() {
    this.selectedPart = 0;
  }

  updateTenantStripeCode() {
    let stripeAuthCode = localStorage.getItem("stripe_auth_code");
    if (stripeAuthCode != null && stripeAuthCode != "null") {
      let clientUrl = window.location.origin;
      let payload = {
        stripeAuthCode: stripeAuthCode,
        clientUrl: clientUrl,
        paymentProvider: "STRIPE"
      };
      this._tenantUserService.removeStripeCode();
      localStorage.removeItem("stripe_auth_code");
      this.subs.add(
        this._settingsService.updateTenant(payload).subscribe(
          result => {
            this.toastr.success("Stripe account successfully integrated");
            this.getTenantInfo();
          },
          error => {
            this.toastr.error(error);
          }
        )
      );
    } else {
      this._tenantUserService.removeStripeCode();
      localStorage.removeItem("stripe_auth_code");
    }
  }

  getTenantInfo() {
    const self = this;
    self._settingsService.getTenantDetail().subscribe((result: any) => {
      self.tenant = result.data;
      this.setPaymentProvider();
    });
  }

  setPaymentProvider() {
    if (this.tenant.paymentKeys && this.tenant.paymentKeys.length > 0) {
      this.tenant.paymentKeys.forEach((item, index) => {
        let paymentProvider = item.provider;
        if (paymentProvider == AppConstants.PAYMENT_TYPE.PAYTM_PAYMENT.toLowerCase() && item.isProviderIntegrated) {
          this.isPaytmIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.PSPRING_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isPspringIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.RAZORPAY_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isRazorpayIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.STRIPE_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isStripeIntegrated = true;
        } else if(
          paymentProvider == AppConstants.PAYMENT_TYPE.PHONEPE_PAYMENT.toLowerCase() &&
          item.isPhonepeIntegrated
        ){
          this.isPhonepeIntegrated = true;
        }
      });
    }
  }
}
