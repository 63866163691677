import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(private http: HttpClient) { }

  getWidgets() {
    return this.http.get(`${environment.apiBaseUrl}/rest/widget`);
  }

  getWidget(guId) {
    return this.http.get(`${environment.apiBaseUrl}/rest/widget/${guId}`);
  }

  updateWidget(payload, guId){
    return this.http.put(`${environment.apiBaseUrl}/rest/widget/${guId}`,payload);
  }
}
