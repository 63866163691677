<div fxFlex="100" fxFlex fxLayout="row" fxLayoutAlign="center center" id="auto-search">
    <div class="center">
        <div class="search-wrapper" style="background: white">
            <div
                    class="search"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    style="color: grey; flex-direction: row; box-sizing: border-box;
                    display: flex; place-content: center flex-start;
                    align-items: center; flex: 1 1 0%; width: 40rem">
                <mat-icon *ngIf="isLoading">
                    <mat-spinner diameter="20" style="background: #00C6D8;border-radius: 50%;"></mat-spinner>
                </mat-icon>
                <mat-icon *ngIf="!isLoading" style="color: #000;">search</mat-icon>
                <input
                    #filter
                    [formControl]="contactSearchControl"
                    [matAutocomplete]="auto"
                    aria-label="Number"
                    matInput
                    placeholder={{placeHolder}}
                    style="text-align: left;color: black"
                    type="text"
                />
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                    <mat-option
                            (onSelectionChange)="setContactID(contact)"
                            *ngFor="let contact of searchContacts | async"
                            [value]="contact.label">
                        <span>{{ contact.label }} {{ contact.email }}</span>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</div>
