import { Component, ElementRef, ViewChild,Input, OnDestroy, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubSink } from 'subsink';
import {AppLoadService} from "../../../../core/services/appload.service";
import {TenantPing} from "../../../../core/models/tenantPing";
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment";
import { AppUtilsService } from 'app/core/services/apputils.service';
import { SignaturePad } from 'angular2-signaturepad';
import { CheckoutUtilService } from "app/main/public/checkout/checkout.util.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { AppConstants } from 'app/core/settings/appconstants';
import { CourseListService } from "app/main/public/courses/courselist/courseslist.service";
import { ItemCheckoutService } from "app/main/public/checkout/checkout.service";
import { LocalstorageService } from "app/core/services/applocalstorage.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';

var _ = require('lodash'); 
@Component({
  selector: 'app-custom-form-preview',
  templateUrl: './custom-form-preview.component.html', 
  styleUrls: ['./custom-form-preview.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class CustomFormPreviewComponent implements OnInit,OnChanges,  OnDestroy {
  @Input() formData: any;
  @Input() type: any;
  subs = new SubSink();
  allFormData: any;
  customFields = [];
  selectFormValue: any;
  isTerms = false;
  guId: any;
  isLoading: boolean;
  _tenantPing: TenantPing;
  formSubmited: boolean = false;
  downloadUrl: any;
  downloadStatus: any;
  downloadStatusId: any;
  loadPhoneCode: boolean= true;
  phone: string;
  signatureImg: string;
  isSignCompleted: boolean = false;
  isSignRequired: boolean = false;
  currDate:any = moment().unix();
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 2,
    'canvasWidth': 350,
    'canvasHeight': 150
  };
  user: any;
  formName: any = "";
  isStudentRole: boolean = false;
  isDemoCourseFlow: boolean = false;
  courseList: any = [];
  course: any;
  courseName: any;
  cfGradeLabel: any = 'grade of child';
  isDemo: boolean = false; 
  firstName:any = "";
  lastName:any = "";
  private recaptchaToken: string;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _appSettingsService: AppSettingsService,
    private SpinnerService: NgxSpinnerService,
    private _appLoadService: AppLoadService,
    public toastr: ToastrService,
    public AppUtilsService: AppUtilsService,
    private checkoutUtilService: CheckoutUtilService,
    private tenantUserService: TenantUserService,
    private courseService: CourseListService,
    private checkoutService: ItemCheckoutService,
    private localstorageService: LocalstorageService,
    private _recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit(): void {
    console.log("type", this.type)
    this.route.queryParams.subscribe(params => {
      this.isDemo = params["isDemo"]; 
      console.log("this.isDemo", this.isDemo)
    });
  }

  ngOnChanges() {
    this.formSubmited = false;
    this.downloadUrl = "";
    this.downloadStatus = "";
    this.downloadStatusId = "";
    this.formName = "";
    this.isLoading = true;
    this.SpinnerService.show(); 
    this.subs.add(
      this._appLoadService.onTenantConfigChanged.subscribe((tenantConfig: any) => {
        this._tenantPing = tenantConfig;
        this.getUserObject(); 
        this.getCountryCode(tenantConfig);
         this.isLoading = false;
        this.SpinnerService.hide(); 
      })
    );
  }

  getUserObject() {
    this.subs.add(
      this.tenantUserService.user.subscribe((user: any) => {
         this.user = user;
        this.isStudentRole = TenantUserService.isStudent(this.user); 
        console.log("this.isStudentRole",this.isStudentRole)
        this.getGuId();
      })
    );
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 2); 
    this.signaturePad.clear(); 
  }

  drawComplete() {
     this.isSignCompleted = true;
    this.isSignRequired = false;
  }

  drawStart() {
   }

  clearSignature() {
    this.signaturePad.clear();
    this.isSignCompleted = false;
    this.isSignRequired = false;
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.isSignCompleted = true;
  }

  selectedValue(obj): any {
     this.guId = this.selectFormValue;

    this.isLoading = true;
    this.SpinnerService.show(); 
    this.getCustomFormDetails();
    // var selectPeriod = this.selectPeriod.find(e => e.value === obj);
    // if (selectPeriod) {
    //   this.selectPeriodParam = selectPeriod;
    //   this.reportView(null, "");
    // }
  }
  getGuId() {
    this.route.params.subscribe((params) => {
      this.guId = params["id"];
      if (!this.type) {
        this.selectFormValue = this.guId; 
        this.getCustomFormDetails();
        this.isLoading = true;
        this.SpinnerService.show();
      } else {
        this.selectFormValue = this.formData.guId;  
        this.formValidations()
      }
    })
  }

  formValidations() {
   
    if (!this.formData.beforeForm) {
      this.formData.beforeForm = {}
      this.formData.beforeForm.description = ''
    }
    if (!this.formData.afterForm) {
      this.formData.afterForm = {}
      this.formData.afterForm.description = ''
    }
    if (!this.formData.customFields) {
      this.formData.customFields = []
    }
    this.allFormData = { ...this.formData };
    this.customFields = [
    ];

    let title = this.allFormData.name;
    //this.isDemoCourseFlow = title.toLowerCase().includes('demo')

    if (this.allFormData.isName) {
      this.customFields.push(
        {
          customField: {
            name: 'Name',
            type: 'text',
            isMandatory: true,
            placeholder: 'Jon Doe',
            value: this.type === 'preview' ? '' : this.user.fullName,
          }
        }
      )
    }

    if (this.allFormData.isEmail) {
      this.customFields.push(
        {
          customField: {
            name: 'Email',
            type: 'text',
            isMandatory: true,
            placeholder: 'jonedoe@acme.com',
            value: this.type === 'preview' ? '' : this.user.email,
          }
        }
      )
    }

    if (this.allFormData.isPhone) {
      this.phone = this.type === 'preview' ? '' : this.phone
      this.customFields.push(
        {
          customField: {
            name: 'Phone',
            type: 'text',
            isMandatory: true,
            placeholder: '123-456-7890',
            phonenumber: true,
            value: this.phone,
          }
        }
      )
    }
   
    if(this.isDemoCourseFlow){
       this.getCoursesList();
    }

   
    this.formData.customFields = _.orderBy(this.formData.customFields, ['sequence'], ['asc']);
    console.log("sorted", this.formData.customFields)

    this.customFields = this.customFields.concat(this.formData.customFields);
    //this.customFields = _.orderBy(this.customFields, ['sequence'], ['asc']);
    
  }

  getCoursesList(){
    this.subs.add(
      this.courseService.getCourseAndProductList(this._tenantPing.orgId, "" ).subscribe(
        (result:any) => {
          this.courseList = result.filter((itemCourse:any) => { 
            return itemCourse.isAllowDemoChapters && itemCourse.paymentType != AppConstants.PaymentType.PRODUCT && itemCourse.paymentType != AppConstants.PaymentType.DONATION
          });
          this.course = this.courseList.length > 0 ? this.courseList[0].guId : ""
        } 
      )
    );
  }

  getCustomFormDetails() {
    this.subs.add(
      this._appSettingsService.getRegistrationFormsById(this.guId).subscribe((result: any) => {
        this.formData = result;
        this.formValidations();
        this.isLoading = false;
        this.SpinnerService.hide();
      }));
  }

  patterns(type) {
     let pattern = '';
    if (type === 'email') {
      pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    }
    return pattern;
  }

  patternsEmail(obj) {
     let pattern = '';
    if (obj.name=== 'Email') {
      pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    }
    return pattern;
  }

  public verifyRecaptcha(f: NgForm){
    this.subs.add(
      this._recaptchaV3Service.execute('custom_form_checkout').subscribe(
        (token) => {
          this.recaptchaToken = token;
          this.submitForm(f);
        },
        (error) => {
          this.recaptchaToken = "";
          this.toastr.error(error)
        },
    ))
  }

  submitForm(f: NgForm) {
    if (this.type === 'preview') {
      return;
    }

    if(this.allFormData.isSignRequired && !this.isSignCompleted){
      this.isSignRequired = true;
      return;
    }else{
      this.isSignRequired = false;
    }

    // if(this.allFormData)

    if(!this.isStudentRole && (this.firstName == '' || this.lastName == '')){
      this.toastr.error("First name and Last name required!"); 
      return 
    }

    this.SpinnerService.show();
    let payload = {}
    // payload = this.getClearedInitialPayload(f);
    let name = this.firstName + " " +this.lastName  //f.value[this.isDemoCourseFlow ? 'Name of child' : 'Name'];
    let email = f.value['Email'];
    let phoneNum = String(f.value['Phone']);
 
    if(this.isStudentRole && (name.trim() == '' || name.trim() == undefined || name.trim() == 'undefined')){
      name = this.user.fullName; 
       var tempName = name.split(" ");
        if (tempName && tempName.length > 1) {
          this.lastName = this.checkoutUtilService.getLastName(tempName);
          this.firstName = tempName[0];
        } else {
          this.firstName = name;
        }

    }
    if(this.isStudentRole && (email == '' || email == undefined || email == 'undefined')){
      email = this.user.email;
    }
    if(this.isStudentRole && (phoneNum == '' || phoneNum == undefined || phoneNum == 'undefined')){
      phoneNum = this.user.phone;
    }

    // let lastname = "";
    // let firstname = "";
    // var tempName = name.split(" ");
    // if (tempName && tempName.length > 1) {
    //   lastname = this.checkoutUtilService.getLastName(tempName);
    //   firstname = tempName[0];
    // } else {
    //   firstname = name;
    // }

    this.formName = this.allFormData.name
    payload['rToken'] = this.recaptchaToken;
    payload['fullName'] = name;
    payload['firstName'] = this.firstName;
    payload['lastName'] = this.lastName;
    payload['email'] = email;
    payload['phone'] = phoneNum == undefined || phoneNum == 'undefined' ? "" : phoneNum;
    
    var keys = Object.keys(f.value);
    payload['customField'] = [];
    this.formData.customFields.forEach((customFieldObj) => {
      keys.forEach((keysValue) => {
        if (customFieldObj?.customField?.name == keysValue) {
          var customField: any = {};
          if (f.value.hasOwnProperty(keysValue)) {
            var value = f.value[keysValue];
            customField.value = value;
          }
          if(this.isDemoCourseFlow && customFieldObj && 
            customFieldObj.customField.name.toLowerCase() == this.cfGradeLabel){
              customField.value = this.course; 
          }
          customField.guId = customFieldObj?.customField?.guId;
          customField.sequence = customFieldObj?.customField?.sequence;
          customField.type = customFieldObj?.customField?.type;
          customField.name = customFieldObj?.customField?.name;
          payload['customField'].push(customField);
        }
      });
    });
    payload['isWaiverFormToBeSigned'] = f.value.isWaiverFormToBeSigned;
    payload['tenant'] = this._tenantPing.orgGuId; 
    payload['title'] = this.allFormData.name; 
    payload['header2'] = this.allFormData.subHeader && this.allFormData.subHeader.description ? this.allFormData.subHeader.description : "";
    payload['header'] = this.allFormData.beforeForm && this.allFormData.beforeForm.description ? this.allFormData.beforeForm.description : ""; 
    payload['footer'] = this.allFormData.afterForm && this.allFormData.afterForm.description ? this.allFormData.afterForm.description : ""; 
    payload['formType'] = this.allFormData.formType; 
    payload['formId'] = this.allFormData.guId; 
    payload['formName'] = this.allFormData.name; 
    payload['orgId'] = this._tenantPing.orgId; 
    payload['signDate'] = (this.currDate * 1000)/1000; 
    payload['signDateStr'] =  moment().format("Do MMM YYYY"); 
    payload['customFormName'] = this.allFormData.customFormName;
    payload['isCustomForm'] = this.allFormData.isCustomForm;
    if(this.isSignCompleted){ 
      const base64Data = this.signaturePad.toDataURL();
      this.signatureImg = base64Data;
      payload['sign'] = this.signatureImg;  
    }else{
      payload['sign'] = "";
    }
     this.subs.add(
      this._appSettingsService.registerFormNewEndpoint(payload, this.type).subscribe(
        (result:any) => {
          this.SpinnerService.hide(); 
          this.toastr.success("Successfully registered");

          if(this.isDemoCourseFlow &&  this.courseList.length > 0){  
            let selectedCourse = this.courseList.find((item:any) =>{
              return item.guId == this.course
            })
            this.courseName = selectedCourse.name
            console.log("selectedCourse",selectedCourse)
            if(this.isDemo || selectedCourse.paymentType == AppConstants.PaymentType.FREE){
              console.log("register")
              
              this.registerCourse(payload);
            }else{
              this.setUserInfoLocalStorage(name, email, phoneNum);
              this.router.navigate([AppConstants.SL_COURSES_ROUTE +"/"+ this.course]);
            }
          }else{
            this.formSubmited = true;
            this.downloadStatus = result.status
            this.downloadStatusId =  result._id
            this.downloadUrl = result.downloadUrl; 
            let elmnt = document.getElementById("topdiv");
            elmnt.scrollIntoView();
            if(this.downloadStatus == 'COMPLETE'){ 
              window.open(this.downloadUrl, '_blank'); 
            }  
          }
          
        },
        (err) => {
          this.SpinnerService.hide(); 
          console.log("err.error",err.error)
          this.toastr.error(err.error.errors.message);
        }
      )
    );
  }

  setUserInfoLocalStorage(fullName, email, phone) {
    this.localstorageService.setItem(AppConstants.FULLNAME, fullName);
    this.localstorageService.setItem(AppConstants.EMAIL, email);
    this.localstorageService.setItem(AppConstants.PHONE, phone);
  }

  buildItemPayload(courseGuId, regPayload){
    let payload = { 
        orgId: this._tenantPing.orgId,
        category: {
          guId: courseGuId
        },
        categoryId: courseGuId,
        itemId: null,
        user: {
          fullName : regPayload.fullName,
          email: regPayload.email,
          phone: regPayload.phone,
          address: "",
          note: "",
          isWaiverFormToBeSigned: false,
          lastname: regPayload.lastName,
          firstname: regPayload.fatherName,
          name: regPayload.fullName,
        },
        isFreeClass: true,
        token: ""
      } 
      return payload;
  }
  registerCourse(regPayload) {
    var payload = this.buildItemPayload(this.course, regPayload); 
    this.subs.add(
      this.checkoutService.registerEvent(payload).subscribe(result => {
        console.log("registerEvent", result);
        this.formSubmited = true;
        this.toastr.success("Course enrolled successfully");
        let elmnt = document.getElementById("topdiv");
          elmnt.scrollIntoView(); 
      })
    );
  }

  getDownloadPdfStatus(){
    this.SpinnerService.show();  
    this.subs.add(
      this._appSettingsService.getDownloadPdfStatus(this.downloadStatusId).subscribe(
        (result:any) => {
          this.SpinnerService.hide();  
          this.downloadStatus = result.status;
          this.downloadUrl = result.downloadUrl; 
          let elmnt = document.getElementById("topdiv");
          elmnt.scrollIntoView();
          if(this.downloadStatus == 'COMPLETE'){ 
            window.open(result.downloadUrl, '_blank'); 
          }

        },
        (err) => {
          this.SpinnerService.hide(); 
          console.log("err.error",err.error)
          this.toastr.error(err.error.errors.message);
        }
      )
    );
  }

  getCountryCode(tenant){
    this.loadPhoneCode = true;
    this.phone = AppUtilsService.getCountryDialCode(tenant.timezone) + "-"
  }

  updatePhoneNumber(number, ngForm: NgForm, key) {
     ngForm.form.patchValue({
      [key]: number
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
