import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Subject, Subscription } from 'rxjs';
import { AppLoadService } from 'app/core/services/appload.service';
import { AppConstants } from 'app/core/settings/appconstants';
import { SubSink } from 'subsink';
import { ExternalWebsiteService } from '../../admin/app-settings/website-setting/external-website/externalwebsite.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './internalwebsitepreview.component.html',
  styleUrls: ['./internalwebsitepreview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class InternalwebsitepreviewComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  unhtml: any;
  showHeader: any;
  showDescription: any;
  tenant: any;
  currentUrl: string;
  isLoading = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} fuseConfigService
   * @param {FormBuilder} formBuilder
   */
  constructor(
    private _externalWebsiteService: ExternalWebsiteService,
    private sanitizer: DomSanitizer,
    private _appLoadService: AppLoadService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.tenant = this._appLoadService.getTenantPing();
    const pageName = this.route.snapshot.data.pageName;
    this.getInternalWebsitePage(pageName);
  }

  private getInternalWebsitePage(pageName) {
    if (pageName == AppConstants.PAGE_NOT_FOUND) {
      this.isLoading = false;
      this.unhtml = null;
    } else {
      this.subs.add(
        this._externalWebsiteService.getInternalWebsitePublicPage(pageName, this.tenant.tenantId).subscribe(
          (result: any) => {
            this.unhtml = this.sanitizer.bypassSecurityTrustHtml(result.unhtml);
            this.showHeader = result.header;
            this.showDescription = result.subHeader;
            this.isLoading = false;
          },
          error => {
            this.isLoading = false;
          },
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
