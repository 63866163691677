import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { fuseAnimations } from '@fuse/animations';
import { TenantPing } from 'app/core/models/tenantPing';
import { AppLoadService } from 'app/core/services/appload.service';
import { AppUtilsService } from 'app/core/services/apputils.service';
import { SubSink } from 'subsink';
import { TenantUserService } from '../../../app/core/services/tenantuser.service';
import { Router } from '@angular/router';
import { AppConstants } from '../../../app/core/settings/appconstants';
import { FuseSidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'fuse-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class FuseNavigationComponent implements OnInit {

  subs = new SubSink();
  tenant: TenantPing;
  
  @Input()
  layout = 'vertical';

  navigation: any;
  navigationKey: string;
  websiteLogo: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _appLoadService: AppLoadService,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  
  ngOnInit(): void {
    // this.SpinnerService.show();
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
    this.navigationKey = this._fuseNavigationService.getCurrentNavigationKey();
    this.tenant = this._appLoadService.tenantConfig;
    const org = this.tenant.org;
    this.websiteLogo = AppUtilsService.buildCloudinaryImageUrl(this.tenant.cloudName, org.logo, org.logoWidth, org.logoHeight, false);
    // Subscribe to the current navigation changes
    this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Load the navigation
      this.navigation = this._fuseNavigationService.getCurrentNavigation();
      this.navigationKey = this._fuseNavigationService.getCurrentNavigationKey();
      this._changeDetectorRef.detectChanges();
    });
  }

  openHomepage(): void {
    if (this._tenantUserService.isInternalHomeAvailable() || this._tenantUserService.isExternalHomeAvailable() || this._tenantUserService.isStudentRole()) {
     if (this._tenantUserService.isInternalHomeAvailable()) {
        this._router.navigate([AppConstants.SL_NO_ROUTE]);
      } else if (this._tenantUserService.isExternalHomeAvailable()) {
        let s = this._tenantUserService.getExternalHomeLink();
        if (!s.match(/^[a-zA-Z]+:\/\//)) s = 'http://' + s;
        window.location.href = s;
      } else if (this._tenantUserService.isStudentRole) {
        this._router.navigate([AppConstants.SL_MYCOURSES_ROUTE]);
      }
    } else {
      window.location.reload();
    }
    }

  public closeNavbar(){
    this._fuseSidebarService.getSidebar('navbar').close();

  }
}