import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { admin_navigation } from 'app/navigation/admin.navigation';
import { student_navigation } from 'app/navigation/student.navigation';

import { AppConfigService } from 'app/core/services/appconfig.service';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { fuseAnimations } from '@fuse/animations';
import { AppLoadService } from 'app/core/services/appload.service';
import { AppConstants } from 'app/core/settings/appconstants';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  studentNavigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(private _fuseConfigService: FuseConfigService, private _appConfigService: AppConfigService, private _tenantUserService: TenantUserService, private _appLoadService: AppLoadService, private _breakpointObserver: BreakpointObserver) {
    // Set the defaults
    this.navigation = admin_navigation;
    this.studentNavigation = student_navigation;
    const tenant = this._appLoadService.getTenantPing();
    let children = [];
    let studentChildren = [];
    //TBD - move this to tenantsettingmenu
    if (this.navigation[0].children && this.navigation[0].children.length > 0) {
      this.navigation[0].children.forEach(navigation => {
        if (navigation.id === 'facility') {
          if (tenant.orgId === 'angular4' || tenant.orgId === 'enviromerica') {
            children.push(navigation);
          }
        } else if (navigation.id === 'assesment') {
          if (tenant.masterOrgId === AppConstants.TEST_ORG || tenant.masterOrgId === AppConstants.MINDSYNCKKIDS_ORG_ID) {
            children.push(navigation);
          }
        } else {
          children.push(navigation);
        }
      });
      this.navigation[0].children = children;
    }
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
