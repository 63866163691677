import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "fuse-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class FuseConfirmDialogComponent {
  public confirmMessage: string;
  public confirmTitle: string = "Confirm";
  public confirmBtn: string = "Confirm";

  /**
   * Constructor
   *
   * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
   */
  constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {}
}
