import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadFileComponent } from './upload-file.component';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { HlsPlayerModule } from '../hls-player/hls-player.module';
import { Cloudinary, CloudinaryModule } from '@cloudinary/angular-5.x';
import { AppLoadService } from 'app/core/services/appload.service';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FuseSharedModule,
    HlsPlayerModule,
    CloudinaryModule.forRoot(Cloudinary, { cloud_name: AppLoadService.getCloudName() }),
    FileUploadModule
  ],
  declarations: [UploadFileComponent],
  exports: [UploadFileComponent, MatFormFieldModule, MatToolbarModule, MatProgressSpinnerModule, HlsPlayerModule]
})
export class UploadFileModule { }
