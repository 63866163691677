<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        widgets
      </mat-icon>
      <span class="logo-text h1"> Widgets ({{ totalRecords }}) </span>
      <div style="font-size: 14px; margin-left: 10px;margin-top: 3px;">
        To be embeded in your website more descripiton.
      </div>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <div id="attendees" style="width: 100%;padding: 16px;" class="center">
    <!-- <h1 class="m-0">Widgets ({{ totalRecords }})</h1>
    <h4 class="m-0" style="padding-bottom: 10px;">Website widgets to be embeded in your website more descripiton</h4> -->
    <div
      class="content"
      style="border: 1px solid #0000001f;
     border-radius: 12px; display: block;"
    >
      <div class="table-search" style="width: 100%;padding: 15px;">
        <div
          class="center"
          fxFlex="100"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="33"
          [@animate]="{ value: '*', params: { y: '100%' } }"
        >
          <div class="search-wrapper" style="background: white">
            <div
              class="search"
              fxFlex
              fxLayout="row"
              fxLayoutAlign="start center"
              style="color: grey; flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center; flex: 1 1 0%;"
            >
              <mat-icon color="accent">search</mat-icon>
              <input
                [formControl]="searchInput"
                type="text"
                placeholder="search"
                aria-label="search"
                style="text-align: left;"
              />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading" style="width: 100%;padding: 15px;">
        <ngx-skeleton-loader
          count="1"
          appearance=""
          [theme]="{
            background: '#F5F5F5',
            height: '30px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="3"
          appearance=""
          [theme]="{
            background: '#F5F5F5',
            height: '18px'
          }"
        ></ngx-skeleton-loader>
      </div>

      <table
        *ngIf="!isLoading"
        style="width: 100%;background: transparent;"
        mat-table
        [dataSource]="dataSource"
        [@animateStagger]="{ value: '50' }"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let data" title="{{ data.name }}" (click)="updateWiget(data)">
            <span *ngIf="data.isPublished" title="Visible On Website" class="online-icon online-icon-color"
              >&nbsp;</span
            >
            <p class="text-truncate ">
              <a class="nav-link " style="cursor:pointer">
                {{ data.name | titlecase }}
              </a>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Type</mat-header-cell>
          <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
            <p class="email text-truncate">
              {{ data.type | titlecase }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="publishedDateStr">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Last Published</mat-header-cell>
          <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
            <p class="email text-truncate">
              {{ data.publishedDateStr }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateUpdatedStr">
          <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Last Updated</mat-header-cell>
          <mat-cell *matCellDef="let data" fxHide fxShow.gt-sm>
            <p class="email text-truncate">
              {{ data.dateUpdatedStr }}
            </p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let data; columns: displayedColumns"
          class="contact"
          [@animate]="{ value: '*', params: { y: '100%' } }"
        >
        </mat-row>
      </table>
      <mat-paginator
        style="background: transparent;"
        *ngIf="!isLoading && widgetsItems"
        #paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalRecords"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        (page)="pageEvent = getWidgets($event)"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
