import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment-timezone';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { SubSink } from 'subsink';
import { AppConstants } from 'app/core/settings/appconstants';
import { AppLoadService } from '../../../core/services/appload.service';
import { TenantPing } from '../../../core/models/tenantPing';
import { Router } from '@angular/router';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  envName = `${environment.version}`;
  lastLoginTimeStr: any;
  pages = [];
  isPublicView: boolean = true;
  subs = new SubSink();
  _tenantConfig: TenantPing;
  legalPages = [];

  /**
   * Constructor
   */
  constructor(
    private appSettingsService: AppSettingsService,
    private _fuseNavigationService: FuseNavigationService,
    private _appLoadService: AppLoadService,
    private router: Router,

  ) {}

  ngOnInit() {
    this._fuseNavigationService.onNavigationChanged.subscribe(key => {
      if (key === AppConstants.INITIAL_EVENT || key === AppConstants.PUBLIC_NAVIGATION) {
        this.isPublicView = true;
        return;
      } else {
        this.isPublicView = false;
        this.getVersion();
      }
    });
    const lastLoginTime = moment().format('ddd MMM-DD-YYYY hh:mm A'); //stores the login activity
    this.lastLoginTimeStr = lastLoginTime;
    this._appLoadService.onTenantConfigChanged.subscribe((tenantConfig: any) => {
      this._tenantConfig = tenantConfig;
      this.pages = this._tenantConfig.web.filter(element => element.isShowFooter && !element.isExternalLink).sort((a, b) => a.sequence - b.sequence);
      this.legalPages = this._tenantConfig.web
        .filter(element => element.isLegal)
        .sort((a, b) => a.sequence - b.sequence);
    });
  }

  getVersion() {
    this.subs.add(
      this.appSettingsService.getAppVersion().subscribe(
        (version: any) => {
          this.envName = version;
        },
        error => {},
      ),
    );
  }

  public openInNewTab(url:string) {
    return this.router.navigate([]).then(res => {  window.open(url, '_blank'); })
  }
}
