<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
    <div
      class="header accent p-24 h-100"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center"
    >
      <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class=" h1 mr-12">settings</mat-icon>
          <span class=" logo-text h2">Widget</span>
        </div>
      </div>
      <div>
        <a mat-mini-fab class="mr-4" [routerLink]="['/settings/widgets']">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
         
      </div>
    </div>
  
    <div id="attendees" style="width: 100%;padding-top: 10px;" class="center">
      <h1 class="m-0" style="padding-bottom: 10px;">Widget <span style="font-size: 14px;">> Edit widget</span></h1>
       <div
        class="content"
        style="border: 1px solid #0000001f;
       border-radius: 12px; display: block;"
      >
      <form fxLayout="column" [formGroup]="basicInfoForm" style="align-items: center;">
         <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1 0 auto" class="mt-24">
          <span class="h2" style="color:#00C6D8;">Widget Info</span>
          <span class="secondary-text">Lets add basic info for your widget.</span>
        </div>

        <div fxFlex="1 0 auto" fxLayout="column" style="width:70%;padding-bottom: 10px;">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" required />
            <mat-error>Name is required!</mat-error>
          </mat-form-field>
        
          <div  fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field appearance="outline" fxFlex="100" matNativeControl style="width: 100%;">
                <mat-label>Type</mat-label>
                <mat-select name="type" name="type" formControlName="type" [disabled]="isEditMode" >
                <mat-option *ngFor="let type of types" [value]="type.key">
                    {{ type.value }}
                </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div  fxLayout="row" fxLayoutAlign="start start"> 
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Url</mat-label>
                <input matInput  formControlName="misc" required />
                <mat-error>Url is required!</mat-error>
              </mat-form-field>
        </div>

        <div  fxLayout="row" fxLayoutAlign="start start"> 
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Width (px)</mat-label>
                <input matInput type="number" formControlName="width"  />
                <mat-error>Width is required!</mat-error>
            </mat-form-field>
        </div>
        <div  fxLayout="row" fxLayoutAlign="start start"> 
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Height (px)</mat-label>
                    <input matInput type="number" formControlName="height"  />
                    <mat-error>Height is required!</mat-error>
                </mat-form-field>
            </div>


        <div fxLayout="row" fxLayoutAlign="end" style="margin-left: auto">
            <button mat-raised-button tabindex="-1" style="margin-right: 10px;" [routerLink]="['/settings/widgets']" >cancel</button>
            <button
              class="mat-accent m-0"
              mat-raised-button
              tabindex="1" 
              aria-label="Add"
              [disabled]="!basicInfoForm.valid" 
              (click)="finishSetup();"
            >
              Submit
        
              <mat-icon *ngIf="isLoading">
                <mat-spinner diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
          </div>

        </div>
      </form>
         
      </div>
    </div>
  </div>
