import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SubSink} from "subsink";
import {Router, ActivatedRoute} from "@angular/router";
import {ExternalWebsiteService} from "../external-website/externalwebsite.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "website-builder-sidebar",
    templateUrl: "./website-builder-sidebar.component.html",
    styleUrls: ["./website-builder-sidebar.component.scss"]
})
export class WebsiteBuilderSidebarComponent implements OnInit {
    subs = new SubSink();
    @Output() outputToParent = new EventEmitter<any>();

    constructor(
        //  private _contactsService: ContactsService,
        public _matDialog: MatDialog,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _externalWebsiteService: ExternalWebsiteService,
        public toastr: ToastrService,
    ) {
    }

    @Input() availablePageList = [];
    @Input() internalWebsiteSelectedType: string = 'home';

    ngOnInit(): void {
    }

    public internalWebsitePageChanged(pageName) {
        this.internalWebsiteSelectedType = pageName;
        this.outputToParent.emit(pageName);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.availablePageList, event.previousIndex, event.currentIndex);
        var pagesArr = [];
        var payload = {"pages": pagesArr};

        this.availablePageList.forEach((currentValue, index) => {
            if (currentValue.id) {
                var eachProduct =
                    {
                        "id": currentValue.id,
                        "name": currentValue.name,
                        "sequence": index + 1,
                    };
                pagesArr.push(eachProduct);
            }
        });
        this._externalWebsiteService.updateInternalWebsitePageSequence(payload).subscribe(
            result => {
                this.toastr.success( 'Sequence updated.');
            },
            error => {
                this.toastr.error('Some error occured. Please try again.');
            },
        );
    }
}
