import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root"
})
export class PlanService {
  constructor(private http: HttpClient) {}

  getPlan() {
    return this.http.get(`${environment.apiBaseUrl}/rest/subscription`);
  }

  updatePlan(payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/subscription`, payload);
  }

  getMasterToken() {
    return this.http.get(`${environment.apiBaseUrl}/rest/tenantPayment/master`);
  }

  getSubscriptions(year, start, length, searchText) {
    return this.http.get(
      `${environment.apiBaseUrl}/rest/subscription/history?draw=1&year=${year}&start=${start}&length=${length}&search%5Bvalue%5D=${searchText}`
    );
  }
}
