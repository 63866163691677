import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSwitchCardComponent } from './app-switch-card.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatSlideToggleModule,
    ReactiveFormsModule
  ],
  declarations: [AppSwitchCardComponent],
  exports: [AppSwitchCardComponent]
})
export class AppSwitchCardModule { }
