import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, catchError, tap, flatMap } from "rxjs/operators";
import { AppConstants } from "app/core/settings/appconstants";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { LocalstorageService } from "app/core/services/applocalstorage.service";
import * as moment from "moment-timezone";
import { StripeService } from "app/core/services/stripe.service";

@Injectable({
  providedIn: "root"
})
export class CheckoutUtilService {
  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private localStorageService: LocalstorageService,
    private stripeService: StripeService
  ) {}

  getPercentageVal(base: any, pct: any): any {
    let percentageVal = ((base * pct) / 100).toFixed(2);
    return +percentageVal;
    //return Math.floor((base * pct) / 100);
  }
  enrichMembership(aItem: any, membership: any) {
    let recurringInfo = {
      isFirstMonthFees: false,
      isLastMonthFees: false,
      processingFees: 0,
      nextBillingPeriod: "",
      hasTrialPeriod: "",
      trialDuration: "",
      billingFreqText: "",
      billingEndsAfter: ""
    };
    if (recurringInfo.isFirstMonthFees) {
      recurringInfo.nextBillingPeriod = "After 1 Month";
      if (membership.billingDayOfMonth) {
        recurringInfo.nextBillingPeriod =
          recurringInfo.nextBillingPeriod + "On " + membership.billingDayOfMonth + "day of Month";
      }
    } else {
      if (membership.billingDayOfMonth) {
        recurringInfo.nextBillingPeriod = "On " + membership.billingDayOfMonth + "day of Month";
      } else {
        recurringInfo.nextBillingPeriod = "Today";
      }
    }
    if (membership.hasTrialPeriod) {
      recurringInfo.hasTrialPeriod = membership.hasTrialPeriod;
      recurringInfo.trialDuration = membership.trialDuration + " " + membership.trialDurationUnit;
      recurringInfo.nextBillingPeriod = "After Trial Period";
    }
    recurringInfo.billingFreqText = membership.billingFreqText;

    if (membership.numberOfBillingCycles > 0) {
      let billingCycles = parseInt(membership.numberOfBillingCycles);
      recurringInfo.billingEndsAfter = billingCycles + " billing cycles";
    } else {
      recurringInfo.billingEndsAfter = "Manual Request has been made";
    }
    return recurringInfo;
  }
  enrichPublicRecurringMembership(aItem: any, membership: any) {
    return this.enrichMembership(aItem, membership);
  }
  calculateCartSummary(
    item: any,
    user: any,
    recurringMembership: any,
    offerToApply: any,
    taxPercent: any,
    cardFeesPercent: any,
    isDonationItem: any,
    bankFeesPercent: any,
    applyCardFees: any
  ) {

    if (applyCardFees == undefined) {                               //Force Apply CardFees
      applyCardFees = true;
    }

    taxPercent = taxPercent == undefined ? 0 : taxPercent;          //Patch
    let result = {
      totalPrice: item.price,
      totalTax: 0,
      cardFees: 0,
      totalTaxSub: 0,
      totalTaxReg: 0,
      cardFeesOnPriceAmt: 0,
      cardFeesOnAmt: 0,
      recurringInfo: {
        isFirstMonthFees: false,
        isLastMonthFees: false,
        processingFees: 0,
        nextBillingPeriod: "",
        hasTrialPeriod: "",
        trialDuration: "",
        billingFreqText: "",
        billingEndsAfter: ""
      },
      itemDiscount: 0,
      showTaxable: false,
      showCardFees: false,
      taxPercent: 0.0,
      cardPercent: 0,
      bankFees: 0,
      bankPercent: 0,
      showBankFees: false
    };

    item.subscriptionPrice = 0;                                   // Reset Subscription Price

    if (item.isOtherPrice) {
      result.totalPrice = user.otherPrice ? user.otherPrice : result.totalPrice ? result.totalPrice : 0;  //Apply Other Price
    }
    
    //RECURRING PAYMENT
    if (item.membershipDetails.membershipType === AppConstants.Item.MEMBERSHIP_TYPE_RECURRING) {
      item.subscriptionPrice = item.price;
      result.recurringInfo = this.enrichPublicRecurringMembership(item, recurringMembership);
      result.recurringInfo.processingFees = item.membershipDetails.registrationFees;
      result.totalPrice = result.recurringInfo.processingFees;
      result.totalPrice += item.subscriptionPrice;

      if (result.recurringInfo.isFirstMonthFees) {
        result.totalPrice += item.price;
      }

      if (result.recurringInfo.isLastMonthFees) {
        result.totalPrice += item.price;
      }

      if(item.isBillFromNextMonth) {
        result.totalPrice = result.totalPrice - item.subscriptionPrice;
      }
    
    }

    let registrationFees = parseFloat(item.membershipDetails.registrationFees || 0);
    let itemPrice = parseFloat(item.price || 0);

    //offer application
    if (offerToApply) {    
      if (item.membershipDetails.membershipType === AppConstants.Item.MEMBERSHIP_TYPE_RECURRING) {
        registrationFees -= item.discount;
      }
      else {
        itemPrice -= item.discount;
      }
      result.totalPrice -= item.discount;
    }
    
    if (applyCardFees && item.isChargeCreditCardFees) {
        result.showCardFees = true;
        result.cardPercent = cardFeesPercent;
        result.bankPercent = bankFeesPercent;       
        result.cardFeesOnPriceAmt = this.getPercentageVal(itemPrice, cardFeesPercent); //On Subscription Fee
        result.cardFeesOnAmt = this.getPercentageVal(registrationFees, cardFeesPercent); //On Registration fee
        result.cardFees = this.getPercentageVal(result.totalPrice, cardFeesPercent);
        result.totalPrice = parseFloat(result.totalPrice) + result.cardFees; //change
    } 

    //TAX CALCULATION
    if (item.isTaxable) {
      result.showTaxable = true;
      result.taxPercent = taxPercent;
      result.totalTaxSub = this.getPercentageVal(itemPrice + result.cardFeesOnPriceAmt, result.taxPercent);
      result.totalTaxReg = this.getPercentageVal(registrationFees + result.cardFeesOnAmt, result.taxPercent);
      result.totalTax = this.getPercentageVal(result.totalPrice, result.taxPercent);
      result.totalPrice = parseFloat(result.totalPrice) + result.totalTax;
    }
  
    if (item.qty && item.qty > 1) {
      result.totalPrice = result.totalPrice * item.qty;
    }

    if (result.totalPrice) {
      result.totalPrice = parseFloat(result.totalPrice).toFixed(2);
    }

    return result;
  }

  isEligibleForOfferDiscount(
    item: any,
    offerToApply: any,
    firstOrSecondItemDiscount: any,
    offerAppliedOnSecondItemsCount: any
  ) {
    let isEligible = false;
    let offerItemCategory = offerToApply.primaryItemCategory;
    let offerOnItem = offerToApply.primaryItem;
    if (firstOrSecondItemDiscount === AppConstants.Offer.SECOND_ITEM_DISCOUNT) {
      offerItemCategory = offerToApply.secondaryItemCategory;
      offerOnItem = offerToApply.secondaryItem;
    }
    if (
      offerItemCategory == AppConstants.Offer.ITEMCATETORY_ITEM ||
      offerItemCategory == item.itemType ||
      offerItemCategory == AppConstants.Offer.ITEMCOURSE
    ) {
      isEligible = true;
    } else if (
      (offerOnItem != null && offerOnItem.name == item.name) ||
      (offerOnItem != null && offerOnItem.name == item.category.name)
    ) {
      isEligible = true;
    }
    if (isEligible == true && firstOrSecondItemDiscount === AppConstants.Offer.SECOND_ITEM_DISCOUNT) {
      //check on the quantity
      if (offerAppliedOnSecondItemsCount >= offerToApply.secondaryItemCount) {
        isEligible = false;
      }
    }
    if (item.itemType == AppConstants.Offer.ITEMCATETORY_ACCOUNT || item.isOtherPrice == true) {
      isEligible = false;
    }

    if (!isEligible) {
      isEligible = true;
    }


    // if (item.membershipType == byConstants.Item.MEMBERSHIP_TYPE_RECURRING &&
    //   $scope.offerToApply.primaryDiscountType === byConstants.PERCENTAGE) {
    //   console.log("percentage discounts cannot be applied to Recurring memberships")
    //   isEligible = false
    // }
    return isEligible;
  }

  calculateAndStoreDiscount(item: any, priceToDiscount: any, offerToApply: any, firstOrSecondItemDiscount: any) {
    let discount = 0;
    let discountVal = offerToApply.discount;
    let discountType = offerToApply.discountType; //AppConstants.PERCENTAGE;
    // if (firstOrSecondItemDiscount == AppConstants.Offer.FIRST_ITEM_DISCOUNT) {
    //   discountVal = offerToApply.primaryDiscount;
    //   discountType = offerToApply.primaryDiscountType;
    // } else if (firstOrSecondItemDiscount === AppConstants.Offer.SECOND_ITEM_DISCOUNT) {
    //   discountVal = offerToApply.secondaryDiscount;
    //   discountType = offerToApply.secondaryDiscountType;
    // } else {
    //   return discount;
    // }

    if (discountType === AppConstants.PERCENTAGE) {
      let percentageDisc = ((discountVal / 100) * priceToDiscount).toFixed(2);
      discount = parseFloat(percentageDisc);
      item.discountPercent = discountVal;
    } else {
      discount = discountVal;
      item.discountPercent = 0;
    }
    // item.discount = discount;
    return discount;
  }

  processOffer(offerToApply: any, recurringMembership: any, item: any, offerAppliedOnSecondItemsCount: any) {
    let offerType = offerToApply.offerType;
    let result = {
      discount: 0,
      priceToDiscount: 0,
      offerId: "",
      isOfferProcessed: false
    };

    if (recurringMembership) {
      result.priceToDiscount = item.membershipDetails.registrationFees;
    } else {
      result.priceToDiscount = item.price;
    }
    let FIRST_ITEM_DISCOUNT = AppConstants.Offer.FIRST_ITEM_DISCOUNT;
    // if (offerType === "flat") {
    if (this.isEligibleForOfferDiscount(item, offerToApply, FIRST_ITEM_DISCOUNT, offerAppliedOnSecondItemsCount)) {
      let discount = this.calculateAndStoreDiscount(item, result.priceToDiscount, offerToApply, FIRST_ITEM_DISCOUNT);
      result.offerId = offerToApply.guId;
      result.isOfferProcessed = true;
      result.discount = discount;
    }
    // }
    return result;
  }
  discountIsValid(item: any, offerToApply: any) {
    var errorMessage =
      "Offer code " + "'" + offerToApply.offerCode + "'" + " is invalid. Discount is greater than amount";
    if (
      item.membershipDetails != null &&
      item.membershipDetails.membershipType != "recurring" &&
      item.discount > item.price
    ) {
      alert(errorMessage);
      return false;
    } else if (
      item.membershipDetails != null &&
      item.membershipDetails.membershipType == "recurring" &&
      item.isDiscountSubscriptionFees == true
    ) {
      if (offerToApply.discountType === AppConstants.PERCENTAGE) {
        let percentageDisc = ((offerToApply.discount / 100) * item.price).toFixed(2);
        let discount = parseFloat(percentageDisc);
        if (discount > item.price) {
          alert(errorMessage);
          return false;
        }
      } else {
        if (offerToApply.discount > item.price) {
          alert(errorMessage);
          return false;
        }
      }

      if (
        item.membershipDetails != null &&
        item.membershipDetails.membershipType == "recurring" &&
        item.membershipDetails.registrationFees != null &&
        item.membershipDetails.registrationFees > 0 &&
        item.discount > item.membershipDetails.registrationFees
      ) {
        alert(errorMessage);
        return false;
      }
    } else if (
      item.membershipDetails != null &&
      item.membershipDetails.membershipType == "recurring" &&
      item.membershipDetails.registrationFees != null &&
      item.membershipDetails.registrationFees > 0 &&
      item.discount > item.membershipDetails.registrationFees
    ) {
      alert(errorMessage);
      return false;
    } else if (item.membershipDetails == null && item.discount > item.price) {
      alert(errorMessage);
      return false;
    }
    return true;
  }

  buildPSpringCardData(cardOwnerName: any, cardNumber: any, cardExpMonth: any, cardExpYear: any, csc: any) {
    console.log("cardOwnerName", cardOwnerName);
    return {
      card_owner_name: cardOwnerName,
      card_number: cardNumber.split(/\s+/).join(""),
      card_exp_month: cardExpMonth,
      card_exp_year: cardExpYear,
      csc: csc
    };
  }

  buildPSpringACHData(
    tokenType: any,
    bankAccountNumber: any,
    bankRoutingNumber: any,
    bankAccountHolderFirstName: any,
    bankAccountHolderLastName: any,
    bankAccountType: any
  ) {
    return {
      token_type: tokenType,
      bank_account_number: bankAccountNumber,
      bank_routing_number: bankRoutingNumber,
      bank_account_holder_first_name: bankAccountHolderFirstName,
      bank_account_holder_last_name: bankAccountHolderLastName,
      bank_account_type: bankAccountType
    };
  }
  getLastName(name) {
    var lastName = "";
    if (name.length > 1) {
      for (var i = 1; i < name.length; i++) {
        lastName = lastName + name[i];
        if (i < name.length - 1) {
          lastName += " ";
        }
      }
    }
    return lastName;
  }

  initUser() {
    return {
      name: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      fullName: "",
      note: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: ""
      },
      otherPrice: "",
      emergencyContactPhone: "",
      emergencyContactName: "",
      dob: "",
      dateOfBirth: null,
      isWaiverFormToBeSigned: false,
      customFields: [],
      guardians: []
    };
  }

  initCartSummary() {
    return {
      totalPrice: 0,
      totalTax: 0,
      cardFees: 0,
      bankFees: 0,
      recurringInfo: {},
      itemDiscount: 0,
      showTaxable: false,
      showCardFees: false,
      showBankFees: false
    };
  }
  createCardForm(): FormGroup {
    return this._formBuilder.group({
      cardOwnerName: [this.localStorageService.getItem(AppConstants.FULLNAME), Validators.required],
      cardNumber: [],
      cardExpMonth: ["MM"],
      cardExpYear: ["YY"],
      csc: []
    });
  }
  createBankForm(): FormGroup {
    let firstName = this.localStorageService.getItem(AppConstants.FULLNAME);
    let lastName = "";
    if (firstName) {
      var tempName = firstName.split(" ");
      if (tempName && tempName.length > 1) {
        lastName = this.getLastName(tempName);
        firstName = tempName[0];
      }
    }

    this.localStorageService.getItem(AppConstants.FULLNAME);
    return this._formBuilder.group({
      bankAccountHolderFirstName: [firstName, Validators.required],
      bankAccountHolderLastName: [lastName, Validators.required],
      bankAccountNumber: [""],
      bankRoutingNumber: [""],
      bankAccountType: ["checking"]
    });
  }

  setUserInfoLocalStorage(user: any) {
    this.localStorageService.setItem(AppConstants.FIRSTNAME, user.firstName);
    this.localStorageService.setItem(AppConstants.LASTNAME, user.lastName);
    this.localStorageService.setItem(AppConstants.FULLNAME, user.fullName);
    this.localStorageService.setItem(AppConstants.EMAIL, user.email);
    this.localStorageService.setItem(AppConstants.PHONE, user.phone);
  }
  createUserForm(): FormGroup {
    return this._formBuilder.group({
      fullName: [
        this.localStorageService.getItem(AppConstants.FULLNAME) == null || "null"
          ? ""
          : this.localStorageService.getItem(AppConstants.FULLNAME)
      ],
      firstName: [
        this.localStorageService.getItem(AppConstants.FIRSTNAME) == null || "null"
          ? ""
          : this.localStorageService.getItem(AppConstants.FIRSTNAME),
        Validators.required
      ],
      lastName: [
        this.localStorageService.getItem(AppConstants.LASTNAME) == null || "null"
          ? ""
          : this.localStorageService.getItem(AppConstants.LASTNAME),
        Validators.required
      ],
      email: [
        this.localStorageService.getItem(AppConstants.EMAIL) == null || "null"
          ? ""
          : this.localStorageService.getItem(AppConstants.EMAIL),
        [Validators.required, Validators.email]
      ],
      phone: [
        this.localStorageService.getItem(AppConstants.PHONE) == null || "null"
          ? ""
          : this.localStorageService.getItem(AppConstants.PHONE)
      ],
      address: [""],
      note: [""],
      isWaiverFormToBeSigned: [false]
    });
  }

  getProductType(type) {
    if (type == AppConstants.PaymentType.PAID.toLowerCase()) {
      return "paidevent";
    } else if (type == AppConstants.PaymentType.FREE.toLowerCase()) {
      return "freeevent";
    } else if (type == AppConstants.PaymentType.DONATION.toLowerCase()) {
      return "donationevent";
    }
  }

  getFilterByPrice(tempArr) {
    let newArr = [];
    let otherPriceArr = [];
    if (tempArr && tempArr.length > 0) {
      tempArr.forEach(item => {
        if (item.isOtherPrice) {
          otherPriceArr.push(item);
        } else {
          newArr.push(item);
        }
      });
      newArr.sort((a, b) => {
        return a.price - b.price;
      });
      newArr = newArr.concat(otherPriceArr);
    }
    return newArr;
  }

  getItemList(item,recurringDonationDetail) {
    console.log("item", item);
    let itemList = [];
    let newItem: any = {};
    newItem.orgId = item.orgId;
    newItem.tenantId = item.tenantId;
    newItem.itemId = item.guId;
    newItem.itemName = item.name;
    if (item.eventGuId) {
      newItem.productId = item.eventGuId;
      newItem.productName = item.eventName;
      newItem.productType = this.getProductType(item.paymentType.toLowerCase());
    } else {
      newItem.productId = item.donationCategory != null ? item.donationCategory.guId : item.category.guId;
      newItem.productName = item.donationCategory != null ? item.donationCategory.name : item.category.name;
      newItem.productType = this.stripeService.getProductType(
        item.donationCategory != null ? AppConstants.PaymentType.DONATION : item.category.paymentType
      );
    }

    newItem.membershipType = item.membershipDetails ? item.membershipDetails.membershipType : "";
    newItem.courseType = newItem.productType;
    newItem.courseGuId = newItem.productId;
    if (
      item.membershipDetails &&
      item.membershipDetails.membershipType == AppConstants.Item.MEMBERSHIP_TYPE_RECURRING
    ) {
      let subscription: any = {};
      subscription.trialPeriod = item.trialPeriod == undefined ? 0 : item.trialPeriod; // not clear
      subscription.billingDay = 0; //item.billingDay;
      subscription.frequency = item.frequency;
      subscription.noOfBillingCycles = item.membershipDetails.numberOfBillingCycles - 1;
      subscription.subscriptionAmount = parseFloat(item.subscriptionAmount);
      subscription.subscriptionTotalAmount = parseFloat(item.finalSubscriptionAmount);
      subscription.subscriptionCardFees = item.cardFeesOnPriceAmt == undefined ? 0 : item.cardFeesOnPriceAmt; //TODO
      subscription.subscriptionTax = item.totalTaxSub == undefined ? 0 : item.totalTaxSub; //this.itemTenant.taxPercent; //TODO
      newItem.subscription = subscription;
      //Registration charges
      newItem.itemPrice = parseFloat(item.oneTimePayment);
      newItem.itemTotal = parseFloat(item.finalOneTimePayment);
      newItem.cardFees = item.cardFeesOnAmt == undefined ? 0 : item.cardFeesOnAmt;
      newItem.tax = item.totalTaxReg == undefined ? 0 : item.totalTaxReg;
    } else if(recurringDonationDetail.isDonationRecurring){
      let subscription: any = {};
      subscription.trialPeriod = item.trialPeriod == undefined ? 0 : item.trialPeriod; // not clear
      subscription.billingDay = 0; //item.billingDay;
      subscription.frequency = recurringDonationDetail.donationRecurringFrequency;
      subscription.noOfBillingCycles = recurringDonationDetail.donationRecurringLimit == '-1' ? recurringDonationDetail.donationRecurringLimit : (parseInt(recurringDonationDetail.donationRecurringLimit) * 12) - 1;
      subscription.subscriptionAmount = parseFloat(item.subscriptionAmount);
      subscription.subscriptionTotalAmount = parseFloat(item.finalSubscriptionAmount);
      subscription.subscriptionCardFees = item.cardFeesOnPriceAmt == undefined ? 0 : item.cardFeesOnPriceAmt; //TODO
      subscription.subscriptionTax = item.totalTaxSub == undefined ? 0 : item.totalTaxSub; //this.itemTenant.taxPercent; //TODO
      newItem.subscription = subscription;
      //Registration charges
      newItem.itemPrice = 0;
      newItem.itemTotal = 0;
      newItem.cardFees = item.cardFeesOnAmt == undefined ? 0 : item.cardFeesOnAmt;
      newItem.tax = item.totalTaxReg == undefined ? 0 : item.totalTaxReg;
      newItem.membershipType = AppConstants.Item.MEMBERSHIP_TYPE_RECURRING
    }else {
      newItem.itemPrice = parseFloat(item.subscriptionAmount);
      newItem.itemTotal = parseFloat(item.txnAmount);
      newItem.cardFees = item.cardFeesOnPriceAmt == undefined ? 0 : item.cardFeesOnPriceAmt; //TODO  this.itemTenant.bankFees;
      newItem.tax = item.totalTaxSub == undefined ? 0 : item.totalTaxSub;
    }

    newItem.total = item.txnAmount;
    newItem.discount = item.discount; //TODO
    newItem.qty = item.qty;
    newItem.currency = item.currency;
    let date = moment().add(1, "years");
    newItem.endDate = Math.floor(date.valueOf() / 1000);
    itemList.push(newItem);
    return itemList;
  }

  setItemCalc(item, result) {
    item.subscriptionAmount = item.price; //Item Price
    item.oneTimePayment = parseFloat(item.membershipDetails.registrationFees || 0)
    item.txnAmount = parseFloat(result.totalPrice);
    item.cardFeesOnPriceAmt = result.cardFeesOnPriceAmt;
    item.cardFeesOnAmt = result.cardFeesOnAmt;
    item.totalTaxSub = result.totalTaxSub;
    item.totalTaxReg = result.totalTaxReg;
    item.finalSubscriptionAmount = parseFloat(item.price) + result.cardFeesOnPriceAmt + result.totalTaxSub;
    item.finalOneTimePayment =  item.oneTimePayment + result.cardFeesOnAmt + result.totalTaxReg  - parseFloat(item.discount || 0); //Item Total

    // if (
    //   item.itemType == "membership" &&
    //   item.membershipDetails.membershipType == AppConstants.Item.MEMBERSHIP_TYPE_RECURRING
    // ) {
    //   item.oneTimePayment = item.membershipDetails.registrationFees;
    //   item.finalOneTimePayment = item.membershipDetails.registrationFees;
    // } else if (item.isOtherPrice) {
    //   item.oneTimePayment = item.txnAmount;
    //   item.finalOneTimePayment = item.txnAmount;
    // } else {
    //   item.oneTimePayment = item.price;
    //   item.finalOneTimePayment = item.price;
    // }

    // if (item.isChargeCreditCardFees) {
    //   item.cardFeesOnPriceAmt = result.cardFeesOnPriceAmt;
    //   item.cardFeesOnAmt = result.cardFeesOnAmt;
    //   if (result.cardFeesOnPriceAmt > 0) {
    //     item.finalSubscriptionAmount = parseFloat(item.price) + result.cardFeesOnPriceAmt;
    //   }
    //   if (result.cardFeesOnAmt > 0) {
    //   }
    // }
    // if (item.isTaxable) {
    //   item.totalTaxSub = result.totalTaxSub;
    //   item.totalTaxReg = result.totalTaxReg;
    //   if (result.totalTaxSub > 0) {
    //     item.finalSubscriptionAmount = parseFloat(item.finalSubscriptionAmount) + result.totalTaxSub;
    //   }
    //   if (result.totalTaxReg > 0) {
    //     item.finalOneTimePayment = parseFloat(item.finalOneTimePayment) + result.totalTaxReg;
    //   }
    // }
    // if (result.itemDiscount > 0) {
    //   item.finalOneTimePayment = item.finalOneTimePayment - result.itemDiscount;
    // }
    // console.log("setItemCalc ", item);
    return item;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
