<div id="user-settings" class="page-layout carded fullwidth">
  <!-- HEADER -->

  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        settings
      </mat-icon>
      <span class="logo-text h1">
        Settings
      </span>
    </div>
    <!-- <div style="float: right;">
        <button mat-icon-button (click)="goBack()" matTooltip="Back " aria-label="Back">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div> -->
  </div>

  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content bg-color-whiteSmoke p-24">
    <div fxLayout="column" fxLayoutAlign="center">
      <div class="settings" fxLayout="row wrap" fxLayoutAlign="center">
        <div *ngIf="isLoading" class="setting courses pb-8 pt-8" style="width: 100%;">
          <div class="setting  bg-color-white" fxFlex="100" style="width: 100%;">
            <ngx-skeleton-loader
              class="course"
              count="4"
              appearance="circle"
              [theme]="{
                'border-radius': '4px',
                height: '200px',
                width: '24%',
                background: 'ffffff',
                'box-shadow': '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>

        <div class="setting" *ngFor="let setting of settingsList" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="25">
          <div
            class="setting-content bg-color-white"
            fxLayout="column"
            fxFlex="1 1 auto"
            (click)="navigateTo(setting.url)"
          >
            <div *ngIf="setting.icon !== 'wysiwyg'" class="setting_icon mt-32 bg-color-whiteSmoke">
              <mat-icon class="icon-size-10 txt-color-themeBlue">{{ setting.icon }}</mat-icon>
            </div>
            <!-- <div *ngIf = "setting.icon !== 'wysiwyg'" mat-button class="header" fxLayout="row" fxLayoutAlign="center center">
                            <div class="h1 box" fxFlex>
                                <mat-icon class="app-icon">{{setting.icon}}</mat-icon>
                            </div>
                        </div> -->
            <div
              *ngIf="setting.icon !== 'wysiwyg'"
              mat-button
              class="header"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div class="h1 box" fxFlex>
                {{ setting.title }}
              </div>
            </div>
            <div
              *ngIf="setting.icon === 'wysiwyg'"
              mat-button
              class="header"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <div class="h1 box" fxFlex>
                {{ setting.title }}
              </div>
            </div>

            <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
              <div class="updated">{{ setting.description }}</div>
            </div>
          </div>
        </div>

        <div class="no-settings" *ngIf="!isLoading && settingsList && settingsList.length === 0">
          No Settings found!
        </div>
      </div>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
<!-- <a [routerLink]="['/integrations']" routerLinkActive="router-link-active">sss</a> -->
