import { CustomFieldSettingComponent } from "./custom-field-setting/custom-field-setting.component";
import { RolesSettingComponent } from "./roles-setting/roles-setting.component";
import { ColorPickerModule } from "ngx-color-picker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { LayoutModule } from "app/layout/layout.module";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { AdminGuard } from "app/admin.guard";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import {TextFieldModule} from '@angular/cdk/text-field';
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FileuploadModule } from "app/main/shared/components/file-upload/file-upload.module";
import { PhoneNumberModule } from "app/main/shared/components/phone-number/phone-number.module";
import { VideoUploadModule } from "app/main/shared/components/video-upload/video-upload.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackbarService } from "app/main/shared/helpers/snackbar.service";
import { AppSettingsComponent } from "./app-settings.component";
import { AppSettingsListComponent } from "./app-settings-list/app-settings-list.component";
import { AccountSettingComponent } from "./account-setting/account-setting.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { DisplaySettingComponent } from "./display-setting/display-setting.component";
import { CommonModule } from "@angular/common";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UserListComponent } from "./user-setting/user-list/user-list.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { StudentAppSettingComponent } from "./student-app-setting/student-app-setting.component";
import { UserDetailComponent } from "./user-setting/user-detail/user-detail.component";
import { FuseSidebarModule, FuseConfirmDialogModule } from "@fuse/components";
import { AppSidebarModule } from "app/main/shared/components/app-sidebar/app-sidebar.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AppSwitchCardModule } from "app/main/shared/components/app-switch-card/app-switch-card.module";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgxSpinnerModule } from "ngx-spinner";
import { ConfirmationDialogModule } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.module";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddCustomFieldDlgComponent } from "./custom-field-setting/add-custom-field-dlg/add-custom-field-dlg.component";
// import { IntegrationsSettingComponent } from './integrations-setting/integrations-setting.component';
import { IntegrationsSettingModule } from "./integration-settings/integrations-setting.module";
import { IntegrationsSettingComponent } from "./integration-settings/integrations-setting.component";
import { MailchimpIntegrationComponent } from "./integration-settings/mailchimp/mailchimp-integration.component";
import { PaymentsComponent } from "./integration-settings/payments/payments.component";
import { AppSearchContactModule } from "app/main/shared/components/app-search-contact/app-search-contact.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AppPipesModule } from "app/main/shared/pipes/apppipes.module";
import { CustomFormPreviewModule } from "app/main/shared/components/custom-form-preview/custom-form-preview.module";
import { TwilioComponent } from "./integration-settings/twilio/twilio.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { NgxMaskModule } from "ngx-mask";
import { WebsiteSettingComponent } from "./website-setting/website-setting.component";
import { WebsiteSettingModule } from "./website-setting/website-setting.module";
import { ExternalWebsiteComponent } from "./website-setting/external-website/external-website.component";
import { SeoSettingComponent } from "./website-setting/seo-setting/seo-setting.component";
import { UploadFileModule } from "app/main/shared/components/upload-file/upload-file.module";
import { WidgetComponent } from "app/main/admin/app-settings/widget/widget.component";
import { WidgetEditComponent } from "app/main/admin/app-settings/widget/widget-edit/widget-edit.component";
import { WidgetModule } from "app/main/admin/app-settings/widget/widget.module";
import { FranchiseModule } from "./franchise/franchise.module";
import { PlanModule } from "app/main/admin/app-settings/plan/plan.module";
import { PlanComponent } from "app/main/admin/app-settings/plan/plan.component";
import { UpgradeComponent } from "app/main/admin/app-settings/plan/upgrade/upgrade.component";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PaymentAccountSettingComponent } from "./payment-account-setting/payment-account-setting.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { PlivoComponent } from "./integration-settings/plivo/plivo.component";
import { BrevoComponent } from "./integration-settings/brevo/brevo.component";
import { TemplateAddEditComponent } from "./templates-setting/template-add-edit/template-add-edit.component";
import { TemplateListComponent } from "./templates-setting/template-list/template-list.component";
import { QuillModule } from "ngx-quill";
import {ConnectEmailComponent} from "./connect-email/connect-email/connect-email.component";
import {ConnectEmailPageComponent} from "./connect-email/connect-email-page/connect-email-page.component";

const routes = [
  {
    path: "",
    component: AppSettingsComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: "franchise",
        loadChildren: () => import("./franchise/franchise.module").then(m => m.FranchiseModule)
      },
      {
        path: "",
        component: AppSettingsListComponent
      },
      {
        path: "account",
        component: AccountSettingComponent
      },
      {
        path: "display",
        component: DisplaySettingComponent
      },
      {
        path: "integrations",
        component: IntegrationsSettingComponent
      },
      {
        path: "integrations/mailchimp",
        component: MailchimpIntegrationComponent
      },
      {
        path: "integrations/paymentprovider",
        component: PaymentsComponent
      },
      {
        path: "integrations/emails",
        component: ConnectEmailPageComponent
      },
      {
        path: "integrations/cloud",
        component: TwilioComponent
      },
      {
        path: "integrations/plivo",
        component: PlivoComponent
      },
      {
        path: "integrations/brevo",
        component: BrevoComponent
      },
      {
        path: "users/:id",
        component: UserDetailComponent
      },
      {
        path: "users",
        component: UserListComponent
      },
      {
        path: "studentapp",
        component: StudentAppSettingComponent
      },
      {
        path: "roles",
        component: RolesSettingComponent
      },
      {
        path: "customfield",
        component: CustomFieldSettingComponent
      },
      {
        path: "website",
        component: WebsiteSettingComponent
      },
      {
        path: "externalwebsite",
        component: ExternalWebsiteComponent
      },
      {
        path: "seosetting",
        component: SeoSettingComponent
      },
      {
        path: "widgets",
        component: WidgetComponent
      },
      {
        path: "widgets/:id",
        component: WidgetEditComponent
      },
      {
        path: "plan",
        component: PlanComponent
      },
      {
        path: "plan/:type",
        component: UpgradeComponent
      },
      {
        path: "paccount",
        component: PaymentAccountSettingComponent
      },
      {
        path: "integrations/paymentprovider/success",
        component: PaymentSuccessComponent
      },
      {
        path: "templates",
        component: TemplateListComponent
      },
      {
        path: "templates/add",
        component: TemplateAddEditComponent
      },
      {
        path: "templates/edit/:id",
        component: TemplateAddEditComponent
      }
    ]
  },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    FuseSharedModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,
    LayoutModule,
    FileuploadModule,
    PhoneNumberModule,
    VideoUploadModule,
    NgxSkeletonLoaderModule,
    ColorPickerModule,
    FuseSidebarModule,
    FuseConfirmDialogModule,
    AppSidebarModule,
    AppSwitchCardModule,
    NgxSpinnerModule,
    ConfirmationDialogModule,
    FormsModule,
    ReactiveFormsModule,
    IntegrationsSettingModule,
    AppSearchContactModule,
    DragDropModule,
    AppPipesModule,
    CustomFormPreviewModule,
    ClipboardModule,
    WebsiteSettingModule,
    WidgetModule,
    PlanModule,
    UploadFileModule,
    FranchiseModule,
    // NgMultiSelectDropDownModule
    QuillModule,
    TextFieldModule,
    FlexLayoutModule
  ],
  declarations: [
    AppSettingsComponent,
    AppSettingsListComponent,
    AccountSettingComponent,
    DisplaySettingComponent,
    UserListComponent,
    StudentAppSettingComponent,
    UserDetailComponent,
    RolesSettingComponent,
    CustomFieldSettingComponent,
    AddCustomFieldDlgComponent,
    PaymentAccountSettingComponent,
    PaymentSuccessComponent,
    TemplateAddEditComponent,
    TemplateListComponent
    // IntegrationsSettingComponent
  ],
  providers: [SnackbarService]
})
export class AppSettingsModule {}
