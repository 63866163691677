import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstants } from "app/core/settings/appconstants";
import { Settings } from "app/main/shared/enums/settings-list";
import { AppUtilsService } from "app/core/services/apputils.service";
import { SubSink } from "subsink";
import { AppLoadService } from "app/core/services/appload.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";

@Component({
  selector: "app-app-settings-list",
  templateUrl: "./app-settings-list.component.html",
  styleUrls: ["./app-settings-list.component.scss"]
})
export class AppSettingsListComponent implements OnInit {
  settingsList: any[];
  subs = new SubSink();
  tenant: any;
  isLoading: any = false;
  constructor(
    private _router: Router,
    private _appLoadService: AppLoadService,
    private _appUtilsService: AppUtilsService,
    private appSettingsService: AppSettingsService
  ) {
    this.settingsList = [];
    this.getTenantConfig();
  }

  navigateTo(url) {
    this._router.navigate([AppConstants.USER_SETTINGS, url]);
  }

  ngOnInit(): void {}

  getTenantConfig() {
    this.isLoading = true;
    this.subs.add(
      this.appSettingsService.getAccountDetail().subscribe(
        (tenantConfig: any) => {
          this.tenant = tenantConfig;
          if (this.tenant.isMasterFranchise) {
            this.settingsList = Settings;
          } else {
            for (let i = 0; i < Settings.length; i++) {
              if (Settings[i].icon != "business") {
                this.settingsList.push(Settings[i]);
              }
            }
          }
          this.isLoading = false;
        },
        errorResp => {
          this.isLoading = false;
        }
      )
    );
  }
}
