import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Contact } from "app/main/shared/models/contact.model";
import { Tenant } from "app/core/models/tenant";
import { TenantService } from "app/core/services/tenant.service";
import { SubSink } from "subsink";
import { AppUtilsService } from "app/core/services/apputils.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";

@Component({
  selector: "app-contact-dlg",
  templateUrl: "./add-contact-dlg.component.html",
  styleUrls: ["./add-contact-dlg.component.scss"]
})
export class AddContactDlgComponent implements OnInit {
  action: string;
  contact: Contact;
  contactForm: FormGroup;
  dialogTitle: string;
  isLoading: boolean = false;
  phone: any;
  tenant: any;
  subs = new SubSink();
  itemTenant: any;
  /**
   * Constructor
   *
   * @param {MatDialogRef<AddContactDlgComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<AddContactDlgComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _settingsService: AppSettingsService,
    private tenantService: TenantService
  ) {
    // Set the defaults
    this.action = _data == null ? "add" : _data.action;

    if (this.action === "edit") {
      this.dialogTitle = "Edit Contact";
      this.contact = _data.contact;
    } else {
      this.dialogTitle = "Create Contact";
      this.contact = new Contact({});
    }

    this.contactForm = this.createContactForm();
  }

  ngOnInit() {
    this.getTenantService();
    this.getItemTenant();
    if (this._data?.value?.length > 0) {
      this.contactForm.get("firstName").setValue(this._data.value[0]);
      this.contactForm.get("lastName").setValue(this._data?.value[1]);
    } else {
      this.contactForm.get("firstName").setValue("");
      this.contactForm.get("lastName").setValue("");
    } 
  }

  getItemTenant() {
    this.subs.add(
      this._settingsService.getTenantDetail().subscribe(
        result => {
          this.isLoading = false;
          let resultData: any = result;
          this.itemTenant = resultData.data;
          if (this.action === "edit") {
            if (this.contact.phone) {
              this.phone = this.contact.phone;
            } else {
              this.getCountryCode(this.itemTenant);
            }
          } else {
            this.getCountryCode(this.itemTenant);
          }
        },
        error => {}
      )
    );
  }


 

  getCountryCode(tenant) {
    this.phone = AppUtilsService.getCountryDialCodeByCode(tenant.defaultCurrency) + "-";
  }

  getTenantService() {
    this.subs.add(
      this.tenantService.tenant$.subscribe((tenant: Tenant) => {
        console.log("tenant", tenant);
        this.tenant = tenant;
      })
    );
  }

  getPhoneNumber(phoneNumber: string) {
    this.phone = phoneNumber;
    this.contact.phone = this.phone;
    this.contactForm.get("phone").setValue(this.phone);
    console.log("phone ", this.phone);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create contact form
   *
   * @returns {FormGroup}
   */
  createContactForm(): FormGroup {
    return this._formBuilder.group({
      name: [this.contact.name],
      firstName: [this.contact.firstName],
      lastName: [this.contact.lastName],
      email: [this.contact.email],
      phone: [this.contact.phone],
      grnNumber: [""],
      password: [""],
      hasSignedWaiverForm: [this.contact.hasSignedWaiverForm],
      subscribeToMailingList: [this.contact.subscribeToMailingList]
    });
  }

  submit() {
    this.contactForm.get("name").setValue(this.contactForm.get("firstName").value);
    this.contactForm.get("lastName").setValue(this.contactForm.get("lastName").value);
    this.contactForm.get("firstName").setValue(this.contactForm.get("firstName").value);
    this.matDialogRef.close(this.contactForm);
  }
}
