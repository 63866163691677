import { Component, OnInit } from "@angular/core";
import { AppSettingsService } from "../services/app-settings.service";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
  animations: fuseAnimations
})
export class PaymentSuccessComponent implements OnInit {
  isLoading: boolean = false;
  subs = new SubSink();
  isConnected: boolean = false;

  constructor(
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.SpinnerService.show();
    this.getConnectedAccount();
  }

  backToPaymentComponent() {}

  getConnectedAccount() {
    let payload = {};
    this.subs.add(
      this._settingsService.getConnectedStripeAccount().subscribe(
        result => {
          this.isConnected = true;
          this.isLoading = false;
          this.SpinnerService.hide();
          console.log("result", result);
          this._router.navigate(["settings/integrations/paymentprovider"]);
        },
        errorResp => {
          this.isLoading = false;
          console.log("error", errorResp);
          this.toastr.error(errorResp.error.errors.message);
          this.SpinnerService.hide();
        }
      )
    );
  }
}
