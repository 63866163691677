export const Settings: any[] = [
  {
    title: 'Account',
    description: 'Account Settings',
    url: 'account',
    icon: 'account_circle',
  },
  {
    title: 'Website',
    description: 'Add External website and SEO keywords',
    url: 'website',
    icon: 'language',
  },
  {
    title: 'Module Settings',
    description: 'Module settings for your studio, changes will get applied immediately',
    url: 'display',
    icon: 'desktop_windows',
  },
  {
    title: 'Payment Settings',
    description: 'Payment Settings',
    url: 'paccount',
    icon: 'settings_applications',
  },
  {
    title: 'Integrations',
    description: 'Third Party Integrations',
    url: 'integrations',
    icon: 'share',
  },
  /*{
    title: "Student App",
    description: "Student Setting",
    url: "studentapp",
    icon: "account_box"
  },*/
  {
    title: 'Users',
    description: 'Add users to your account with appropriate permissions',
    url: 'users',
    icon: 'supervisor_account',
  },
  {
    title: 'Roles',
    description: 'Control User Roles, Functionality/Screens will only be allowed based on permissions given',
    url: 'roles',
    icon: 'supervised_user_circle',
  },
  {
    title: 'Custom Fields',
    description: 'Create custom fields for your contacts.',
    url: 'customfield',
    icon: 'text_fields',
  },
  // {
  //   title: 'Custom Forms',
  //   description: 'Create custom registration forms',
  //   url: 'customforms',
  //   icon: 'text_format',
  // },
  // {
  //   title: 'Website Widgets',
  //   description: 'Website widgets to be embeded in your website more descripiton',
  //   url: 'widgets',
  //   icon: 'widgets',
  // },
  {
    title: 'Franchise',
    description: 'Add or manage your franchises.',
    url: 'franchise',
    icon: 'business',
  },
  {
    title: 'Templates',
    description: 'Manage your email and success screen templates.',
    url: 'templates',
    icon: 'dvr',
  },
  {
    title: 'Plans',
    description: 'Your current plan, you may upgrade your plan.',
    url: 'plan',
    icon: 'account_balance_wallet',
  },
];
