<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" class="page-layout carded fullwidth" fxLayout="column" *ngIf="!isLoading">
    <div class="new-header">
        <div style="float: left; display: flex; align-items: center; justify-content: center">
            <mat-icon class="logo-icon s-32" style="margin-right: 10px"> share</mat-icon>
            <span class="logo-text h1"> Email Integration </span>
        </div>
        <div style="float: right">
            <button mat-icon-button [routerLink]="['/settings/integrations']" matTooltip="Back " aria-label="Back">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="24px grid" class="p-24">
        <div class="hero-content">
            <h1>Email</h1>
            <p style="margin-top: -5px;">Connect your personal email accounts to HubSpot to log, track, send, and
                receive emails in the HubSpot
                CRM. To manage any team emails.</p>
            <div>
                <button *ngIf="!isLoading" mat-raised-button type="button" color="accent"
                        (click)="openConnectEmailDialog()">
                    Connect Personal Email
                </button>
            </div>
        </div>
    </div>
    <div
            class="content"
            fxLayout="row wrap"  class="p-24 mb-24 w-100p boxshadow-media"   >
        <div class="mb-24">
            <table style="width: 100%;">
                <thead style="background: #EFEFEF;">
                <td width="55%">EMAIL</td>
                <td></td>
                <td></td>
                <td>SHARED ACCOUNT</td>
                </thead>
                <tbody>
                <tr *ngFor="let item of connectedEmail">
                    <td >
                                                                 <img *ngIf="item.productName == 'googlemail'"  class="logo-icon" src="assets/images/logos/google-gmail.svg">
                                                                 <img *ngIf="item.productName == 'zohomail'" class="logo-icon" src="assets/images/logos/zoho-mail.png">
                                                                 <img *ngIf="item.productName == 'outlookmail'" class="logo-icon" src="assets/images/logos/outlook-mail.svg">
                    </td>
                    <td>{{item.userName}}</td>
                    <td>  <mat-checkbox
                            class="label-font-size"
                            formControlName="isAllowWebRegistration"
                            [(ngModel)]="item.isShareAccount"
                            (click)="$event.preventDefault()"
                            fxFlex="100"></mat-checkbox></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
