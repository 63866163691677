import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FuseConfig } from '@fuse/types';
import { AppConstants } from '../settings/appconstants';
import { TUser } from '../models/tenantuser';
import { AppUtilsService } from './apputils.service';
import { TenantUserService } from './tenantuser.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  _lastSavedRoute: string;

  constructor(private _router: Router) {
    this._init();
  }

  getLastSavedRoute() {
    return AppConstants.DASHBOARD_ROUTE;
  }
  /*
    monitor the router events and save the last logged in route
    if the user is logged in, then save his last activated route and last active role
  */
  private _init(): void {
    // Reload the default layout config on every RoutesRecognized event
    // if the current layout config is different from the default one
    // this._router.events
    //     .pipe(filter(event => event instanceof ResolveEnd))
    //     .subscribe(() => {
    //         if ( !_.isEqual(this._configSubject.getValue().layout, this._defaultConfig.layout) )
    //         {
    //             // Clone the current config
    //             const config = _.cloneDeep(this._configSubject.getValue());
    //             // Reset the layout from the default config
    //             config.layout = _.cloneDeep(this._defaultConfig.layout);
    //             // Set the config
    //             this._configSubject.next(config);
    //         }
    //     });
  }

  setNavigationRoute(user: TUser): void {
    if (user.role == AppConstants.ROLE_ADMIN) {
      this._router.navigate([AppUtilsService.getSlashedRoute(AppConstants.DASHBOARD_ROUTE)]);
    } else if (user.role == AppConstants.ROLE_STUDENT) {
      this._router.navigate([AppUtilsService.getSlashedRoute(AppConstants.CLASSES_ROUTE)]);
    } else {
      this._router.navigate([AppUtilsService.getSlashedRoute(AppConstants.CALENDAR_ROUTE)]);
    }
  }

  static getNavkey(user: TUser): string {
    const isWebView = user.isPreviewMode;
    let navKey: string = AppConstants.PUBLIC_NAVIGATION;
    if (TenantUserService.isStaff(user)) {
      navKey = isWebView ? AppConstants.PUBLIC_NAVIGATION : AppConstants.ADMIN_NAVIGATION;
    } else if (TenantUserService.isStudent(user)) {
       navKey = isWebView ? AppConstants.PUBLIC_NAVIGATION : AppConstants.STUDENT_NAVIGATION;
    }
    return navKey;
  }

  static getLayoutConfig(user: TUser): FuseConfig {
    const isWebView = user.isPreviewMode;
    let layout: FuseConfig;
    if (TenantUserService.isStaff(user)) {
      layout = isWebView ? this.getPublicConfig() : this.getAdminConfig();
    } else if (TenantUserService.isStudent(user)) {
      layout = isWebView ? this.getPublicConfig() : this.getStudentConfig();
    } else {
      layout = this.getPublicConfig();
    }
    return layout;
  }

  static getAdminConfig(): FuseConfig {
    return {
      // Color themes can be defined in src/app/app.theme.scss
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'vertical-layout-1', //Admin layout
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'fuse-navy-700',
          secondaryBackground: 'fuse-navy-900',
          folded: false,
          hidden: false,
          position: 'left',
          variant: 'vertical-style-1',
        },
        toolbar: {
          background: 'fuse-white-500',
          customBackgroundColor: false,
          hidden: false,
          position: 'below-static',
        },
        footer: {
          background: 'fuse-navy-900',
          customBackgroundColor: true,
          hidden: false,
          position: 'below-static',
        },
        sidepanel: {
          hidden: false,
          position: 'right',
        },
      },
    };
  }

  static getPublicConfig(): FuseConfig {
    return {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1', //Admin layout
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'fuse-navy-700',
          secondaryBackground: 'fuse-navy-900',
          folded: false,
          hidden: false,
          position: 'top',
          variant: 'horizontal-style-1',
        },
        toolbar: {
          background: 'fuse-white-500',
          customBackgroundColor: false,
          hidden: false,
          position: 'above',
        },
        footer: {
          background: 'fuse-navy-900',
          customBackgroundColor: true,
          hidden: false,
          position: 'above-static',
        },
        sidepanel: {
          hidden: false,
          position: 'right',
        },
      },
    };
  }

  static getStudentConfig(): FuseConfig {
    return {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-2', //Admin layout
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'fuse-navy-700',
          secondaryBackground: 'fuse-navy-900',
          folded: false,
          hidden: false,
          position: 'top',
          variant: 'horizontal-style-1',
        },
        toolbar: {
          background: 'fuse-white-500',
          customBackgroundColor: false,
          hidden: false,
          position: 'above',
        },
        footer: {
          background: 'fuse-navy-900',
          customBackgroundColor: true,
          hidden: false,
          position: 'above-static',
        },
        sidepanel: {
          hidden: false,
          position: 'right',
        },
      },
    };
  }

  static getPreviewConfig(): FuseConfig {
    return {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1', //Admin layout
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'fuse-navy-700',
          secondaryBackground: 'fuse-navy-900',
          folded: false,
          hidden: true,
          position: 'top',
          variant: 'vertical-style-1',
        },
        toolbar: {
          background: 'fuse-white-500',
          customBackgroundColor: false,
          hidden: false,
          position: 'above',
        },
        footer: {
          background: 'fuse-navy-900',
          customBackgroundColor: true,
          hidden: false,
          position: 'above-fixed',
        },
        sidepanel: {
          hidden: false,
          position: 'right',
        },
      },
    };
  }
}
