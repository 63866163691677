export const TimeZone: any[] = [
    { value: 'America/Los_Angeles', text: 'America/Los_Angeles - PST'},
    { value: 'America/Denver', text: 'America/Denver - MST' },
    { value: 'America/Chicago', text: 'America/Chicago - CST' },
    { value: 'America/New_York', text: 'America/New_York - EST' },
    { value: 'Asia/Kolkata', text: 'Asia/Kolkata - IST' },
    { value: 'Australia/Perth', text: 'Australia/Perth - AWST' },
    { value: 'Australia/Melbourne', text: 'Australia/Melbourne - AEST' },
    { value: 'Australia/Broken_Hill', text: 'Australia/Broken_Hill - ACST' },
    { value: 'Pacific/Pitcairn', text: 'Pacific/Pitcairn - PST' },
    { value: 'America/Grand_Turk', text: 'America/Grand_Turk - GMT' },
    { value: 'America/Anguilla', text: 'America/Anguilla - AST' },
    { value: 'Atlantic/Stanley', text: 'Atlantic/Stanley - FKST' },
    { value: 'America/Argentina/Jujuy', text: 'America/Argentina/Jujuy-GST' },
    { value: 'America/Anguilla', text: 'Europe/Gibraltar - CET' },
    { value: 'Europe/Sofia', text: 'Europe/Sofia - EET' },
    { value: 'America/Anguilla', text: 'Indian/Chagos - IOT' },
    { value: 'Dubai', text: 'Dubai - GST' },
    { value: 'Europe/Guernsey', text: 'Europe/Guernsey - BST' },
]