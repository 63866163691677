<div class="content">
    <!-- SEARCH -->
    <div style="width: 100%" fxLayout="row wrap" fxLayoutAlign="center">
        <div class="table-search mb-16" fxLayoutAlign="center center" fxFlex="100" fxFlex.gt-xs="100"
             fxFlex.gt-sm="100">
            <div
                    class="center"
                    style="width: 100%;justify-content: center;display: flex;align-items: center;padding-bottom: 5px;padding-top: 10px;"
            >
                <div
                        class="search-wrapper"
                        style="box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.15); background: white; min-width: 240px;max-width: 350px; display: inline-block;"
                >
                    <div
                            class="search"
                            fxFlex
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            style="color: grey; flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center; flex: 1 1 0%;"
                    >
                        <mat-icon color="accent">search</mat-icon>
                        <input #filter (change)="filterTemplates()" placeholder="Search Templates"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- / SEARCH -->

    <div style="width: 100%" fxLayout="row wrap" fxLayoutAlign="center">
        <div class="untemplates" fxLayout="row wrap" fxLayoutAlign="center" style="text-align: center;width: 100%">
            <!--Empty template start-->
            <div
                    class="untemplate"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-sm="20"
                    style="padding-left: 5px;
        padding-right: 5px;
        padding-top: 16px;
        padding-bottom: 16px;"
                    *ngIf="pageIndex == 0">
                <div class="untemplate-content" fxLayout="column wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                    <div
                            (click)="onTemplateClick.emit(undefined)"
                            fxLayout="row "
                            fxLayoutAlign="start"
                            style="min-height: 232px;max-height:232px;overflow: hidden;background: #f5f5f5;">
                    </div>
                    <div
                            fxLayout="row "
                            class="p-16 mt-12"
                            style="display: flex;
                                width: 100%;">
                        <div style="text-align: left; width: 100%;" (click)="onTemplateClick.emit(undefined)"
                             class="h4 bold">
                            Empty Template
                        </div>
                        <!--                  <div style="float: right;cursor: pointer;">-->
                        <!--                    <button-->
                        <!--                            [disabled]="untemplate.orgId != orgId"-->
                        <!--                            style="border: none; background: none;"-->
                        <!--                            (click)="deleteTemplate(untemplate)"-->
                        <!--                    >-->
                        <!--                      <mat-icon style="color: #FF8845">delete</mat-icon>-->
                        <!--                    </button>-->
                        <!--                  </div>-->
                    </div>
                </div>
            </div>
            <!--Empty template end-->
            <div
                    class="untemplate"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-sm="20"
                    style="padding-left: 5px;
        padding-right: 5px;
        padding-top: 16px;
        padding-bottom: 16px;"
                    *ngIf="pageIndex == 0">
                <div class="untemplate-content" fxLayout="column wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                    <div
                            (click)="onTemplateClick.emit('raw')"
                            fxLayout="row "
                            fxLayoutAlign="start"
                            style="min-height: 232px;max-height:232px;overflow: hidden;background: #f5f5f5;">
                    </div>
                    <div
                            fxLayout="row "
                            class="p-16 mt-12"
                            style="display: flex;
                                width: 100%;">
                        <div style="text-align: left; width: 100%;" (click)="onTemplateClick.emit('raw')"
                             class="h4 bold">
                            HTML template
                        </div>
<!--Raw Template Ends here-->
                    </div>
                </div>
            </div>
            <!--raw template end-->

            <div
                    class="untemplate"
                    *ngFor="let untemplate of templateList"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-sm="20"
                    style="padding-left: 5px;
        padding-right: 5px;
        padding-top: 16px;
        padding-bottom: 16px;"
            >
                <div class="untemplate-content" fxLayout="row wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
                    <div
                            (click)="onTemplateClick.emit(untemplate.unjson)"
                            fxLayout="row "
                            fxLayoutAlign="start"
                            style="min-height: 200px;max-height:200px;overflow: hidden;"
                    >
                        <img [src]="getThumbnailImg(untemplate)"/>
                    </div>
                    <div
                            fxLayout="row "
                            class="p-16"
                            style="display: flex;
                              width: 100%;
                              justify-content: center;
                              align-items: center;"
                    >
                        <div
                                style="text-align: left; width: 100%;"
                                (click)="onTemplateClick.emit(untemplate.unjson)"
                                class="h4 bold"
                        >
                            {{ untemplate.name | titlecase }}
                        </div>
                        <div style="float: right;cursor: pointer;">
                            <button
                                    [disabled]="untemplate.orgId != orgId"
                                    style="border: none; background: none;"
                                    (click)="deleteTemplate(untemplate)"
                            >
                                <mat-icon style="color: #FF8845">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="no-untemplates" *ngIf="unTemplates?.length === 0">
              No Templates found!
            </div> -->
            <div
                    fxLayout="row"
                    fxLayoutAlign="center stretch"
                    fxFlex="100"
                    *ngIf="unTemplates?.length === 0"
                    style="width: 100%;background: white; padding-bottom: 20px"
            >
                <ngx-skeleton-loader
                        count="8"
                        appearance=""
                        style="width: 100%;"
                        [theme]="{
            border: '5px solid #FFF',
            height: '18px',
            background: 'rgb(140 137 137)'
          }"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <mat-paginator
                [length]="length"
                [pageSize]="pageSize"
                #paginator
                [pageSizeOptions]="pageSizeOptions"
                (page)="getTemplatesPagination($event)"
        >
        </mat-paginator>
    </div>
</div>
