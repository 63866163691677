import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseMatchMediaService } from "@fuse/services/match-media.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { SubSink } from "subsink";
import { TUser } from "app/core/models/tenantuser";
import { AppLoadService } from "app/core/services/appload.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { NoticeService } from "app/main/shared/components/notice/notice.service";
import { NoticeDlgComponent } from "app/main/shared/components/notice-dlg/notice-dlg.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AppConstants } from "app/core/settings/appconstants";
import { Router, ActivatedRoute } from "@angular/router";
import { FeedbackDlgComponent } from "app/main/shared/components/feedback-dlg/feedback-dlg.component";

@Component({
  selector: "fuse-shortcuts",
  templateUrl: "./shortcuts.component.html",
  styleUrls: ["./shortcuts.component.scss"]
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy {
  shortcutItems: any[];
  navigationItems: any[];
  filteredNavigationItems: any[];
  searching: boolean;
  mobileShortcutsPanelActive: boolean;
  user: any;
  tenant: any;
  isStudentRole: boolean = false;
  isStudentPreview: boolean = false;
  subs = new SubSink();
  notices = [];
  noticeCount: any = 0;
  quotes = [];
  @Input()
  navigation: any;

  @ViewChild("searchInput")
  searchInputField;

  @ViewChild("shortcuts")
  shortcutsEl: ElementRef;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CookieService} _cookieService
   * @param {FuseMatchMediaService} _fuseMatchMediaService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {MediaObserver} _mediaObserver
   * @param {Renderer2} _renderer
   */
  constructor(
    private _cookieService: CookieService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private _fuseNavigationService: FuseNavigationService,
    private _mediaObserver: MediaObserver,
    private _renderer: Renderer2,
    private _tenantUserService: TenantUserService,
    private _appLoadService: AppLoadService,
    private appSettingsService: AppSettingsService,
    private noticeService: NoticeService,
    private dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    // Set the defaults
    this.shortcutItems = [];
    this.searching = false;
    this.mobileShortcutsPanelActive = false;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getTenantConfig();
  }
  getUserObject() {
    this.subs.add(
      this._tenantUserService.user.subscribe((user: TUser) => {
        this.user = user;
        this.isStudentPreview = TenantUserService.isStudentPreview(this.user);
        this.isStudentRole = TenantUserService.isStudent(this.user);
        this.getNotices();
        this.setConfig(this.isStudentRole);
      })
    );
  }

  getTenantConfig() {
    this.subs.add(
      this.appSettingsService.getAccountDetail().subscribe(data => {
        this.tenant = data;
        this.getUserObject();
      })
    );
  }

  setConfig(isStudentRole) {
    // Get the navigation items and flatten them
    this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(
      this.navigation
    );

    if (this._cookieService.check("FUSE2.shortcuts")) {
      this.shortcutItems = JSON.parse(this._cookieService.get("FUSE2.shortcuts"));
    } else {
      if (isStudentRole) {
        this.shortcutItems = [];
      } else {
        // User's shortcut items

        this.shortcutItems = [];
        // {
        //     title: 'Calendar',
        //     type : 'item',
        //     icon : 'today',
        //     url  : '/apps/calendar'
        // },
        // {
        //     title: 'Mail',
        //     type : 'item',
        //     icon : 'email',
        //     url  : '/apps/mail'
        // },
        // {
        //     title: 'Contacts',
        //     type : 'item',
        //     icon : 'account_box',
        //     url  : '/apps/contacts'
        // },

        // if (this.tenant.isShowScheduleMenu) {
        //   this.shortcutItems.push({
        //     title: "Programs",
        //     type: "Programs",
        //     icon: "event",
        //     url: "/rules"
        //   });
        // }

        if (this.tenant.isShowStoreMenu) {
          this.shortcutItems.push({
            title: "Store",
            type: "Store",
            icon: "add_shopping_cart",
            url: "/store"
          });
        }
      }
    }
  }

  ngAfterViewInit(): void {
    // Subscribe to media changes
    this._fuseMatchMediaService.onMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      if (this._mediaObserver.isActive("gt-sm")) {
        this.hideMobileShortcutsPanel();
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Search
   *
   * @param event
   */
  search(event): void {
    const value = event.target.value.toLowerCase();

    if (value === "") {
      this.searching = false;
      this.filteredNavigationItems = this.navigationItems;

      return;
    }

    this.searching = true;

    this.filteredNavigationItems = this.navigationItems.filter(navigationItem => {
      return navigationItem.title.toLowerCase().includes(value);
    });
  }

  /**
   * Toggle shortcut
   *
   * @param event
   * @param itemToToggle
   */
  toggleShortcut(event, itemToToggle): void {
    event.stopPropagation();

    for (let i = 0; i < this.shortcutItems.length; i++) {
      if (this.shortcutItems[i].url === itemToToggle.url) {
        this.shortcutItems.splice(i, 1);

        // Save to the cookies
        this._cookieService.set("FUSE2.shortcuts", JSON.stringify(this.shortcutItems));

        return;
      }
    }

    this.shortcutItems.push(itemToToggle);

    // Save to the cookies
    this._cookieService.set("FUSE2.shortcuts", JSON.stringify(this.shortcutItems));
  }

  /**
   * Is in shortcuts?
   *
   * @param navigationItem
   * @returns {any}
   */
  isInShortcuts(navigationItem): any {
    return this.shortcutItems.find(item => {
      return item.url === navigationItem.url;
    });
  }

  /**
   * On menu open
   */
  onMenuOpen(): void {
    setTimeout(() => {
      this.searchInputField.nativeElement.focus();
    });
  }

  /**
   * Show mobile shortcuts
   */
  showMobileShortcutsPanel(): void {
    this.mobileShortcutsPanelActive = true;
    this._renderer.addClass(this.shortcutsEl.nativeElement, "show-mobile-panel");
  }

  /**
   * Hide mobile shortcuts
   */
  hideMobileShortcutsPanel(): void {
    this.mobileShortcutsPanelActive = false;
    this._renderer.removeClass(this.shortcutsEl.nativeElement, "show-mobile-panel");
  }

  showNotices() {
    const dialogRef = this.dialog.open(NoticeDlgComponent, {
      panelClass: "contact-search-dialog",
      disableClose: true,
      width: "700px",
      height: "320px",
      data: {
        notices: this.notices,
        quotes: this.quotes,
        isStudentRole: this.isStudentRole
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.getNotices();
      }
    });
  }

  showFeedBack() {
    const dialogRef = this.dialog.open(FeedbackDlgComponent, {
      panelClass: "contact-search-dialog",
      disableClose: true,
      width: "600px",
      height: this.isStudentRole ? "350px" : "",
      data: {
        isStudentRole: this.isStudentRole
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {});
  }
  getNotices() {
    this.noticeService.getNotices(this.isStudentPreview).subscribe((response: any) => {
      const notices = [];
      const quotes = [];
      response.data.forEach(notice => {
        if (notice.isActive) {
          if (notice.type === "notice") {
            notices.push(notice);
          } else if (notice.type === "quote") {
            quotes.push(notice);
          }
        }
      });
      this.notices = notices;
      this.quotes = quotes;
      this.noticeCount = this.notices.length;
    });
  }
}
