import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AppConstants } from "app/core/settings/appconstants";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { AppSettingsService } from "../../services/app-settings.service";
import { AppLoadService } from "app/core/services/appload.service";

@Component({
  selector: "app-seo-setting",
  templateUrl: "./seo-setting.component.html",
  styleUrls: ["./seo-setting.component.scss"],
})
export class SeoSettingComponent implements OnInit, OnDestroy {
  isLoading;
  subs = new SubSink();
  isLoadingBtn: boolean = false;

  seoForm: FormGroup = this._formBuilder.group({
    seoKeywords: new FormControl(""),
    seoDescription: new FormControl(""),
    apiKey: new FormControl(""),
  });
  back = `${AppConstants.SL_APP_SETTINGS_ROUTE}/website`;

  constructor(public toastr: ToastrService, private _formBuilder: FormBuilder, private AppSettingsService: AppSettingsService, private _appLoadService: AppLoadService) {}

  ngOnInit() {
    this.isLoading = true;
    const tenant = this._appLoadService.getTenantPing();
    this.subs.add(
      this.AppSettingsService.getPluginIntTenant().subscribe(
        (details: any) => {
          this.seoForm.patchValue({
            seoKeywords: details.seoKeywords,
            seoDescription: details.seoDescription,
            apiKey: details.apiKey,
          });
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      )
    );
  }

  generateKey() {
    this.subs.add(
      this.AppSettingsService.getApiKey().subscribe((key) => {
        this.seoForm.get("apiKey").setValue(key);
      })
    );
  }

  submitForm() {
    this.seoForm.markAsTouched();
    if (this.seoForm.valid) {
      this.isLoadingBtn = true;
      this.subs.add(
        this.AppSettingsService.updatePluginTenant(this.seoForm.value).subscribe(
          (resp) => {
            this.toastr.success("Tenant updated");
            this.isLoadingBtn = false;
          },
          (errorResp) => {
            this.toastr.error(errorResp.error.errors.message);
            this.isLoadingBtn = false;
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }
}
