import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { AppConstants } from "app/core/settings/appconstants";
import { ToastrService } from "ngx-toastr";
import { fuseAnimations } from "@fuse/animations";
import { FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-payment-spring",
  templateUrl: "./payment-spring.component.html",
  styleUrls: ["./payment-spring.component.scss"],
  animations: fuseAnimations
})
export class PaymentSpringComponent implements OnInit {
  form: FormGroup;
  subs = new SubSink();
  showAudienceGif: boolean = false;
  showApiKey: boolean = false;
  isEditMode: boolean = true;
  issubmitting: boolean = false;
  adminList;
  tenant: any;
  isPaymentSpringIntegrated: boolean = false;
  isPaymentProviderIntegrated: boolean = false;
  isPaymentSpring: boolean = false;
  isOrganizePlan;
  isExpandPlan;
  isLeapPlan;
  isFreePlan;
  isShowError;
  isInstamojoInt: boolean = true;
  isAccountOwner: boolean = false;

  isLoading: boolean = true;

  @Output() payment: EventEmitter<any> = new EventEmitter<any>();
  public isProviderIntegrated: boolean;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    public toastr: ToastrService,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private SpinnerService: NgxSpinnerService,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.SpinnerService.show();
    this.form = this._formBuilder.group({
      pspringPublicKey: ["", Validators.required],
      pspringPrivateKey: ["", Validators.required],
      isTestMode: [""],
      pspringPublicKeyTest: [""],
      pspringPrivateKeyTest: [""]
    });
    this.getTenantDetail();
  }

  getTenantDetail() {
    this.subs.add(
      this._settingsService.getTenantDetail().subscribe(
        result => {
          let resultData: any = result;
          this.tenant = resultData.data;
          let providerObj = this.tenant.paymentKeys.find(item => {
            return item.provider == AppConstants.PAYMENT_TYPE.PSPRING_PAYMENT.toLowerCase();
          });
          if (providerObj != null && providerObj != undefined) {
            this.tenant.isTestMode = providerObj.isTestMode;
            this.tenant.pspringPrivateKey = providerObj.secretKey;
            this.tenant.pspringPublicKey = providerObj.apiKey;
            this.tenant.pspringPrivateKeyTest = providerObj.testSecretKey;
            this.tenant.pspringPublicKeyTest = providerObj.testApiKey;
            this.tenant.isProviderIntegrated = providerObj.isProviderIntegrated
            this.tenant.paymentProvider = providerObj.provider;
            if (this.tenant.pspringPublicKey) {
              this.isPaymentSpring = true;
            }
            this.isAccountOwner = true;
            this.bindFormData();
          }
          this.SpinnerService.hide();
          this.isLoading = false;
        },
        error => {
          this.SpinnerService.hide();
          //this.toastr.error(error);
          this.getTenantInfoV4();
        }
      )
    );
  }

  getTenantInfoV4() {
    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this.tenant = { ...data };
        this.setPaymentProvider();
        this.bindFormData();
        this.SpinnerService.hide();
        this.isLoading = false;
      })
    );
  }
  updatePayload(payload) {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.updatePspringProvider(payload).subscribe(
        result => {
          this.tenant = result;
          this.SpinnerService.hide();
          this.toastr.success("Successfully updated Settings.");
          // this._router.navigate(["/settings/integrations/paymentprovider"]);
          this.backToPaymentComponent();
          this.payment.emit(null);
        },
        errorResp => {
          this.SpinnerService.hide();
          this.toastr.error(errorResp.error.errors.message);
        }
      )
    );
  }
  validateTestMode() {
    this.isShowError = "";
  }

  setPaymentProvider() {
    this.tenant.plantype = this.tenant.plantype.toLowerCase();
    this.isOrganizePlan =
      this.tenant.plantype == AppConstants.InternalPlanNameMonthly.ORGANIZE_PLAN_MONTHLY ||
      this.tenant.plantype == AppConstants.InternalPlanNameYearly.ORGANIZE_PLAN_YEARLY;
    this.isExpandPlan =
      this.tenant.plantype == AppConstants.InternalPlanNameMonthly.EXPAND_PLAN_MONTHLY ||
      this.tenant.plantype == AppConstants.InternalPlanNameYearly.EXPAND_PLAN_YEARLY;
    this.isLeapPlan =
      this.tenant.plantype == AppConstants.InternalPlanNameMonthly.LEAP_PLAN_MONTHLY ||
      this.tenant.plantype == AppConstants.InternalPlanNameYearly.LEAP_PLAN_YEARLY;
    this.isFreePlan = this.tenant.plantype == AppConstants.Plans.FREE_PLAN || this.tenant.plantype == "";
    if (this.tenant.pspringPublicKey) {
      this.isPaymentSpring = true;
    }
    this.isAccountOwner = true;
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      pspringPublicKey: [this.tenant.pspringPublicKey],
      pspringPrivateKey: [this.tenant.pspringPrivateKey],
      isTestMode: [this.tenant.isTestMode],
      pspringPublicKeyTest: [this.tenant.pspringPublicKeyTest],
      pspringPrivateKeyTest: [this.tenant.pspringPrivateKeyTest]
    });
    this.isProviderIntegrated = this.tenant.isProviderIntegrated;
  }

  audienceId() {
    this.showAudienceGif = true;
  }
  apiKey() {
    this.showApiKey = true;
  }
  showPaymentProviderIntegrated = function() {
    this.isPaymentProviderIntegrated = false;
    this.isInstamojoIntegrated = false;
    this.isPaymentSpringIntegrated = false;
    this.isStripeIntegrated = false;
  };

  onSubmit() {
    this.tenant.paymentProvider = AppConstants.PAYMENT_TYPE.PSPRING_PAYMENT.toLowerCase();
    let paymentSpringInfo = this.form.value;
    this.tenant.pspringPublicKey = paymentSpringInfo.pspringPublicKey;
    this.tenant.pspringPrivateKey = paymentSpringInfo.pspringPrivateKey;
    this.tenant.isTestMode = paymentSpringInfo.isTestMode;
    this.tenant.pspringPublicKeyTest = paymentSpringInfo.pspringPublicKeyTest;
    this.tenant.pspringPrivateKeyTest = paymentSpringInfo.pspringPrivateKeyTest;
    if (this.tenant.pspringPublicKey == "" && this.tenant.pspringPrivateKey == "") {
      if (this.tenant.pspringPrivateKeyTest != "" && this.tenant.pspringPublicKeyTest != "") {
        if (this.tenant.isTestMode == "") {
          this.isShowError = "Test mode should be on";
          return;
        }
      }
    }

    var payload: any = {};
    var tenant = this.tenant;
    payload.secretKey = tenant.pspringPrivateKey;
    payload.apiKey = tenant.pspringPublicKey;
    payload.testApiKey = tenant.pspringPublicKeyTest;
    payload.testSecretKey = tenant.pspringPrivateKeyTest;
    payload.isTestMode = tenant.isTestMode;
    payload.isProviderIntegrated = this.isProviderIntegrated;
    this.isPaymentSpring = true;
    this.showPaymentProviderIntegrated();
    this.updatePayload(payload);
  }

  public openDialog(): void {
    const dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      data:{'dialogTitle': 'Disconnect Payment spring','content': 'Would you really like to disconnect Payment Spring ?','isErrorDlg': false}
    });
    dialogRef.afterClosed().subscribe((result) =>{
      if(result == true){
        this.isProviderIntegrated = false;
        this.onSubmit();
      }
    })
  }

  public enableProvider(){
    this.isProviderIntegrated = true;
    this.onSubmit()
  }

  backToPaymentComponent() {
    this._router.navigate(["/settings/integrations"]);
    this.payment.emit(null);
  }
}
