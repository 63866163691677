import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Tenant } from '../models/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private _tenant: Tenant = null;
  private _configSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    // this._configSubject = new BehaviorSubject(this._tenant);
  }

  get tenant$(): any | Observable<any> {
    return this._configSubject.asObservable();
  }
  
  get tenant(){
    return this._configSubject.value;
  }

  getOrgId(): string {
    return this._tenant.orgId;
  }

  getTenant(): Tenant {
    return this._tenant;
  }

  populateTenant(data: any): void {
    this._tenant = {
      ...data,
      cloudName: data.cloudinaryCloudName,
      csymbol: data.csymbol,
      customDomain: data.customDomain,
      email: data.email,
      environmentName: data.environmentName,
      isAddressNeededForEvent: data.isAddressNeededForEvent,
      // isDonationPublicCheckout: data.isDonationPublicCheckout,
      isRegAndPurchaseOnSamePage: data.isRegAndPurchaseOnSamePage,
      isShowCalenderView: data.isShowCalenderView,
      isShowCourses: data.isShowCourses,
      isShowDonation: data.isShowDonation,
      isShowScheduleMenu: data.isShowScheduleMenu,
      isShowStoreMenu: data.isShowStoreMenu,
      isSupportGrn: data.isSupportGrn,
      isShowRegistrationLink: data.isShowRegistrationLink,
      isShowRoomName: data.isShowRoomName,
      isShowSchedule: data.isShowSchedule,
      isWaiverFormToBeSigned: data.isWaiverFormToBeSigned,
      isMasterFranchise: data.isMasterFranchise,
      logo: data.logo,
      name: data.name,
      orgId: data.orgId,
      tenantId:data.tenantId,
      smallLogo: data.smallLogo,
      timezone: data.timezone,
      version: data.version,
      waiverFormLink: data.waiverFormLink,
      website: data.website,
      workshopLabel: data.workshopLabel,
      isAuthValid: data.isAuthValid,
      tenantAuthViewCmd: data.tenantAuthViewCmd,
      paymentProvider: data.paymentProvider,
      taxPercent: data.taxPercent,
      stripeApiKey: data.stripeApiKey,
      stripeAuthCode: data.stripeAuthCode,
      stripeClientId: data.stripeClientId,
      stripePublishableKey: data.stripePublishableKey,
      zoomApi: data.zoomApi,
      zoomAuthCode: data.zoomAuthCode,
      clanMeetingDomain: data.clanMeetingDomain,
      clanMeetingSecret: data.clanMeetingSecret,
      activePaymentProvider: data.activePaymentProvider,
      cloudinaryCloudName: data.cloudinaryCloudName,
      cloudinaryPreset: data.cloudinaryPreset,
      termsOfServiceLink: data.termsOfServiceLink,
      privacyPolicyLink: data.privacyPolicyLink,
      pagelinks: data.pagelinks,
      address: data.address == null ? '' : data.address.line1,
    };
    this._configSubject.next(this._tenant);
  }
}
