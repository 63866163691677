import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError, tap, flatMap } from "rxjs/operators";
import { CourseSummary } from "./coursesummary.model";

@Injectable({
  providedIn: "root"
})
export class CourseListService {
  constructor(private http: HttpClient) {}

  getCourseAndProductList(orgId,tid): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/icategory/public/?tid=${tid}`;
    return this.http.get(apiUrl);
  }

  getEventList(orgId): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/snode/pevent/${orgId}`;
    return this.http.get(apiUrl);
  }
}
