import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

@Component({
  selector: "fuse-nav-horizontal-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.scss"]
})
export class FuseNavHorizontalItemComponent implements OnInit {
  @HostBinding("class")
  classes = "nav-item";

  @Input()
  item: any;

  /**
   * Constructor
   */
  constructor(private router: Router, private _fuseSidebarService: FuseSidebarService) {}

  ngOnInit() {
    //console.log(this.item);
  }

  closeNav(){
    this._fuseSidebarService.getSidebar('navbar').close();
  }
}
