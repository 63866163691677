<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
</ngx-spinner>  

<div *ngIf="isLoading" class="courses" style="background: white; padding-bottom: 20px">
  <ngx-skeleton-loader class="course" count="4" appearance="" [theme]="{
      border: '5px solid #FFF',
      background: '#F5F5F5'
    }"></ngx-skeleton-loader>
</div>

<div id="forms" *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">

  <!-- HEADER -->
  <div class="header accent p-24 h-100" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center" style="font-size: 24px;">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start" style="font-size: 24px;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text h1 mr-12">settings</mat-icon>
        <span class="secondary-text logo-text h2">Student App</span>
      </div>

    </div>
    <a mat-raised-button class="reference-button mt-16 mt-sm-0" [routerLink]="['/settings']">
      <mat-icon class="mr-8">keyboard_backspace</mat-icon>
    </a>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center stretch" 
    fxFlex="grow" fxLayout.gt-xs="row" fxFlex.gt-xs="50" fxFlex.gt-sm="33">
    <div class="mb-24" style="width: 100%;">

      <div fxFlex="5"></div>

      <form class=" mat-elevation-z4 p-24 mr-24" style="border-radius: 24px;" fxLayout="column" fxLayoutAlign="center" fxFlex="90" fxFlex="grow"
        name="form" [formGroup]="form" (ngSubmit)="updateSettings()" fxFlexFill>
        <div class="h1 mb-16" fxFlex="100">Student Mobile App Setting</div>  
         
        <!-- <div class="h4" fxFlex="100">Display settings for your studio, changes will get applied immediately</div> -->
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label fxFlex="100">Course Menu Label</mat-label>
            <input matInput formControlName="appLabel1" class="right-align">
            <mat-error>Course Menu Label is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label fxFlex="100">Schedule Menu Label</mat-label>
            <input matInput formControlName="appLabel2" class="right-align">
            <mat-error>Schedule Menu Label is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label fxFlex="100">Myfiles Menu Label</mat-label>
            <input matInput formControlName="appLabel3" class="right-align">
            <mat-error>Myfiles Menu Label is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label fxFlex="100">Other Label</mat-label>
            <input matInput formControlName="appLabel4" class="right-align">
            <mat-error>Other is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control" fxFlex="100">
            Color
          </span>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label fxFlex="100">Primary Color</mat-label>
            <input fxFlex="80" [style.background]="color"  readonly matInput formControlName="appPrimaryColor" class="right-align">
            <mat-icon matSuffix fxFlex="20" [(colorPicker)]="color" [style.background]="color" (colorPickerChange)="colorSubject.next($event)">
              palette
            </mat-icon>
            <mat-error>Primary Color is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-bottom: 10px;padding-top: 10px;">
            <span class="custom-control" fxFlex="100" style="font-weight: 600">
                Upload initial Photos
            </span>
            <span class="custom-control" fxFlex="100">
                These images appear when mobile app get's lauch
            </span>
          </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" >
            <app-file-upload [photos]="bannerImage1" (getFileObject)="bannerImage1$.next($event)"
              [title]="'First cover image '">
            </app-file-upload>
  
            <app-file-upload style="padding-left: 50px" [photos]="bannerImage2" (getFileObject)="bannerImage2$.next($event)"
                [title]="'Second cover image '">
              </app-file-upload>
  
              <app-file-upload style="padding-left: 50px"[photos]="bannerImage3" (getFileObject)="bannerImage3$.next($event)"
              [title]="'Third cover image '">
            </app-file-upload>
          </div>

        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>
          <button type="submit" mat-raised-button color="accent">Submit</button>
        </div>
        
      </form>

    </div>

  </div>
  <!-- / CONTENT -->

</div>