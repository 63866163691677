import { AppSettingsModule } from "./main/admin/app-settings/app-settings.module";
import { NgModule } from "@angular/core";
// import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseProgressBarModule } from "@fuse/components";

import { AppRoutingModule } from "./app.routing.module";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { CoreModule } from "./core/core.module";
import { RequestInterceptor } from "./core/services/request.interceptor";
import { AppConfigService } from "./core/services/appconfig.service";
import { LocalstorageService } from "./core/services/applocalstorage.service";
import { WindowRef } from "./core/services/winref.service";
import { ToastrModule } from "ngx-toastr";
import { FlexLayoutModule } from "@angular/flex-layout";
import { OnboardingModule } from "./main/shared/onboarding/onboarding.module";
import {QuillModule} from "ngx-quill";
import { InternalwebsitepreviewModule } from "./main/public/internalwebsite/internalwebsitepreview.module";
import { CalendarService } from "./main/shared/components/calendar/monthly-calendar/monthly-calendar.service";
import { MONACO_PATH, MonacoEditorModule } from "@materia-ui/ngx-monaco-editor";
import { RECAPTCHA_V3_SITE_KEY,RecaptchaV3Module } from "ng-recaptcha";
import { environment } from "environments/environment";
import { MatTooltipModule } from "@angular/material/tooltip";
import {ConnectEmailModule} from "./main/admin/app-settings/connect-email/connect-email.module";
// import { TransferHttpCacheModule } from '@nguniversal/common'; // HERE

// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    // TransferHttpCacheModule,
    // TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    // Fuse modules
    FuseModule.forRoot(AppConfigService.getPublicConfig()),
    FuseProgressBarModule,
    FuseSharedModule,
    // FuseSidebarModule,
    // FuseThemeOptionsModule,
    // App modules
    LayoutModule,
    AppSettingsModule,
    InternalwebsitepreviewModule,
    CoreModule.forRoot(),
    ToastrModule.forRoot(),
    OnboardingModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          [{'direction': 'rtl'}],                         // text direction

          [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
          [{'header': [1, 2, 3, 4, 5, 6, false]}],

          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          [{'font': []}],
          [{'align': []}],

          ['clean'],                                         // remove formatting button

          ['link']
        ],
        clipboard: {
          matchVisual: false
        }
      }
    }),
    MonacoEditorModule,
    RecaptchaV3Module,
    ConnectEmailModule,
    // PdfViewerModule,
    // PdfJsViewerModule
  ],
  exports: [CoreModule],
  providers: [
    WindowRef,
    { provide: LocalstorageService, useClass: LocalstorageService },
    { provide: AppConfigService, useClass: AppConfigService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: MONACO_PATH,
      useValue: 'https://unpkg.com/monaco-editor@0.31.1/min/vs',
    },
    { 
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
