import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TenantPing } from 'app/core/models/tenantPing';
import { AppLoadService } from 'app/core/services/appload.service';
import { AppUtilsService } from 'app/core/services/apputils.service';
import { AppConstants } from 'app/core/settings/appconstants';
import { SubSink } from 'subsink';
import { ProductService } from 'app/main/admin/products/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { ExternalWebsiteService } from '../../../admin/app-settings/website-setting/external-website/externalwebsite.service';

@Component({
  selector: 'internal-website-template-list',
  templateUrl: './internal-website-template-list.component.html',
  styleUrls: ['./internal-website-template-list.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AppTemplateListComponent implements OnInit, OnChanges, OnDestroy {
  subs = new SubSink();
  @Input() unTemplates: any[] = [];
  @Input() untotal: number;
  @Input() skipTemplate: boolean;
  @Input() pageName: string;
  @Output() onTemplateClick = new EventEmitter<any>();
  @ViewChild('filter') filter: ElementRef;
  templateList: any[] = [];
  start: number = 0;
  length = 100;
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  _tenantConfig: any;
  _imagePrefixPath: any;
  orgId: any;
  constructor(
    public _matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _appLoadService: AppLoadService,
    private _productsService: ProductService,
    private _websiteService: ExternalWebsiteService,
    public toastr: ToastrService,
    public _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.orgId = this._appLoadService.getOrgId();
    // this.length = this.total;
    //console.log("this.unTemplatesTotal", this.total)
  }
  ngOnChanges(changes: SimpleChanges) {
    // setTimeout(() => {
    this.pageSize = 5;
    this.start = 0;
    this.length = 0;
    this.pageIndex = 0;
    //this.pageSize = this.length
    //this.setPrefixPath();

    this.filterTemplates();
    this.getTemplates(null);
    this.cdRef.detectChanges();
    //}, 100);
  }

  getTemplates(event: PageEvent | null) {
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }

    if (this.unTemplates) {
      this.templateList = this.unTemplates.slice(this.start, this.start + this.pageSize);
    }
  }

  getTemplatesPagination(event: PageEvent | null) {
    this.pageIndex = this.pageIndex;
    var pageName;
    if (['home', 'about', 'contact', 'privacypolicy', 'termsofservice'].includes(this.pageName)) {
      pageName = this.pageName;
    } else {
      pageName = 'others';
    }
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
    }
    this.getTemplateList(pageName, event.pageIndex, this.pageSize);
  }
  
  getTemplateList(pageName, pageIndex, pageSize) {
    this.subs.add(
      this._websiteService.getTemplateListByPageType(pageName, pageIndex, pageSize).subscribe((result: any) => {
        if (result.data.length > 0) {
          this.templateList = [];
          this.templateList = result.data;
        }
      }),
    );
  }
  setPrefixPath() {
    this.subs.add(
      this._appLoadService.onTenantConfigChanged.subscribe(tenantConfig => {
        this._tenantConfig = tenantConfig;
        this._imagePrefixPath = AppUtilsService.getCloudinaryPath(this._tenantConfig.cloudName, AppConstants.IMG_OPTIONS_TEMPLATE);
      }),
    );
  }

  getThumbnailImg(templateObj) {
    if (templateObj.s3Url) {
      return templateObj.s3Url;
    } else {
      return 'assets//images//default-image.jpg';
    }
  }

  deleteTemplate(templateObj) {
    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      width: '490px',
      id: 'dialog',
    });
    confirmDialogRef.componentInstance.confirmMessage = `${templateObj.name} will be deleted, Are you sure?`;
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subs.add(
          this._websiteService.deleteUntemplate(templateObj._id).subscribe(
            (result: any) => {
              this.toastr.success('Template deleted successfully');
              this.start = 0;
              this.pageSize = 5;
              this.getTemplatesPagination(null);
              this._changeDetectorRef.detectChanges();
            },
            errorResp => {
              this.toastr.error(errorResp.error.errors.message);
            },
          ),
        );
      }
    });
  }

  skipConfirmPopup(): void {
    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false,
      width: '490px',
      id: 'dialog',
    });
    confirmDialogRef.componentInstance.confirmMessage = `Its always a good Idea to add more information about your course, you can always add it later. Would you like to Proceed?`;
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onTemplateClick.emit('skip');
      }
    });
  }

  filterTemplates() {
    this.templateList = this.unTemplates; //this.unTemplates?.filter(x => x.name.toLowerCase().includes(`${this.filter.nativeElement.value.toLowerCase()}`));
    this.length = this.untotal;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
