import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from '../../services/app-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'app/main/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddCustomFieldDlgComponent } from '../../custom-field-setting/add-custom-field-dlg/add-custom-field-dlg.component';

@Component({
  selector: 'app-template-add-edit',
  templateUrl: './template-add-edit.component.html',
  styleUrls: ['./template-add-edit.component.scss'],
})
export class TemplateAddEditComponent implements OnInit {
  // public members
  public availableFields: Object;
  public isLoading: boolean = false;
  public standardFields = [
    {name:"First Name",tag:"firstname"},
    {name:"Last Name",tag:"lastname"},
    {name:"Attendee Email",tag:"email"},
    {name:"Attendee Phone",tag:"phone"},
    {name:"Product Name",tag:"productname"},
    {name:"Product Type",tag:"producttype"},
    {name:"Organization Name",tag:"orgname"},
    {name:"Organization Address",tag:"orgaddress"},
    {name:"Organization Phone",tag:"orgphone"},
    {name:"Organization Emaiil",tag:"orgemail"},
  ];
  public templateForm: FormGroup;

  // private members
  private _templateDetails: any;
  private _subs = new SubSink();

  constructor(private _appSettingsService: AppSettingsService, private _fb: FormBuilder, private _matDialog: MatDialog, private _route: ActivatedRoute, private _router: Router, private _spinner: NgxSpinnerService, private _toastr: ToastrService) {}

  ngOnInit(): void {
    this._spinner.show();
    this.isLoading = true;
    this._subs.add(
      of(this.templateGuId)
        .pipe(
          switchMap(templateGuId => {
            if (templateGuId) {
              return this._appSettingsService.getEmailTemplateDetails(templateGuId);
            }
            return of(null);
          }),
        )
        .subscribe({
          next: result => {
            if (result) {
              this._templateDetails = result.data;
            }
          },
          error: error => {
            console.error('Error during initialization:', error);
          },
          complete: () => {
            this.bindForm();
            this.getAvailableFields();
            this._spinner.hide();
            this.isLoading = false;
          },
        }),
    );
  }

  // private method
  private bindForm() {
    this.templateForm = this._fb.group({
      name: [this._templateDetails?.name || '',Validators.required],
      subject: [this._templateDetails?.subject || 'Sample subject for {{name}}', Validators.required],
      template: [this._templateDetails?.template || '<div>This is a sample template for {{name}} under your organization {{orgname}}</div><div><br></div><ul><li>You can <strong>drag </strong>the available fields from above</li><li><strong>Orange </strong>ones are <strong>standard </strong>fields such as organization email {{orgemail}}</li><li><strong>Blue</strong> ones are custom fields which can be created by clicking on the <strong>Add Button</strong> above</li></ul><div><br></div>',Validators.required],
    });
  }

  private getAvailableFields() {
    this._subs.add(
      this._appSettingsService.getCustomSettings().subscribe((result: [])  => {
        this.availableFields = result.filter((item:any) =>item?.tag != null);
      }),
    );
  }

  // public methods

  public addCustomField(){
    const cfDialogRef = this._matDialog.open(AddCustomFieldDlgComponent, {
      disableClose: true,
      height: "674px",
      width: "450px",
      data:{
        action: 'new',
        type: 'all',
      }
    })
  }

  public deleteTemplate(id){
    this._spinner.show();
    this._appSettingsService.deleteEmailTemplate(id).subscribe((result)=> {
      this._toastr.success('Template Deleted');
      this._spinner.hide();
      this._router.navigate(['/settings/templates'],{relativeTo: this._route});
    },
    error => {
      this._toastr.error(error.error.errors.message);
      this._spinner.hide();
    })
  }

  public onDragStart(event: DragEvent, field: any): void {
    event.dataTransfer?.setData('text/plain', "{{"+field.tag+"}}");
  }

  public showDeleteDialog(id: string){
    const dialogRef = this._matDialog.open(ConfirmationDialogComponent,
      {
        disableClose: true,
        width: "350px",
        data: {
          dialogTitle: "Delete Template",
          content: "Are you sure you want to delete template <strong>"+this._templateDetails.name+"<strong> ?"
        },
      })

    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.deleteTemplate(id);
      }
    })
  }

  public submit() {
    if(!this.templateForm.valid){
      this.templateForm.controls.control.markAsTouched();
      return
    }
    this._spinner.show();
    var msg = "Template Created";
    const payload = this.templateForm.value;
    if(this.templateGuId){
      payload.id = this.templateGuId;
      msg = "Template Edited"
    }
    const $request = this.templateGuId ? this._appSettingsService.updateEmailTemplate(payload) : this._appSettingsService.addEmailTemplate(payload);
    $request.subscribe(
      result => {
        this._toastr.success(msg);
        this._spinner.hide();
        this._router.navigate(['/settings/templates'],{relativeTo: this._route});
      },
      error => {
        this._toastr.error(error.error.errors.message);
        this._spinner.hide();
      },
    );
  }

  public get templateGuId() {
    return this._route.snapshot.params['id'];
  }
}
