import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidDateFormat } from "../../../shared/enums/valid-date-format";
import { AppLoadService } from "app/core/services/appload.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { TimeZone } from "app/main/shared/enums/time-zone";
import { FileObject } from "app/main/shared/components/file-upload/file-upload.component";
import { AccountDetail } from "../models/account-details";
import { AppSettingsService } from "../services/app-settings.service";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AssestModel } from "app/main/shared/models/assest.model";
import { AppUtilsService } from "app/core/services/apputils.service";
import { ProductService } from "../../products/services/product.service";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-account-setting",
  templateUrl: "./account-setting.component.html",
  styleUrls: ["./account-setting.component.scss"]
})
export class AccountSettingComponent implements OnInit, OnDestroy {
  form: FormGroup;
  timeZoneList: any[];
  bannerImageData: any;
  validDateFormatList: any[];
  adminList: any[];
  _accountDetail: AccountDetail;
  phone: string;
  isLoading: boolean = true;
  issubmitting: boolean = false;

  bannerImageUrl: string;
  bannerImage: { id: number; url: string }[] = [];
  bannerImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  logo: any[] = [];
  logo$: EventEmitter<any> = new EventEmitter<any>();

  cloudinaryUrl: {} = {};
  cloudinaryUrl$: EventEmitter<any> = new EventEmitter<any>();

  introVideo: any = [];
  introVideo$: EventEmitter<any> = new EventEmitter<any>();

  outroVideo: any = [];
  outroVideo$: EventEmitter<any> = new EventEmitter<any>();

  folderName: string = "";
  assetData: AssestModel = new AssestModel({});

  // Private
  subs = new SubSink();

  dropdownList = [];
  selectedItems = [];
  tableData = [];
  apiKey: string;
  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService,
    private _productsService: ProductService
  ) {
    // Set the private defaults
    this.timeZoneList = TimeZone;
    this.validDateFormatList = ValidDateFormat;
  }

  ngOnInit() {
    this.SpinnerService.show();
    this.folderName = "courses/tenant";
    this.subs.add(
      this.introVideo$.subscribe(files => {
        if (files.files && files.files.length > 0) {
          this.introVideo = files.files;
          this.getIntroTenant();
        }
      })
    );

    this.subs.add(
      this.outroVideo$.subscribe(files => {
        if (files.files && files.files.length > 0) {
          this.outroVideo = files.files;
          this.getOutroTenant();
        }
      })
    );

    this.subs.add(
      this.logo$.subscribe(files => {
        if (files.files && files.files.length > 0) {
          this.logo = [files.files[0]];
          this.getLogo();
        }
      })
    );

    this.subs.add(
      this.cloudinaryUrl$.subscribe(url => {
        if (!!url) {
          this.postLogo(url.url);
        }
      })
    );

    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this._accountDetail = { ...data };
        this._accountDetail.accountOwner = this._tenantUserService.getuser().guId;
        if (this._accountDetail.phone && this._accountDetail.phone.match(/\-/g)) {
          this.phone = this._accountDetail.phone;
        } else {
          this.phone = this.getCountryCode(this._accountDetail) + this._accountDetail.phone;
        }
        this.bindFormData();

        if (this._accountDetail.bigLogo) {
          this.subs.add(
            this._productsService
              .getcloudinaryBaseURL()
              .pipe(take(1))
              .subscribe(resp => {
                let baseURL = resp.cloudinaryBaseURL;
                this.bannerImage.push({
                  id: 1,
                  url: this._accountDetail.bigLogo
                });
                this.bannerImageData = this.bannerImage[0].url;
              })
          );
        }
        this.isLoading = false;
        this.SpinnerService.hide();
      })
    );
    this.subs.add(
      this._settingsService.getPluginIntTenant().subscribe(
        (details: any) => {
          this.apiKey = details.apiKey;
          this.bindFormData();

        },
        (error) => {
        }
      )
    );
    this.subs.add(
      this._tenantUserService.getAdminList().subscribe(result => {
        this.adminList = result;
      })
    );

    // this.getLogo();

    // this.getIntroTenant();

    // this.getOutroTenant();
    this.initBannerImage();
  }

  initBannerImage() {
    
    this.subs.add(
      this.bannerImage$.subscribe(res => {
        this.bannerImage = res;
        this.bannerImageData = '';
        this.bannerImageData = this.bannerImage[0].url;

        let list = this.bannerImage[0].url.split("/");
        let length = list.length;
        this.bannerImageUrl = `${list[length - 2]}/${list[length - 1]}`;
        console.log("this.bannerImageUrl", this.bannerImageUrl);
        this.postLogo(this.bannerImageUrl);
      })
    );
  }

  getCountryCode(tenant) {
    return AppUtilsService.getCountryDialCode(tenant.timezone) + "-";
  }

  getOutroTenant() {
    this.subs.add(
      this.getAsset("outroTenant").subscribe((res: any) => {
        if (res && res.data.length > 0) {
          this.outroVideo = res.data;
        }
      })
    );
  }

  getIntroTenant() {
    this.subs.add(
      this.getAsset("introTenant").subscribe((res: any) => {
        if (res && res.data.length > 0) {
          this.introVideo = res.data;
        }
      })
    );
  }

  getLogo() {
    this.subs.add(
      this.getAsset("logo").subscribe((res: any) => {
        if (res && res.data.length > 0) {
          this.logo = [res.data[0]];
        }
      })
    );
  }

  postLogo(url: any) {
    let list = url.split("/");
    let length = list.length;
    let logoUrl = `${list[length - 2]}/${list[length - 1]}`;

    let detail: AccountDetail = { logoUrl: logoUrl };
    this.bannerImage[0].url = '';
    this.subs.add(
      this._settingsService.updateSettings(detail).subscribe(
        next => {
          this._appLoadService.updateTenantConfig(true);
          this.toastr.success("Successfully updated logo.");
          window.location.href = window.location.origin + "/settings/account";
        },
        error => {
          this.toastr.error(error);
        }
      )
    );
  }

  getAsset(type) {
    return this._settingsService.getAsset(type);
  }

  bindFormData(): void {

    // Reactive Form
    this.form = this._formBuilder.group({
      name: [this._accountDetail.name, Validators.required],
      orgId: [this._accountDetail.orgId, Validators.required],
      timezone: [this._accountDetail.timezone, Validators.required],
      accountOwner: [this._accountDetail.accountOwner, Validators.required],
      termsOfServiceLink: [this._accountDetail.termsOfServiceLink],
      defaultClassName: [this._accountDetail.defaultClassName],
      salesEmailList: [this._accountDetail.salesEmailList],
      fromEmailName: [this._accountDetail.fromEmailName],
      fromEmail: [this._accountDetail.fromEmail],
      subscriptionRegistrationFees: [this._accountDetail.subscriptionRegistrationFees],
      phone: [this._accountDetail.phone, Validators.required],
      defaultMaxAttendees: [this._accountDetail.defaultMaxAttendees],
      dateFormat: [this._accountDetail.dateFormat],
      itemExpiresAfter: [this._accountDetail.itemExpiresAfter],
      taxPercent: [this._accountDetail.taxPercent],
      cardFees: [this._accountDetail.cardFees],
      bankFees: [this._accountDetail.bankFees],
      isShowGuardian: [this._accountDetail.isShowGuardian],
      tabLabelName: [this._accountDetail.tabLabelName],
      primaryGuardianLabel: [this._accountDetail.primaryGuardianLabel],
      secondaryGuardianLabel: [this._accountDetail.secondaryGuardianLabel],
      introMembershipPeriod: [this._accountDetail.introMembershipPeriod],
      isSendReminder: [this._accountDetail.isSendReminder],
      isSendSmsReminder: [this._accountDetail.isSendSmsReminder],
      reminderTime: [this._accountDetail.reminderTime],
      customDomain: [this._accountDetail.customDomain],
      aboutOrg: [this._accountDetail.aboutOrg],
      currencies: [this._accountDetail.currencies],
      address_line1: [
        this._accountDetail.address && this._accountDetail.address.line1 ? this._accountDetail.address.line1 : ""
      ],
      address_city: [
        this._accountDetail.address && this._accountDetail.address.city ? this._accountDetail.address.city : ""
      ],
      address_state: [
        this._accountDetail.address && this._accountDetail.address.state ? this._accountDetail.address.state : ""
      ],
      address_zip: [
        this._accountDetail.address && this._accountDetail.address.zip ? this._accountDetail.address.zip : ""
      ], apiKey: [this.apiKey]
    });
  }

  get f() {
    return this.form.controls;
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }

  getLogoUrl(photoFile: EventEmitter<FileObject[]>) { }

  getPhoneNumber(phoneNumber: string) {
    this.phone = phoneNumber;
    this.form.get("phone").setValue(this.phone);
    if (this.phone === "") {
      this.form.get("phone").setValidators(Validators.required);
    }
  }

  get isShowGuardian(): boolean {
    return this.form.get("isShowGuardian").value;
  }

  updateSettings() {
    if (this.form.valid) {
      this.issubmitting = true;
      let address = {
        line1: this.form.value.address_line1,
        state: this.form.value.address_state,
        zip: this.form.value.address_zip,
        city: this.form.value.address_city
      };

      this.form.value.address = address;
      this.subs.add(
        this._settingsService.updateSettings(this.form.value).subscribe(
          next => {
            this.issubmitting = false;
            this._appLoadService.updateTenantConfig(true);
            this.toastr.success("Successfully updated settings.");
            this._router.navigate(["/settings"]);
          },
          error => {
            this.toastr.error(error);
            this.issubmitting = false;
          }
        )
      );
    } else {
      this.toastr.error("Invalid Form!");
    }
  }

  getAssetData(type: string) {
    switch (type) {
      case "introTenant":
        return new AssestModel({
          folderName: this.folderName,
          fileName: "intro.mp4",
          fileType: type,
          isAddIntros: true
        });
      case "outroTenant":
        return new AssestModel({
          folderName: this.folderName,
          fileName: "outro.mp4",
          fileType: type,
          isAddIntros: true
        });
      case "logo":
        return new AssestModel({
          folderName: this.folderName,
          fileName: "logo",
          fileType: type,
          isAddIntros: true
        });
      default:
        break;
    }
  }
  generateKey() {
    this.subs.add(
      this._settingsService.getApiKey().subscribe((key: string) => {
        this.apiKey = key;
        this.bindFormData();
      })
    );
  }
}
