import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IntegrationsSettingComponent } from "./integrations-setting.component";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FuseConfirmDialogModule, FuseSidebarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { AppSidebarModule } from "app/main/shared/components/app-sidebar/app-sidebar.module";
import { AppSwitchCardModule } from "app/main/shared/components/app-switch-card/app-switch-card.module";
import { ConfirmationDialogModule } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.module";
import { FileuploadModule } from "app/main/shared/components/file-upload/file-upload.module";
import { PhoneNumberModule } from "app/main/shared/components/phone-number/phone-number.module";
import { VideoUploadModule } from "app/main/shared/components/video-upload/video-upload.module";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxSpinnerModule } from "ngx-spinner";
import { MailchimpIntegrationComponent } from "./mailchimp/mailchimp-integration.component";
import { MailchimpListInfoDialogComponent } from "./mailchimp/mailchimp-list-info-dialog/mailchimp-list-info-dialog.component";
import { PaymentsComponent } from "./payments/payments.component";
import { PaymentSpringComponent } from "./payments/payment-spring/payment-spring.component";
import { RazorpayComponent } from "./payments/razorpay/razorpay.component";
import { PhonepeComponent } from "./payments/phonepe/phonepe.component";
import { TwilioComponent } from "./twilio/twilio.component";
import { PaytmComponent } from "./payments/paytm/paytm.component";
import { PlivoComponent } from './plivo/plivo.component';
import { BrevoComponent } from './brevo/brevo.component';
import { MatCardModule } from "@angular/material/card";
import {ConnectEmailPageComponent} from "../connect-email/connect-email-page/connect-email-page.component";

@NgModule({
  declarations: [
    IntegrationsSettingComponent,
    MailchimpIntegrationComponent,
    MailchimpListInfoDialogComponent,
    PaymentsComponent,
    PaymentSpringComponent,
    ConnectEmailPageComponent,
    PhonepeComponent,
    RazorpayComponent,
    TwilioComponent,
    PaytmComponent,
    PlivoComponent,
    BrevoComponent
  ],
  imports: [
    MatCardModule,
    CommonModule,
    RouterModule,
    FuseSharedModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatGridListModule,
    LayoutModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    FileuploadModule,
    PhoneNumberModule,
    VideoUploadModule,
    MatSnackBarModule,
    NgxSkeletonLoaderModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ColorPickerModule,
    FuseSidebarModule,
    FuseConfirmDialogModule,
    AppSidebarModule,
    MatSlideToggleModule,
    AppSwitchCardModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    ConfirmationDialogModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [IntegrationsSettingComponent, MailchimpIntegrationComponent]
})
export class IntegrationsSettingModule {}
