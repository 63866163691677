import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import * as _ from 'lodash';

import { SubSink } from 'subsink';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { admin_navigation } from 'app/navigation/admin.navigation';
import { student_navigation } from 'app/navigation/student.navigation';
import { AppConstants } from '../core/settings/appconstants';
import { AppConfigService } from 'app/core/services/appconfig.service';
import { TUser } from 'app/core/models/tenantuser';
import { AppLoadService } from 'app/core/services/appload.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TenantPing } from 'app/core/models/tenantPing';
import { Router, Routes } from '@angular/router';
import { InternalwebsitepreviewComponent } from 'app/main/public/internalwebsite/internalwebsitepreview.component';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { AccountDetail } from 'app/main/admin/app-settings/models/account-details';
import { catchError, switchMap } from 'rxjs/operators';
import { FuseNavigation } from '@fuse/types';

/*
 * this class is used to do all the menu initialization before the UI is rendered
 */
@Injectable({
  providedIn: 'root',
})
export class TenantSettingMenuService {
  public static STANDARD_PAGES = ['calendar', 'courses', 'merchandise', 'events', 'forms', 'mediagallery'];
  private _onTenantSettingUpdated: BehaviorSubject<any>;
  private subs = new SubSink();

  constructor(
    private _tenantUserService: TenantUserService,
    private _appLoadService: AppLoadService,
    private _fuseNavigationService: FuseNavigationService,
    private _appSettingService: AppSettingsService,
  ) {
    this._onTenantSettingUpdated = new BehaviorSubject('initial');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get onTenantSettingChanged(): Observable<any> {
    return this._onTenantSettingUpdated.asObservable();
  }

  public updateTenantSettingMenus(user: TUser): void {
    const isLoggedIn = user.isLoggedIn;
    if (!isLoggedIn) {
      this._onTenantSettingUpdated.next({ value: AppConstants.TENANT_NO_CONFIG, isCustomizeCss: false });
    } else {
      this._onTenantSettingUpdated.next('updated');
    }
  }

  public initNavigationMenus(user: TUser, router: Router): string {
    const tenantConfig = this._appLoadService.tenantConfig;
    const navKey = AppConfigService.getNavkey(user);
    var accountDetails;
    of(user.isLoggedIn)
      .pipe(
        switchMap((isLoggedIn: any, _: any) => {
          if (isLoggedIn) {
            return this._appSettingService.getAccountDetail();
          }
          return of(null);
        }),
        catchError(() => of(null)),
      )
      .subscribe({
        next: result => {
          accountDetails = result;
        },
        complete: () => {
          this._fuseNavigationService.setCurrentNavigation(navKey, true);

          if (navKey === AppConstants.PUBLIC_NAVIGATION) {
            // this.updatePublicCalendarMenu(tenantConfig);
            // this.updatePublicCourseMenu(tenantConfig);
            this.initPublicPageNav(tenantConfig, router);
          } else if (navKey === AppConstants.STUDENT_NAVIGATION) {
            this._fuseNavigationService.removeNavigationItem('profile');
            this.addStudentMenu(user, tenantConfig, router);
            // this.updateStoreMenu(tenantConfig);
            // this.updateScheduleMenu(tenantConfig);
          } else if (navKey === AppConstants.ADMIN_NAVIGATION) {
            this.updateStoreMenu(tenantConfig);
            this.updateScheduleMenu(tenantConfig);
            this.updateAdminEventMenu(tenantConfig);
            this.updateAdminMasterOnlyMenus(tenantConfig);
            this.updateTenantSpecificMenus(tenantConfig);
            this.updateDonationMenu(accountDetails);
            this.updateEventsMenu(accountDetails);
            this.updateAssessmentMenu(accountDetails);
            this.updateFormsMenu(accountDetails);
            this.updateMerchandiesMenu(accountDetails);
            this.updateCampaignsMenu(accountDetails);
            this.hideSelfAssesmentMenu(accountDetails);
          }
        },
      });
    return navKey;
  }

  private updateEventsMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowEventsMenu === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_EVENTS);
    }
  }

  private hideSelfAssesmentMenu(tenantConfig: TenantPing) {
    // Define the organization ID for which the self assessment should be shown
    const acceptedOrgIds = [AppConstants.MINDSYNCKKIDS_ORG_ID, AppConstants.TENANT_MARKSAMPLETEST];
    const acceptedMasterOrgIds = [AppConstants.MINDSYNCKKIDS_ORG_ID, AppConstants.TENANT_PANKAJTEST, AppConstants.M300_ORG, AppConstants.KOOKABOORA_ORG_ID,]

    const updateSelfAssessmentVisibility = (items: FuseNavigation[]) => {
      items.forEach(item => {
        if (item.id === 'selfAssessment') {
          item.hidden = !acceptedOrgIds.includes(tenantConfig.orgId) && !acceptedMasterOrgIds.includes(tenantConfig.masterOrgId);
        } else if (item.children) {
          updateSelfAssessmentVisibility(item.children);
        }
      });
    };

    admin_navigation.forEach(group => {
      if (group.id === 'applications') {
        updateSelfAssessmentVisibility(group.children);
      }
    });
  }

  private updateStoreMenu(tenantConfig: TenantPing) {
    if (tenantConfig.isShowStoreMenu == false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_STORE);
    }
  }

  private updateAssessmentMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowAssessment === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_ASSESSMENT_ID);
    }
  }

  private updateFormsMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowFormsMenu === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_FORM_ID);
    }
  }

  private updateMerchandiesMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowMerchandiseMenu === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_MERCHANDISE);
    }
  }

  private updateScheduleMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowScheduleMenu == false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_SCHEDULE_ID);
    }
  }

  private updateDonationMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowDonationMenu === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_DONATION_ID);
    }
  }

  private updateCampaignsMenu(accountDetails: AccountDetail) {
    if (accountDetails?.isShowCampaignsMenu === false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_CAMPAIGNS);
    }
  }

  private updateAdminEventMenu(tenantConfig: TenantPing) {
    if (tenantConfig.isShowEventMenu == false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_SERVICES_ID);
    }
  }

  private updateAdminMasterOnlyMenus(tenantConfig: TenantPing) {
    if (tenantConfig.orgId != AppConstants.MASTER_TENANT) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_TENANTPLAN);
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_TENANT);
    }
  }

  private updateTenantSpecificMenus(tenantConfig: TenantPing) {
    //removing self assessment
    if (
      tenantConfig.masterOrgId != AppConstants.TENANT_MINDSYNCKIDS &&
      tenantConfig.masterOrgId != AppConstants.TENANT_MARKSAMPLETEST &&
      tenantConfig.masterOrgId != AppConstants.M300_ORG &&
      tenantConfig.masterOrgId != AppConstants.SMDUMMY
    ) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_ASSESSMENT);
    }
  }

  private updatePublicCalendarMenu(tenantConfig: TenantPing) {
    if (tenantConfig.isShowSchedule == false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_PUBLIC_CALENDAR);
    }
  }

  private updatePublicCourseMenu(tenantConfig: TenantPing): void {
    if (tenantConfig.isShowCourses == false) {
      this._fuseNavigationService.removeNavigationItem(AppConstants.MENU_PUBLIC_COURSES);
    }
  }

  private addStudentMenu(user: TUser, tenantConfig: TenantPing, router: Router): void {
    const position = AppConstants.MENU_POSITION_END;
    if (tenantConfig.isShowScheduleMenu) {
      const myClassNav = this.getMyClassNav();
      this._fuseNavigationService.addNavigationItem(myClassNav, position);
    }

    const myCourseNav = this.getMyCoursesNav();
    this._fuseNavigationService.addNavigationItem(myCourseNav, position);

    if (tenantConfig.isShowStoreMenu) {
      const myStoreNav = this.getMyStoreNav();
      this._fuseNavigationService.addNavigationItem(myStoreNav, position);
    }

    // if (
    //   tenantConfig.masterOrgId === AppConstants.TEST_ORG ||
    //   tenantConfig.masterOrgId === AppConstants.TENANT_MARKSAMPLETEST ||
    //   tenantConfig.masterOrgId === AppConstants.MINDSYNCKKIDS_ORG_ID ||
    //   tenantConfig.masterOrgId === AppConstants.KOOKABOORA_ORG_ID
    // ) {
    //   const assesmentNav = this.getMyAssementNav(tenantConfig);
    //   this._fuseNavigationService.addNavigationItem(assesmentNav, position);
    // }
    const myAssessmentNav = this.getAssessmentNav();
    this._fuseNavigationService.addNavigationItem(myAssessmentNav, position);

    const myProfileNav = this.getMyProfileNav(user);
    this._fuseNavigationService.addNavigationItem(myProfileNav, position);
  }

  private getMyClassNav(): any {
    const nav: any = {
      id: AppConstants.SCHEDULE_ROUTE,
      title: 'Calendar',
      type: AppConstants.MENU_TYPE_ITEM,
      icon: 'calendar_today',
      url: '/classes',
    };
    return nav;
  }

  private getAssessmentNav(): any {
    const nav: any = {
      id: 'assessments',
      title: 'Assessments',
      type: 'item',
      icon: '',
      url: '/student-assessments',
    };
    return nav;
  }

  // private getSelfAssesmentNav(): any {
  //   const nav: any = {
  //     id: 'selfAssesments',
  //     title: 'Self Assessments',
  //     type: 'item',
  //     icon: '',
  //     url: '/assesment',
  //   };
  //   return nav;
  // }

  private getMyCoursesNav(): any {
    const nav: any = {
      id: AppConstants.MYCOURSES_ROUTE,
      title: 'Courses',
      type: AppConstants.MENU_TYPE_ITEM,
      icon: 'category',
      url: '/mycourses',
    };
    return nav;
  }

  private getMyStoreNav(): any {
    const nav: any = {
      id: AppConstants.MENU_STORE,
      title: 'My Store',
      type: AppConstants.MENU_TYPE_ITEM,
      icon: 'add_shopping_cart',
      url: '/store',
    };
    return nav;
  }

  // private getMyAssementNav(tenantConfig: TenantPing): any {
  //   const nav: any = {
  //     id: AppConstants.MYASSESMENT_ROUTE,
  //     title: 'Self Assessment',
  //     type: AppConstants.MENU_TYPE_ITEM,
  //     icon: 'assignment',
  //     url: AppConstants.SL_ASSESMENT_ROUTE,
  //   };
  //   return nav;
  // }

  private getMyProfileNav(user: TUser): any {
    const children = [];
    const studentNav = {
      id: AppConstants.CONTACTS_ROUTE,
      title: 'Profile',
      type: 'item',
      icon: '',
      url: `${AppConstants.SL_CONTACTS_ROUTE}/` + user.guId + '/detail',
    };
    const logoutNav = {
      id: AppConstants.LOGOUT_ROUTE,
      title: 'Logout',
      type: 'item',
      icon: '',
      url: AppConstants.SL_LOGOUT_ROUTE,
    };
    children.push(studentNav);
    children.push(logoutNav);

    const profileNav: any = {
      id: 'profile',
      title: user.name,
      type: AppConstants.MENU_TYPE_GROUP,
    };

    profileNav.children = children;
    return profileNav;
  }

  private initPublicPageNav(tenantConfig: TenantPing, router: Router): void {
    const dynamicRoutes: Routes = [];
    const allNavItems = this._fuseNavigationService.getCurrentNavigation();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allNavItems.length; i++) {
      const item = allNavItems[i];
      console.log('removing item ' + item.id);
      this._fuseNavigationService.removeNavigationItem(item.id);
    }
    // const allPageList = this.sortWebPages(tenantConfig.web);
    // const webPageList = this.sortWebPagesBySequence(allPageList);

    const webPageList = this.getSortedPageData(tenantConfig.web);
    webPageList.forEach(web => {
      const webdata = { pageName: web.name, header: web.header, subHeader: web.subHeader, footer: web.footer, headerColor: web.headerColor };
      const position = web.name === AppConstants.HOME ? AppConstants.MENU_POSITION_START : AppConstants.MENU_POSITION_END;
      const homeNav: any = {
        id: web.name,
        title: web.title,
        type: AppConstants.MENU_TYPE_ITEM,
        externalUrl: web.isExternalLink,
        url: web.isExternalLink ? web.externalLink : web.url,
        data: webdata,
        isShowNavigation: web.isShowNavigation,
        isShowFooter: web.isShowFooter,
      };
      const path = web.url;

      if (web.name === AppConstants.HOME) {
        if (web.isExternalLink) {
          this._tenantUserService.setIsExternalHomeAvailable(true);
          this._tenantUserService.setExternalHomeLink(web.externalLink);
        } else {
          this._tenantUserService.setIsInternalHomeAvailable(true);
          dynamicRoutes.push({ path, component: InternalwebsitepreviewComponent, data: webdata });
        }
      } else if (web.name === AppConstants.STANDARD_PAGE_FORMS_NAME && tenantConfig.forms.length > 0) {
        const children = this.getFormSubMenus(tenantConfig, web.url);
        if (children.length > 0) {
          homeNav.type = AppConstants.MENU_TYPE_GROUP;
          homeNav.children = children;
          homeNav.id = AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE;
          homeNav.url = null;
        }
        this._fuseNavigationService.addNavigationItem(homeNav, position);
      } else {
        if (homeNav.isShowNavigation) {
          this._fuseNavigationService.addNavigationItem(homeNav, position);
        }
      }
      if (!web.isExternalLink && !this.isStandardPage(web.name)) {
        dynamicRoutes.push({ path, component: InternalwebsitepreviewComponent, data: webdata });
      }
    });
    if (dynamicRoutes.length > 0) {
      router.config.unshift(...dynamicRoutes);
    }
  }

  public getSortedPageData(webpages: any[]): any[] {
    const allPageList = this.sortWebPages(webpages);
    const webPageList = this.sortWebPagesBySequence(allPageList);
    return webPageList;
  }

  private isStandardPage(name: string): boolean {
    return TenantSettingMenuService.STANDARD_PAGES.includes(name);
  }

  private getFormSubMenus(tenantConfig, formUrl): any[] {
    const children = [];
    tenantConfig.forms.forEach(item => {
      const obj = {
        id: item.name,
        title: item.name,
        type: 'item',
        icon: '',
        url: `/${AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE}/` + item.guId,
      };
      children.push(obj);
    });
    return children;
  }

  private addPublicFormsMenu(tenantConfig: TenantPing): void {
    const children = [];
    tenantConfig.forms.forEach(item => {
      const obj = {
        id: item.name,
        title: item.name,
        type: 'item',
        icon: '',
        url: `/${AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE}/` + item.guId,
      };
      children.push(obj);
    });

    const regformsNav: any = {
      id: AppConstants.MENU_REFORMS_ID,
      title: tenantConfig.registrationLinkName || 'forms',
      type: 'collapsable',
      icon: '',
      url: `/${AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE}`,
      children: children,
    };
    const position = AppConstants.MENU_POSITION_END;
    this._fuseNavigationService.addNavigationItem(regformsNav, position);
  }

  private sortWebPagesBySequence(webpages: any[]): any[] {
    return webpages.sort((a, b) => a.sequence - b.sequence);
  }

  //should return in following order, home, calendar, events, courses, merchandise, forms), then other pages, About, Conatact,
  private sortWebPages(web: any[]): any[] {
    const webPagesSorted = this.sortStandardWebPages(web);
    const web1 = this.addAboutToEnd(webPagesSorted);
    const web2 = this.addContactToEnd(web1);
    return web2;
  }

  private addAboutToEnd(web: any[]): any[] {
    const aboutPage = web.find(page => page.name === AppConstants.PAGE_ABOUT);
    if (aboutPage) {
      const filteredArray = web.filter(page => page.name !== AppConstants.PAGE_ABOUT);
      return [...filteredArray, aboutPage];
    } else {
      return web;
    }
  }

  private addContactToEnd(web: any[]): any[] {
    const contactPage = web.find(page => page.name === AppConstants.PAGE_CONTACT);
    if (contactPage) {
      const filteredArray = web.filter(page => page.name !== AppConstants.PAGE_CONTACT);
      return [...filteredArray, contactPage];
    } else {
      return web;
    }
  }

  private sortStandardWebPages(web: any[]): any[] {
    web.sort((a, b) => {
      const indexA = AppConstants.standardPages.indexOf(a.name);
      const indexB = AppConstants.standardPages.indexOf(b.name);

      if (indexA === -1 && indexB === -1) {
        return a.name.localeCompare(b.name);
      }

      if (indexA === -1) {
        return 1;
      }

      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });

    return web;
  }

  private parsePageUrl(pageDetail: string) {
    const [title, url] = pageDetail.split(/::/);
    const pattern = /^https?:\/\//i;
    const fixedUrl = pattern.test(url) ? url : `http://${url}`;
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    return { title: capitalizedTitle, url: fixedUrl };
  }

  private addPageMenu(pageVal) {
    const { title, url } = this.parsePageUrl(pageVal);
    if (title && url) {
      const position = AppConstants.MENU_POSITION_END;
      const pageNav: any = {
        id: title,
        title: title,
        type: 'item',
        icon: 'link',
        url: url,
        externalUrl: true,
      };
      this._fuseNavigationService.addNavigationItem(pageNav, position);
    }
  }
}
