import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeedbackDlgComponent } from "./feedback-dlg.component";
import { FeedbackDlgService } from "./feedback-dlg.service";

import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [FeedbackDlgComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    RouterModule
  ],
  exports: [FeedbackDlgComponent],
  providers: [FeedbackDlgService]
})
export class FeedbackDlgModule {}
