import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SidebarService {
  public item: Observable<any>;

  private _configItemSubject: BehaviorSubject<any>;

  constructor() {
    this._configItemSubject = new BehaviorSubject(this.item);
  }

  get getSidebarItem(): any | Observable<any> {
    return this._configItemSubject.asObservable();
  }

  setSidebarItem(item: any) {
    this.item = item;
    this._configItemSubject.next(this.item);
  }
}
