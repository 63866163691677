import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { WidgetService } from "app/main/admin/app-settings/widget/widget.service"
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstants } from "app/core/settings/appconstants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-widget-edit',
  templateUrl: './widget-edit.component.html',
  styleUrls: ['./widget-edit.component.scss']
})
export class WidgetEditComponent implements OnInit {
  guId: any;
  widget: any;
  isLoading: any;
  basicInfoForm: FormGroup;
  subs = new SubSink();
  isEditMode: boolean = false
  
  types: any = [
    { key: "Announcement", value: "Announcement" },
    { key: "Event Sidebar", value: "Event Sidebar" },
    { key: "Event List", value: "Event List" },
    { key: "Past Event List", value: "Past Event List" },
    { key: "Link", value: "Link" } 
  ];
  constructor(private _formBuilder: FormBuilder,
    private widgetService: WidgetService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = false; 
    this.basicInfoForm = this._formBuilder.group({
      name: ["", Validators.required],
      type: [""], 
      misc: ["", Validators.required],
      height: [""],
      width: [""],
      page: [""],
      template: [""],
      cssBody: [""],
      title: [""]
    });

    this.route.params.subscribe(params => {
      this.guId = params["id"]; 
      this.isEditMode = true;
      this.getWidget();
    });
  }

  getWidget(){
    this.subs.add(
      this.widgetService.getWidget(this.guId).subscribe(result => {
        this.widget = result;
        this.setForm();
      })
    );

  }

  setForm(){
    this.basicInfoForm.controls.name.setValue(this.widget.name);
    this.basicInfoForm.controls.height.setValue(this.widget.height);
    this.basicInfoForm.controls.width.setValue(this.widget.width);
    this.basicInfoForm.controls.misc.setValue(this.widget.misc);
    this.basicInfoForm.controls.type.setValue(this.widget.type);
  }

  finishSetup(){
    this.isLoading = true; 

    let payload = this.basicInfoForm.getRawValue();
    this.subs.add(
      this.widgetService.updateWidget(payload, this.guId).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.toastr.success("Widget updated successfully");
          this.router.navigate(["/"+AppConstants.APP_SETTINGS_ROUTE+"/" + AppConstants.WIDGET], { relativeTo: this.route });

        },
        (errorResp) => {
          console.log("errorResp",errorResp)
          this.toastr.error(errorResp.error.error.message);
          this.isLoading = false; 
        }
      )
    );

  }

}
