import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { AppConstants } from "app/core/settings/appconstants";
import { ServicesService } from "app/main/admin/services/services.service";
import { SidebarService } from "app/main/shared/components/app-sidebar/app.sidebar.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserSettingServiceService } from "../../services/user-setting-service.service";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
  animations: fuseAnimations
})
export class UserDetailComponent implements OnInit {
  form: FormGroup;
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  subs = new SubSink();

  guId: string;
  users: any = [];
  user: any;
  sidebarType: any = AppConstants.SIDEBAR_COURSE_TYPE;
  title: string = "Users List";
  isLoading: any = false;
  slideToggleGroup = [];

  constructor(
    public _matDialog: MatDialog,
    private userSettingsService: UserSettingServiceService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _fuseSidebarService: FuseSidebarService,
    private sidebarService: SidebarService,
    public toastr: ToastrService,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.guId = "";
    this.isLoading = true;
    this.getAllUsers();

    this.sidebarItemClick();
  }

  getAllUsers() {
    this.SpinnerService.show();
    this.subs.add(
      this.userSettingsService.getUsersObj().subscribe(result => {
        this.users = result;
        this.subs.add(
          this.route.params.subscribe(params => {
            this.guId = params["id"];
            this.getUserDetail();
          })
        );
      })
    );
  }
  sidebarItemClick() {
    this.subs.add(
      this.sidebarService.getSidebarItem.subscribe(result => {
        if (result && result.sidebarType == this.sidebarType) {
          this.guId = result.guId;
          this.getUserDetail();
        }
      })
    );
  }

  getUserDetail() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.subs.add(
      this.userSettingsService.getUserDetail(this.guId, false).subscribe(user => {
        // set user
        this.user = user;
        // get user role;
        this.subs.add(
          this.userSettingsService.getUserRoles(this.guId, false).subscribe(roles => {
            // set role
            this.user.roles = roles.roles;
            this.subs.add(
              // get notification data
              this.userSettingsService.getAllNotifications().subscribe((notificationsData: any) => {
                let notificationData = notificationsData.data.filter(x => x.contactGuId == this.guId);
                if (notificationData.length > 0) {
                  this.user.isNotifyContacts = notificationData[0].isNotifyContacts;
                  this.user.isNotifyPrograms = notificationData[0].isNotifyPrograms;
                  this.user.isNotifySales = notificationData[0].isNotifySales;
                } else {
                  this.user.isNotifyContacts = false;
                  this.user.isNotifyPrograms = false;
                  this.user.isNotifySales = false;
                }
                this.bindFormData();
                this.SpinnerService.hide();
                this.isLoading = false;
              })
            );
          })
        );
      })
    );
  }

  get isNotifySales(): boolean {
    return this.form.get("isNotifySales").value;
  }

  getformDataByControlName(formControlName): boolean {
    return this.form.get(formControlName).value;
  }

  bindSlideToggleValue(value, formFieldName) {
    this.form.get(formFieldName).setValue(value);
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      isNotifySales: [this.user.isNotifySales],
      isNotifyContacts: [this.user.isNotifyContacts],
      isNotifyPrograms: [this.user.isNotifyPrograms],
      accountLocked: [this.user.accountLocked],
      isTeacher: [this.user.isTeacher],
      roles: [this.user.roles]
    });
    this.setSlideToggleData();
  }

  updateUser() {
    this.SpinnerService.show();
    // update user
    this.subs.add(
      this.userSettingsService.updateUser(this.form.value, this.guId).subscribe(
        result => {
          // update notification
          this.subs.add(
            this.userSettingsService.updateNotification(this.form.value, this.guId).subscribe(
              result => {
                this.getAllUsers();
                this.SpinnerService.hide();
                this.toastr.success("Successfully updated user.");
              },
              error => {
                this.SpinnerService.hide();
                this.toastr.error(error);
              }
            )
          );
        },
        errorResp => {
          this.SpinnerService.hide();
          console.log("error", errorResp);
          this.toastr.error(errorResp.error.error.message);
        }
      )
    );
  }

  setSlideToggleData() {
    this.slideToggleGroup = [
      {
        groupName: "Notifications",
        list: [
          {
            title: "Sales",
            description:
              "Receive notifications when user buys a course, registers for an event or recurring payment is received",
            formFieldName: "isNotifySales",
            value: this.getformDataByControlName("isNotifySales")
          },
          {
            title: "Contact",
            description: "Receive notifications when new contact gets added",
            formFieldName: "isNotifyContacts",
            value: this.getformDataByControlName("isNotifyContacts")
          },
          {
            title: "Product",
            description: "Receive notifications when changes are made to any product",
            formFieldName: "isNotifyPrograms",
            value: this.getformDataByControlName("isNotifyPrograms")
          }
        ]
      },
      {
        groupName: "Account",
        list: [
          {
            title: "Account Suspended",
            description: "Account will be suspended as per selection",
            formFieldName: "accountLocked",
            value: this.getformDataByControlName("accountLocked")
          },
          {
            title: "Teacher",
            description: "Is this Teacher?",
            formFieldName: "isTeacher",
            value: this.getformDataByControlName("isTeacher")
          }
        ]
      }
    ];
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();

    this.sidebarService.setSidebarItem(null);
  }
}
