import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CheatSystemService {
  private activationSequence = ['Control', 'Shift', '~'];
  private commandSequenceMap : { [key: string]: string }  ;
  private commandStatusSource = new BehaviorSubject<'standby' | 'executed' | 'failed' | null>(null);
  public commandStatus$ = this.commandStatusSource.asObservable();
  public tid:string;
  private isListeningForCommand = false;
  private currentCommandSequence = '';

  constructor(private http: HttpClient) {
     this.commandSequenceMap = {
      clearc: `${environment.apiBaseUrl}/snode/cache`,
    };
  }

  public handleKeyPress(key: string) {
    if (key === 'Escape') {
      this.resetCommandSequence();
      return;
    }
    if (this.isListeningForCommand) {
      if (key.length === 1 && this.currentCommandSequence.length < 6) {
        this.currentCommandSequence += key;
        const command = Object.keys(this.commandSequenceMap).find(cmd => this.currentCommandSequence.endsWith(cmd));
        if (command) {
          this.executeCommand(command);
        } else if (this.currentCommandSequence.length === 6) {
          this.resetCommandSequence();
        }
      }
    } else if (this.isActivationSequence(key)) {
      this.isListeningForCommand = true;
      this.currentCommandSequence = '';
      this.updateCommandStatus('standby');
    }
  }

  private isActivationSequence(key: string): boolean {
    return key === this.activationSequence[this.activationSequence.length - 1];
  }

  private executeCommand(command: string) {
    const url = this.commandSequenceMap[command];
    this.http.post(url,"").subscribe({
      next: response => {
        console.log(`${command} executed: `, response);
        this.resetCommandSequence('executed');
      },
      error: err => {
        console.error(`Error executing ${command}: `, err);
        this.resetCommandSequence('failed');
      },
    });
  }

  private resetCommandSequence(status=null) {
    this.isListeningForCommand = false;
    this.currentCommandSequence = '';
    this.updateCommandStatus(status);
  }

  private updateCommandStatus(status: 'standby' | 'executed' | 'failed' | null) {
    this.commandStatusSource.next(status);
  }
}
