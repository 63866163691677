import { ViewChild, ElementRef, Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidDateFormat } from "../../../shared/enums/valid-date-format";
import { AppLoadService } from "app/core/services/appload.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { TimeZone } from "app/main/shared/enums/time-zone";
import { FileObject } from "app/main/shared/components/file-upload/file-upload.component";
import { AccountDetail } from "../models/account-details";
import { AppSettingsService } from "../services/app-settings.service";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AssestModel } from "app/main/shared/models/assest.model";
import { AppUtilsService } from "app/core/services/apputils.service";
// import { IDropdownSettings } from "ng-multiselect-dropdown";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { InvoiceService } from "../../invoices/invoice.service";
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
@Component({
  selector: "app-payment-account-setting",
  templateUrl: "./payment-account-setting.component.html",
  styleUrls: ["./payment-account-setting.component.scss"]
})
export class PaymentAccountSettingComponent implements OnInit {
  isLoading: boolean = true;
  issubmitting: boolean = false;
  form: FormGroup;

  dropdownList = [];
  // dropdownSettings: IDropdownSettings = {};
  dropdownSettings = {};
  selectedItems = [];
  tableData = [];
  selectedCurrencyList = [];
  tenant: any;
  subs = new SubSink();
  currencies = [];

  @ViewChild("search") searchTextBox: ElementRef;

  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues = [];
  //data: string[] = ["A1", "A2", "A3", "B1", "B2", "B3", "C1", "C2", "C3"];

  filteredOptions: Observable<any[]>;
  tenantPing: any;
  constructor(
    private _appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService
  ) {
    // Set the private defaults
  }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.getDefaultCurrencies();
    this.dropdownList = this.getCurrencyList();
    this.dropdownSettings = {
      idField: "code",
      textField: "text",
      allowSearchFilter: true
    };
    this.getTenantDetail();
  }

  changesCurrency() {
    let dCurrency = this.form.get("defaultCurrency").value;
    console.log("dCurrency", dCurrency);
    let usdCode = this.currencies.find(item => {
      return item.code == dCurrency;
    });
    if (usdCode == undefined && dCurrency == "USD") {
      this.currencies.push({ code: "USD", text: "United States Dollars – USD" });
    }

    if (usdCode == undefined && dCurrency == "INR") {
      this.currencies.push({ code: "INR", text: "India Rupees – INR" });
    }
  }

  getTenantDetail() {
    this.tenantPing = this._appLoadService.getTenantPing();

    this.subs.add(
      this._settingsService.getTenantDetail().subscribe(
        result => {
          this.isLoading = false;
          let resultData: any = result;
          this.tenant = resultData.data;
          this.bindFormData();
          this.SpinnerService.hide();
          if (this.tenant.currencies && this.tenant.currencies.length > 0) {
            this.tenant.currencies.forEach(e => {
              this.selectedValues.push(e.code);
            });
            this.selectedCurrencyList = this.tenant.currencies;
          }

          this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
            startWith<string>(""),
            map(name => this._filter(name))
          );
        },
        error => {
          this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
            startWith<string>(""),
            map(name => this._filter(name))
          );
          this.bindFormData();
          this.isLoading = false;
          this.SpinnerService.hide();
        }
      )
    );
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      name: [this.tenant ? this.tenant.name : "", Validators.required],
      orgId: [this.tenant ? this.tenant.orgId : this.tenantPing.orgId, Validators.required],
      defaultCurrency: [this.tenant ? this.tenant.defaultCurrency : "USD"],
      taxPercent: [this.tenant ? this.tenant.taxPercent : ""],
      cardFees: [this.tenant ? this.tenant.cardFees : ""],
      bankFees: [this.tenant ? this.tenant.bankFees : ""],
      currencies: [this.tenant ? this.tenant.currencies : ""],
      taxLabel1: [this.tenant ? this.tenant.taxLabel1 : ""],
      taxValue1: [this.tenant ? this.tenant.taxValue1 : ""],
      invoiceNote: [this.tenant?.invoiceNote ? this.tenant.invoiceNote : ""]
    });
  }

  getDefaultCurrencies() {
    this.selectedCurrencyList = InvoiceService.defaultSelectedCurrency();
  }

  getCurrencyList() {
    return InvoiceService.getCurrencyList();
  }

  getPayload() {
    let form = this.form.value;
    let currencies = [];
    this.dropdownList.forEach(e => {
      if (this.selectedValues && this.selectedValues.includes(e.code)) {
        currencies.push(e);
      }
    });
    let payload = this.tenant;
    payload.cardFees = form.cardFees;
    payload.bankFees = form.bankFees;
    payload.taxPercent = form.taxPercent;
    payload.currencies = currencies;
    payload.defaultCurrency = form.defaultCurrency;
    payload.taxLabel1 = form.taxLabel1;
    payload.taxValue1 = form.taxValue1;
    payload.invoiceNote = form.invoiceNote;
    return payload;
  }

  updateSettings() {
    if (this.form.valid) {
      this.changesCurrency();
      this.issubmitting = true;
      this.subs.add(
        this._settingsService.updateTenantSettings(this.getPayload()).subscribe(
          next => {
            this.issubmitting = false;
            this._appLoadService.updateTenantConfig(true);
            this.toastr.success("Successfully updated settings.");
            this._router.navigate(["/settings"]);
          },
          error => {
            this.toastr.error(error);
            this.issubmitting = false;
          }
        )
      );
    } else {
      this.toastr.error("Invalid Form!");
    }
  }

  /**
   * Used to filter data based on search input
   */
  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    console.log("filterValue", filterValue);
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this.dropdownList.filter(option => option.text.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue("");
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue("");
  }

  clearSelection() {
    this.selectedValues = [];
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues() {
    console.log("selectFormControl", this.selectFormControl.value);
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach(e => {
        console.log("value", this.selectFormControl);
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  /**
   * Prevents the default behavior of the Enter key in a keyboard event.
   * @param event - The keyboard event object that triggered the method.
   */
  public preventEnter(event: KeyboardEvent): void {
    const enterKey = 'Enter';
    
    if (event.key === enterKey) {
      event.preventDefault();
    }
  }
}
