import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CustomFormPreviewComponent } from "./custom-form-preview.component";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FuseSharedModule } from "@fuse/shared.module";
import { RouterModule, Routes } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { PhoneNumberModule } from "../phone-number/phone-number.module";
import { SignaturePadModule } from "angular2-signaturepad";

const routes: Routes = [
  {
    path: "registration/:id",
    component: CustomFormPreviewComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FuseSharedModule,
    NgxSpinnerModule,
    MatRadioModule,
    PhoneNumberModule,
    SignaturePadModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CustomFormPreviewComponent],
  exports: [CustomFormPreviewComponent]
})
export class CustomFormPreviewModule {}
