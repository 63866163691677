<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
      <span class="title dialog-title">Save as Template</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="p-24 pb-0 m-0" style="margin-top:25px; margin-bottom:25px">
    <div style="width: 100%;">
      <form [formGroup]="templateForm" fxLayout="column" fxLayoutAlign="center" fxFlex="grow" style="width: 100%">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Template Name</mat-label>
            <input name="name" formControlName="name" matInput required />
          </mat-form-field>
        </div>
        <!-- <app-file-upload
          [photos]="templateImage"
          (getFileObject)="templateImage$.next($event)"
          [title]="'Upload Thumbnail'"
        >
        </app-file-upload> -->
      </form>
    </div>
  </mat-dialog-content>
  <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions style="float:right;margin-left: 25px;margin-right: 25px;">
    <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">Cancel</button>
    <button
      class="mat-accent m-0"
      mat-raised-button
      tabindex="1"
      (click)="submit(this.templateForm)"
      [disabled]="templateForm.invalid"
      aria-label="Add"
    >
      Submit
      <mat-icon *ngIf="isLoading">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>
