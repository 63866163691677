export class AssestModel {
    assetLink?: string;
    assetType?: string;
    chapterId?: string;
    chapterName?: string;
    courseId?: string;
    courseName?: string;
    ownerId?: string;
    ownerName?: string;
    sectionId?: string;
    fileName?: string;
    fileType?: string;
    folderName?: string;
    isIntroVideoConcat?: boolean;
    isOutroVideoConcat?: boolean;
    isPublic?: boolean;
    isTenantIntroVideoConcat?: boolean;
    isTenantOutroVideoConcat?: boolean;
    notes?: string;
    ruleId?: string;
    ruleName?: string;
    section?: string;
    sequence?: number;
    studentId?: string;
    studentName?: string;
    videoTitle?: string;
    s3signedUrl?: string;
    isAddIntros?: boolean;
    id?: string;
    activity?: string;
    type?: string;
    title?: string;

    constructor(assest: any) {
        this.assetLink = assest.assetLink;
        this.assetType = assest.assetType;
        this.chapterId = assest.chapterId;
        this.chapterName = assest.chapterName;
        this.courseId = assest.courseId;
        this.courseName = assest.courseName;
        this.folderName = assest.folderName;
        this.isTenantOutroVideoConcat = assest.isTenantOutroVideoConcat;
        this.isIntroVideoConcat = assest.isIntroVideoConcat;
        this.isOutroVideoConcat = assest.isOutroVideoConcat;
        this.isPublic = assest.isPublic;
        this.isTenantIntroVideoConcat = assest.isTenantIntroVideoConcat;
        this.notes = assest?.notes ?? "";
        this.ruleId = assest.ruleId;
        this.ruleName = assest.ruleName;
        this.section = assest.section;
        this.sequence = assest.sequence;
        this.studentId = assest.studentId;
        this.studentName = assest.studentName;
        this.videoTitle = assest.videoTitle;
        this.ownerId = assest.ownerId;
        this.ownerName = assest.ownerName;
        this.sectionId = assest.sectionId;
        this.fileName = assest.fileName;
        this.fileType = assest.fileType;
        this.s3signedUrl = assest.s3signedUrl;
        this.isAddIntros = assest.isAddIntros;
        this.id = assest.id;
        this.activity = assest.activity ? assest.activity : "";
        this.type = assest.type ? assest.type : ""
        this.title = assest.title ? assest.title : ""

    }
}
