<ng-container *ngIf="isLoading; else content">
  <mat-icon *ngIf="isLoading" style="background: lightgray"> <mat-spinner diameter="20"> </mat-spinner> </mat-icon>
</ng-container>

<ng-template #content>
  <div id="forms" class="page-layout carded fullwidth" fxLayout="column">
    <div style="flex-direction: column">
      <ng-container *ngIf="unhtml">
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
          <div style="margin-bottom: 10px;" class="h2" fxLayout="row"  [innerHTML]="showHeader"></div>
          <div style="margin-bottom: 20px; text-align: justify; width: 100%;" class="h3" fxLayout="row"  [innerHTML]="showDescription"></div>
          <div class="h2" fxLayout="row" fxLayoutAlign="center center" [innerHTML]="unhtml"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="!unhtml">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
          <h1>404 Page Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <button routerLink="/">Go to Home Page</button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
