<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">MailChimp List - {{ data.mailChimpData.name }}</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content class="pb-0 m-0" style="margin-top: 25px; margin-bottom: 25px">
    <div
      class="filters layoutView"
      fxLayoutAlign="start center"
      fxFlex="100"
      fxLayout="row"
      fxFlex.gt-xs="100"
      fxFlex.gt-sm="100"
      style="margin: 0px auto; background: white; padding-top: 5px"
    >
      <div
        class="filters layoutView"
        fxLayoutAlign="start"
        fxFlex="100"
        fxLayout="column"
        fxFlex.gt-xs="100"
        fxFlex.gt-sm="50"
        style="margin: 0px auto; background: white; padding-top: 5px"
      >
        <div class="h2">Stat</div>

        <div>Unsubscribe Count</div>
        <div>Campaign Count</div>
        <div>Click Rate</div>
        <div>Open Rate</div>
        <div>Sync</div>
        <div>List</div>
        <div>API Key</div>
      </div>
      <div
        class="filters layoutView"
        fxLayoutAlign="start"
        fxFlex="100"
        fxLayout="column"
        fxFlex.gt-xs="100"
        fxFlex.gt-sm="50"
        style="margin: 0px auto; background: white; padding-top: 5px"
      >
        <div class="h2">Value</div>
        <div>{{ data.mailchimpInfo.syncCount }}</div>
        <div>{{ data.mailchimpInfo.unsubscribeCount }}</div>
        <div>{{ data.mailchimpInfo.campaignCount }}</div>
        <div>{{ data.mailchimpInfo.clickRate }}</div>
        <div>{{ data.mailchimpInfo.openRate }}</div>
        <div>{{ data.mailChimpData.listId }}</div>
        <div>{{ data.mailChimpData.apiKey }}</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider style="margin-top: 25px; margin-bottom: 3px"></mat-divider>

  <mat-dialog-actions style="float: right; margin-right: 25px">
    <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px">cancel</button>
  </mat-dialog-actions>
</div>
