<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
    <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
      <span class="title dialog-title">{{ title }}</span>
      <button mat-dialog-close mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-dialog-content style="width: 100%; height: 100%;padding: 10px !important; margin: 0px !important;">
    <div fxLayout="row wrap">
      <div *ngIf="isStudentRole" class="" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
        <form name="basicInfoForm" [formGroup]="basicInfoForm" style="align-items: center;">
          <div fxLayout="row" fxLayoutAlign="start start" style="margin: 10px">
            <mat-form-field appearance="outline" fxFlex="100">
              <mat-label>Message</mat-label>
              <textarea
                matInput
                formControlName="message"
                rows="5"
                cols="50"
                placeholder="Please enter your feedback message"
              ></textarea>
              <mat-error>Message is required!</mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div *ngIf="!isStudentRole" class="" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
        <div class="profile-box groups m-8" fxLayout="column" style="border-radius: 0px;box-shadow: none">
          <!-- <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="title">Notes</div>
          </header> -->
          <div class="content" style="padding-top: 0px;">
            <div *ngIf="isLoading" style="padding:20px">
              <ngx-skeleton-loader
                appearance=""
                count="3"
                [theme]="{
                  background: '#ededed',
                  height: '18px'
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                appearance=""
                count="3"
                [theme]="{
                  background: '#ededed',
                  height: '18px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div *ngFor="let feedback of dataList">
              <div class="notes" fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="row">
                  <img
                    class="logo"
                    [src]="
                      feedback.picture ||
                      'https://lh3.googleusercontent.com/-i1q_aCDjTf4/AAAAAAAAAAI/AAAAAAAAAAA/MZA3-rTo01Y/photo.jpg'
                    "
                  />
                  <div>
                    <div class="name" style="font-weight: normal">{{ feedback.message }}</div>
                    <div class="category secondary-text">
                      <a
                        class="nav-link "
                        style="cursor: pointer"
                        mat-dialog-close
                        [routerLink]="['/contacts/' + feedback.contactId + '/details']"
                      >
                        {{ feedback.name | titlecase }}
                      </a>
                      - {{ feedback.updatedAt | date: "MMM dd, YYYY":"UTC" }}
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
            <div class="h2 secondary-text m-8" *ngIf="!isLoading && dataList?.length === 0">No Feedback Found</div>

            <div *ngIf="!isStudentRole" fxLayout="row" fxLayoutAlign="start start">
              <mat-paginator
                style="background: transparent;"
                *ngIf="!isLoading && dataList"
                #paginator
                [pageSizeOptions]="pageSizeOptions"
                [length]="totalRecords"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                (page)="pageEvent = getFeedbackList($event)"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider *ngIf="isStudentRole" style="margin-top:25px;margin-bottom:3px"></mat-divider>

  <mat-dialog-actions *ngIf="isStudentRole" style="float:right;margin-right: 25px;" fxLayoutAlign="end end">
    <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">cancel</button>
    <button
      [disabled]="basicInfoForm.invalid"
      *ngIf="!isLoading"
      mat-raised-button
      type="button"
      color="accent"
      (click)="submitForm()"
    >
      Submit
    </button>
    <button *ngIf="isLoading" mat-raised-button type="button" color="accent">
      Submit
      <mat-icon>
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </mat-dialog-actions>
</div>
