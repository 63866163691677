import { FuseNavigation } from "@fuse/types";

export const student_navigation: FuseNavigation[] = [
  // {
  //   id: "applications",
  //   title: "Services",
  //   // translate: "NAV.STUDENT",
  //   type: "group",
  //   children: [
  //     {
  //       id: "schedule",
  //       title: "Classes & Events",
  //       // translate: "NAV.SCHEDULE.TITLE",
  //       type: "item",
  //       icon: "calendar_today",
  //       url: "/classes"
  //     },
  //     {
  //       id: "mycourses",
  //       title: "Courses",
  //       // translate: "NAV.SCOURSES.TITLE",
  //       type: "item",
  //       icon: "category",
  //       url: "/mycourses"
  //     },
  //     {
  //       id: "myfiles",
  //       title: "Forms",
  //       type: "item",
  //       icon: "face",
  //       url: "/myform"
  //     },
  //     // {
  //     //   id: "myfiles",
  //     //   title: "Files",
  //     //   // translate: "NAV.MYFILES.TITLE",
  //     //   type: "item",
  //     //   icon: "file_copy",
  //     //   url: "/myfiles"
  //     // },
  //     {
  //       id: "myassesment",
  //       title: "Self Assessments",
  //       // translate: "NAV.MYASSESMENT.TITLE",
  //       type: "item",
  //       icon: "assignment",
  //       url: "/myassesment"
  //     }
  //   ]
  // },
  // {
  //   id: "others",
  //   title: "others",
  //   // translate: "NAV.STUDENT",
  //   type: "group",
  //   children: [
  //     {
  //       id: "store",
  //       title: "Store",
  //       type: "item",
  //       icon: "add_shopping_cart",
  //       url: "/store"
  //     },
  //     {
  //       id: "invoices",
  //       title: "Invoices",
  //       type: "item",
  //       icon: "receipt",
  //       url: "/invoices"
  //     }
  //   ]
  // },
  // {
  //   id: "profileMenu",
  //   title: "",
  //   type: "group",
  //   children: [
  //     {
  //       id: "logout",
  //       title: "Logout",
  //       type: "item",
  //       icon: "exit_to_app",
  //       url: "/logout"
  //     }
  //   ]
  // }
];
