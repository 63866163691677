import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { InternalwebsitepreviewComponent } from "./internalwebsitepreview.component";

const routes = [
  {
    path: "",
    component: InternalwebsitepreviewComponent,
  },
];

@NgModule({
  declarations: [InternalwebsitepreviewComponent],
  imports: [
    RouterModule.forChild(routes),

    FuseSharedModule,
  ],
  exports: [InternalwebsitepreviewComponent],
})
export class InternalwebsitepreviewModule {}
