import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppLoadService } from './services/appload.service';
import { AppUtilsService } from './services/apputils.service';
import { TenantUserService } from './services/tenantuser.service';

export function initializeApp(appLoadService: AppLoadService): any {
  return () => appLoadService.initializeApp();
}

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppLoadService, TenantUserService], multi: true },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [AppUtilsService],
    };
  }
}
