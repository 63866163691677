import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { NgxSpinnerService } from "ngx-spinner";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { SubSink } from "subsink";
import { ToastrService } from "ngx-toastr";
import { AppUtilsService } from "app/core/services/apputils.service";
import { TenantService } from "app/core/services/tenant.service";

@Component({
  selector: "app-twilio",
  templateUrl: "./twilio.component.html",
  styleUrls: ["./twilio.component.scss"],
  animations: fuseAnimations
})
export class TwilioComponent implements OnInit {
  form: FormGroup;
  phone: string;
  options: boolean;
  viewInput: any;
  additionalInfo = false;
  mainIno = true;
  showmsg = false;
  showphone = true;
  tenant: any;
  subs = new SubSink();
  isLoading: boolean = false;
  isSetting: boolean = false;
  loadPhoneCode: boolean = false;
  constructor(
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.SpinnerService.show();
    this.getTenantInfo();
    this.SpinnerService.hide();
  }

  getTenantInfo() {
    this.getTenantService();
    this.initForm();
    this.subs.add(
      this._settingsService.getSMSTenantDetail().subscribe(
        (result: any) => {
          this.tenant = result.tenant;
          this.setFormValue(this.tenant);
          this.SpinnerService.hide();
        },
        error => {}
      )
    );
  }

  setFormValue(tenant) {
    this.form.get("twilioSid").setValue(tenant.twilioSid);
    this.form.get("twilioToken").setValue(tenant.twilioToken);
    this.form.get("twilioPhone").setValue(tenant.twilioPhone ? tenant.twilioPhone : tenant.twilioMesagingId);
    this.form.get("adminPhone").setValue(tenant.adminPhone);
    this.form.get("footer").setValue(tenant.footer);
    this.form.get("pageSize").setValue(tenant.pageSize);
    this.form.get("pageWaitTime").setValue(tenant.pageWaitTime);
    this.loadPhoneCode = true;
    this.phone = tenant.twilioPhone;
  }

  initForm() {
    this.form = this._formBuilder.group({
      twilioSid: ["", Validators.required],
      twilioToken: ["", Validators.required],
      pageSize: ["250"],
      footer: [""],
      twilioPhone: ["", Validators.required],
      adminPhone: [""],
      pageWaitTime: ["3"]
    });
  }
  showAdditionalInfo() {
    this.isSetting = true;
  }
  showMainInfo() {
    this.isSetting = false;
  }
  getPhoneNumber(phoneNumber: string) {
    this.phone = phoneNumber;
    console.log("this.phone", this.phone);
    // this.form.get("phone").setValue(this.phone);
  }

  getCountryCode(tenant) {
    this.loadPhoneCode = true;
    this.phone = AppUtilsService.getCountryDialCode(tenant.timezone) + "-";
  }

  getTenantService() {
    this.subs.add(
      this.tenantService.tenant$.subscribe((tenant: any) => {
        this.getCountryCode(tenant);
      })
    );
  }

  viewPhone(event: any) {
    this.viewInput = event.value;
  }
  hidemsg() {
    this.showmsg = false;
    this.showphone = true;
  }
  hidephone() {
    this.showmsg = !this.showmsg;
    this.showphone = !this.showphone;
  }

  submit() {
    this.isLoading = true;
    let payload = this.form.getRawValue();
    if (payload.twilioPhone && isNaN(payload.twilioPhone)) {
      payload.twilioMesagingId = payload.twilioPhone;
      payload.twilioPhone = "";
    }

    //payload.twilioPhone = this.phone;
    this.subs.add(
      this._settingsService.updateSMSTenant(payload).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.toastr.success("Twilio updated successfully");
        },
        errorResp => {
          this.isLoading = false;
          this.toastr.error(errorResp.error.errors.message);
        }
      )
    );
  }
}
