import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { Router } from "@angular/router";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { TUser } from "app/core/models/tenantuser";
import { AppLoadService } from "app/core/services/appload.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { WindowRef } from "app/core/services/winref.service";
import { navigation } from "app/navigation/navigation";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { AppConstants } from "../../../core/settings/appconstants";

@Component({
  selector: "webtoolbar",
  templateUrl: "./webtoolbar.component.html",
  styleUrls: ["./webtoolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WebtoolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  // selectedLanguage: any;
  userStatusOptions: any[];
  user: TUser;
  tenant;
  smallLogo = "";

  // Private
  private _unsubscribeAll: Subject<any>;
  subs = new SubSink();

  /**
   * Constructor
   *
   * @param {FuseSidebarService} _fuseSidebarService
   */
  constructor(
    private _fuseSidebarService: FuseSidebarService,
    // private _translateService: TranslateService,
    private _tenantUserService: TenantUserService,
    private router: Router,
    private _appLoadService: AppLoadService,
    private _windowRef: WindowRef
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr",
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the selected language from default languages
    // this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    this._tenantUserService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: TUser) => {
      this.user = user;
    });
    this.tenant = this._appLoadService.getTenantPing();

    this.subs.add(
      this._appLoadService.onTenantConfigChanged.subscribe((tenantConfig) => {
        this.smallLogo = tenantConfig.smallLogo;
      })
    );
  }

  onLogoClicked() {
    const tenantConfig = this._appLoadService.tenantConfig;
    if (tenantConfig.webstate === AppConstants.WEBSTATE_EXT) {
      const window = this._windowRef.nativeWindow;
      window.location.href = tenantConfig.home;
    }
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  // setLanguage(lang): void {
  //   // Set the selected language for the toolbar
  //   // this.selectedLanguage = lang;

  //   // Use the selected language for translations
  //   // this._translateService.use(lang.id);
  // }

  logInUser(): void {
    if (this._tenantUserService.isUserLoggedIn()) {
      this._tenantUserService.setPreviewMode(false);
    } else {
      this.router.navigate(["/login"]);
    }
  }
  @HostListener("window:resize", []) windowChanged() {
    this._fuseSidebarService.getSidebar('navbar').close();
  }
}
