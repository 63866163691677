import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { AppSidebarComponent } from "app/main/shared/components/app-sidebar/app-sidebar.component";

@NgModule({
  declarations: [AppSidebarComponent],
  imports: [RouterModule, MatDividerModule, MatListModule, CommonModule],
  exports: [AppSidebarComponent]
})
export class AppSidebarModule {}
