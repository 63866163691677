<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
        <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
            <span class="title dialog-title">Select Org To Login.</span>
                <mat-icon  mat-dialog-close mat-icon-button aria-label="Close dialog" style="background-color: #FFFFFF">close</mat-icon>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-dialog-content class="m-0" style="overflow: auto !important; height: 380px; padding: 0">
        <span class="title dialog-title"
              style="padding-left: 16px; margin-top: -10px;">We found you in multiple orgs
        </span>
        <div class="card-container">
            <div class="card" *ngFor="let item of _data.availableTenantList;"
                 [ngClass]="{ 'tenantSelected': selectedTenant == item }">
                <div class="content" (click)="setSelectedTenant(item)">
                    <h3>{{item}}</h3>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>
    <mat-dialog-actions style="float:right;margin-right: 20px;" >
        <button
                class="mat-accent m-0"
                mat-raised-button
                tabindex="1"
                aria-label="Add"
                [disabled]="selectedTenant == undefined"
                (click)="submit()">
            Submit
        </button>
    </mat-dialog-actions>
</div>
