<div id="forms" *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        business
      </mat-icon>
      <span class="logo-text h1"> Franchise </span>
      <div style="font-size: 14px; margin-left: 10px;margin-top: 3px;">
        > {{ franchiseData && franchiseData.name ? "Update" : "Create" }}
      </div>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings/franchise']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none">
    <div class="mb-24" style="width: 100%;">
      <div fxFlex="5"></div>

      <form
        class="mat-card mat-elevation-z4 p-24 mr-24"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="90"
        fxFlex="grow"
        name="form"
        [formGroup]="form"
        (ngSubmit)="submit()"
        fxFlexFill
      >
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Franchise Name</mat-label>
            <input matInput formControlName="businessName" class="right-align" />
            <mat-error>Name of franchise is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Franchise OrgId</mat-label>
            <input matInput formControlName="orgId" class="right-align" placeholder="Tenant Name" />
            <mat-error>Franchise OrgId is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="user">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Admin name</mat-label>
            <input matInput formControlName="name" class="right-align" placeholder="Admin Name" />
            <mat-error>Admin name is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="user">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" class="right-align" placeholder="Email" />
            <mat-error>Email is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="user" *ngIf="!isEditMode">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" class="right-align" placeholder="Password" />
            <mat-error>Password is required!</mat-error>
          </mat-form-field>
        </div>

        <!-- <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="user" *ngIf="!isEditMode">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Connection Name</mat-label>
            <input matInput formControlName="connectionName" class="right-align" placeholder="Connection Name">
            <mat-error>Connection Name is required!</mat-error>
          </mat-form-field>
        </div> -->

        <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" *ngIf="!isEditMode">
          <mat-form-field appearance="outline" fxFlex="100" matNativeControl>
            <mat-label>TimeZone</mat-label>
            <mat-select formControlName="timezone">
              <mat-option [value]="timeZone.value" *ngFor="let timeZone of timeZoneList">
                {{ timeZone.text }}
              </mat-option>
            </mat-select>
            <mat-error>TimeZone is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control" fxFlex="100">Phone</span>
        </div>
        <app-phone-number fxFlex="100" (getPhoneNumber)="getPhoneNumber($event)" [phone]="phone" [heading]="true">
        </app-phone-number>

        <div class="h2 mt-24" fxFlex="100">Address</div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="address">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Country</mat-label>
            <input matInput formControlName="country" class="right-align" placeholder="Country">
            <mat-error>Country is required!</mat-error>
          </mat-form-field>
        </div> -->
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="address">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Street Address</mat-label>
            <input matInput formControlName="line1" class="right-align" placeholder="Street Address" />
            <mat-error>Street Address is required!</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="address">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Apartment/Suite</mat-label>
            <input matInput formControlName="line2" class="right-align" placeholder="Apartment/Suite" />
            <mat-error>Apartment/Suite is required!</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="address">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>City</mat-label>
            <input matInput formControlName="city" class="right-align" placeholder="City" />
            <mat-error>City is required!</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" formGroupName="address">
          <mat-form-field appearance="outline" fxFlex="50" matInput>
            <mat-label>State</mat-label>
            <input matInput formControlName="state" class="right-align" placeholder="State" />
            <mat-error>State is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="50" matInput>
            <mat-label>Zip</mat-label>
            <input matInput formControlName="zip" class="right-align" placeholder="Zip" />
            <mat-error *ngIf="hasError('address.zip', 'required')">Zip code is required!</mat-error>
            <mat-error *ngIf="hasError('address.zip', 'minlength')"
              >Zip code must be atleast 5 characters long!</mat-error
            >
            <mat-error *ngIf="hasError('address.zip', 'maxlength')"
              >Zip code should be less than 10 characters long!</mat-error
            >
            <mat-error *ngIf="hasError('address.zip', 'pattern')">Zip code should only contain numbers!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/franchise']" class="mr-4" color="accent">Cancel</button>
          <button type="submit" mat-raised-button color="accent">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
