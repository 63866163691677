import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { NgxSpinnerService } from "ngx-spinner";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { SubSink } from "subsink";
import { ToastrService } from "ngx-toastr";
import { AppUtilsService } from "app/core/services/apputils.service";
import { TenantService } from "app/core/services/tenant.service";

@Component({
  selector: "app-brevo",
  templateUrl: "./brevo.component.html",
  styleUrls: ["./brevo.component.scss"],
  animations: fuseAnimations
})
export class BrevoComponent implements OnInit {
  form: FormGroup;
  phone: string;
  options: boolean;
  viewInput: any;
  additionalInfo = false;
  mainIno = true;
  showmsg = false;
  showphone = true;
  tenant: any;
  subs = new SubSink();
  isLoading: boolean = false;
  isSetting: boolean = false;
  loadPhoneCode: boolean = false;

  constructor(
    private _settingsService: AppSettingsService,
    public toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.SpinnerService.show();
    this.getTenantInfo();
    this.SpinnerService.hide();
  }

  initForm() {
    this.form = this._formBuilder.group({
      sibApiKey: ["", Validators.required],
      fromEmail: ["", Validators.required],
      fromName: ["", Validators.required]
    });
  }

  setFormValue(tenant) {
    this.form.get("sibApiKey").setValue(tenant.sibApiKey);
    this.form.get("fromName").setValue(tenant.fromName);
    this.form.get("fromEmail").setValue(tenant.fromEmail);
  }

  getTenantInfo() {
    this.initForm();
    this.subs.add(
      this._settingsService.getCampaignDetail().subscribe(
        (result: any) => {
          this.tenant = result.tenant;
          this.setFormValue(this.tenant);
          this.SpinnerService.hide();
        },
        error => {}
      )
    );
  }

  submit() {
    this.isLoading = true;
    let payload = this.form.getRawValue();
    
    this.subs.add(
      this._settingsService.updateBrevoTenant(payload).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.toastr.success("Brevo updated successfully");
        },
        errorResp => {
          this.isLoading = false;
          this.toastr.error(errorResp.error.errors.message);
        }
      )
    );
  }
}
