import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import {
  ExternalWebsiteService
} from "../../admin/app-settings/website-setting/external-website/externalwebsite.service";
import {SubSink} from "subsink";
import {ToastrService} from "ngx-toastr";

@Component({
  templateUrl: "./internal-website-publish-unpublish-dlg.component.html",
  styleUrls: ["./internal-website-publish-unpublish-dlg.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InternalWebsitePublishUnpublishDlgComponent implements OnInit {
  isShowFooter: boolean = true;
  isShowNavigation: boolean = true;
    subs = new SubSink();

  constructor(
    public matDialogRef: MatDialogRef<InternalWebsitePublishUnpublishDlgComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _externalWebsiteService: ExternalWebsiteService,
    public toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
      this.isShowNavigation = this._data.isShowNavigation;
      this.isShowFooter = this._data.isShowFooter;
  }

  addUpdateInternalWebsite( ) {
       var payload = {
          isShowFooter: this.isShowFooter,
          isShowNavigation: this.isShowNavigation,
      };
    this.subs.add(
        this._externalWebsiteService.updateInternalWebsitePage(this._data.pageId, payload).subscribe(
            result => {
              this.toastr.success('Updated successfully.');
            },
            error => {
              this.toastr.error(error.message);
            },
        ),
    );
  }
}
