import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConstants } from 'app/core/settings/appconstants';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/' + AppConstants.CALENDAR_ROUTE,
    pathMatch: 'full',
  },
  //Public facing modules
  {
    path: AppConstants.CALENDAR_ROUTE,
    loadChildren: () => import('./main/public/calendar/calendar.module').then(m => m.CalendarModule),
  },
  {
    path: AppConstants.COURSES_ROUTE,
    loadChildren: () => import('./main/public/courses/coursecontent.module').then(m => m.CourseContentModule),
  },
  {
    path: AppConstants.CONTENT_ROUTE,
    loadChildren: () => import('./main/admin/content/content.module').then(m => m.ContentModule),
  },
  {
    path: AppConstants.PREVIEW_REGISTER_FORM,
    loadChildren: () => import('./main/public/introductory-form-component/introductory-form-component.module').then(m => m.IntroductoryFormModule),
  },
  {
    path: AppConstants.MERCHANDISE_ROUTE,
    loadChildren: () => import('./main/public/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: AppConstants.STUDENT_INVOICE_ROUTE,
    loadChildren: () => import('./main/public/student-invoice/student-invoice.module').then(m => m.StudentInvoiceModule),
  },
  {
    path: AppConstants.INVOICE_ROUTE,
    loadChildren: () => import('./main/public/invoice/invoice.module').then(m => m.InvoiceModule),
  },
  {
    path: AppConstants.PDONATIONS_ROUTE,
    loadChildren: () => import('./main/public/donations/public-donations.module').then(m => m.DonationsModule),
  },
  {
    path: AppConstants.DONATE_ROUTE,
    loadChildren: () => import('./main/public/donate/donate.module').then(m => m.DonateModule),
  },
  {
    path: AppConstants.PUBLIC_REGISTRATION_FORM_ROUTE,
    loadChildren: () => import('./main/public/public-registration-form/public-registration-form.module').then(m => m.PublicRegistrationFormModule),
  },
  {
    path: AppConstants.FORGOT_PASSWORD_ROUTE,
    loadChildren: () => import('./main/public/forgotpassword/forgot.password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: AppConstants.REGISTER_ROUTE,
    loadChildren: () => import('./main/public/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: AppConstants.FORMS_ROUTE,
    loadChildren: () => import('./main/shared/components/custom-form-preview/custom-form-preview.module').then(m => m.CustomFormPreviewModule),
  },
  {
    path: AppConstants.EVENTS_ROUTE,
    loadChildren: () => import('./main/public/events/eventcontent.module').then(m => m.EventContentModule),
  },
  {
    path: AppConstants.MEDIA_GALLERY_ROUTE,
    loadChildren: () => import('./main/public/events/eventsgallery.module').then(m => m.EventGalleryModule),
  },
  {
    path: AppConstants.CHECKOUT_ROUTE,
    loadChildren: () => import('./main/public/checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: AppConstants.LOGIN_ROUTE,
    loadChildren: () => import('./main/public/login/login.module').then(m => m.LoginModule),
  },
  {
    path: AppConstants.ONBOARDING_ROUTE,
    loadChildren: () => import('./main/shared/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: AppConstants.CONTACTS_ROUTE,
    loadChildren: () => import('./main/admin/contacts/contacts.module').then(m => m.ContactsModule),
  },
  //Student modules
  {
    path: AppConstants.MYCOURSES_ROUTE,
    loadChildren: () => import('./main/student/mycourses/mycourses.module').then(m => m.MyCoursesModule),
  },
  {
    path: AppConstants.STUDENTASSESSMENTS_ROUTE,
    loadChildren: () => import('./main/student/assessment/assessment.module').then(m => m.AssessmentModule)
  },
  {
    path: AppConstants.MYREGISTRATION_ROUTE,
    loadChildren: () => import('./main/student/my-registration/my-registration.module').then(m => m.MyRegistrationModule),
  },
  {
    path: AppConstants.MYASSESMENT_ROUTE,
    loadChildren: () => import('./main/student/myassesment/myassesment.module').then(m => m.MyassesmentModule),
  },
  {
    path: AppConstants.MYDONATION_ROUTE,
    loadChildren: () => import('./main/student/mydonation/mydonation.module').then(m => m.MydonationModule),
  },
  {
    path: AppConstants.MYFILES_ROUTE,
    loadChildren: () => import('./main/student/myfiles/myfiles.module').then(m => m.MyfilesModule),
  },
  //admin modules
  {
    path: AppConstants.DASHBOARD_ROUTE,
    loadChildren: () => import('./main/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: AppConstants.SERVICES_ROUTE,
    loadChildren: () => import('./main/admin/services/services.module').then(m => m.ServicesModule),
  },
  {
    path: AppConstants.PRODUCT_BUNDLE_ROUTE,
    loadChildren: () => import('./main/admin/product-bundle/product-bundle.module').then(m => m.ProductBundleModule),
  },
  {
    path: AppConstants.EMAILCAMPAIGNS_ROUTE,
    loadChildren: () => import('./main/admin/emailcampaigns/emailcampaigns.module').then(m => m.EmailCampaignsModule),
  },
  {
    path: AppConstants.QUESTIONBANK_ROUTE,
    loadChildren: () => import('./main/admin/question-bank/question-bank.module').then(m => m.QuestionBankModule),
  },
  {
    path: AppConstants.ASSESMENT_ROUTE,
    loadChildren: () => import('./main/admin/assesment/assesment.module').then(m => m.AssesmentModule),
  },
  {
    path: AppConstants.DONATION_ROUTE,
    loadChildren: () => import('./main/admin/donations/donations.module').then(m => m.DonationsModule),
  },
  {
    path: AppConstants.CUSTOMFORMS_ROUTE,
    loadChildren: () => import('./main/admin/custom-forms/custom-forms.module').then(m => m.CustomFormsModule),
  },
  {
    path: AppConstants.OFFERS_ROUTE,
    loadChildren: () => import('./main/admin/offers/offers.module').then(m => m.OffersModule),
  },
  //fallback  
  {
    path: AppConstants.SCHEDULE_ROUTE,
    loadChildren: () => import('./main/admin/schedule/schedulecontent.module').then(m => m.ScheduleContentModule),
  },
  {
    path: AppConstants.CLASSES_ROUTE,
    loadChildren: () => import('./main/student/schedule/schedulecontent.module').then(m => m.ScheduleContentModule),
  },
  {
    path: AppConstants.REGISTRATIONFORMS_ROUTE,
    loadChildren: () => import('./main/admin/registration-forms/registration-forms.module').then(m => m.RegistrationFormsModule),
  },
  {
    path: AppConstants.TESTS_ROUTE,
    loadChildren: () => import('./main/admin/tests/tests.module').then(m => m.TestsModule),
  },
  //user settings modules
  {
    path: AppConstants.USER_SETTINGS,
    loadChildren: () => import('./main/admin/app-settings/app-settings.module').then(m => m.AppSettingsModule),
  },
  {
    path: AppConstants.WEBSITE,
    loadChildren: () => import('./main/admin/app-settings/website-setting/external-website/external-website.module').then(m => m.ExternalWebsiteModule),
  },
  {
    path: AppConstants.WIDGET,
    loadChildren: () => import('./main/admin/app-settings/widget/widget.module').then(m => m.WidgetModule),
  },
  {
    path: AppConstants.PLAN,
    loadChildren: () => import('./main/admin/app-settings/plan/plan.module').then(m => m.PlanModule),
  },
  {
    path: AppConstants.MASTER_PLAN,
    loadChildren: () => import("./main/admin/plans/plans.module").then(m => m.PlansModule)
  },
  {
    path: AppConstants.TENANT_PLAN,
    loadChildren: () => import('./main/admin/tenantplan/tenantplan.module').then(m => m.TenantplanModule),
  },
  {
    path: AppConstants.SIGNIN_ROUTE,
    loadChildren: () => import('./main/admin/signin/signin.module').then(m => m.SigninModule),
  },
  {
    path: AppConstants.RULES_ROUTE,
    loadChildren: () => import('./main/admin/programs/programs.module').then(m => m.ProgramsModule),
  },
  {
    path: AppConstants.CLASS_RULES_ROUTE,
    loadChildren: () => import('./main/admin/class-rule/class-rule.module').then(m => m.ClassRuleModule),
  },
  {
    path: AppConstants.PRODUCTS_ROUTE,
    loadChildren: () => import('./main/admin/products/products.module').then(m => m.ProductsModule),
  },

  {
    path: AppConstants.STORE_ROUTE,
    loadChildren: () => import('./main/admin/store/store.module').then(m => m.StoreModule),
  },
  {
    path: AppConstants.CART_CHECKOUT_ROUTE,
    loadChildren: () => import('./main/admin/cart-checkout/cart-checkout.module').then(m => m.CartCheckoutModule),
  },
  {
    path: AppConstants.REPORTS_ROUTE,
    loadChildren: () => import('./main/admin/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: AppConstants.CLASS_ROUTE,
    loadChildren: () => import('./main/admin/classes/classes.module').then(m => m.ClassesModule),
  },
  {
    path: AppConstants.SIGNUP_ROUTE,
    loadChildren: () => import('./main/student/signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: AppConstants.FILES_ROUTE,
    loadChildren: () => import('./main/admin/files/files.module').then(m => m.FilesModule),
  },
  {
    path: AppConstants.INVOICES_ROUTE,
    loadChildren: () => import('./main/admin/invoices/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: AppConstants.LOGOUT_ROUTE,
    loadChildren: () => import('./main/admin/logout/logout.module').then(m => m.LogoutModule),
  },
  {
    path: AppConstants.PROFILE_ROUTE,
    loadChildren: () => import('./main/admin/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: AppConstants.TENANT_ROUTE,
    loadChildren: () => import('./main/admin/tenants/tenants.module').then(m => m.TenantsModule),
  },
  {
    path: `${AppConstants.TPLAN_ROUTE}/:name/:freq`,
    loadChildren: () => import('./main/public/tplan/tplan.module').then(m => m.TplanModule),
  },
  {
    path: AppConstants.GET_STARTED,
    loadChildren: () => import('./main/admin/get-started/getstarted.module').then(m => m.GetstartedModule),
  },
  {
    path: AppConstants.CHECKOUT_REGISTER_FORM,
    loadChildren: () => import('./main/public/checkout-form/checkout-form.module').then(m => m.CheckoutFormModule),
  },
  {
    path: 'integrations',
    redirectTo: '/settings/integrations',
  },
  {
    path: AppConstants.PREVIEW_ROUTE,
    loadChildren: () => import('./main/public/courses/coursecontent.module').then(m => m.CourseContentModule),
  },
  {
    path: AppConstants.OAUTH,
    loadChildren: () => import('./main/admin/oauth/oauth-integration/oauth-integration.module').then(m => m.OauthIntegrationModule),
  },
  {
    path: '**',
    loadChildren: () => import('./main/public/internalwebsite/internalwebsitepreview.module').then(m => m.InternalwebsitepreviewModule),
    data: { pageName: '404' }, // set the pageName data property here
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
  bootstrap: [],
})
export class AppRoutingModule { }
