import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ItemCheckoutService {
  private _configSubject: BehaviorSubject<any>;
  constructor(private http: HttpClient) {}

  getYears() {
    let years = [];
    let date = new Date();
    let fullYear = date.getFullYear();
    let year = { key: fullYear, value: fullYear };
    years.push(year);
    for (let i = 1; i < 10; i++) {
      let val = fullYear + i;
      let year = { key: val, value: val };
      years.push(year);
    }
    return years;
  }

  getMonths() {
    return [
      { key: "01", value: "01 - January" },
      { key: "02", value: "02 - February" },
      { key: "03", value: "03 - March" },
      { key: "04", value: "04 - April" },
      { key: "05", value: "05 - May" },
      { key: "06", value: "06 - June" },
      { key: "07", value: "07 - July" },
      { key: "08", value: "08 - August" },
      { key: "09", value: "09 - September" },
      { key: "10", value: "10 - October" },
      { key: "11", value: "11 - November" },
      { key: "12", value: "12 - December" }
    ];
  }

  getQtyList() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9];
  }
  getbankAccountTypes() {
    return [{ key: "checking", value: "Checking" }, { key: "savings", value: "Savings" }];
  }
  buildQueryString(cardInfo: any) {
    let str = [];

    // At the end of this step, we'll have something like this:
    // [ 'foo=bar', 'bar=hello%20friend' ]
    for (let key in cardInfo) {
      // We only want info from the actual submitted card info, instead of any
      // prototype it might belong to.
      if (cardInfo.hasOwnProperty(key)) {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(cardInfo[key]));
      }
    }

    let output = str.join("&");
    return output;
  }
  initPSpringPayment(cardInfo: any, key: any): Observable<any> {
    let param = this.buildQueryString(cardInfo);
    param += "&public_api_key=" + key;
    let apiUrl = `https://api.paymentspring.com/api/v1/tokens/jsonp?${param}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        console.log("result", result);
        return result;
      })
    );
  }

  checkout(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/checkout`;
    return this.http.post(apiUrl, payload).pipe(
      map((result: any) => {
        console.log("result", result);
        return result;
      }),
      catchError(err => of(err))
    );
  }

  registerEvent(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/register`;
    return this.http.post(apiUrl, payload).pipe(
      map((result: any) => {
        console.log("result", result);
        return result;
      }),
      catchError(err => of(err))
    );
  }

  getDonationCategoryItems(orgId: any, guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/promotions/${guId}/items?tenant=${orgId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getDonationItems(orgId: any, guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/donations/${guId}/items?tenant=${orgId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  createPayment(type: any, payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/payment?type=${type}`;
    return this.http.post(apiUrl, payload).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getPaymentsCheckoutDetails(orgId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/checkout?orgId=${orgId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getPaymentsTokenPublic(orgId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/cart?orgId=${orgId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(err => of(err))
    );
  }

  createCartInvoicePublic(payload: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/cart`;
    return this.http.post(apiUrl, payload).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(err => of(err))
    );
  }

  getMembershipDetails(orgId: any, guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/public/pitem/${guId}?tenant=${orgId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        if (result.donationCategory) {
          result["category"] = result.donationCategory;
          result["donation_type"] = "donation";
        }
        return result;
      })
    );
  }

  getInvoice(guId: any): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/item/invoice/public/${guId}`;
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getOffer(offerCode: any, orgId: any, isOtherPrice: any, categoryGuId: any, itemGuId: any): Observable<any> {
    let offer = null;
    if (isOtherPrice) {
      return offer;
    }
    let apiUrl = `${environment.apiBaseUrl}/public/itemOffer?offerCode=${offerCode}&tenant=${orgId}`;
    if (categoryGuId) {
      apiUrl += "&categoryGuId=" + categoryGuId;
    }
    if (itemGuId) {
      apiUrl += "&itemGuId=" + itemGuId;
    }
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        offer = result;
        if (offer.offerType === "buyFirstAndGetOther") {
          alert("Not a valid code for this item");
          offer = null;
        }
        return offer;
      }),
      catchError(err => of(err))
    );
  }

  getOfferItem(offerCode: any, tenantId: any, isOtherPrice: any, categoryGuId: any, itemGuId: any): Observable<any> {
    let offer = null;
    if (isOtherPrice) {
      return offer;
    }
    offerCode = offerCode.toLowerCase();
    let apiUrl = `${environment.apiBaseUrl}/item/offercode/${offerCode}/public?tenantId=${tenantId}`;
    // if (categoryGuId) {
    //   apiUrl += "&categoryGuId=" + categoryGuId;
    // }
    // if (itemGuId) {
    //   apiUrl += "&itemGuId=" + itemGuId;
    // }
    return this.http.get(apiUrl).pipe(
      map((result: any) => {
        offer = result;
        if (offer.offerType === "buyFirstAndGetOther") {
          alert("Not a valid code for this item");
          offer = null;
        }
        return offer;
      }),
      catchError(err => of(err))
    );
  }

  getItemTenant(tenantId: any) {
    return this.http.get(`${environment.apiBaseUrl}/item/tenant/public/${tenantId}`);
  }

  getPaymentIntent(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/payment/public/paymentintent`, payload);
  }

  validationCheckoutPayload(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/checkout/public/validate`, payload);
  }

  checkoutPayment(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/item/checkout/public`, payload);
  }

  productCheckout(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/snode/product/checkout`, payload);
  }

  multiCheckout(payload: any) {
    return this.http.post(`${environment.apiBaseUrl}/snode/product/multicheckout`, payload);
  }
}
