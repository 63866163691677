import {Component, Inject, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, EMPTY, Subject} from 'rxjs';
import {
    map,
    startWith,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
    catchError,
    takeUntil
} from 'rxjs/operators';
import {AppSearchContactService} from './app-search-contact.service';
import { TenantUserService } from 'app/core/services/tenantuser.service';

@Component({
    selector: 'app-search-contact',
    templateUrl: './app-search-contact.component.html',
    styleUrls:['./app-search-contact.component.scss'],
})
export class AppSearchContactComponent implements OnInit {
    @Input() placeHolder = 'Search Contact';
    @Output() outputToParent = new EventEmitter<any>();

    contactSearchControl = new FormControl();
    searchContacts: Observable<string[]>;  
    guId: string;
    isLoading: boolean;
    showSearch: boolean = false;
    private _unsubscribeAll: Subject<any>;

    constructor(private tenantUserService: TenantUserService, private appSearchContactService: AppSearchContactService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.searchContacts = this.contactSearchControl.valueChanges.pipe(
            debounceTime(20),
            distinctUntilChanged(),
            switchMap(search => this.getSearchResult(search)),
           
            map(data => {
                // return data.slice(0, data.length - 1);
                this.isLoading = false;
                return data;
            })
          
            
        );
      
        
    }

    getSearchResult(search: any) {
        this.isLoading = true;
        if (search) {
            this.showSearch = true;
            return this.appSearchContactService.contactSearch(search).pipe(
                catchError(err => {
                    return EMPTY;
                })
            );
        } else {
            this.showSearch = false;
            return this.appSearchContactService.contactSearch(search = undefined).pipe(
                catchError(err => {
                     this.isLoading = false;
                    return [];
                })
            );
       
        }
    }

    setContactID(contact) {
        this.guId = contact.id;
        this.outputToParent.emit(contact);
        this.contactSearchControl.setValue("");
    }

    onConfirmClick(): void {
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
