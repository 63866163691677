import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { LocalstorageService } from "app/core/services/applocalstorage.service";
import { AppConstants } from "app/core/settings/appconstants";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root"
})
export class StripeService {
  baseUrl = environment.apiBaseUrl;
  static METHOD_TYPE_PSPRING_CARD = "credit_card";
  static METHOD_TYPE_PSPRING_BANK = "bank_account";

  constructor(
    private http: HttpClient,
    private _toastrService: ToastrService,
    private _localStorageService: LocalstorageService
  ) {}

  async processStripePayment(user: any, card: any, stripe: any, clientSecret: any) {
    let fullName = user.firstName;
    if (user.lastName != null) {
      fullName += user.lastName;
    }
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          name: fullName,
          email: user.contactEmail
        }
      }
    });
    if (error) {
      //this.isLoading = false;
      console.log("Error:", error);
      this._toastrService.error(error.message);
      return false;
    } else if (paymentIntent) {
      console.log("Success!", paymentIntent);
      return paymentIntent.id;
    }
  }

  async confirmCardSetup(user: any, card: any, stripe: any, clientSecret: any) {
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          name: user.fullName,
          email: user.contactEmail
        }
      }
    });
    if (error) {
      //this.isLoading = false;
      console.log("Error:", error);
      this._toastrService.error(error.message);
      return false;
    } else if (setupIntent) {
      console.log("Success!", setupIntent);
      return setupIntent.id;
    }
  }

  public checkPhonepePaymentStatus(payload: any) {
    return this.http.get(`${environment.apiBaseUrl}/item/phonepe/${payload.tenantId}/confirm/${payload.paymentIntent}`);
  }

  getStripeCardStyleOptions() {
    return {
      style: {
        base: {
          iconColor: "#000000",
          color: "#000000",
          fontWeight: "500",
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "17px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#3c4252"
          },
          "::placeholder": {
            color: "#3c4252"
          }
        },
        invalid: {
          iconColor: "#fa755a",
          color: "#fa755a"
        }
      }
    };
  }

  static getPaymentintentUrl() {
    return `${environment.apiBaseUrl}/item/payment/paymentintent`;
  }

  getRequestToken() {
    return "Bearer " + this._localStorageService.getItem(AppConstants.AUTHTOKEN_KEY);
  }

  getTransactionalPayload(methodId, paymentType) {
    let payload = {
      methodId: methodId,
      paymentType: paymentType,
      nonce: "",
      methodType: "",
      paymentIntent: "",
      setupIntent: ""
    };
    console.log("Transactional payload", payload);
    return payload;
  }

  getTransactionalPspringPayload(nonce, methodId, paymentType, methodType) {
    let payload = {
      nonce: nonce,
      methodId: methodId,
      methodType: methodType,
      paymentType: paymentType,
      paymentIntent: ""
    };
    console.log("Transactional payload", payload);
    return payload;
  }

  getUserInfo(userInfo: any, amount: any, currency: any) {
    let user = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      contactId: userInfo.contactId,
      contactEmail: userInfo.contactEmail,
      amount: amount,
      currency: currency,
      contactPhone: userInfo.contactPhone,
      smallLogo: userInfo.smallLogo,
      notes: userInfo.notes
    };
    return user;
  }

  getPaymentIntentPayload(user, paymentType) {
    let payload = {
      contactId: user.contactId,
      contactEmail: user.contactEmail,
      currency: user.currency,
      amount: parseFloat(user.amount),
      paymentType: paymentType,
      redirectUrl: ""
    };
    return payload;
  }

  getProductType(paymentType) {
    console.log("paymentType", paymentType);
    if (paymentType == AppConstants.PaymentType.PRODUCT) {
      return AppConstants.PaymentType.PRODUCT.toLowerCase();
    } else if (paymentType == AppConstants.PaymentType.DONATION) {
      return AppConstants.PaymentType.DONATION.toLowerCase();
    } else {
      return AppConstants.PaymentType.COURSE.toLowerCase();
    }
  }

  razorpayOption(key, orderId, user) {
    let fullName = user.firstName;
    if (user.lastName) {
      fullName = fullName + " " + user.lastName;
    }
    return {
      key: key,
      amount: user.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: user.currency,
      name: fullName, // company name or product name
      description: user.notes, // product description
      image: user.smallLogo, // company logo or product image
      order_id: orderId, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false
      },
      prefill: {
        name: fullName,
        email: user.contactEmail,
        contact: user.contactPhone
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: "#0c238a"
      },
      notify: {
        sms: true,
        email: true
      },
      reminder_enable: true,
      options: {
        checkout: {
          method: {
            netbanking: "1",
            card: "1",
            upi: "0",
            wallet: "0"
          }
        }
      }
    };
  }
  isB2CTenant(orgId) {
    return orgId == AppConstants.MINDSYNC_USA_ORG || orgId == AppConstants.B2C_ORG || orgId == AppConstants.TEST_ORG
      ? true
      : false;
  }

  isValidOfferCodeByType(type, cartItems) {
    let validCode = false;
    let category = cartItems.find((item: any) => {
      return item.courseType == type;
    });
    if (category != null && category != undefined) {
      validCode = true;
    }
    return validCode;
  }

  isValidOfferCodeById(cartItems, offerCodeResponse) {
    let validCode = false;
    let category = cartItems.find((item: any) => {
      return item.courseGuId == offerCodeResponse.appliesOn;
    });
    if (category != null && category != undefined) {
      validCode = true;
    }
    return validCode;
  }

  validateOfferCode(cartItems, offerCodeResponse, isPublic) {
    let validCode = false;
    if (offerCodeResponse.itemType == AppConstants.Offer.ITEMCATETORY_ITEM) {
      if (offerCodeResponse.appliesOn == AppConstants.Offer.ITEMCOURSE) {
        validCode = this.isValidOfferCodeByType(
          isPublic ? AppConstants.Offer.ITEMCATETORY_COURSE : AppConstants.PaymentType.PAID,
          cartItems
        );
      } else if (offerCodeResponse.appliesOn == AppConstants.Offer.ITEMCATETORY_PRODUCT) {
        validCode = this.isValidOfferCodeByType(AppConstants.PaymentType.PRODUCT, cartItems);
      } else if (offerCodeResponse.appliesOn == AppConstants.Offer.ITEMCATETORY_ITEM) {
        validCode = true;
      }
    } else if (
      offerCodeResponse.itemType == AppConstants.Offer.ITEMCATETORY_COURSE ||
      offerCodeResponse.itemType == AppConstants.Offer.ITEMCATETORY_PRODUCT
    ) {
      validCode = this.isValidOfferCodeById(cartItems, offerCodeResponse);
    }
    return validCode;
  }
}
