export const environment = {
  production: true,
  hmr: false,
  version: "v6.4 December 2022",
  apiBaseUrl: "https://api.wajooba.xyz",
  awsS3Folder: "staging/post/",
  tenatAuthInfoDomain: "wajooba-stg.auth0.com",
  tenatAuthInfoClientId: "orF9Ycw2DyC1Y4S4L1nxNVARMuxRPkyK",
  recaptchaSiteKey: "6LeW-BgpAAAAABg1DxexeLELVqFS5ATYzqcfSsYZ",
  idleTimeoutTimeInMinutes: 5,
  tokenRefreshBufferTimeInMinutes: 2,
};
