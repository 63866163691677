import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountDetail } from '../models/account-details';
import { AppSettingsService } from '../services/app-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '@fuse/animations';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles-setting',
  templateUrl: './roles-setting.component.html',
  styleUrls: ['./roles-setting.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class RolesSettingComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean = true;
  _accountDetail: AccountDetail;
  staffData;
  frontDeskData;

  subs = new SubSink();
  
   /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _settingsService: AppSettingsService,
    private _formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this.isLoading = true; 
    this.SpinnerService.show();
    this.subs.add(this._settingsService
      .getAccountDetail()
      .subscribe((data) => {
        this._accountDetail = { ...data };
        this.staffData = this._accountDetail.tenantRoles.filter(x => x.role == "ROLE_STAFF")[0];
        this.frontDeskData = this._accountDetail.tenantRoles.filter(x => x.role == "ROLE_FRONTDESK")[0];
        this.bindFormData();
        this.isLoading = false;
        this.SpinnerService.hide();
      }))
  }

  bindFormData(): void {
    this.form = this._formBuilder.group({
      isAllowCreateClassesStaff: [this.staffData?.isAllowCreateClasses ?? false],
      isShowCourseStaff: [this.staffData?.isShowCourse ?? false],
      isShowContactsListStaff: [this.staffData?.isShowContactsList ?? false],
      isShowOffersStaff: [this.staffData?.isShowOffers ?? false],
      isAllowCreateClassesFrontdesk: [this.frontDeskData?.isAllowCreateClasses ?? false],
      isShowCourseFrontdesk: [this.frontDeskData?.isShowCourse ?? false],
      isShowContactsListFrontdesk: [this.frontDeskData?.isShowContactsList ?? false],
      isShowOffersFrontdesk: [this.frontDeskData?.isShowOffers ?? false],
    });
  }

  getformDataByControlName(formControlName): boolean {
    return this.form.get(formControlName).value;
  }

  updateSettings() {
    if (this.form.valid) {
      this.SpinnerService.show();
      let payload = {
        tenantRoles: [
          {
            isAllowCreateClasses : this.getformDataByControlName("isAllowCreateClassesStaff"),
            isShowCourse : this.getformDataByControlName("isShowCourseStaff"),
            isShowContactsList : this.getformDataByControlName("isShowContactsListStaff"),
            isShowOffers : this.getformDataByControlName("isShowOffersStaff"),
            role : "ROLE_STAFF"
          },
          {
            isAllowCreateClasses : this.getformDataByControlName("isAllowCreateClassesFrontdesk"),
            isShowCourse : this.getformDataByControlName("isShowCourseFrontdesk"),
            isShowContactsList : this.getformDataByControlName("isShowContactsListFrontdesk"),
            isShowOffers : this.getformDataByControlName("isShowOffersFrontdesk"),
            role : "ROLE_FRONTDESK"
          }
        ]
      };
      this.subs.add(this._settingsService.updateSettings(payload).subscribe(
        (next) => {
          this.SpinnerService.hide(); 
          this.toastr.success("Successfully updated roles.");
          this._router.navigate(['/settings']);
        },
        (error) => {
          this.SpinnerService.hide(); 
          this.toastr.error(error);
        }
      ))
    } else {
      this.toastr.error("Invalid Form!");
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }

}
