import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  public dialogTitle: string;
  public content: string;
  public actionBtn: string;
  public isErrorDlg: boolean;
  public cancelBtn: any;
  constructor(public matDialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.dialogTitle = _data.dialogTitle;
    this.content = _data.content;
    this.actionBtn = _data.actionBtn == null ? 'Yes' : _data.actionBtn;
    this.cancelBtn = _data.cancelBtn == null ? 'No' : _data.cancelBtn;
    this.isErrorDlg = _data.isErrorDlg == null ? false : true;
  }

  ngOnInit() {}
}
