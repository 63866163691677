import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConstants } from '../settings/appconstants';
import { AppLoadService } from './appload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { astronautNames, coolKeywords } from './utilArrays';
import { TenantService } from './tenant.service';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AppUtilsService {
  private _fakeUrl: string;

  constructor(private http: HttpClient, private _router: Router, public appLoadService: AppLoadService, public sanitizer: DomSanitizer, private _settingService: AppSettingsService) {
    this._fakeUrl = 'https://jsonplaceholder.typicode.com/posts';
  }

  public isSameTenant(tId) {
    return this._settingService.accountDetails?.tenantId == tId;
  }

  public getQuestionTypeDetails(questionsType): any {
    switch (questionsType) {
      case 'graded':
        return { text: 'Graded', colorClass: 'yellow' };
      case 'singlechoice':
        return { text: 'Single Choice', colorClass: 'green' };
      case 'multichoice':
        return { text: 'Multiple Choice', colorClass: 'orange' };
      case 'openended':
        return { text: 'Open Ended', colorClass: 'blue' };
      default:
        return { text: 'N/A', colorClass: 'grey' };
    }
  }

  public getResultStatus(data): any {
    if (data?.quizType == 'graded') {
      return { text: 'N/A', colorClass: 'grey' };
    }

    switch (data.status) {
      case 'inreview':
        return { text: 'In Review', colorClass: 'yellow' };
      case 'completed':
        return { text: data.isPass ? 'Pass' : 'Fail', colorClass: data.isPass ? 'green' : 'red' };
      case 'inprogress':
        return { text: 'In Progress', colorClass: 'blue' };
      case 'abandoned':
        return { text: 'Abandoned', colorClass: 'orange' };
      default:
        return { text: 'N/A', colorClass: 'grey' };
    }
  }

  static buildCloudinaryImageUrl(cloudName: string, uri: string, width: number, height: number, isScale: boolean): string {
    const imagePrefixPath = AppConstants.CLOUDINARY_URL + cloudName;
    let imageUrl;
    if (!isScale) {
      imageUrl = `${imagePrefixPath}/image/upload/${uri}`;
    } else {
      imageUrl = `${imagePrefixPath}/image/upload/c_fill,h_${height},w_${width}/${uri}`;
    }
    return imageUrl;
  }

  fakePostCall(expectedResult): Observable<any> {
    let data = {
      title: 'foo',
      body: 'bar',
      userId: 1,
    };
    return this.http.post(this._fakeUrl, data).pipe(
      tap(data => console.log('fake post api call')),
      map(data => {
        return expectedResult;
      }),
    );
  }

  fakeGetCall(expectedResult): Observable<any> {
    return this.http.get(this._fakeUrl).pipe(
      tap(data => console.log('fake get api call')),
      map(data => {
        return expectedResult;
      }),
    );
  }

  /*
   returns a route, with forward slash added to it
  */
  static getSlashedRoute(route: string) {
    return '/' + route;
  }

  isLoginRoute(): boolean {
    return _.isEqual(this._router.url, AppUtilsService.getSlashedRoute(AppConstants.LOGIN_ROUTE));
  }

  static VALID_STUDENT_ROUTES = [
    AppConstants.SL_CLASSES_ROUTE,
    AppConstants.SL_MYCOURSES_ROUTE,
    AppConstants.SL_MYREGISTRATION_ROUTE,
    AppConstants.SL_MY_DONATION_ROUTE,
    AppConstants.SL_REGISTRATIONFORM_ROUTE,
    AppConstants.SL_ASSESMENT_ROUTE,
    AppConstants.SL_MYFILES_ROUTE,
    AppConstants.SL_ONBOARDING_ROUTE,
    AppConstants.SL_SIGNUP_ROUTE,
    AppConstants.SL_STORE_ROUTE,
    AppConstants.SL_CART_CHECKOUT_ROUTE,
    AppConstants.SL_LOGOUT_ROUTE,
    AppConstants.SL_PROFILE_ROUTE,
  ];

  static VALID_ADMIN_ROUTES = [
    AppConstants.SL_DASHBOARD_ROUTE,
    AppConstants.SL_ONBOARDING_ROUTE,
    AppConstants.SL_SERVICES_ROUTE,
    AppConstants.SL_TENANT_PLAN_ROUTE,
    AppConstants.SL_MASTER_PLAN_ROUTE,
    AppConstants.SL_PLAN_ROUTE,
    AppConstants.SL_TENANT_ROUTE,
    AppConstants.SL_QUESTIONBANK_ROUTE,
    AppConstants.SL_ADD_QUESTIONBANK_ROUTE,
    AppConstants.SL_ASSESMENT_ROUTE,
    AppConstants.SL_OFFERS_ROUTE,
    AppConstants.SL_SCHEDULE_ROUTE,
    AppConstants.SL_CONTACTS_ROUTE,
    AppConstants.SL_RULES_ROUTE,
    AppConstants.SL_CLASS_RULE_ROUTE,
    AppConstants.SL_APP_SETTINGS_ROUTE,
    AppConstants.SL_USER_SETTINGS_ROUTE,
    AppConstants.SL_USER_DETAILS_ROUTE,
    AppConstants.SL_ACCOUNTS_SETTINGS_ROUTE,
    AppConstants.SL_DISPLAY_SETTINGS_ROUTE,
    AppConstants.SL_STUDENTAPP_SETTINGS_ROUTE,
    AppConstants.SL_ROLES_SETTINGS_ROUTE,
    AppConstants.SL_CUSTOMFIELD_SETTINGS_ROUTE,
    AppConstants.SL_PRODUCTS_ROUTE,
    AppConstants.SL_NEW_PRODUCTS_ROUTE,
    AppConstants.SL_STORE_ROUTE,
    AppConstants.SL_NEW_STORE_ROUTE,
    AppConstants.SL_EMAILCAMPAIGNS_ROUTE,
    AppConstants.SL_REPORTS_ROUTE,
    AppConstants.SL_DONATION_ROUTE,
    AppConstants.SL_CLASS_ROUTE,
    AppConstants.SL_SIGNIN_ROUTE,
    AppConstants.SL_INTEGRATIONS_ROUTE,
    AppConstants.SL_CUSTOMFORMS_ROUTE,
    AppConstants.SL_REGISTRATIONFORM_ROUTE,
    AppConstants.SL_WIDGET_ROUTE,
    AppConstants.SL_FACILITY_ROUTE,
    AppConstants.SL_FILES_ROUTE,
    AppConstants.SL_INVOICES_ROUTE,
    AppConstants.SL_PRODUCT_BUNDLE_ROUTE,
  ];

  public static isValidPublicRoute(route: string): boolean {
    let isValid = false;
    if (
      route.startsWith(AppConstants.SL_CALENDAR_ROUTE) ||
      route.startsWith(AppConstants.SL_COURSES_ROUTE) ||
      route.startsWith(AppConstants.SL_TPLAN_ROUTE) ||
      route.startsWith(AppConstants.SL_PDONATIONS_ROUTE) ||
      route.startsWith(AppConstants.SL_DONATE_ROUTE) ||
      route.startsWith(AppConstants.SL_CHECKOUT_ROUTE) ||
      route.startsWith(AppConstants.SL_CHECKOUT_REGISTER_FORM) ||
      route.startsWith(AppConstants.SL_PREVIEW_REGISTER_FORM) ||
      route.startsWith(AppConstants.SL_EVENTS_ROUTE) ||
      route.startsWith(AppConstants.SL_PUBLIC_REGISTRATION_FORM) ||
      route.startsWith(AppConstants.SL_STUDENT_INVOICE_ROUTE) ||
      route.startsWith(AppConstants.SL_MERCHANDISE_ROUTE) ||
      route.startsWith(AppConstants.SL_FORGOT_PASSWORD_ROUTE) ||
      route.startsWith(AppConstants.SL_LOGIN_ROUTE) ||
      route.startsWith(AppConstants.SL_REGISTER_ROUTE) ||
      route.startsWith(AppConstants.SL_PREVIEW_ROUTE) ||
      route.startsWith(AppConstants.SL_MEDIA_GALLERY_ROUTE)
    ) {
      isValid = true;
    }
    return isValid;
  }

  public static isValidAdminRoute(route: string): boolean {
    return (
      route.startsWith(AppConstants.SL_CONTENT_ROUTE) ||
      route.startsWith(AppConstants.SL_SERVICES_ROUTE) ||
      route.startsWith(AppConstants.SL_PRODUCT_BUNDLE_ROUTE) ||
      route.startsWith(AppConstants.SL_TENANT_PLAN_ROUTE) ||
      route.startsWith(AppConstants.SL_MASTER_PLAN_ROUTE) ||
      route.startsWith(AppConstants.SL_TENANT_ROUTE) ||
      route.startsWith(AppConstants.SL_PRODUCTS_ROUTE) ||
      route.startsWith(AppConstants.SL_QUESTIONBANK_ROUTE) ||
      route.startsWith(AppConstants.SL_ASSESMENT_ROUTE) ||
      route.startsWith(AppConstants.SL_OFFERS_ROUTE) ||
      route.startsWith(AppConstants.SL_CONTACTS_ROUTE) ||
      route.startsWith(AppConstants.SL_SCHEDULE_ROUTE) ||
      route.startsWith(AppConstants.SL_EMAILCAMPAIGNS_ROUTE) ||
      route.startsWith(AppConstants.SL_RULES_ROUTE) ||
      route.startsWith(AppConstants.SL_CLASS_RULE_ROUTE) ||
      route.startsWith(AppConstants.SL_USER_DETAILS_ROUTE) ||
      route.startsWith(AppConstants.SL_REPORTS_ROUTE) ||
      route.startsWith(AppConstants.SL_DONATION_ROUTE) ||
      // route.startsWith(AppConstants.SL_COURSES_ROUTE) ||
      route.startsWith(AppConstants.SL_CART_CHECKOUT_ROUTE) ||
      route.startsWith(AppConstants.SL_STORE_ROUTE) ||
      route.startsWith(AppConstants.SL_SIGNIN_ROUTE) ||
      route.startsWith(AppConstants.SL_INTEGRATIONS_ROUTE) ||
      route.startsWith(AppConstants.SL_CUSTOMFORMS_ROUTE) ||
      route.startsWith(AppConstants.SL_REGISTRATIONFORM_ROUTE) ||
      route.startsWith(AppConstants.SL_APP_SETTINGS_ROUTE) ||
      route.startsWith(AppConstants.SL_FACILITY_ROUTE) ||
      route.startsWith(AppConstants.SL_FILES_ROUTE) ||
      route.startsWith(AppConstants.SL_INVOICES_ROUTE) ||
      route.startsWith(AppConstants.SL_GET_STARTED) ||
      route.startsWith(AppConstants.SL_WEBSITE) ||
      route.startsWith(AppConstants.SL_TESTS_ROUTE) ||
      route.startsWith(AppConstants.SL_OAUTH) ||
      // route.startsWith(AppConstants.SL_EVENTS_ROUTE) ||
      // route.startsWith(AppConstants.SL_COURSES_ROUTE) ||
      // AppUtilsService.isValidPublicRoute(route) ||
      AppUtilsService.VALID_ADMIN_ROUTES.includes(route)
    );
  }

  public static isValidStudentRoute(route: string): boolean {
    return (
      route.startsWith(AppConstants.SL_SERVICES_ROUTE) ||
      route.startsWith(AppConstants.SL_TENANT_PLAN_ROUTE) ||
      route.startsWith(AppConstants.SL_PLAN_ROUTE) ||
      route.startsWith(AppConstants.SL_TENANT_ROUTE) ||
      route.startsWith(AppConstants.SL_MYCOURSES_ROUTE) ||
      route.startsWith(AppConstants.SL_STUDENTASSESSMENTS_ROUTE) ||
      route.startsWith(AppConstants.SL_MYREGISTRATION_ROUTE) ||
      route.startsWith(AppConstants.SL_MYASSESMENT_ROUTE) ||
      route.startsWith(AppConstants.SL_MY_DONATION_ROUTE) ||
      route.startsWith(AppConstants.SL_MYFILES_ROUTE) ||
      route.startsWith(AppConstants.SL_INVOICES_ROUTE) ||
      route.startsWith(AppConstants.SL_LOGOUT_ROUTE) ||
      route.startsWith(AppConstants.SL_PROFILE_ROUTE) ||
      AppUtilsService.VALID_STUDENT_ROUTES.includes(route)
    );
  }

  static extractTags(inputArray: string[] = []): string[] {
    const tagPattern = /^\{\{(.+?)\}\}$/;
    return inputArray
      .filter(item => tagPattern.test(item))
      .map(item => {
        const match = item.match(tagPattern);
        return match ? match[1] : '';
      });
  }

  static getValidStudentRoute(route: string): string {
    return AppUtilsService.isValidStudentRoute(route) ? route : AppConstants.SL_CLASSES_ROUTE;
  }

  static getValidAdminRoute(route: string): string {
    return AppUtilsService.isValidAdminRoute(route) ? route : AppConstants.SL_DASHBOARD_ROUTE;
  }

  static getValidPublicRoute(route: string): string {
    return AppUtilsService.isValidPublicRoute(route) ? route : AppConstants.SL_NO_ROUTE;
  }

  static getValidCoursePublicRoute(route: string): string {
    return AppUtilsService.isValidPublicRoute(route) ? route : AppConstants.SL_COURSES_ROUTE;
  }

  static currentMonth(): string {
    var monthAndYear = moment().startOf('month').format('MMM') + ' & ' + moment().year();
    return monthAndYear.toString();
  }

  static previousMonth(): string {
    var year = moment().format('MMM') == 'Jan' ? moment().subtract(1, 'year').year() : moment().year();
    var monthandYear = moment().subtract(1, 'month').startOf('month').format('MMM') + ' & ' + year;
    return monthandYear;
  }

  static getFormatedHr(startOrEndHour): string {
    var finalStartStr = '';
    var endArr = startOrEndHour.split(':');
    var edHrFirst = endArr[0];
    var edHrSec = endArr[1];
    if (edHrFirst.startsWith('0')) {
      edHrFirst = edHrFirst.slice(1);
    }
    if (edHrSec.startsWith('00')) {
      var amPmTime = edHrSec.slice(2);
      finalStartStr = edHrFirst + ' ' + amPmTime;
    } else {
      finalStartStr = edHrFirst + ':' + edHrSec;
    }
    return finalStartStr;
  }

  static graphLabel(): Array<string> {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  }

  static graphLabelResult(): Array<string> {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }
  static toSEOUrl(title, guId): any {
    // title is String(Event Name)
    var seoUrl = title.toLowerCase() + ' ' + guId;
    return seoUrl
      .toString() // Convert to string
      .replace(/[^\x00-\x7F]/g, '') // Change diacritics
      .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
      .replace(/\s+/g, '-') // Change whitespace to dashes
      .replace(/&/g, '-and-') // Replace ampersand
      .replace(/-+/g, '-') // Remove duplicate dashes
      .replace(/^-*/, '') // Remove starting dashes
      .replace(/-*$/, ''); // Remove trailing dashes
  }

  static currentYear(): string {
    return moment().format('YYYY');
  }

  static getMonthYear(params): string {
    if (params.period === AppConstants.MONTH) {
      let monthAndYear = params.value.split('&');
      return params.period + '=' + monthAndYear[0].trim() + '&year=' + monthAndYear[1].trim();
    } else {
      return params.period + '=' + params.value;
    }
  }

  static getMonth() {
    const d = new Date();
    let month = d.getMonth();
    return month;
  }

  static createUTCMoment(): any {
    var mom = moment();
    var utcMoment = moment().tz('UTC');
    utcMoment.year(mom.year());
    utcMoment.month(mom.month());
    utcMoment.date(mom.date());
    utcMoment.hour(mom.hour());
    utcMoment.minute(mom.minute());
    return utcMoment;
  }

  static createUTCMomentEpoch(epoc): any {
    var m = moment.unix(epoc).tz('UTC');
    return m;
  }

  static getStartAndEndTimes(aMoment: any, periodType): any {
    let classStartTime;
    let classEndTime;
    if (periodType == 'month') {
      let startTimeForFirstWeekOfMonth = aMoment.clone().startOf('month').startOf('week');
      let endTimeForLastWeekOfMonth = aMoment.clone().endOf('month');
      classStartTime = startTimeForFirstWeekOfMonth.unix();
      classEndTime = endTimeForLastWeekOfMonth.unix();
    } else {
      classStartTime = aMoment.clone().startOf('week').unix();
    }

    return { startTime: classStartTime, endTime: classEndTime };
  }

  static openMeetingUrl(clanMeetingDomain: any, jwtToken: any, url: any) {
    var isWajoobaProvider = url.includes(clanMeetingDomain);
    if (isWajoobaProvider) {
      url = url + '?jwt=' + jwtToken;
    }
    window.open(url);
  }
  static getCloudinaryPath(cloudName: any, img_options: any) {
    return AppConstants.CLOUDINARY_URL + cloudName + AppConstants.IMG_SUFFIX_PATH + img_options + '/';
  }

  static getLastName(name): any {
    var lastName = '';
    if (name.length > 1) {
      for (var i = 1; i < name.length; i++) {
        lastName = lastName + name[i];
        if (i < name.length - 1) {
          lastName += ' ';
        }
      }
    }
    return lastName;
  }
  static getGuidFromSeoUrl(title): any {
    return title.split('-').splice(-1);
  }
  static getEventDate(datestr): any {
    let startDateEvent = new Date(datestr);
    return startDateEvent.getFullYear() + ('0' + (startDateEvent.getMonth() + 1)).slice(-2) + ('0' + startDateEvent.getDate()).slice(-2);
  }
  static getEventTime(startTimeStr): any {
    let starthour = startTimeStr.split(' ')[1].substring(0, 2);
    if (startTimeStr.substring(startTimeStr.length - 2) == AppConstants.TIMEFORMAT.PM) {
      starthour = 12 + parseInt(starthour);
    }
    return starthour + '' + startTimeStr.split(' ')[1].substring(3) + '00';
  }

  public getLegalLink(linkType: string = 'privacypolicy'): any {
    let pages = this.appLoadService.tenantConfig.web;
    let outputLink = pages.find(link => {
      return link.name === linkType;
    });
    if(outputLink && !outputLink?.isExternalLink){
      outputLink.url = outputLink.name
    }
    return outputLink;
  }

  static formatDayStr(epoch): any {
    return moment(this.convertToDate(epoch).getTime()).format('dddd');
  }

  static convertToDate(epoch): any {
    var cm = this.createUTCMomentEpoch(epoch).toDate();
    var y1 = cm.getUTCFullYear();
    var m1 = cm.getUTCMonth();
    var dd1 = cm.getUTCDate();
    var h1 = cm.getUTCHours();
    var min = cm.getUTCMinutes();
    var d1 = new Date(y1, m1, dd1, h1, min);
    return d1;
  }

  static getCountryDialCode(timezoneValue: any) {
    if (timezoneValue == 'America/Los_Angeles' || timezoneValue == 'America/Denver' || timezoneValue == 'America/Chicago' || timezoneValue == 'America/New_York') {
      return '+1';
    } else if (timezoneValue == 'Asia/Kolkata') {
      return '+91';
    } else {
      return '+1';
    }
  }

  static getCountryDialCodeByCode(currency: any = 'usd') {
    switch (currency.toLowerCase()) {
      case 'usd':
      case 'cad':
        return '+1';
      case 'inr':
        return '+91';
      case 'eur': // Euro is used in many countries, this is just an example for France
        return '+33';
      case 'gbp': // UK
        return '+44';
      case 'aud': // Australia
        return '+61';
      case 'aed': // UAE Dirham
        return '+971';
      case 'jpy': // Japan Yen
        return '+81';
      case 'cny': // China Yuan Renminbi
        return '+86';
      case 'sgd': // Singapore
        return '+65';
      case 'idr': // Indonesia
        return '+62';
      case 'myr': // Malaysia
        return '+60';
      case 'php': // Philippines
        return '+63';
      default:
        return '+1';
    }
  }

  static isMasterTennant(tenant: any) {
    return tenant.orgId == tenant.masterOrgId ? true : false;
  }

  static getSuffix(billingDay) {
    const day = Number(billingDay);
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  static generateTag(name) {
    return name
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '') // Remove spaces
      .replace(/[^a-z0-9]/g, ''); // Remove all non-alphanumeric characters
  }

  getSantaizedResourceUrlForYoutube(url) {
    try {
      var embeddedLink = '';
      const newUrl = new URL(url);
      if (newUrl.hostname === 'youtube.com' || newUrl.hostname === 'www.youtube.com') {
        embeddedLink = url.replace('watch?v=', 'embed/');
      } else if (newUrl.hostname === 'vimeo.com' || newUrl.hostname === 'www.vimeo.com') {
        embeddedLink = url.replace('https://vimeo.com', 'https://player.vimeo.com/video');
      } else {
        embeddedLink = url;
      }
    } catch (error) {
      embeddedLink = '';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(embeddedLink);
  }

  generateRandomName(text, length = 0) {
    const epoch = new Date().getTime();
    const uniqueId = `${text}-${epoch}`;
    return uniqueId;
  }

  stripHtmlTags(htmlString) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
  }
}
