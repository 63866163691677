<div class="demo-sidebar">
  <h3 style="padding: 0; margin: 0; padding-left: 16px; font-weight: 600" matSubheader>{{ title }}</h3>
  <mat-list>
    <div class="course" *ngFor="let sidebar of sidebarData">
      <mat-list-item
        (click)="showDetailView(sidebar)"
        style="cursor: pointer"
        [ngClass]="{ accent: sidebar.guId == guId }"
        class=""
      >
        <img
          *ngIf="sidebarType == 'contact'"
          class="avatar"
          [alt]="sidebar.name"
          [src]="sidebar.picture || 'https://passets.wajooba.com/img/RecentStudentIcon.svg'"
        />
        <span>{{ sidebar.name | titlecase }}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </mat-list>
</div>
