import { User } from './../models/user';
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "environments/environment";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class UserSettingServiceService implements Resolve<any> {
  users: User[] = [];
  public user: Observable<any>;
  public role: Observable<any>;
  
  onUsersChanged: BehaviorSubject<any>;
  private _configUsers: BehaviorSubject<any>;
  private _configUser: BehaviorSubject<any>;
  private _configRole: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onUsersChanged = new BehaviorSubject({});
    this._configUsers = new BehaviorSubject(this.users);
    this._configUser = new BehaviorSubject(this.user);
    this._configRole = new BehaviorSubject(this.role);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getUsers()]).then(() => {
        resolve();
      }, reject);
    });
  }

  setUsers(users: any) {
    this.users = users;
    this._configUsers.next(users);
  }

  setUser(user: any, isNotify: boolean) {
    this.user = user;
    if(isNotify)
      this._configUser.next(user);
  }

  setUserRoles(role: any, isNotify: boolean) {
    this.role = role;
    if(isNotify)
      this._configRole.next(role);
  }

  /**
   * Get Users
   *
   * @returns {Promise<any>}
   */
  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiBaseUrl}/rest/contact?userType=staff`)
        .subscribe((response: any) => {
          this.users = response;
          this.onUsersChanged.next(this.users);
          resolve(response);
        }, reject);
    });
  }

  getUsersObj() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/contact?userType=staff`).pipe(
      map((result: User[]) => {
        result.map(x => x.guId = x.id);
        this.setUsers(result);
        return result;
      }));
  }

  getUserDetail(guid, isNotify) {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/contact/${guid}`).pipe(
      map((result: any) => {
        this.setUser(result, isNotify);
        return result;
      }));
  }

  getAllNotifications() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/notification/`).pipe(
      map((result: any) => {
        return result;
      }));
  }

  getUserRoles(guid, isNotify) {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/contact/${guid}/roles`).pipe(
      map((result: any) => {
        this.setUserRoles(result, isNotify);
        return result;
      }));
  }

  getStaffUsers() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/contact?userType=staff`);
  }

  get getUser() {
    return this._configUser.asObservable();
  }

  get getUserRole() {
    return this._configRole.asObservable();
  }

  get getAllUsers() {
    return this._configUsers.asObservable();
  }

  updateUser(form:any, guId) {
    form.isUpdateRole = true;
    return this._httpClient.put(`${environment.apiBaseUrl}/rest/contact/${guId}`, form);
  }

  updateNotification(form:any, guId) {
    const payload = [{
      contactGuId: guId,
      isNotifyContacts: form.isNotifyContacts,
      isNotifyPrograms: form.isNotifyPrograms,
      isNotifySales: form.isNotifySales
    }];
    return this._httpClient.put(`${environment.apiBaseUrl}/rest/notification/`, payload);
  }
}
