import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FranchiseComponent } from './franchise.component';
import { FranchiseListComponent } from './franchise-list/franchise-list.component';
import { FranchiseAddEditComponent } from './franchise-add-edit/franchise-add-edit.component';
import { AdminGuard } from 'app/admin.guard';
import { FranchiseViewComponent } from './franchise-view/franchise-view.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from 'app/layout/layout.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogModule } from 'app/main/shared/components/confirmation-dialog/confirmation-dialog.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoneNumberModule } from 'app/main/shared/components/phone-number/phone-number.module';
import { ClipboardModule } from "@angular/cdk/clipboard";

const routes = [
  {
    path: "franchise",
    component: FranchiseListComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "franchise/new",
    component: FranchiseAddEditComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "franchise/:id",
    component: FranchiseViewComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "franchise/edit/:id",
    component: FranchiseAddEditComponent,
    canActivate: [AdminGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatGridListModule,
    LayoutModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    NgxSkeletonLoaderModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FuseConfirmDialogModule,
    ConfirmationDialogModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    PhoneNumberModule,
    ClipboardModule
  ],
  declarations: [FranchiseComponent, FranchiseListComponent, FranchiseAddEditComponent, FranchiseViewComponent]
})
export class FranchiseModule { }
