<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="users" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        supervisor_account
      </mat-icon>
      <span class="logo-text h1">
        Users Setting
      </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- / HEADER -->

  <!-- CONTENT CARD -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
    style="background-color: #f5f5f5"
  >
    <div class="mb-24" style="width: 100%">
      <div
        fxLayout="column"
        fxLayoutAlign="center stretch"
        class="users-table mat-card"
        style="width: 100%; padding: 10px;border-radius: 4px;"
      >
        <div class="h1">User</div>
        <div class="h4 mb-16" fxFlex="100">
          Add users to your accounts with appropriate permissions
        </div>
        <!-- <div
          fxLayoutAlign="center stretch"
          fxLayout="row"
          fxFlex="100"
          style="justify-content: flex-start !important"
        >          -->
        <div
          class="search ng-tns-c281-46"
          fxFlex=""
          fxLayout="row"
          fxLayoutAlign="start center"
          style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center; flex: 1 1 0%;"
        >
          <app-search-contact
            style="
              text-align: center;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 8px;
            "
            fxFlex="30"
            (outputToParent)="getOutputVal($event)"
          ></app-search-contact>
          <div
            class="user float-right"
            fxFlex="10"
            fxFlex.gt-xs="70"
            style="justify-content: flex-end !important; width: 100%"
            [@animate]="{ value: '*', params: { y: '100%' } }"
            style="margin-left: auto;"
          >
            <button class="mr-4" mat-mini-fab (click)="addContact()" matTooltip="Add Contact " aria-label="Add Contact">
              <mat-icon>person_add</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlex="5"></div>
        <table
          mat-table
          fxLayout="column"
          fxLayoutAlign="center"
          style="width: 100%"
          fxFlex="1 1 0"
          #table
          [dataSource]="dataSource"
          matSort
          [@animateStagger]="{ value: '50' }"
          fusePerfectScrollbar
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="100" fxFlex.gt-xs="20">Name</mat-header-cell>
            <mat-cell *matCellDef="let user" fxFlex="100" fxFlex.gt-xs="20">
              <p class="text-truncate">{{ user.fullName }}</p>
            </mat-cell>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-xs fxFlex="50" fxFlex.gt-sm="40"
              >Email</mat-header-cell
            >
            <mat-cell class="email" *matCellDef="let user" fxHide fxShow.gt-xs fxFlex="50" fxFlex.gt-sm="40">
              <p class="category text-truncate">
                {{ user.email }}
              </p>
              <span class="copy-icon">
                <button
                  mat-icon-button
                  matTooltip="copy clipboard"
                  aria-label="copy clipboard"
                  [cdkCopyToClipboard]="user.email"
                >
                  <mat-icon style=" font-size: 16px !important;">file_copy</mat-icon>
                </button>
              </span>
            </mat-cell>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="10">Role</mat-header-cell>
            <mat-cell *matCellDef="let user" fxHide fxShow.gt-xs fxFlex="10">
              <p class="price text-truncate">
                {{ user.roleName | titlecase }}
              </p>
            </mat-cell>
          </ng-container>

          <!-- Teacher Column -->
          <ng-container matColumnDef="teacher">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="10">Teacher</mat-header-cell>
            <mat-cell *matCellDef="let user" fxHide fxShow.gt-xs fxFlex="10">
              <mat-icon *ngIf="user.isTeacher" class="active-icon green-600 s-16">check</mat-icon>
              <mat-icon *ngIf="!user.isTeacher" class="active-icon red-500 s-16">close</mat-icon>
            </mat-cell>
          </ng-container>

          <!-- Last Signed In Column -->
          <ng-container matColumnDef="lastSignedIn">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-md fxFlex="20"
              >Last Login Time</mat-header-cell
            >
            <mat-cell *matCellDef="let user" fxHide fxShow.gt-sm fxFlex="20">
              <p class="category text-truncate" *ngIf="user.lastLoginTime">
                {{ user.lastLoginTime * 1000 | date }}
              </p>
              <p class="category text-truncate" *ngIf="!user.lastLoginTime">-</p>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row
            *matRowDef="let user; columns: displayedColumns"
            class="user"
            matRipple
            [routerLink]="['/settings/users', user.id]"
          >
          </mat-row>
        </table>
        <!-- <div *ngIf="isLoading" style="width: 100%; padding: 15px">
          <ngx-skeleton-loader
            count="1"
            appearance="line"
            [theme]="{
              background: '#F5F5F5',
              height: '30px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            count="3"
            appearance="line"
            [theme]="{
              background: '#F5F5F5',
              height: '18px'
            }"
          ></ngx-skeleton-loader>
        </div> -->
        <mat-paginator
          #paginator
          [length]="totalData"
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <!-- / CONTENT CARD -->
</div>
