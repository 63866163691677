<!-- <div *ngIf="isLoading" style="width: 100%;padding: 15px;">
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      background: '#F5F5F5',
      height: '30px'
  }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="3" appearance="line" [theme]="{
      background: '#F5F5F5',
      height: '18px'
  }"></ngx-skeleton-loader>
</div> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div *ngIf="!isLoading" id="user" class="fullwidth page-layout simple inner-sidebar inner-scroll right-sidebar">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">
    <div class="new-header">
      <div style="float: left; display: flex; align-items: center; justify-content: center">
        <img
          *ngIf="user"
          class="avatar"
          [alt]="user.name"
          [src]="user.picture || 'https://passets.wajooba.com/img/RecentStudentIcon.svg'"
        />

        <span class="logo-text h1"> {{ user.name }} </span>
        <div class="subtitle secondary-text" style="margin-left: 10px">{{ user.email }}</div>
      </div>
      <div style="float: right">
        <button mat-icon-button [routerLink]="['/settings/users']" matTooltip="Back " aria-label="Back">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>

    <!-- HEADER -->

    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content">
      <!-- / SIDEBAR -->
      <!-- CONTENT -->
      <div *ngIf="user" class="p-24" fxLayout="row wrap" fxLayoutAlign="center stretch" fxFlex>
        <div class="mb-24" style="width: 100%">
          <form
            class="mat-card mat-elevation-z4 p-24 mr-24"
            fxLayout="column"
            fxLayoutAlign="center"
            fxFlex="90"
            name="form"
            [formGroup]="form"
            (ngSubmit)="updateUser()"
          >
            <div class="h1">User Details</div>
            <div class="h4">Your account with appropriate permissions and notifications</div>
            <mat-divider></mat-divider>

            <div *ngFor="let group of slideToggleGroup" fxLayout="column" fxLayoutAlign="start">
              <div class="h2 mt-24" fxLayout="row" fxFlex="100">{{ group.groupName }}</div>
              <div class="courses" fxLayout="row wrap" fxLayoutAlign="start" fxFlex="100" style="width: 100%">
                <app-app-switch-card
                  [@animateStagger]="{ value: '50' }"
                  *ngFor="let slidToggle of group.list"
                  [title]="slidToggle.title"
                  [value]="slidToggle.value"
                  (getChildValue)="bindSlideToggleValue($event, slidToggle.formFieldName)"
                  [description]="slidToggle.description"
                ></app-app-switch-card>
              </div>
            </div>

            <div class="h2">Role</div>
            <div fxLayout="row" fxLayoutAlign="start">
              <mat-button-toggle-group dir="ltr" formControlName="roles">
                <mat-button-toggle value="ROLE_ADMIN">Administrator</mat-button-toggle>
                <mat-button-toggle value="ROLE_STAFF">Staff</mat-button-toggle>
                <mat-button-toggle value="ROLE_FRONTDESK">FrontDesk</mat-button-toggle>
                <mat-button-toggle value="ROLE_STUDENT">Student</mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end">
              <button mat-stroked-button [routerLink]="['/settings/users']" class="mr-4" color="accent">Cancel</button>
              <button type="submit" mat-raised-button color="accent">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <!-- / CONTENT -->
      <!-- SIDEBAR -->
      <fuse-sidebar class="sidebar" name="simple-right-sidebar-4" position="left" lockedOpen="gt-md" style="min-width: 260px;">
        <!-- SIDEBAR CONTENT -->
        <div *ngIf="guId" class="content p-24" fusePerfectScrollbar style="max-height: 700px">
          <fuse-app-sidebar [sidebarList]="users" [title]="title" [type]="sidebarType" [selectedGuId]="guId">
          </fuse-app-sidebar>
        </div>
        <!-- / SIDEBAR CONTENT -->
      </fuse-sidebar>
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>
