import { User } from "./../../models/user";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

import { fuseAnimations } from "@fuse/animations";
import { FuseUtils } from "@fuse/utils";

import { takeUntil } from "rxjs/operators";
import { UserSettingServiceService } from "../../services/user-setting-service.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { AddContactDlgComponent } from "app/main/shared/components/add-contact-dlg/add-contact-dlg.component";
import { FormGroup } from "@angular/forms";
import { ContactsService } from "app/main/admin/contacts/contacts.service";
import { SubSink } from "subsink";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent implements OnInit, OnDestroy {
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  users: User[];
  isLoading: boolean = false;
  dataSource: FilesDataSource | null;
  displayedColumns = ["name", "email", "role", "teacher", "lastSignedIn"];
  pageEvent: PageEvent;
  totalData: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild("filter", { static: true }) filter: ElementRef;

  dialogRef: any;

  // Private
  subs = new SubSink();

  constructor(
    private _userSettingsService: UserSettingServiceService,
    public _matDialog: MatDialog,
    public toastr: ToastrService,
    public contactsService: ContactsService,
    private router: Router,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService
  ) {
    // Set the private defaults
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.isLoading = true;
    this.SpinnerService.show();
    this.getUserList();
  }

  addContact() {
    this.showAddDialog();
  }

  getUserList(){
    this._userSettingsService.getUsers().then((users) => {
      this.isLoading = false;      
      this.SpinnerService.hide();
      this.dataSource = new FilesDataSource(
        this._userSettingsService,
        this.paginator,
        this.sort
      );
      this.totalData = this.dataSource?.filteredData?.length ?? users.length;
    });
  }

  showAddDialog(): void {
    this.dialogRef = this._matDialog.open(AddContactDlgComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      height: '590px',
      width: '590px',
      data: {
        action: "new",
      },
    });

    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this.contactsService
            .addClient(response.getRawValue())
            .subscribe((result: any) => {
              if(result.error){
                this.toastr.error(result.error.error.message); 
              }else{ 
                this.toastr.success("Added contact successfully");
                this.router.navigate(["/settings/users/" + result.id], { relativeTo: this.route });
              } 
            },
            errorResp => {
              this.toastr.error(errorResp.error.errors.message);
            })
        );
      })
    );
  }

  getOutputVal(searchContact: any) {
    if (searchContact.id == "addnew") {
      this.showAddDialog();
    } else {
      this.router.navigate([searchContact.id], { relativeTo: this.route });
    }
  }
}

export class FilesDataSource extends DataSource<any> {
  private _filterChange = new BehaviorSubject("");
  private _filteredDataChange = new BehaviorSubject("");

  /**
   * Constructor
   *
   * @param {appSettingsService} _userSettingsService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private _userSettingsService: UserSettingServiceService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();
    this.filteredData = this._userSettingsService.users;
    this.filteredData.map(
      (x) => (x.roleName = x.roles.substr(5, x.roles.length - 1))
    );
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._userSettingsService.onUsersChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange,
    ];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data = this._userSettingsService.users.slice();

        data = this.filterData(data);

        this.filteredData = [...data];

        data = this.sortData(data);

        // Grab the page's slice of data.
        const startIndex =
          this._matPaginator.pageIndex * this._matPaginator.pageSize;
        return data.splice(startIndex, this._matPaginator.pageSize);
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === "") {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = "";
      let propertyB: number | string = "";

      switch (this._matSort.active) {
        case "id":
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case "name":
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case "email":
          [propertyA, propertyB] = [a.email, b.email];
          break;
        case "role":
          [propertyA, propertyB] = [a.role, b.role];
          break;
        case "isTeacher":
          [propertyA, propertyB] = [a.isTeacher, b.isTeacher];
          break;
        case "lastLoginTime":
          [propertyA, propertyB] = [a.lastLoginTime, b.lastLoginTime];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (
        (valueA < valueB ? -1 : 1) *
        (this._matSort.direction === "asc" ? 1 : -1)
      );
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
