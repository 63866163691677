import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CookieService } from "ngx-cookie-service";
import { MatBadgeModule } from "@angular/material/badge";

import { FuseShortcutsComponent } from "./shortcuts.component";
import { NoticeDlgModule } from "app/main/shared/components/notice-dlg/notice-dlg.module";
import { FeedbackDlgModule } from "app/main/shared/components/feedback-dlg/feedback-dlg.module";

@NgModule({
  declarations: [FuseShortcutsComponent],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatTooltipModule,
    MatBadgeModule,
    NoticeDlgModule,
    FeedbackDlgModule
  ],
  exports: [FuseShortcutsComponent],
  providers: [CookieService]
})
export class FuseShortcutsModule {}
