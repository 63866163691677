export class Contact {
  name: string;
  email: string;
  phone: string;
  dateCreated: string;
  guId: string;
  picture: string;
  hasSignedWaiverForm: boolean = true;
  subscribeToMailingList: boolean = true;
  firstName: string;
  lastName: string;
  constructor(contact) {
    {
      this.name = contact.name || "";
      this.email = contact.email || "";
      this.phone = contact.phone || "";
      this.hasSignedWaiverForm = contact.hasSignedWaiverForm || true;
      this.subscribeToMailingList = contact.subscribeToMailingList || true;
    }
  }
}
