import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TenantService } from "app/core/services/tenant.service";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";

@Component({
  selector: "select-tenant-dlg",
  templateUrl: "./select-tenant-dlg.component.html",
  styleUrls: ["./select-tenant-dlg.component.scss"]
})
export class SelectTenantDlgComponent implements OnInit {
  selectedTenant: string;
  /**
   * Constructor
   *
   * @param {MatDialogRef<AddContactDlgComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
      public matDialogRef: MatDialogRef<SelectTenantDlgComponent>,
      @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
  }

  ngOnInit() {
  }

  submit() {
      this.matDialogRef.close(this.selectedTenant);
  }
  setSelectedTenant(id) {
      this.selectedTenant = id;
  }
}
