import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
// import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FuseConfirmDialogModule, FuseSidebarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { AppSidebarModule } from "app/main/shared/components/app-sidebar/app-sidebar.module";
import { AppSwitchCardModule } from "app/main/shared/components/app-switch-card/app-switch-card.module";
import { ConfirmationDialogModule } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.module";
import { FileuploadModule } from "app/main/shared/components/file-upload/file-upload.module";
import { PhoneNumberModule } from "app/main/shared/components/phone-number/phone-number.module";
import { VideoUploadModule } from "app/main/shared/components/video-upload/video-upload.module";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxSpinnerModule } from "ngx-spinner";
import { WebsiteSettingComponent } from "./website-setting.component";
import { ExternalWebsiteComponent } from "./external-website/external-website.component";
import { SeoSettingComponent } from "./seo-setting/seo-setting.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import {WebsiteBuilderSidebarModule} from "./website-builder-sidebar/website-builder-sidebar.module";
import {AppTemplateListModule} from "../../../shared/components/app-template-list/app-template-list.module";
import {MatStepperModule} from "@angular/material/stepper";
import {EmailEditorModule} from "angular-email-editor";
import {
    InternalWebsiteTemplateListModule
} from "../../../shared/components/internal-website-template-list/internal-website-template-list.module";
import {
    ExternalWebsiteAddTemplateDlgComponent
} from "../../../public/internal-website-add-template-dlg/external-website-add-template-dlg.component";
import {
    ExternalWebsiteSeosettingTemplateDlgComponent
} from "./external-website-seosetting-template-dlg/external-website-seosetting-template-dlg.component";
import {
    InternalWebsitePublishUnpublishDlgComponent
} from "../../../public/internal-website-publish-unpublish-dlg/internal-website-publish-unpublish-dlg.component";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [WebsiteSettingComponent, SeoSettingComponent, ExternalWebsiteAddTemplateDlgComponent, ExternalWebsiteSeosettingTemplateDlgComponent, InternalWebsitePublishUnpublishDlgComponent],
    imports: [
        CommonModule,
        RouterModule,
        // TranslateModule,
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatGridListModule,
        LayoutModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        FileuploadModule,
        PhoneNumberModule,
        VideoUploadModule,
        MatSnackBarModule,
        NgxSkeletonLoaderModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        ColorPickerModule,
        FuseSidebarModule,
        FuseConfirmDialogModule,
        AppSidebarModule,
        MatSlideToggleModule,
        AppSwitchCardModule,
        MatButtonToggleModule,
        NgxSpinnerModule,
        ConfirmationDialogModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AppSidebarModule,
        WebsiteBuilderSidebarModule,
        AppTemplateListModule,
        MatStepperModule,
        EmailEditorModule,
        InternalWebsiteTemplateListModule,
        QuillModule,
    ],
  exports: []
})
export class WebsiteSettingModule {}
