import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {from, Observable, Subject} from 'rxjs';
import {delay, filter, take, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {TenantUserService} from 'app/core/services/tenantuser.service';
import {TUser} from 'app/core/models/tenantuser';
import {AppLoadService} from 'app/core/services/appload.service';
import {TenantPing} from 'app/core/models/tenantPing';
import {AppConstants} from 'app/core/settings/appconstants';
import {AppUtilsService} from 'app/core/services/apputils.service';
import {TenantService} from 'app/core/services/tenant.service';
import {AppSettingsService} from 'app/main/admin/app-settings/services/app-settings.service';
import {SubSink} from 'subsink';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    cloudinaryCloudName: any;
    user: TUser;
    websiteLogo: any;
    _tenantConfig: any;
    _imagePrefixPath: string;
    subs = new SubSink();
    _tenantConfigData: TenantPing;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private route: ActivatedRoute,
        private _tenantUserService: TenantUserService,
        private _appLoadService: AppLoadService,
        private _tenantService: TenantService,
        private _settingsService: AppSettingsService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled.pipe(delay(500), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._fusePerfectScrollbar.update();
        });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1),
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const navigation = this._fuseNavigationService.getCurrentNavigation();
        const navKey = this._fuseNavigationService.getCurrentNavigationKey();
        const tenantConfig = this._appLoadService.tenantConfig;
        const org = tenantConfig.org;

        if (navigation && navigation.length > 0) {
            // const currentnav = navigation[0];
            // vertical navigation will not have a home element at all
            // if (currentnav.id == "home") {
            //   this._appLoadService.onTenantConfigChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((tenantConfig) => {
            //     let tenantObj = tenantConfig;
            //     if (tenantObj) {
            //       if (tenantObj.smallLogo) {
            //         let tempArr = tenantObj.smallLogo.split("c_fill");
            //         tenantObj.smallLogo = tempArr[0] + "c_fill,h_50,w_150" + tempArr[1];
            //       }
            //       this._tenantConfig = tenantObj;
            //     } else {
            //       this._tenantConfig = tenantConfig;
            //     }
            //     this.setUserDetail();
            //   });
            // } else {
            if (navKey !== AppConstants.PUBLIC_NAVIGATION) {
                this.subs.add(
                    this._settingsService.getAccountDetail().subscribe(
                        (res: any) => {
                            this.cloudinaryCloudName = res.cloudinaryCloudName;
                        },
                        (errResp) => {
                            console.log(errResp);

                        }
                    )
                );
                this.websiteLogo = AppUtilsService.buildCloudinaryImageUrl(tenantConfig.cloudName, org.favIcon, 20, 20, true);
                this.setUserDetail();

                // this.subs.add(
                //     this._settingsService.getPluginIntTenant().subscribe(
                //         (res: any) => {
                //             let tenantObj = res;
                //             setTimeout(() => {
                //                 this._imagePrefixPath = "https://res.cloudinary.com/" + this.cloudinaryCloudName;
                //                 this.websiteLogo = `${this._imagePrefixPath}/image/upload/c_fill/c_fill,h_50,w_150/${tenantObj.logo}`;
                //             }, 1000);
                //
                //
                //             if (tenantObj.logo) {
                //                 let tempArr = tenantObj.logo.split("c_fill");
                //                 tenantObj.smallLogo = tempArr[0] + "c_fill,h_50,w_150" + tempArr[1];
                //             }
                //             this._tenantConfig = tenantObj;
                //             this.setUserDetail();
                //         },
                //         (errResp) => {
                //         }
                //     )
                // );
            }
            // }
        }

        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.fuseConfig = config;
        });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        //Set the applicaiton layout and navigation when user changes
        this._tenantUserService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: TUser) => {
            this.user = user;
            this.setProfileImgPath();
        });
    }

    setUserDetail(): void {
        this.subs.add(
            this._tenantUserService.user.subscribe((user: TUser) => {
                this.user = user;
                this.setProfileImgPath();
            }),
        );
    }

    setProfileImgPath(): void {
        if (this.user.picture == null) {
            this.user.picture = 'https://lh3.googleusercontent.com/-i1q_aCDjTf4/AAAAAAAAAAI/AAAAAAAAAAA/MZA3-rTo01Y/photo.jpg';
        } else if (this.user.picture != null) {
            let cloudinaryPath = '';
            if (this._tenantConfig) {
                cloudinaryPath = AppUtilsService.getCloudinaryPath(this._tenantConfig.cloudinaryCloudName, AppConstants.IMG_OPTIONS_PROFILE);
            }

            this.user.picture = this.user.picture != null && (this.user.picture.startsWith('http')) ? this.user.picture : cloudinaryPath + this.user.picture;
        }
    }

    profile(): void {
        this._router.navigate(['/' + AppConstants.CONTACTS_ROUTE + '/' + this.user.guId + '/detail'], {
            relativeTo: this.route,
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}