import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup } from "@angular/forms";
import { SubSink } from "subsink";
import { WidgetService } from "./widget.service"
import { from, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConstants } from "app/core/settings/appconstants";

@Component({
  selector: "app-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
  animations: fuseAnimations
})
export class WidgetComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean = false;
  widgetsItems: any = [];
  totalRecords: number;
  pageIndex: number;
  select: {};
  start: number = 0;
  pageSize: number = 50;
  dataSource: any;
  pageEvent: PageEvent;

  displayedColumns: string[] = ["name", "type", "publishedDateStr", "dateUpdatedStr"];
  pageSizeOptions: any[] = [50, 100, 150];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dialogRef: any;
  searchInput: FormControl;
  searchTerm: any;
  isShowAll: boolean = true;
  subs = new SubSink();
  constructor(
    private widgetService: WidgetService,
    private router: Router,
    private route: ActivatedRoute) {
    this.select = {};
    this.searchInput = new FormControl("");
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getWidgets(null);
    this.searchFromTable();
  }

  getWidgets(event: PageEvent){
    this.subs.add(
      this.widgetService.getWidgets().subscribe(result => {
        if(result) {
          this.widgetsItems = result;
          this.totalRecords = this.widgetsItems.length;
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(this.widgetsItems);
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    );
  }
  addWidget() {}
  updateWiget(data){
    this.router.navigate(["/"+AppConstants.APP_SETTINGS_ROUTE+"/" + AppConstants.WIDGET+ "/" + data.guId], { relativeTo: this.route });
  }
  searchFromTable() {
    this.searchInput.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        const filteredData = this.widgetsItems.filter(d => d.name.toLowerCase().includes(searchText.toLowerCase()));
        this.dataSource = new MatTableDataSource(filteredData);
      });
  }
}
