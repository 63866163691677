<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
</ngx-spinner>
<!-- startshere -->
<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        view_module
      </mat-icon>
      <span class="logo-text h1"> PhonePe</span>
    </div>
    <div style="float: right;">
      <button mat-icon-button (click)="backToPaymentComponent()" matTooltip="Back" aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
<!-- / HEADER -->

  <div
  class="content p-24"
  fxLayout="row"
  fxFlex="100"
  fxLayoutAlign="center"
  fxFlex.gt-xs="50"
  fxFlex.gt-sm="33"
  >

    <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="form mat-elevation-z4" style="padding:20px;border-radius: 15px;">
        <!-- Merchant Id Field -->
        <div fxLayout="row" fxLayoutGap="25px" style="margin-bottom:20px;">
          <div fxFlex="40">
            <img src="https://res.cloudinary.com/wajooba/image/upload/v1693224283/master/download.svg" alt="PhonePe Logo">
          </div>
          <small fxFlexAlign="center">
            UPI - 0%<br>
            Wallet enablement Services - 0.84%<br>
            PhonePe Gift Card (eGV) - 0.84%
          </small>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Merchant Id</mat-label>
          <input matInput formControlName="merchantId">
          <mat-error *ngIf="form.controls.merchantId.errors?.required">
            Merchant Id is required.
          </mat-error>
        </mat-form-field>

        <!-- Salt Key Field -->
        <mat-form-field appearance="outline">
          <mat-label>Salt Key</mat-label>
          <input matInput formControlName="saltKey">
          <mat-error *ngIf="form.controls.saltKey.errors?.required">
            Salt Key is required.
          </mat-error>
        </mat-form-field>
        
        <!-- Salt Index Field -->
      <mat-form-field appearance="outline">
        <mat-label>Salt Index</mat-label>
        <input matInput formControlName="saltIndex">
          <mat-error *ngIf="form.controls.saltIndex.errors?.required">
            Salt Index is required.
          </mat-error>
        </mat-form-field>
        
        <!-- Buttons -->
        <div fxLayout="row" [fxLayoutAlign] = "tenantKeys ? 'space-between center' : 'end center'">
          <button *ngIf="tenantKeys?.isProviderIntegrated" mat-raised-button style="background: #6739B7; color: white" type="button" (click)="openDialog()">Disconnect</button>
          <button *ngIf="tenantKeys?.isProviderIntegrated == false" mat-raised-button style="background: #6739B7; color: white" type="button" (click)="enableProvider()">Reconnect</button>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button (click)="backToPaymentComponent()" matTooltip="Back" aria-label="Back">Cancel</button>
            <button mat-raised-button color="accent" type="submit">Submit</button>
          </div>
        </div>
    </form>  
    
  </div>
</div>