<div id="forms" class="page-layout carded fullwidth" fxLayout="column">
    <div class="new-header">
        <div style="float: left; display: flex;  align-items: center; justify-content: center;">
            <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
                language
            </mat-icon>
            <span class="logo-text h1">
                Website <span>- Lets build a website</span>
            </span>
        </div>
        <div class="external-link-toggle"
            style="justify-content: flex-end !important;display: flex;float: right;align-items: center;">
            <button mat-mini-fab style="border: 1px solid #00AFBF;" class=" mr-4 hoverEffect"
                (click)="openWebsiteSettingsPage()" matTooltip="Settings " aria-label="Settings" color="white">
                <mat-icon style="color: #00AFBF">settings</mat-icon>
            </button>
        </div>
        <div class="external-link-toggle"
            style="justify-content: flex-end !important;display: flex;float: right;align-items: center;">
            <button class="hoverEffect"
                style="margin: 12px; margin-top: 0; margin-right: 4px; border: 1px solid #00AFBF;" mat-mini-fab
                (click)="openAddNewWebsitePage()" matTooltip="Add New Page" aria-label="Add new page" color="white">
                <mat-icon style="color: #00AFBF">add</mat-icon>
            </button>
        </div>
    </div>


    <div class="p-16" fxFlex="100" fxLayout="row" style="background: #F5F5F5;margin: 0 -15px;">
        <div fxFlex="100" fxLayout="row">
            <div class="contact_sidebar pb-8" style="margin-top: 54px;margin-right: 10px;width: 180px;">
                <website-builder-sidebar [availablePageList]="availablePageList"
                    [internalWebsiteSelectedType]="pageName"
                    (outputToParent)="setSelectedInternalWebsitePage($event)"></website-builder-sidebar>
                <!--                <website-builder-availablepages-sidebar-->
                <!--                        (outputToParent)="setSelectedInternalWebsitePage($event)"></website-builder-availablepages-sidebar>-->
            </div>
            <div fxFlex="100" style="margin-top: -16px;" class="external-web-spinner">
                <mat-icon *ngIf="isInternalSiteLoading"
                    style="width: 100%; height: 100%; transform: translate(50%,50%);">
                    <mat-spinner diameter="40"></mat-spinner>
                </mat-icon>
                <div [hidden]="!isInternalSiteLoading && isDefaultWebsitePagesSelected || isPagePrivacyPolicyOrTermsOfService">
                    <div class="page-header"
                        style="background: #fff;height: 60px;align-items: center;margin-bottom: 3px; ">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" layout-align="center center"
                            fxFlex="1 0 auto" class="title-header">
                            <span class="logo-text h3" *ngIf="!isPageNameEdit" style="margin: 0 10px 0 10px;">
                                {{ pageTitle | titlecase }}
                            </span>
                            <mat-icon matChipTrailingIcon class='ml-a br-blue pointer'
                                *ngIf="!isPageNameEdit && pageName != 'home'" (click)="isPageNameEdit = true"
                                style="font-size: 16px;line-height: 24px;color: #00afbf; margin-right: 20px">mode_edit
                            </mat-icon>
                            <mat-form-field class="external-url" *ngIf="isPageNameEdit"
                                style="padding: 10px;padding-bottom: 0;max-width: 240px!important;">
                                <mat-label>Page Name</mat-label>
                                <input matInput class="riuctght-align" type="text" [(ngModel)]="pageTitle"
                                    (keydown.enter)="addUpdateInternalWebsite(false); isPageNameEdit = false"
                                    requried />
                                <mat-icon matSuffix class="pointer"
                                    (click)="addUpdateInternalWebsite(false); isPageNameEdit = false">done
                                </mat-icon>
                                <mat-icon matSuffix class="pointer" (click)="isPageNameEdit = false">cancel</mat-icon>
                                <mat-error>Page Name is required!</mat-error>
                            </mat-form-field>
                        </div>
                        <div
                            style="justify-content: flex-end !important;display: flex;float: right;align-items: center;">
                            <div style="text-align: right;padding: 10px; margin-right: 25px;">
                                <mat-slide-toggle *ngIf="!isInternalPageEdit" [(ngModel)]="isPublished"
                                class="external-toggle" style="padding-left: 10px;margin-right: 10px;"
                                    (change)="publishPage($event)"
                                    labelPosition="after">
                                    <span style="font-size: 15px; margin-right: 10px;" *ngIf="isPublished">Published</span><span
                                        style="font-size: 15px; margin-right: 10px;" *ngIf="!isPublished">Private</span>
                                </mat-slide-toggle>
                                <button
                                    *ngIf="this.isPagePrivacyPolicyOrTermsOfService"
                                    mat-mini-fab style="border: 1px solid #00AFBF;" class=" mr-4 hoverEffect"
                                    (click)="editSelectedPage()" matTooltip="Edit " aria-label="Edit" color="white">
                                    <mat-icon style="color: #00AFBF">edit</mat-icon>
                                </button>
                                <button class="mr-4 hoverEffect" *ngIf="!isNonDeletable" mat-mini-fab (click)="deletePage()"
                                    style="border: 1px solid; border-color: #FF8845" color="white" matTooltip="Delete "
                                    matTooltip="Delete">
                                    <mat-icon style="color: #FF8845">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="template-wrap mt-8"
                        style="height: auto; background:#fff; background-color: #fff; overflow-y: scroll;">
                        <div fxLayout="column" class="p-12" style="margin: 10px 0;background: #fff;">

                            <div *ngIf="pageName == 'events'">
                                <ng-template *ngIf="eventsComponentInjector"
                                    [ngComponentOutlet]="eventsComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName == 'forms'">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem"><mat-icon>info</mat-icon>
                                    
                                    <span>All Form with  <span class="primary_dot mx-4" [ngStyle]="{ 'background-color': 'green' }"></span> are visible in public view.</span></div>
                                <ng-template *ngIf="formsComponentInjector"
                                    [ngComponentOutlet]="formsComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName == 'calendar'">
                                <ng-template *ngIf="calenderComponentInjector"
                                    [ngComponentOutlet]="calenderComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName == 'courses'">
                                <ng-template *ngIf="coursesComponentInjector"
                                    [ngComponentOutlet]="coursesComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName == 'merchandise'">
                                <ng-template *ngIf="merchandiseComponentInjector"
                                    [ngComponentOutlet]="merchandiseComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName == 'donations'">
                                <ng-template *ngIf="donationComponentInjector"
                                    [ngComponentOutlet]="donationComponentInjector | async"></ng-template>
                            </div>
                            <div *ngIf="pageName === 'mediagallery'">
                                <ng-template *ngIf="eventGalleryComponentInjector"
                                             [ngComponentOutlet]="eventGalleryComponentInjector | async"></ng-template>
                            </div>
                        </div>
                        <!--     ****************  *******************-->
                    </div>
                </div>
                <div [hidden]=" !this.isPagePrivacyPolicyOrTermsOfService && !isInternalSiteLoading && !isDefaultWebsitePagesSelected">
                    <div class="page-header"
                        style="background: #fff;height: 60px;align-items: center;margin-bottom: 3px;">
                        <div fxLayoutAlign="start center" layout-align="center center" fxFlex="1 0 auto"
                            class="title-header">
                            <span class="logo-text h3" *ngIf="!isPageNameEdit" style="margin: 0 5px 0 10px;">
                                {{ pageTitle | titlecase }}
                            </span>
                            <mat-icon matChipTrailingIcon class='ml-a br-blue pointer'
                                *ngIf="!isPageNameEdit && pageName != 'home' && !this.isPagePrivacyPolicyOrTermsOfService" (click)="isPageNameEdit = true"
                                style="font-size: 16px;line-height: 24px;color: #00C6D8;">mode_edit
                            </mat-icon>
                            <mat-form-field class="external-url" *ngIf="isPageNameEdit"
                                style="padding: 10px;padding-bottom: 0;max-width: 240px!important;">
                                <mat-label>Page Name</mat-label>
                                <input matInput class="riuctght-align" type="text" [(ngModel)]="pageTitle"
                                    (keydown.enter)="addUpdateInternalWebsite(false); isPageNameEdit = false"
                                    requried />
                                <mat-icon matSuffix class="pointer"
                                    (click)="addUpdateInternalWebsite(false); isPageNameEdit = false">done
                                </mat-icon>
                                <mat-icon matSuffix class="pointer" (click)="isPageNameEdit = false">cancel</mat-icon>
                                <mat-error>Page Name is required!</mat-error>
                            </mat-form-field>
                            <div fxFlex="auto" fxLayoutAlign="start center" fxLayoutGap="1rem">
                            <mat-slide-toggle
                            class="external-toggle" 
                                [(ngModel)]="isExternalWebsiteUrl"
                                (click)="isExternalWebsiteUrl = !isExternalWebsiteUrl;isRawTemplateSelected =false;addUpdateInternalWebsite(false)"
                                name="isExternalWebsiteUrl"></mat-slide-toggle><span>{{!isExternalWebsiteUrl ? 'Internal Page' : 'External Link' }}</span></div>
                        </div>
                        <div
                            style="justify-content: flex-end !important;display: flex;float: right;align-items: center;">
                            <div style="text-align: right;padding: 10px;">
                                <mat-slide-toggle
                                class="external-toggle" style="padding-left: 10px;margin-right: 10px;"
                                    *ngIf="!isInternalPageEdit && (selectedPageUnJson != '' || selectedPageUhhtml || '' && externalLinkValue || '')"
                                    [(ngModel)]="isPublished"
                                    (change)="publishPage($event)"
                                    labelPosition="after">
                                    <span style="font-size: 15px; margin-right: 10px;" *ngIf="isPublished">Published</span><span
                                        style="font-size: 15px; margin-right: 10px;" *ngIf="!isPublished">Private</span>
                                </mat-slide-toggle>
<!--                                <button-->
<!--                                        mat-mini-fab style="border: 1px solid #00AFBF;" class=" mr-4 hoverEffect"-->
<!--                                        (click)="openWebsiteSettingsPage()" matTooltip="Settings " aria-label="Settings" color="white">-->
<!--                                    <mat-icon style="color: #00AFBF">settings</mat-icon>-->
<!--                                </button>-->
                                <button
                                        *ngIf="!isInternalPageEdit && (selectedPageUnJson != '' || selectedPageUhhtml || '' && externalLinkValue || '' && this.isPagePrivacyPolicyOrTermsOfService)"
                                    mat-mini-fab style="border: 1px solid #00AFBF;" class=" mr-4 hoverEffect"
                                    (click)="openPublishUnpublishDialog()" matTooltip="Website Settings " aria-label="Website Settings" color="white">
                                    <mat-icon style="color: #00AFBF">settings</mat-icon>
                                </button>
                                <button
                                    *ngIf="!isInternalPageEdit && !isExternalWebsiteUrl && isDefaultWebsitePagesSelected || this.isPagePrivacyPolicyOrTermsOfService"
                                    mat-mini-fab style="border: 1px solid #00AFBF;" class=" mr-4 hoverEffect"
                                    (click)="editSelectedPage()" matTooltip="Edit " aria-label="Edit" color="white">
                                    <mat-icon style="color: #00AFBF">edit</mat-icon>
                                </button>

                                <button *ngIf="!isInternalPageEdit && !isNonDeletable" class="mr-4 hoverEffect" mat-mini-fab
                                    (click)="deletePage()" style="border: 1px solid; border-color: #FF8845"
                                    color="white" matTooltip="Delete " matTooltip="Delete">
                                    <mat-icon style="color: #FF8845">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="isExternalWebsiteUrl" fxLayout="column" style="width: 100%;margin: 0 auto;">
                        <div class="enter-link-wrap p-12" style="background: #fff;" fxFlex="100" fxLayout="column">
                            <p style="margin-bottom: 0;">Enter URL</p>
                            <p style="color: #8b8e95;margin-top: 0;">Enter absolute page address e.g
                                https://www.abc.com/services</p>
                            <form [formGroup]="externalWebsiteForm" fxLayoutAlign="center" fxFlex="100" fxLayout="column" fxLayoutGap="1rem">
                                <mat-form-field appearance="outline" style="width: 60%">
                                    <mat-label>URL</mat-label>
                                    <input matInput formControlName="externalLinkValue"/>
                                    <mat-error> URL is required!</mat-error>
                                </mat-form-field>
                                <div fxLayout="row" fxLayoutAlign="center" class="mb-8">
                                    <button mat-raised-button type="button" color="accent"
                                        [disabled]="!externalWebsiteForm.valid"
                                        (click)="addUpdateInternalWebsite(false)">
                                        Submit
                                        <mat-icon *ngIf="isInternalSubmitLoading">
                                            <mat-spinner diameter="20"></mat-spinner>
                                        </mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="!isExternalWebsiteUrl">
                        <div class="external-stepper" [hidden]="!isInternalPageEdit &&  selectedPageUhhtml != ''">
                             <div  *ngIf="!isPageNameEdit && pageName == 'about' || !isPageNameEdit && pageName == 'contact'" class="addTitleInput">
                                <standard-page-header-subheader style="background-color: white;"
                                    pageName= {{pageName}}></standard-page-header-subheader>
                            </div>
                            <mat-horizontal-stepper #stepper style="margin-bottom: 20px;">
                                <mat-step>
                                    <ng-template matStepLabel>Select Template</ng-template>
                                    <div fxLayout="column" fxLayoutAlign="center center" class="mt-24">
                                        <span class="h2" style="color:#00C6D8;">Select Template </span>
                                        <span class="secondary-text">Select a based template to start adding a new
                                            page.</span>

                                        <internal-website-template-list [unTemplates]="selectedPageUnTemplates"
                                            [untotal]="selectedPageUntotal" [skipTemplate]="true" [pageName]="pageName"
                                            (onTemplateClick)="templateSelect($event,stepper)"></internal-website-template-list>
                                        <div fxLayout="row" fxLayoutAlign="end" class="mt-24" style="margin-left: auto"
                                            *ngIf="selectedPageUhhtml != '' || selectedPageUnJson != ''">
                                            <button class="mr-8" mat-stroked-button type="button" color="accent"
                                                (click)="cancelAddEditInternalPage()">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <form fxLayout="column">
                                        <ng-template matStepLabel>Edit Page</ng-template>
                                        <div class="editor-container" fxLayout="column" *ngIf="isRawTemplateSelected">
                                            <ngx-monaco-editor fxFlex [options]="editorOptions"
                                                [(ngModel)]="htmlEditorVal" [ngModelOptions]="{standalone: true}"
                                                (init)="editorInit($event)">
                                            </ngx-monaco-editor>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="center center" class="mt-12 mb-12"
                                            style="margin-left: auto" *ngIf="!isRawTemplateSelected">
                                            <mat-icon class="action-button mr-8" color="accent"
                                                matTooltip="All Templates" (click)="stepper.previous()">
                                                view_list
                                            </mat-icon>
                                            <mat-icon class="action-button" color="accent" matTooltip="Save as Template"
                                                (click)="saveDesign()" [style.visibility]="'visible' ">
                                                save
                                            </mat-icon>
                                        </div>

                                        <div id="editorLoadedId" class="h2 m-16" fxLayout="column"
                                            fxLayoutAlign="start stretch" [style.visibility]="'visible' "
                                            *ngIf="!isRawTemplateSelected">
                                            <email-editor [options]="options" #editor (loaded)="editorLoaded()">
                                            </email-editor>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="end" class="mt-24" style="margin-left: auto">
                                            <button class="mr-8" mat-stroked-button type="button" color="accent"
                                                *ngIf="selectedPageUhhtml != '' || selectedPageUnJson != ''"
                                                (click)="cancelAddEditInternalPage()">
                                                Cancel
                                            </button>
                                            <button class="mr-8" mat-raised-button matStepperPrevious type="button"
                                                color="accent">
                                                Previous
                                            </button>
                                            <button mat-raised-button matStepperNext type="button" color="accent"
                                                (click)="getHtml()">
                                                Next
                                            </button>
                                        </div>
                                    </form>
                                </mat-step>

                                <mat-step>
                                    <ng-template matStepLabel>Preview</ng-template>
                                    <div style="width:100%;" >
                                        <div fxLayout="column"
                                            style="width:100%;max-width:980px;margin: 0 auto;box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px; border: 1px solid rgb(238, 238, 238);margin-top: 15px; margin-bottom: 15px;">

                                            <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
                                                <div class="h2 m-16"
                                                    *ngIf="editorHtml !='' && !isRawTemplateSelected"
                                                    [innerHTML]="editorHtml"></div>
                                                <div class="h2 m-16" fxLayout="column" fxLayoutAlign="center center"
                                                    *ngIf="isRawTemplateSelected" [innerHTML]="htmlEditorVal"></div>
                                            </div>
                                        </div>
                                    </div>


                                    <div fxLayout="row" class="mt-16" fxLayoutAlign="end" style="margin-left: auto">
                                        <button class="mr-8" mat-stroked-button type="button" color="accent"
                                            (click)="cancelAddEditInternalPage()">
                                            Cancel
                                        </button>
                                        <button class="mr-8" mat-raised-button matStepperPrevious type="button"
                                            color="accent">
                                            Previous
                                        </button>
                                        <button mat-raised-button type="button" color="accent"
                                            (click)="addUpdateInternalWebsite()">
                                            Submit
                                            <mat-icon *ngIf="isInternalSubmitLoading">
                                                <mat-spinner diameter="20"></mat-spinner>
                                            </mat-icon>
                                        </button>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="!isInternalPageEdit &&  selectedPageUhhtml != ''" style="flex-direction: column;">


                            <div fxLayout="column" fxLayoutAlign="start" fxFlex="100">
                                <div class="h2" fxLayout="column" fxLayoutAlign="center center" *ngIf="editorHtml !=''"
                                    [innerHTML]="selectedPageUhhtml"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>