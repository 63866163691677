import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

// import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { HorizontalLayout2Component } from 'app/layout/horizontal/layout-2/layout-2.component';

@NgModule({
  declarations: [HorizontalLayout2Component],
  imports: [MatSidenavModule, FuseSharedModule, FuseSidebarModule, FuseThemeOptionsModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule],
  exports: [HorizontalLayout2Component],
})
export class HorizontalLayout2Module {}
