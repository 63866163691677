import { AppSettingsService } from "./../services/app-settings.service";
import { Component, OnDestroy, OnInit, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SnackbarService } from "app/main/shared/helpers/snackbar.service";
import { BehaviorSubject, Subject } from "rxjs";
import { AccountDetail } from "../models/account-details";
import { AppLoadService } from "app/core/services/appload.service";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { FileObject } from "app/main/shared/components/file-upload/file-upload.component";

@Component({
  selector: "app-student-app-setting",
  templateUrl: "./student-app-setting.component.html",
  styleUrls: ["./student-app-setting.component.scss"]
})
export class StudentAppSettingComponent implements OnInit, OnDestroy {
  form: FormGroup;
  _accountDetail: AccountDetail;
  isLoading: boolean = true;
  showSpinner: boolean = false;
  color: string;
  colorSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  subs = new SubSink();

  bannerImageUrl1: string;
  bannerImage1: { id: number; url: string }[] = [];
  bannerImage1$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  bannerImageUrl2: string;
  bannerImage2: { id: number; url: string }[] = [];
  bannerImage2$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  bannerImageUrl3: string;
  bannerImage3: { id: number; url: string }[] = [];
  bannerImage3$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    private _router: Router
  ) {
    // Set the private defaults
  }

  ngOnInit() {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this._accountDetail = { ...data };
        this._accountDetail.accountOwner = this._tenantUserService.getuser().guId;
        this.bindFormData();
        this.setCoverImg();
        this.initBannerImage();
        this.isLoading = false;
        this.SpinnerService.hide();
      })
    );
  }

  initBannerImage() {
    this.subs.add(
      this.bannerImage1$.subscribe(res => {
        this.bannerImage1 = res;
        let list = this.bannerImage1[0].url.split("/");
        let length = list.length;
        this.bannerImageUrl1 = `${list[length - 2]}/${list[length - 1]}`;
        if (this.bannerImageUrl1 == "undefined/") {
          this.form.get("appWelcomeImage1").setValue("");
        } else {
          this.form.get("appWelcomeImage1").setValue(this.bannerImageUrl1);
        }
      })
    );

    this.subs.add(
      this.bannerImage2$.subscribe(res => {
        this.bannerImage2 = res;
        let list = this.bannerImage2[0].url.split("/");
        let length = list.length;
        this.bannerImageUrl2 = `${list[length - 2]}/${list[length - 1]}`;
        if (this.bannerImageUrl2 == "undefined/") {
          this.form.get("appWelcomeImage2").setValue("");
        } else {
          this.form.get("appWelcomeImage2").setValue(this.bannerImageUrl2);
        }
      })
    );

    this.subs.add(
      this.bannerImage3$.subscribe(res => {
        this.bannerImage3 = res;
        let list = this.bannerImage3[0].url.split("/");
        let length = list.length;
        this.bannerImageUrl3 = `${list[length - 2]}/${list[length - 1]}`;
        if (this.bannerImageUrl3 == "undefined/") {
          this.form.get("appWelcomeImage3").setValue("");
        } else {
          this.form.get("appWelcomeImage3").setValue(this.bannerImageUrl3);
        }
      })
    );
  }

  setCoverImg() {
    let path = "c_fill,h_23,w_113/";
    if (this._accountDetail.appWelcomeImage1) {
      let temp = this._accountDetail.appWelcomeImage1.split(path);
      this.form.controls.appWelcomeImage1.setValue(temp[1]);
      this.bannerImage1.push({
        id: 1,
        url: `${this._accountDetail.appWelcomeImage1}`
      });
    }
    if (this._accountDetail.appWelcomeImage2) {
      let temp = this._accountDetail.appWelcomeImage2.split(path);
      this.form.controls.appWelcomeImage2.setValue(temp[1]);
      this.bannerImage2.push({
        id: 1,
        url: `${this._accountDetail.appWelcomeImage2}`
      });
    }
    if (this._accountDetail.appWelcomeImage3) {
      let temp = this._accountDetail.appWelcomeImage3.split(path);
      this.form.controls.appWelcomeImage3.setValue(temp[1]);
      this.bannerImage3.push({
        id: 1,
        url: `${this._accountDetail.appWelcomeImage3}`
      });
    }
  }

  bindFormData(): void {
    // Reactive Form
    this._accountDetail.appPrimaryColor =
      this._accountDetail.appPrimaryColor == "4d8dfc" ? "#4d8dfc" : this._accountDetail.appPrimaryColor;
    this.form = this._formBuilder.group({
      appLabel1: [this._accountDetail.appLabel1], // Course Menu Label
      appLabel2: [this._accountDetail.appLabel2], // Schedule Menu Label
      appLabel3: [this._accountDetail.appLabel3], // Myfiles Menu Label
      appLabel4: [this._accountDetail.appLabel4], // Other
      appPrimaryColor: [this._accountDetail.appPrimaryColor], // Primary Color
      appWelcomeImage1: [""],
      appWelcomeImage2: [""],
      appWelcomeImage3: [""]
    });

    this.subs.add(
      this.colorSubject.subscribe(color => {
        this.form.get("appPrimaryColor").setValue(color);
        this.color = color;
      })
    );

    this.colorSubject.next(this._accountDetail.appPrimaryColor);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subs.unsubscribe();
  }

  updateSettings() {
    if (this.form.valid) {
      // if(this.bannerImageUrl1 == undefined){
      //   this.form.get("appWelcomeImage1").setValue("");
      // }
      // if(this.bannerImageUrl2 == undefined){
      //   this.form.get("appWelcomeImage2").setValue("");
      // }
      // if(this.bannerImageUrl3 == undefined){
      //   this.form.get("appWelcomeImage3").setValue("");
      // }
      this.SpinnerService.show();
      this.subs.add(
        this._settingsService.updateSettings(this.form.value).subscribe(
          next => {
            this.SpinnerService.hide();
            this.toastr.success("Successfully updated settings.");
            this._router.navigate(["/settings"]);
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error(error);
          }
        )
      );
    } else {
      this.toastr.error("Invalid Form!");
    }
  }

  get appPrimaryColor(): string {
    return this.form.get("appPrimaryColor").value;
  }
}
