import { Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoadService } from 'app/core/services/appload.service';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { AccountDetail } from '../models/account-details';
import { AppSettingsService } from '../services/app-settings.service';
import { SubSink } from 'subsink';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-display-setting',
  templateUrl: './display-setting.component.html',
  styleUrls: ['./display-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisplaySettingComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public isSubmitting = false;
  public otherForm: FormGroup;
  public toggleForm: FormGroup;
  private _accountDetail: AccountDetail;
  private subs = new SubSink();

  constructor(
    private _appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this._accountDetail = { ...data };
        this._accountDetail.accountOwner = this._tenantUserService.getuser().guId;
        this.bindFormData();
        this.SpinnerService.hide();
        this.isLoading = false;
      }),
    );
  }

  bindFormData(): void {
    // Reactive Form
    this.toggleForm = this._formBuilder.group({
      isShowEventsMenu: [this._accountDetail.isShowEventsMenu],
      isShowScheduleMenu: [this._accountDetail.isShowScheduleMenu], //Classes
      isShowDonationMenu: [this._accountDetail.isShowDonationMenu],
      isShowMerchandiseMenu: [this._accountDetail.isShowMerchandiseMenu],
      isShowAssessment: [this._accountDetail.isShowAssessment],
      isShowFormsMenu: [this._accountDetail.isShowFormsMenu],
      isShowCampaignsMenu: [this._accountDetail.isShowCampaignsMenu],
      isShowStoreMenu: [this._accountDetail.isShowStoreMenu],
    });
    
    this.otherForm = this._formBuilder.group({
      isShowCalenderView: [this._accountDetail.isShowCalenderView],
      isWaiverFormToBeSigned: [this._accountDetail.isWaiverFormToBeSigned],
      waiverFormLink: [this._accountDetail.waiverFormLink],
      registerButtonLabel: [this._accountDetail.registerButtonLabel],
      isSupportGrn: [this._accountDetail.isSupportGrn],
    });

    this.toggleForm.valueChanges.subscribe(change => {
      this.updateToggleSettings();
    });

    this.setCustomValidators();
  }

  public updateToggleSettings() {
    this.putData(this.toggleForm.value);
  }

  public updateOtherSettings() {
    if (this.otherForm.valid) {
      this.SpinnerService.show();
      this.putData(this.otherForm.value);
    }
  }

  private putData(data) {
    this.subs.add(
      this._settingsService.updateSettings(Object.assign(data)).subscribe(
        next => {
          this._appLoadService.updateTenantConfig(true);
          this.SpinnerService.hide();
          this.toastr.success('Successfully updated Settings.');
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error(error);
        },
      ),
    );
  }

  public get isShowCalenderView(): boolean {
    return this.toggleForm.get('isShowCalenderView').value;
  }

  private setCustomValidators() {
    this.setValidatorsRequiredOnCheck('isWaiverFormToBeSigned', 'waiverFormLink');
  }

  private setValidatorsRequiredOnCheck(checkBoxName: string, validatingFieldName: string) {
    const field = this.otherForm.get(validatingFieldName);
    const checkBox = this.otherForm.get(checkBoxName);

    // Subscribe to the checkbox's valueChanges to dynamically update the validators
    checkBox.valueChanges.subscribe(val => {
      field[val ? 'setValidators' : 'clearValidators']([Validators.required]);
      field.updateValueAndValidity();
    });

    // Immediately update validators based on the current checkbox state
    field[checkBox.value ? 'setValidators' : 'clearValidators']([Validators.required]);
    field.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
