<div id="academy-courses" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex; align-items: center; justify-content: center">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> account_balance_wallet </mat-icon>
      <span class="logo-text h1"> Plans </span>
      <div style="font-size: 14px; margin-left: 10px; margin-top: 3px">Your current plan, you may upgrade your plan.</div>
    </div>
    <!-- <div style="float: right">
      <button style="margin-right: 10px" mat-raised-button type="button" (click)="upgrade()" matTooltip="Upgrade" aria-label="Upgrade">Upgrade</button>
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div> -->
  </div>
  <!-- / HEADER -->

  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex.gt-xs="100" style="padding-bottom: 0px !important" *ngIf="subscription">
    <div class="mb-24 membershipDetails" fxLayout="row" fxFlex="100">
      <div class="course" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100" style="margin: 5px; margin-right: 0px">
        <h2 style="margin: 5px; margin-left: 0px; font-weight: 600">Plan - {{ subscription.planName | titlecase }}</h2>
        <table class="table" style="width: 100%">
          <tr>
            <td>Current Plan</td>
            <td>
              <span>{{ subscription.planName | titlecase }} </span>
            </td>
          </tr>
          <tr>
            <td>Current Billing Period</td>
            <td>
              <span>{{ subscription.dateCreatedStr }} </span> Until
              <span>{{ subscription.nextBillingDateStr }} </span>
            </td>
          </tr>
          <tr>
            <td>Next Billing Date</td>
            <td>
              <span>{{ subscription.nextBillingDateStr }} </span>
            </td>
          </tr>
          <tr>
            <td>Billing Amount</td>
            <td>
              <span *ngIf="_tenantConfig">{{ _tenantConfig.csymbol }} {{ subscription.amount }} </span>
            </td>
          </tr>
          <!-- <tr>
            <td>Card On File</td>
            <td>
              <span class="col-sm-12 top10" *ngIf="subscription && subscription.savedCards.length > 0">
                <span *ngFor="let card of subscription.savedCards">
                  <span> {{ card.prefCard | titlecase }}<br /> </span>
                </span>
              </span>
            </td>
          </tr> -->
        </table>
      </div>
    </div>
  </div>

  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex.gt-xs="100" style="padding-top: 0px !important">
    <div class="mb-24 membershipDetails" fxLayout="row" fxFlex="100">
      <div class="course" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100" style="margin: 5px; margin-right: 0px">
        <h2 style="margin: 5px; margin-left: 0px; font-weight: 600; margin-bottom: 10px">Payments</h2>
        <div *ngIf="isLoading" class="courses bg-color-white pb-16">
          <div style="width: 100%; padding: 15px">
            <ngx-skeleton-loader
              appearance=""
              count="3"
              [theme]="{
                background: '#ededed',
                height: '18px'
              }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              appearance=""
              count="3"
              [theme]="{
                background: '#ededed',
                height: '18px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
        <div *ngIf="!isLoading" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 0px; background: white">
          <table style="width: 100%" mat-table [dataSource]="dataSource" [@animateStagger]="{ value: '50' }">
            <ng-container matColumnDef="client">
              <mat-header-cell *matHeaderCellDef>Client</mat-header-cell>
              <mat-cell *matCellDef="let report">
                <p style="color: #00C6D8; cursor: pointer" (click)="contactView(report.user.guId)" class="name text-truncate">
                  {{ report.user.name | titlecase }} {{ report.user.lastName | titlecase }}
                </p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm>Name</mat-header-cell>
              <mat-cell *matCellDef="let report" fxHide fxShow.gt-sm [routerLink]="['/reports/sales/' + report.guId]" style="color: #00C6D8; cursor: pointer">
                <p>
                  {{ report.itemName | titlecase }}
                </p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="price">
              <mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;Price</mat-header-cell>
              <mat-cell *matCellDef="let report">
                <p>
                  &nbsp;&nbsp;
                  <span *ngIf="_tenantConfig">{{ _tenantConfig.csymbol }} {{ report.price }}</span>
                  <img
                    *ngIf="report.subscription"
                    aria-label="Recurring Membership"
                    matTooltip="Recurring Membership"
                    style="width: auto; margin-left: 5px; margin-top: 1px"
                    src="https://passets.wajooba.com/img/Autopay_01.png"
                  />
                </p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>Transaction Status</mat-header-cell>
              <mat-cell *matCellDef="let report">
                <p>
                  <!-- <mat-icon *ngIf="report.transactionStatus == 'SETTLED'" style="color: green; font-size: 19px; vertical-align: bottom;">check_circle_outline</mat-icon> -->
                  <span *ngIf="report.transactionStatus == 'SETTLED'" style="color: green"> Completed </span>
                  <span *ngIf="report.transactionStatus != 'SETTLED'" style="color: red"> Failed</span>
                </p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="created_date">
              <mat-header-cell *matHeaderCellDef>Date Created</mat-header-cell>
              <mat-cell *matCellDef="let report">
                <p>
                  {{ report.dateCreated * 1000 | date : "MMM dd, YYYY" : "UTC" }}
                </p>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let report; columns: displayedColumns" class="contact" [@animate]="{ value: '*', params: { y: '100%' } }"> </mat-row>
          </table>

          <div *ngIf="!isLoading && data.length == 0" style="width: 100%; padding: 15px; background: white; border-bottom: 1px solid lightgray">
            <h4>No Record Found</h4>
          </div>
          <mat-paginator
            style="background: transparent"
            *ngIf="!isLoading && data"
            #paginator
            [pageSizeOptions]="pageSizeOptions"
            [length]="totalRecords"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            (page)="pageEvent = getSubsriptions($event)"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
