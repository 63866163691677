<!-- spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<!-- startshere -->
<div id="forms" *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex; align-items: center; justify-content: center">
      <mat-icon class="logo-icon s-32" style="margin-right: 10px"> view_module </mat-icon>
      <span class="logo-text h1"> Payment Providers </span>
    </div>
    <div style="float: right">
      <button mat-icon-button (click)="backToPaymentComponent()" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24" fxLayout="row wrap" fxLayoutAlign="center none" fxFlex="grow" fxLayout.gt-xs="row" fxFlex.gt-xs="50" fxFlex.gt-sm="33">
    <div class="mb-24">
      <div fxFlex="5"></div>

      <form
        *ngIf="tenant"
        class="mat-card mat-elevation-z4 p-24 mr-24"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        name="form"
        (ngSubmit)="onSubmit()"
        [formGroup]="form"
        fxFlexFill
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <div class="integrations" fxLayout="row wrap" fxFlex="100" style="width: 100%; display: flex; align-items: center; justify-content: center">
            <div class="card-preview" fxFlex="30" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div class="fuse-card turquoise">
                <div class="mailchimp-title">
                  <div class="h1" style="font-size: 17px">Nelnet Inc Payments</div>
                </div>
              </div>
            </div>
            <div class="card-preview" fxFlex="70" [@animate]="{ value: '*', params: { y: '100%' } }">
              <div class="fuse-card grey">
                <p>Accept Payments in USD</p>
                <p>
                  Merchant Fees from, ACH 0.80%, Credit Card 2.9% plus 30¢
                  <br><span>                  
                    To apply
                    <a style="text-transform: none" href="https://signup.paymentspring.com/instances?product_id=3ac42665-f2d8-43e9-ad0b-fad2eefbae11" target="_blank">click here</a></span
                  >
                </p>
                <p>Navigate to Account -> API Keys for following information</p>
              </div>
            </div>
          </div>
        </div>

        <mat-divider class="mb-24" style="display: inline-block" fxFlex="100"></mat-divider>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-checkbox [checked]="tenant?.paymentProvider == 'PSPRING'" fxFlex="100"
            >Active Payment Provider
          </mat-checkbox>
        </div> 
        <br />-->
        <div class="h2" style="border-bottom: 1px solid #8080805e; padding-bottom: 10px">Production Mode</div>
        <hr />
        <div fxLayout="row wrap" *ngIf="showAudienceGif" fxLayoutAlign="center" fxFlex="1 0 auto">
          <img fxFlex="80" src="https://passets.wajooba.com/img/hq_listId.gif" alt="mailchimp List id" />
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Live Public Key*</mat-label>
            <input formControlName="pspringPublicKey" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Live Private Key*</mat-label>
            <input formControlName="pspringPrivateKey" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <div class="h2">Test Mode</div>
        <div class="h3" style="border-bottom: 1px solid #8080805e; padding-bottom: 10px">
          In Test mode, you can accept test credit cards to test the payments
          <a href="https://docs.paymentspring.com/#test-card-numbers" target="_blank" class="help-link">Click Here</a>
          for Test Card Numbers.
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 10px">
          <mat-checkbox (change)="validateTestMode()" formControlName="isTestMode" fxFlex="100">
            <div class="h2">Switch To Test Mode</div>
          </mat-checkbox>
        </div>
        <div *ngIf="isShowError" class="col-sm-12">
          <label class="col-md-2 col-xs-12"></label>
          <div class="col-md-8 col-xs-12 form-group-lg has-feedback has-error">
            <small style="margin-top: 0; margin-left: -15px" class="help-block">{{ isShowError }} </small>
          </div>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Test Public Key*</mat-label>
            <input formControlName="pspringPublicKeyTest" matInput class="right-align" />
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>Test Private Key*</mat-label>
            <input formControlName="pspringPrivateKeyTest" matInput class="right-align" />
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <!-- Buttons -->
        <div fxLayout="row" [fxLayoutAlign] = "tenant ? 'space-between center' : 'end center'">
          <button *ngIf="tenant?.isProviderIntegrated" mat-raised-button style="background: #40E0D0; color: white" type="button" (click)="openDialog()">Disconnect</button>
          <button *ngIf="tenant?.isProviderIntegrated == false" mat-raised-button style="background: #40E0D0; color: white" type="button" (click)="enableProvider()">Reconnect</button>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button (click)="backToPaymentComponent()" matTooltip="Back" aria-label="Back">Cancel</button>
            <button mat-raised-button color="accent" type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
