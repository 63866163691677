<div class="dialog-content-wrapper" style="overflow: hidden">
    <mat-toolbar matDialogTitle class="mat-accent m-0 accent">
        <mat-toolbar-row fxLayoutAlign="space-between center" style=" display: flex; justify-content: space-between; ">
            <div class="content-align">
                <span class="title dialog-title"   *ngIf="loadSection == 'information'"> Save time in Wajooba and your inbox</span>
                <span class="title dialog-title"   [hidden]="loadSection != 'submitEmail'"> Set up your email account</span>
                <span class="title dialog-title"   *ngIf="loadSection == 'emailIdentified'"> Set up your email account</span>
                <span class="title dialog-title"   *ngIf="loadSection == 'emailIdentifiedAndConfirmed'">Connect your {{identifiedEmailProviderDetails.providerLabel}} account to HubSpot CRM</span>
            </div>
            <button mat-dialog-close mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-dialog-content class="mb-20 mt-10" style="width: 100%; height: 100%;min-height: 150px">
        <div class="content-align">
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loadSection == 'information'">
                <div>
                        <p><mat-icon  style="color: #00c6d8;  font-size: 17px;">check_circle_outline</mat-icon> Send and schedule emails from HubSpot</p>
                        <p><mat-icon  style="color: #00c6d8;  font-size: 17px;">check_circle_outline</mat-icon> Log email replies to HubSpot automatically</p>
                        <p><mat-icon  style="color: #00c6d8;  font-size: 17px;">check_circle_outline</mat-icon> Suggest follow-up tasks and capture contact details from your email</p>
                    <br/>
                    <p>Requires inbox automation</p>

                        <button mat-raised-button type="button" color="accent" (click)="loadSection = 'submitEmail'">
                            Connect your inbox
                        </button>
                </div>
                <div>
                    <img class="logo-icon" src="assets/images/logos/connect-email-desc.png" style="width: 152px; height: 152px;">

                </div>
            </div>
            <div class="ml-5" [hidden]="loadSection != 'submitEmail'">
                <form class="content-align" fxLayout="column" fxLayoutAlign="space-around start" [formGroup]="emailForm" style="align-items: center;">
                    <mat-form-field appearance="outline" fxFlex="100" style="width: 75%; margin-bottom: -3rem">
                        <mat-label>Email Address</mat-label>
                        <input formControlName="email" matInput  (ngModelChange)="identifiedEmailProviderDetails = undefined" required/>
                        <mat-icon matSuffix class="secondary-text" style="color: #00c6d8">account_circle</mat-icon>
                        <mat-error>Email is required!</mat-error>
                    </mat-form-field>
                    <div fxLayout="column" fxLayoutAlign="space-between start"  *ngIf="identifiedEmailProviderDetails == undefined">
                        <div style="padding: 20px 0;">
                            <input type="checkbox" class="label-font-size"
                               formControlName="isShareAccount"/><span style="padding: 0 0 0 5px;">Share your email account across</span>
                        </div>
                        <button
                                class="mat-accent m-0"
                                mat-raised-button
                                tabindex="1"
                                [disabled]="!emailForm.valid"
                                (click)="submitEmail();">
                            Next
                            <mat-icon *ngIf="isLoading">
                                <mat-spinner diameter="20"></mat-spinner>
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-between start"*ngIf="identifiedEmailProviderDetails != undefined && identifiedEmailProviderDetails.providerName == 'other'">
                        <p style="color: red; padding: 5px 0;">We can't identify your email provider.</p>
                        <button
                                class="mat-accent m-0"
                                mat-raised-button
                                tabindex="1"
                                aria-label="Add">
                            Choose email provider
                        </button>
                    </div>

                </form>
            </div>
            <div class="ml-5" *ngIf="loadSection == 'emailIdentified'"
                        fxLayout="column" fxLayoutAlign="space-between start">
                    <mat-form-field appearance="outline" fxFlex="100" style="width: 75%; margin-bottom: -3rem">
                        <mat-label>Email Address</mat-label>
                        <input [(ngModel)]="usedEmail" matInput readonly/>
                        <mat-icon matSuffix class="secondary-text" style="color: #00c6d8">account_circle</mat-icon>
                        <mat-error>Email is required!</mat-error>
                    </mat-form-field>
                    <p style="padding: 5px 0;">Your email is hosted by {{identifiedEmailProviderDetails.providerLabel}}. We recommend connecting to {{identifiedEmailProviderDetails.appLabel}}</p>
                    <button
                            class="mat-accent m-0"
                            mat-raised-button
                            tabindex="1"
                            aria-label="Add"
                            [disabled]="!emailForm.valid"
                            (click)="handleConnectToMailBtnClick();">
                        Connect to {{identifiedEmailProviderDetails.appLabel}}
                    </button>
                </div>

            <div class="ml-5"   *ngIf="loadSection == 'emailIdentifiedAndConfirmed'">
                <div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="identifiedEmailProviderDetails.providerName == 'googlemail'" >
                    <img alt="" class="private-image private-image--object-fit-contain private-illustration"
                         src="//static.hsappstatic.net/ui-images/static-2.671/optimized/google-gmail.svg"
                         style="height: 40px;">
                    <span>Google/Gmail</span>
                    <span> {{usedEmail}}</span>
                    <a class="link" style="font-size: 14px;">Connect a different account</a>
                    <p>Here's what to expect when you connect to HubSpot:</p>
                    <textarea value>When you integrate your Google account with the HubSpot platform, you can connect a number of Google services to your HubSpot account. By doing so, HubSpot will be able to access data and information from your Google account. Those services and types of data are outlined below;

                        Gmail: You can send and receive Gmail messages, schedule email sends, and modify email drafts in the HubSpot app. You will also be able to log and track emails sent in-app. HubSpot will have access to your Gmail data, like email headers, subject lines, and email bodies.
                        We'll only use your Google data to power different parts of our product that connect with the Google Workspace tools you use every day. It's your data. We just want to help you grow better.

                        You can remove the integration in the HubSpot app by navigating to Settings > Integrations > Email Integrations and clicking 'Disable'. You can also revoke HubSpot's access from within your Google account by following the instructions listed here.

                        For more information on the Google integration and the types of data we can access, please see our Privacy Policy.
                    </textarea>
                    <button
                            class="mat-accent m-0"
                            mat-raised-button
                            tabindex="1"
                            aria-label="Add"
                            (click)="confirmConnectEmail();">
                        Continue
                    </button>
                </div>
                <div *ngIf="identifiedEmailProviderDetails.providerName == 'outlookmail'">
                    <img alt="" class="private-image private-image--object-fit-contain private-illustration"
                         src="//static.hsappstatic.net/ui-images/static-2.672/optimized/outlook.svg"
                         style="height: 40px;">
                    <p>Microsoft Outlook</p>
                    <p> {{usedEmail}}</p>
                    <a class="link" style="font-size: 14px;">Connect a different account</a>
                    <p>Here's what to expect when you connect to HubSpot:</p>
                    <textarea value>
                        First, by granting HubSpot access to your inbox, your HubSpot will be able to locate relevant email conversations and log them in your CRM so you and your team can work on them together. Emails that you send from HubSpot will also appear in your Outlook sent folder, so there'll be no confusion about which emails live where.

                        This means that your HubSpot will have access to some information about your emails, like the email address you’re sending them from, the email addresses of your recipients, what’s in the subject line, and what’s in the body of the email. We’ll only use that access to power your HubSpot Outlook integration and make life easier for you, never for any nefarious purposes of our own. It's your data. We just want to help you use it better. You'll always be able to delete specific email records from your CRM with just a couple of clicks. HubSpot will also have access to view and create calendar events so we can ensure your CRM and calendar are perfectly in sync.

                        You'll be able to make more sweeping changes to how your integration works (for instance, removing the integration or adding another email account) just by adjusting your settings. It's all up to you. Want to learn more? Visit www.hubspot.com/security for more information on our security policies and read the FAQ page about the HubSpot Outlook 365 integration.
                    </textarea>
                    <button
                            class="mat-accent m-0"
                            mat-raised-button
                            tabindex="1"
                            aria-label="Add"
                            (click)="confirmConnectEmail();">
                        Continue
                    </button>
                </div>
                <div *ngIf="identifiedEmailProviderDetails.providerName == 'zohomail'" >
                    <img alt="" class="private-image private-image--object-fit-contain private-illustration"
                         src="//static.hsappstatic.net/ui-images/static-2.671/optimized/google-gmail.svg"
                         style="height: 40px;">
                    <p>Zoho</p>
                    <p> {{usedEmail}}</p>
                    <a class="link" style="font-size: 14px;">Connect a different account</a>
                    <p>Here's what to expect when you connect to HubSpot:</p>
                    <textarea value>
                        ZOHO </textarea>
                    <button
                            class="mat-accent m-0"
                            mat-raised-button
                            tabindex="1"
                            aria-label="Add"
                            (click)="confirmConnectEmail();">
                        Continue
                    </button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
<!--    <mat-divider style="margin-top:25px;margin-bottom:3px"></mat-divider>-->

<!--    <mat-dialog-actions style="float:right;margin-right: 25px;margin-bottom:25px;" fxLayoutAlign="end end">-->
<!--        &lt;!&ndash;      <button mat-raised-button mat-dialog-close tabindex="-1" style="margin-right: 10px;">Cancel</button>&ndash;&gt;-->
<!--    </mat-dialog-actions>-->
</div>
