import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '../../services/app-settings.service';
import { SubSink } from 'subsink';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnDestroy {
  // public members
  public dataSource = new MatTableDataSource();
  public displayedColumns = ['name','createdAt'];
  public isLoading: boolean = false;
  public pageIndex: number = 0;
  public pageLength: number;
  public pageSize: number = 5;
  public pageSizeOptions: number[] = [5, 10, 50, 100, 150];

  // private members
  private _subs = new SubSink();
  private _templateList: any;

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private _appSettingsService: AppSettingsService, private _router: Router) {

   }

  ngOnInit(): void {
    this.getEmailTemplates();
  }

  private getEmailTemplates(){
    this.isLoading = true;
    this._subs.add(
    this._appSettingsService.getEmailTemplateList().subscribe((result: any) => {
      this.dataSource.data = result.data.eTemplates;
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.data.length;
      this.isLoading = false;
    },
    error => {
      this.isLoading = false;
    })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // public methods                                                                                            
  // -----------------------------------------------------------------------------------------------------

  public gotoAddScreen(){
    this._router.navigate([`/settings/templates/add`])
  }

  public gotoEditScreen(id){
    this._router.navigate([`/settings/templates/edit/${id}`])
  }

  public get templateList(){
    return this._templateList;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
