<div style="padding:0px;background: white; height: auto;" class="sidebar-content" *ngIf="availablePageList.length >0">
    <div class="card">
        <div class="content py-16" fusePerfectScrollbar>
            <div cdkDropList (cdkDropListDropped)="drop($event)" class="nav material2">
                <div class="nav-item" aria-label="inbox" style="display: flex; white-space: nowrap;"
                     (click)="internalWebsitePageChanged(item.name)" *ngFor="let item of availablePageList" >
                    <div >
                    <a class="nav-link" matRipple
                       [ngClass]="{ 'active accent': internalWebsiteSelectedType.toLowerCase() === item.name.toLowerCase(), 'gray-background': item.isPublished == false }"
                       style="width: 100%;max-width: 160px;" cdkDrag><mat-icon>drag_indicator</mat-icon>
                        {{item.title | titlecase}}
                    </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- / SIDEBAR CONTENT -->
