import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { TenantUserService } from "app/core/services/tenantuser.service";
import { LocalstorageService } from "app/core/services/applocalstorage.service";
import { AppConstants } from "app/core/settings/appconstants";

@Injectable({
  providedIn: "root"
})
export class ContactdialogService {
  constructor(private http: HttpClient, 
    private tenantUserService: TenantUserService,
    private _localStorageService: LocalstorageService
  ) {}

  contactSearch(search: any): Observable<any> {
    if (typeof search != "object") {
      let url = `${environment.apiBaseUrl}/rest/contact/?autoComplete=true&q=${search}`;
      return this.http.get(url);
    }
  }

  getContactToken(guId: string): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/contact/${guId}/token`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data.access_token;
      })
    );
  }

  setAdminAuthTokenForPreview(token: string) {
    return this._localStorageService.setItem(AppConstants.ADMIN_TOKEN, token);
  }

  getAdminAuthTokenForPreview(): string | null {
    return this._localStorageService.getItem(AppConstants.ADMIN_TOKEN);
  }

  getTenantToken(id: string, contactId: string): Observable<any> {
    let url = `${environment.apiBaseUrl}/rest/tenant/${id}/token`;
    if (contactId) {
      url += "?masterAdminId=" + contactId;
    }
    return this.http.get(url).pipe(
      map((data: any) => {
        return data.access_token;
      })
    );
  }
}
