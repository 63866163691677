<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
<div id="forms" *ngIf="!isLoading" class="page-layout carded fullwidth" fxLayout="column">
  <!-- HEADER -->
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        settings_applications
      </mat-icon>
      <span class="logo-text h1">
        Payment Settings
      </span>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div
    class="content p-24"
    fxLayout="row wrap"
    fxLayoutAlign="center none"
    fxFlex="grow"
    fxLayout.gt-xs="row"
    fxFlex.gt-xs="50"
    fxFlex.gt-sm="33"
  >
    <div class="mb-24" style="width: 100%;">
      <div fxFlex="5"></div>

      <form
        class="mat-elevation-z4 p-24 mr-24"
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="grow"
        fxFlex="90"
        name="form"
        [formGroup]="form"
        fxFlexFill
        (ngSubmit)="updateSettings()"
      >
        <h1>Payment Settings</h1>
        <mat-divider style="display: inline-block;" fxFlex="100" style="padding-bottom: 20px;"></mat-divider>
        <!-- <div class="h4">Default settings for your organization, this will apply immediately</div> -->

        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <h2>Default Currency</h2>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matNativeControl>
            <mat-label>Select Default Currency</mat-label>
            <mat-select (selectionChange)="changesCurrency()" formControlName="defaultCurrency" required>
              <mat-option [value]="option.code" *ngFor="let option of selectedCurrencyList">
                {{ option.text }}
              </mat-option>
            </mat-select>
            <mat-error>Default Date Format is required!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
         <h2>Supported Currency</h2>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <div style="width:100%;">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25">
              <mat-select
                (openedChange)="openedChange($event)"
                placeholder="Select Currencies"
                [formControl]="selectFormControl"
                multiple
              >
                <mat-select-trigger>
                  <span *ngIf="selectFormControl && selectFormControl.value != null">{{
                    selectFormControl.value
                  }}</span>
                  <!-- {{ selectFormControl.value ? selectFormControl.value[0] : "" }}
                  <span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
                    (+{{ selectFormControl.value.length - 1 }}
                    {{ selectFormControl.value?.length === 2 ? "other" : "others" }})
                  </span> -->
                </mat-select-trigger>
                <div class="select-container">
                  <mat-optgroup>
                    <mat-form-field style="width:100%;">
                      <input
                        #search
                        autocomplete="off"
                        placeholder="Search.."
                        aria-label="Search"
                        matInput
                        [formControl]="searchTextboxControl"
                      />
                      <button
                        [disableRipple]="true"
                        *ngIf="search.value"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="clearSearch($event)"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-optgroup>
                  <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
                    <div>No results found!</div>
                  </mat-optgroup>
                  <!-- <mat-optgroup *ngIf="selectFormControl && selectFormControl.value != null">
                    <button
                      [disableRipple]="true"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="clearSelection()"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-optgroup> -->
                  <mat-option
                    (onSelectionChange)="selectionChange($event)"
                    *ngFor="let option of filteredOptions | async"
                    [value]="option.code"
                  >
                    {{ option.text }}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
            <!-- <ng-multiselect-dropdown
              [settings]="dropdownSettings"
              [data]="dropdownList"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
              (onDeSelect)="onItemDeSelect($event)"
              (onDeSelectAll)="onDeSelectAll()"
              formControlName="currencies"
            >
            </ng-multiselect-dropdown> -->
          </div>
        </div>

        <!-- <div *ngIf="selectFormControl.value" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="1 0 auto">
          {{ "You have choosen " + selectFormControl.value }}
        </div> -->

        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <h2>Taxes</h2>
        </div>

        <h3>Sales tax</h3>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" fxLayoutGap="10px">
          <!-- <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Tax Label</mat-label>
            <input matInput formControlName="taxLabel" class="right-align" />
            <mat-error>Tax Label is required!</mat-error>
          </mat-form-field> -->
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Sales Tax(%)</mat-label>
            <input matInput formControlName="taxPercent" class="right-align" />
            <span matSuffix fxFlex="5">%</span>
            <mat-error>Tax is required!</mat-error>
          </mat-form-field>
        </div>
        <!-- <h3>Tax-2</h3>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Tax Label</mat-label>
            <input matInput formControlName="taxLabel2" class="right-align" />
            <mat-error>Tax Label is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Tax(%)</mat-label>
            <input matInput formControlName="taxPercent2" class="right-align" />
            <span matSuffix fxFlex="5">%</span>
            <mat-error>Tax is required!</mat-error>
          </mat-form-field>
        </div> -->
        <h2>Card Fees</h2>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <span style="margin-bottom: 5px" fxFlex="100"
            >The amount user is billed on top of the product or service value if paid by Credit Card.</span
          >
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Credit Card Fees(%)</mat-label>
            <input matInput formControlName="cardFees" class="right-align" />
            <span matSuffix fxFlex="5">%</span>
            <mat-error>Credit Card Fees is required!</mat-error>
          </mat-form-field>
        </div>

        <!-- <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <span class="title" style="margin-bottom: 5px" fxFlex="100"
            >The amount user is billed on top of the product or service value if paid by Bank Account.</span
          >
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Bank Fees(%)</mat-label>
            <input matInput formControlName="bankFees" class="right-align" />
            <span matSuffix fxFlex="5">%</span>
            <mat-error>Bank Fees is required!</mat-error>
          </mat-form-field>
        </div> -->

        <h2>Invoice Settings</h2>
        <h3>Invoice Note</h3>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span style="margin-bottom: 5px" fxFlex="100"
            >This info appears on the rights of Invoice e.g GSTN, EIN
          </span>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto">
          <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-xs="25"
            fxFlex.gt-sm="25"
            matInput
            style="margin-right: 10px;"
          >
            <mat-label>Label</mat-label>
            <input matInput formControlName="taxLabel1" class="right-align" />
            <!-- <span matSuffix fxFlex="5">%</span> -->
            <mat-error>Label is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="25" matInput>
            <mat-label>Value</mat-label>
            <input matInput formControlName="taxValue1" class="right-align" />
            <!-- <span matSuffix fxFlex="5">%</span> -->
            <mat-error>Value is required!</mat-error>
          </mat-form-field>
        </div>
        <h3>Invoice Note</h3>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 0 auto" style="margin-bottom: 10px">
          <mat-form-field fxFlex.gt-md="0 0 50" fxFlex>
            <textarea matInput
              (keydown)="preventEnter($event)"
              formControlName="invoiceNote"
              #invoiceNote 
              placeholder="Your Note"
              cdkTextareaAutosize
              maxlength="255"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10">
            </textarea>
            <mat-hint align="end"><strong>{{invoiceNote.value.length}} / 255</strong></mat-hint>
          </mat-form-field>
        </div>      
        <div fxLayout="row wrap" fxLayoutAlign="end" fxFlex="1 0 auto">
          <button mat-stroked-button [routerLink]="['/settings']" class="mr-4" color="accent">Cancel</button>
          <button type="submit" mat-raised-button color="accent">
            Submit
            <mat-icon *ngIf="issubmitting">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
