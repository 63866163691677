import { Component, OnInit } from '@angular/core';
import { NoticeService } from './notice.service';
import { SubSink } from "subsink";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "app/main/shared/components/confirmation-dialog/confirmation-dialog.component";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {
  basicInfoForm: FormGroup;
  isAddNotice = false;
  isEditNotice = false;
  message = '';
  isActive: boolean;
  type = 'notice';
  notices = [];
  selectedNotice: any;
  isLoading: boolean;
  dialogRef: any;
  subs = new SubSink();
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  public Editor;
  constructor(private noticeService: NoticeService, 
    public _matDialog: MatDialog,
    public toastr: ToastrService,private _formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    // const ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
    // this.Editor = ClassicEditor;
    this.getNotices();
    this.basicInfoForm = this._formBuilder.group({
      message: ["", Validators.required]
    });
  }

  getNotices() {
    this.isLoading = true;
    this.noticeService.getNotices(false).subscribe(
      (noticesResponse: any) => {
         this.notices = noticesResponse.data;
        this.isLoading = false;
      }
    );
  }

  addNotice() {
    const payload = {
      message: this.message,
      isActive: this.isActive,
      type: this.type,
      expiryDate: new Date().getTime()
    }

    this.noticeService.createNotice(payload).subscribe(
      (createNoticeResponse: any) => {
         this.message = '';
        this.isActive = false;
        this.type = 'notice';
        this.isAddNotice = false;
        this.getNotices();
      }
    )
  }

  edit(notice) {
    console.log("notice", notice)
    this.isEditNotice=true;
    this.selectedNotice={...notice};
    if (!this.selectedNotice?.type) {
      this.selectedNotice.type = 'notice';
    }
    this.basicInfoForm.get("message").setValue(notice.message);

   }

  showAddNotice(){
    this.isAddNotice = true;
    this.isActive = true;
  }
  editNotice() {
    this.selectedNotice.message = this.basicInfoForm.get("message").value;
     this.noticeService.updateNotice(this.selectedNotice).subscribe(
      (createNoticeResponse: any) => {
         this.isEditNotice = false;
        this.selectedNotice = null;
        this.getNotices();
      }
    )
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  delete(notice) {
     this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      panelClass: "delete-course-dialog",
      disableClose: true
    });
    this.confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";

    this.subs.add(
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) { 
            this.noticeService.delete(notice._id).subscribe(
              (result: any) => {
                this.getNotices(); 
                this.toastr.success("Notice or Quote deleted successfullly"); 
 
              },
              (error: any) => {
                 this.toastr.error(error.error.message);
              }
            ) 
        } 
        this.confirmDialogRef = null;
      })
    );


     
  }

}
