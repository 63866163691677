<ngx-skeleton-loader
  *ngIf="isLoading"
  count="4"
  [theme]="{
    border: '5px solid #FFF',
    background: '#F5F5F5'
  }"
></ngx-skeleton-loader>
<div *ngIf="!isLoading" id="forms" class="page-layout carded fullwidth" fxLayout="column">
  <div class="new-header">
    <div style="float: left; display: flex;  align-items: center; justify-content: center;">
      <mat-icon class="logo-icon s-32 " style="margin-right: 10px;">
        search
      </mat-icon>
      <span class="logo-text h1"> SEO Settings </span>
      <div style="font-size: 14px; margin-left: 10px;margin-top: 3px;">
        Improve Google search engine results.
      </div>
    </div>
    <div style="float: right;">
      <button mat-icon-button [routerLink]="[back]" matTooltip="Back " aria-label="Back">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <div fxFlex="1 0 auto" fxLayout="column" style="width:100%;">
    <form class="m-16" fxLayout="column" [formGroup]="seoForm">
      <div fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control secondary-text" fxFlex="100">
            SEO keywords (also known as “keywords” or “keyphrases”) are terms added in order to improve search engine
            rankings for those terms. Feel free to add single word phrases e.g wellness-training or long tail keywords
            e.g wellness training in bayarea. These keywords will applied to your generated content
          </span>
        </div>
        <div fxLayout="row wrap" class="mt-8" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>SEO Keywords</mat-label>
            <textarea matInput type="text" formControlName="seoKeywords" maxlength="255"> </textarea>
            <!-- <input matInput formControlName="seoKeywords" class="right-align"> -->
            <!-- <mat-error *ngIf="f.seoKeywords.errors && f.seoKeywords.errors.required">SEO Keywords is required!</mat-error>
                        <mat-error *ngIf="f.seoKeywords.errors && f.seoKeywords.errors.maxlength">SEO Keywords must be less than 255 character.</mat-error> -->
          </mat-form-field>
        </div>

        <!-- <div class="h2">SEO Description</div> -->

        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control secondary-text" fxFlex="100">
            SEO Description is a blurb of text that provides a brief description of a webpage. These Description will
            applied to your generated content
          </span>
        </div>
        <div fxLayout="row wrap" class="mt-8" fxLayoutAlign="center" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="100" matInput>
            <mat-label>SEO Description</mat-label>
            <textarea matInput type="text" formControlName="seoDescription" maxlength="255"> </textarea>
            <!-- <input matInput formControlName="seoDescription" class="right-align"> -->
            <!-- <mat-error *ngIf="f.seoDescription.errors && f.seoDescription.errors.required">SEO Description is required!</mat-error>
                        <mat-error *ngIf="f.seoDescription.errors && f.seoDescription.errors.maxlength">SEO Description must be less than 255 character.</mat-error> -->
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="1 0 auto">
          <span class="custom-control secondary-text" fxFlex="100">
            API Key
          </span>
        </div>

        <div fxLayout="row wrap" class="mt-8" fxLayoutAlign="start" fxFlex="1 0 auto">
          <mat-form-field appearance="outline" fxFlex="50" matInput>
            <mat-label>API Key</mat-label>
            <input matInput formControlName="apiKey" />

            <mat-icon
              matSuffix
              class="secondary-text"
              (click)="generateKey()"
              matTooltip="Generate Key "
              aria-label="Generate Key"
              color="accent"
              >refresh</mat-icon
            >

            <!-- <mat-error *ngIf="f.seoDescription.errors && f.seoDescription.errors.required">SEO Description is required!</mat-error>
                            <mat-error *ngIf="f.seoDescription.errors && f.seoDescription.errors.maxlength">SEO Description must be less than 255 character.</mat-error> -->
          </mat-form-field>
          <!-- <div>
            <button mat-mini-fab (click)="generateKey()" matTooltip="Generate Key " aria-label="Generate Key">
              <mat-icon>refresh</mat-icon>
            </button>
          </div> -->
        </div>
      </div>
    </form>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="end" class="m-16">
    <button mat-stroked-button [routerLink]="[back]" class="mr-4" color="accent">Cancel</button>
    <button type="submit" mat-raised-button color="accent" (click)="submitForm()">
      Submit
      <mat-icon *ngIf="isLoadingBtn">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>
