import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { AppConstants } from "app/core/settings/appconstants";
import { AppSettingsService } from "../services/app-settings.service";
import { SubSink } from "subsink";
import { MailchimpService } from "../integration-settings/mailchimp/mailchimp.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-integrations-setting",
  templateUrl: "./integrations-setting.component.html",
  styleUrls: ["./integrations-setting.component.scss"],
  animations: fuseAnimations
})
export class IntegrationsSettingComponent {
  isPspringIntegrated: boolean = false;
  isStripeIntegrated: boolean = false;
  isPaytmIntegrated: boolean = false;
  isRazorpayIntegrated: boolean = false;
  isPhonepeIntegrated: boolean = false;
  isOnlineMeetingIntegrated: boolean = false;
  isMailchimpIntegrated: boolean = false;
  isTwilioIntegrated: boolean = false;
  isPlivoIntegrated: boolean = false;
  isBrevoIntegrated: boolean = false;

  tenant: any;
  twilioTenant: any;
  _accountDetail: any;
  subs = new SubSink();

  integrationLists: any[] = [
    {
      title: "Payments",
      description: "Payment Provider",
      url: "paymentprovider",
      color: "cyan"
    },
    {
      title: "Twilio",
      description:
        "Enables to you send and recieve SMS messages and notifications right your phone. Get started by registering a phone number on Twillio",
      url: "cloud",
      color: "green"
    },
    {
      title: "Mailchimp",
      description:
        "The integration enables two-way sync between Wajooba and Mailchimp You can send Mailchimp campaigns right within Wajooba, checkout Marketing->Campaigns.",
      url: "mailchimp",
      color: "yellow"
    },
    {
      title: "Zoom Meeting",
      description:
        "Zoom Integration enables you to conduct online training. Members can attend meetings after registration.",
      url: "zoom",
      color: "indigo"
    }
  ];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private mailchimpService: MailchimpService,
    private _settingsService: AppSettingsService
  ) {}

  ngOnInit(): void {
    this.getTenantInfo();
  }
  navigateTo(url) {
    this._router.navigate([AppConstants.INTEGRATIONS, url]);
  }
  navigateToOauth(url) {
    this._router.navigate(["oauth", url]);
  }

  getTenantInfo() {
    const self = this;
    self._settingsService.getTenantDetail().subscribe((result: any) => {
      self.tenant = result.data;
      this.setPaymentProvider();
    });

    this.subs.add(
      this._settingsService.getAccountDetail().subscribe(data => {
        this._accountDetail = { ...data };
        const validEmailProviders = ['zoom', 'googlemeet'];

        data.oauthProviderList.forEach(item => {
          if (validEmailProviders.includes(item.productName)) {
            this.isOnlineMeetingIntegrated = true;
          }
        });
        debugger;

      })
    );

    // this.subs.add(
    //   this.mailchimpService.getMailchimpData().subscribe(result => {
    //     this.isMailchimpIntegrated = result.listId ? true : false;
    //   })
    // );

    this.subs.add(
      this._settingsService.getCampaignDetail().subscribe((result: any) => {
        this.isMailchimpIntegrated = result.tenant.provider == "mailchimp" ? true : false;
        this.isBrevoIntegrated = result.tenant.provider == "brevo" ? true : false;
      })
    );

    this.subs.add(
      this._settingsService.getSMSTenantDetail().subscribe(
        (result: any) => {
          this.twilioTenant = result.tenant;
          this.isTwilioIntegrated = this.twilioTenant.provider == "twilio" ? true : false;
          this.isPlivoIntegrated = this.twilioTenant.provider == "plivo" ? true : false;
        },
        error => {}
      )
    );
  }

  provider(param) {
    this._router.navigate(["/" + AppConstants.INTEGRATIONS + "/paymentprovider/"], {
      relativeTo: this.route,
      queryParams: { provider: param }
    });
  }

  setPaymentProvider() {
    if (this.tenant.paymentKeys && this.tenant.paymentKeys.length > 0) {
      this.tenant.paymentKeys.forEach((item, index) => {
        let paymentProvider = item.provider;
        if (paymentProvider == AppConstants.PAYMENT_TYPE.PAYTM_PAYMENT.toLowerCase() && item.isProviderIntegrated) {
          this.isPaytmIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.PSPRING_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isPspringIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.RAZORPAY_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isRazorpayIntegrated = true;
        } else if (
          paymentProvider == AppConstants.PAYMENT_TYPE.STRIPE_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ) {
          this.isStripeIntegrated = true;
        } else if (
          paymentProvider = AppConstants.PAYMENT_TYPE.PHONEPE_PAYMENT.toLowerCase() &&
          item.isProviderIntegrated
        ){
          this.isPhonepeIntegrated = true;
        }
      });
    }
  }
}
