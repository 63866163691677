import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppSettingsService } from "app/main/admin/app-settings/services/app-settings.service";
import { SubSink } from "subsink";
import { PlanService } from "./../plan.service";
import { ToastrService } from "ngx-toastr";
import { AppConstants } from "app/core/settings/appconstants";
import { ItemCheckoutService } from "app/main/public/checkout/checkout.service";
import { CheckoutUtilService } from "app/main/public/checkout/checkout.util.service";
declare var Stripe: any;
import { TenantUserService } from "app/core/services/tenantuser.service";
import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-upgrade",
  templateUrl: "./upgrade.component.html",
  styleUrls: ["./upgrade.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UpgradeComponent implements OnInit {
  subs = new SubSink();
  _tenantConfig: any;
  subscription: any = {};
  isPlanTypeYearly: boolean = false;
  allAvailablePlan: any = [];
  currentPlan: any = {};
  currentPlanName: any;
  pspringType: any;
  paymentProvider: any;
  paymentInfo: any;
  isPlanSelected: boolean = false;
  isLoadingCheckout: boolean = false;
  cardForm: FormGroup;
  bankForm: FormGroup;
  methodId: any;
  zipcode: any;
  isSaveCard: boolean = false;
  years: any = [];
  months: any = [];
  types: any = [];
  braintreeType: any;
  instamojoType: any;
  paymentspringType: any;
  stripeType: any;
  paytmType: any;
  creditCardOptions: any;
  activeCard: any = "other";
  cardPayment = true;
  bankPayment = false;
  availableCards = [];
  paymentSpringError: any = "";

  stripe;
  loading = false;
  confirmation;
  user: any;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  @ViewChild("cardInfo", { static: false }) cardInfo: ElementRef;
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private planService: PlanService,
    private toastr: ToastrService,
    public appSettingsService: AppSettingsService,
    private checkoutService: ItemCheckoutService,
    private _formBuilder: FormBuilder,
    private checkoutUtilService: CheckoutUtilService,
    private cd: ChangeDetectorRef,
    private _tenantUserService: TenantUserService,
    public _matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.creditCardOptions = {
      creditCard: {
        creditCard: true
      }
    };
    this.braintreeType = AppConstants.PAYMENT_TYPE.BRAINTREE_PAYMENT;
    this.instamojoType = AppConstants.PAYMENT_TYPE.INSTAMOJO_PAYMENT;
    this.paymentspringType = AppConstants.PAYMENT_TYPE.PSPRING_PAYMENT;
    this.stripeType = AppConstants.PAYMENT_TYPE.STRIPE_PAYMENT;
    this.paytmType = AppConstants.PAYMENT_TYPE.PAYTM_PAYMENT;
    this.paymentProvider = this.stripeType;

    this.years = this.checkoutService.getYears();
    this.months = this.checkoutService.getMonths();
    this.types = this.checkoutService.getbankAccountTypes();
    this.initForm();
    this.subs.add(
      this._tenantUserService.user.subscribe((user: any) => {
        this.user = user;
      })
    );
    this.subs.add(
      this.appSettingsService.getAccountDetail().subscribe(data => {
        this._tenantConfig = data;
      })
    );
    this.getPlan();
    this.getTenantConfig();
  }

  getAvailableCards() {
    if (this.subscription.savedCards) {
      this.availableCards = this.subscription.savedCards;
      this.availableCards.push({
        methodId: "other",
        prefCard: "None"
      });
      if (this.availableCards.length > 0) {
        this.activeCard = this.availableCards[0].methodId;
      } else {
        this.activeCard = "other";
      }
    }
  }

  initForm() {
    this.cardForm = this._formBuilder.group({
      cardOwnerName: [""],
      cardNumber: [""],
      expMonth: ["", Validators.required],
      expYear: [""],
      cvv: [""],
      zip: [""],
      storeCard: []
    });

    this.bankForm = this._formBuilder.group({
      bankFirstName: [""],
      bankLastName: [""],
      bankAccountNumber: [""],
      bankRoutingNumber: [""],
      bankAccountType: ["checking", Validators.required]
    });
  }

  onStripeTabChanged(event) {}

  getTenantConfig() {
    this.subs.add(
      this.appSettingsService.getAccountDetail().subscribe(data => {
        this._tenantConfig = data;
        //this.paymentProvider = this._tenantConfig.paymentProvider;
        this.getPaymentsCheckoutDetails();
      })
    );
  }

  getPaymentsCheckoutDetails() {
    this.subs.add(
      this.planService.getMasterToken().subscribe(result => {
        this.paymentInfo = result;
      })
    );
  }

  upgrade() {
    this.router.navigate(["/settings/plan/upgrade"], {
      relativeTo: this.route
    });
  }

  getPlanDetails = function() {
    return !this.isPlanTypeYearly
      ? [
          {
            name: AppConstants.PlanNameDesc.FREE,
            price: AppConstants.PlanMonthPrice.FREE,
            period: AppConstants.PlanPeriod.MONTH,
            planName: AppConstants.InternalPlanNameMonthly.FOREVER_FREE_PLAN,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.ORGANIZE,
            price: AppConstants.PlanMonthPrice.ORGANIZE,
            period: AppConstants.PlanPeriod.MONTHLY,
            planName: AppConstants.InternalPlanNameMonthly.ORGANIZE_PLAN_MONTHLY,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.EXPAND,
            price: AppConstants.PlanMonthPrice.EXPAND,
            period: AppConstants.PlanPeriod.MONTHLY,
            planName: AppConstants.InternalPlanNameMonthly.EXPAND_PLAN_MONTHLY,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.LEAP,
            price: AppConstants.PlanMonthPrice.LEAP,
            period: AppConstants.PlanPeriod.MONTHLY,
            planName: AppConstants.InternalPlanNameMonthly.LEAP_PLAN_MONTHLY,
            isCurrentPlan: false
          }
        ]
      : [
          {
            name: AppConstants.PlanNameDesc.FREE,
            price: AppConstants.PlanMonthPrice.FREE,
            period: AppConstants.PlanPeriod.YEAR,
            planName: AppConstants.InternalPlanNameMonthly.FOREVER_FREE_PLAN,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.ORGANIZE,
            price: AppConstants.PlanYearPrice.ORGANIZE,
            period: AppConstants.PlanPeriod.YEAR,
            planName: AppConstants.InternalPlanNameYearly.ORGANIZE_PLAN_YEARLY,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.EXPAND,
            price: AppConstants.PlanYearPrice.EXPAND,
            period: AppConstants.PlanPeriod.YEAR,
            planName: AppConstants.InternalPlanNameYearly.EXPAND_PLAN_YEARLY,
            isCurrentPlan: false
          },
          {
            name: AppConstants.PlanNameDesc.LEAP,
            price: AppConstants.PlanYearPrice.LEAP,
            planName: AppConstants.InternalPlanNameYearly.LEAP_PLAN_YEARLY,
            isCurrentPlan: false
          }
        ];
  };

  loadStripe() {
    /*var stripePublishableKey = this._tenantConfig.stripePublishableKey;
    var stripeAuthCode = this._tenantConfig.stripeAuthCode;
    if (stripeAuthCode) {
      this.stripe = Stripe(stripePublishableKey, {
        stripeAccount: stripeAuthCode
      }); 
    }*/
    this.stripe = Stripe(this.paymentInfo.stripePublishableKey);
    const elements = this.stripe.elements();
    this.card = elements.create("card");
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  planTypeChange() {
    this.allAvailablePlan = this.getPlanDetails();
    this.setCurrentPlanFlag();
  }
  changePlan(plan) {
    setTimeout(() => this.loadStripe(), 1000);

    this.currentPlan = plan;
    this.isPlanSelected = true;
    this.currentPlanName = this.currentPlan.planName.replace(/_/g, " ");
  }
  backToPlan() {
    this.isPlanSelected = false;
    this.currentPlanName = "";
  }
  freePlan(planObj) {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      panelClass: "delete-course-dialog",
      disableClose: true
    });

    this.confirmDialogRef.componentInstance.confirmMessage =
      "You miss the Valuable Features, Are you sure you want Free plan?";

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.currentPlan = planObj;
        this.currentPlanName = this.currentPlan.planName.replace(/_/g, " ");

        let payload: any = {
          planName: this.currentPlan.planName,
          payment: {
            nonce: "na",
            type: "na",
            amount: 0
          }
        };
        this.updatePlan(payload);
      } else {
      }
      this.confirmDialogRef = null;
    });
  }
  contactus() {
    window.open("https://wajooba-latest.onrender.com/contact/index.html");
  }
  getPlan() {
    this.allAvailablePlan = this.getPlanDetails();

    this.subs.add(
      this.planService.getPlan().subscribe(
        result => {
          this.subscription = result;
          this.getAvailableCards();
          this.setCurrentPlanFlag();
          this.subscription.planName =
            this.subscription.planName == null ? "Free Plan" : this.subscription.planName.replace(/_/g, " ");
        },
        error => {
          console.log(error);
          this.toastr.error(error.message);
        }
      )
    );
  }

  setCurrentPlanFlag() {
    if (this.subscription) {
      this.allAvailablePlan.forEach(plan => {
        if (this.subscription.planName == plan.planName) {
          plan.isCurrentPlan = true;
        }
      });
    }
  }

  processStripePayment(id) {
    this.pspringType = AppConstants.Payment.STRIPE_CARD;
    this.processPayment(this.currentPlan.planName, id, this.pspringType, "");
  }

  processPayment(plan, nonce, type, methodId) {
    let payload: any = {
      planName: plan,
      payment: {
        nonce: nonce,
        type: type,
        methodId: methodId
      }
    };
    this.updatePlan(payload);
  }

  updatePlan(payload) {
    this.subs.add(
      this.planService.updatePlan(payload).subscribe(
        result => {
          this.isLoadingCheckout = false;
          this.toastr.success("Plan updated");
          this.router.navigate(["/settings/plan"], {
            relativeTo: this.route
          });
        },
        error => {
          console.log(error);
          this.toastr.error(error.message);
          this.isLoadingCheckout = false;
        }
      )
    );
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  onSubmit(form: NgForm) {
    this.isLoadingCheckout = true;
    let cardObj = {
      type: "card",
      card: this.card,
      billing_details: {
        name: this.user.name
      }
    };

    this.stripe.createPaymentMethod(cardObj).then(result => {
      if (result.error) {
        this.toastr.error(result.error.message);
        this.isLoadingCheckout = false;
      } else {
        this.processPayment(this.currentPlan.planName, "na", AppConstants.Payment.STRIPE_CARD, result.paymentMethod.id);
      }
    });
  }

  cardPaymentChoosen() {
    if (!this.cardPayment) {
      this.cardPayment = true;
      this.bankPayment = false;
      this.cardForm.reset();
    }
  }

  bankPaymentChoosen() {
    if (!this.bankPayment) {
      this.bankPayment = true;
      this.cardPayment = false;
      this.bankForm.reset();

      let lastname = "";
      let firstname = "";
      this.bankForm.get("bankFirstName").setValue(firstname);
      this.bankForm.get("bankLastName").setValue(lastname);
      this.bankForm.get("bankAccountType").setValue("checking");
    }
  }

  onTabChanged(event) {
    this.paymentProvider = this._tenantConfig.paymentProvider;

    if (event.index == 0) {
      this.cardPaymentChoosen();
    } else if (event.index == 1) {
      this.bankPaymentChoosen();
    }
  }

  isValidate() {
    let isValid = false;
    if (this.cardPayment) {
      if (this.cardForm.valid) {
        isValid = true;
      }
    } else if (this.bankPayment) {
      if (this.bankForm.valid) {
        isValid = true;
      }
    }
    return isValid;
  }

  buildPaymentSpringData() {
    this.zipcode = "";
    let tokenType = "bank_account";
    let paymentData;
    if (this.cardPayment) {
      let cardInfo = this.cardForm.getRawValue();
      this.isSaveCard = cardInfo.storeCard;
      paymentData = this.checkoutUtilService.buildPSpringCardData(
        cardInfo.cardOwnerName,
        cardInfo.cardNumber,
        cardInfo.expMonth,
        cardInfo.expYear,
        cardInfo.cvv
      );
      if (cardInfo.zip) {
        paymentData.zip = cardInfo.zip;
        this.zipcode = paymentData.zip;
      }
      this.pspringType = AppConstants.Payment.PSPRING_CARD;
    } else {
      let bankInfo = this.bankForm.getRawValue();
      paymentData = this.checkoutUtilService.buildPSpringACHData(
        tokenType,
        bankInfo.bankAccountNumber,
        bankInfo.bankRoutingNumber,
        bankInfo.bankFirstName,
        bankInfo.bankLastName,
        bankInfo.bankAccountType
      );
      this.pspringType = AppConstants.Payment.PSPRING_ACH;
    }
    return paymentData;
  }

  processPSpringPayment(paymentData: any) {
    this.subs.add(
      this.checkoutService.initPSpringPayment(paymentData, this.paymentInfo.token).subscribe(response => {
        console.log("response", response);
        if (response.errors) {
          //Error
          var errorsObj = response.errors[0];
          this.paymentSpringError = "" + errorsObj.message;
          this.isLoadingCheckout = false;
          this.toastr.error(this.paymentSpringError);
          return;
        } else {
          this.paymentSpringError = "";
          this.processPayment(this.currentPlan.planName, response.id, this.pspringType, "");
        }
      })
    );
  }

  processPaymentSpring(isExistingCard) {
    this.isLoadingCheckout = true;
    if (isExistingCard) {
      this.methodId = this.activeCard;
      this.pspringType = AppConstants.Payment.PSPRING_CLIENT;
      this.processPayment(this.currentPlan.planName, "na", this.pspringType, this.methodId);
    } else {
      let paymentData = this.buildPaymentSpringData();
      this.processPSpringPayment(paymentData);
    }
  }
  completeOrder() {
    if (this.paymentProvider == this.paymentspringType) {
      if (this.activeCard === "other") {
        if (this.isValidate()) {
          this.processPaymentSpring(false);
        }
      } else {
        this.processPaymentSpring(true);
      }
    } else if (this.paymentProvider == this.stripeType) {
      this.onSubmit(null);
    }
  }
}
