<ng-container *ngIf="!item.hidden && !item?.forms">
  <!-- item.url -->

  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && !item.function"
    [routerLink]="[item.url]"
    [routerLinkActive]="['active', 'accent_new']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && !item.function"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="item.function()">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <!-- item.url && item.function -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && item.function"
    (click)="item.function()"
    [routerLink]="[item.url]"
    [routerLinkActive]="['active', 'accent_new']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && item.function"
    (click)="item.function()"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
<!--    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>-->
    <span class="nav-link-title" (click)="closeNav()" >{{ item.title | titlecase }}</span>
    <span
      class="nav-link-badge"
      *ngIf="item.badge"
      [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
    >
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>

<ng-container *ngIf="item.forms">
  <div style="display: flex;height:100%">
    <a
      class="nav-link"
      style="height:100%"
      [routerLink]="[item.url + form.guId]"
      *ngFor="let form of item.data"
      [routerLinkActive]="['active', 'accent_new']"
      [routerLinkActiveOptions]="{ exact: true }"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <span class="nav-link-title">{{ form.name }}</span>
    </a>
  </div>
</ng-container>
<!-- <ng-container *ngIf="item?.forms">
    <a class="nav-link" (click)="route(form)" style="display: inline-block;" *ngFor="let form of item.data"
        [routerLinkActive]="['active', 'accent_new']" [routerLinkActiveOptions]="true" target="_self">
        <span class="nav-link-title">{{form.name}}</span>
    </a> -->

<!-- <ng-template #formContent>
        <span class="nav-link-title">{{form.name}}</span>
    </ng-template> -->
<!-- </ng-container> -->
