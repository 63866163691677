import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { SubSink } from "subsink";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-mailchimp-list-info-dialog',
  templateUrl: './mailchimp-list-info-dialog.component.html',
  styleUrls: ['./mailchimp-list-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MailchimpListInfoDialogComponent implements OnInit {

  tagGuId: string;
  isLoading: boolean = false;
  isLoadAddTag: boolean = false;
  isUpdateLoading: boolean = false;
  subs = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toastr: ToastrService,
   
    private dialogRef: MatDialogRef<MailchimpListInfoDialogComponent>
  ) {}

  ngOnInit(): void {}

}



