<div class="page-layout carded fullwidth" fxLayout="column" fxLayoutAlign="start" *ngIf="_tenantConfig != undefined">
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto">
    <div class="mt-5" style="font-size: 16px; font-weight: bold">
      {{ title }}
    </div>
  </div>
  <div class="col-sm-2 img-wrapper" fxFlex="50">
    <span *ngIf="prePhoto" (click)="close()" class="close"><mat-icon>close</mat-icon></span>
    <img [src]="prePhoto" alt="" class="p-1" />
  </div>
  
  <div fxLayout="row wrap" fxLayoutAlign="start" fxFlex="1 1 auto" *ngIf="description != ''">
    <span class="custom-control" [ngClass]="{ courseSubTitleClass: isFontApply }" fxFlex="100">{{ description }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" *ngIf="photos.length > 0 || videos.length > 0">
    <div class="col-sm-2 img-wrapper" fxFlex="50" *ngFor="let photo of photos">
      <span *ngIf="photo.url" (click)="close()" class="close"><mat-icon>close</mat-icon></span>
      <div><img src="{{ getCloudinaryImageForLogo(photo.url) }}" alt="" class="p-1" /></div>
    </div>
    <div class="col-sm-2 img-wrapper" fxFlex="20" *ngFor="let video of videos">
      <video width="270" height="220" controls disabled="true" (click)="toggleVideo()" #videoPlayer>
        <source [src]="video.url" type="video/mp4" />
      </video>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto" style="padding-top: 5px">
    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (change)="uploadFile()" hidden />
    <button mat-stroked-button color="accent" class="mt-5 mb-16" (click)="fileInput.click()" type="button">
      {{ buttonName }}
    </button>
    <!-- <mat-form-field style="width: 100%;" appearance="outline" matInput>
            <mat-toolbar>
                <span matNativeControl>{{title}}</span>
                <input matInput hidden readonly />
            </mat-toolbar>
            <input type="file" ng2FileSelect [uploader]="uploader" />
        </mat-form-field>       -->
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 0 auto">
    <mat-progress-bar
      *ngIf="uploader.progress > 0"
      class="mb-12"
      [color]="'accent'"
      [mode]="'determinate'"
      [value]="uploader.progress"
      [bufferValue]="100"
    >
    </mat-progress-bar>
  </div>
</div>
