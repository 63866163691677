import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
// import { AppLocalStorage } from "../models/localstorage";

// import { isPlatformBrowser } from "@angular/common";

// angular storage service which works both in localstorage and ssr mode
// ref:https://santibarbat.medium.com/how-to-use-localstorage-with-angular-9-universal-ssr-517578615637
@Injectable({
  providedIn: "root"
})
export class LocalstorageService implements Storage {
  length: number;

  [name: string]: any;

  private storage: Storage;
  // Store and Cart Page related
  private cartItems = [];
  private storeSelectedUser;
  private offerCode;
  private courseId;

  constructor() {
    // this.storage = new AppLocalStorage();
    // for now we we are assuming storage as localStorage
    // in future if we plan SSR then this code has to be changed as per link above
    // if (isPlatformBrowser(platformId)) {
    this.storage = localStorage;
    // }
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }

  // Store and Cart Page related
  setCartItems(cartItem: any): void {
    this.cartItems.push(cartItem);
  }

  getCartItems(): any {
    return this.cartItems;
  }

  removeCartItem(index): any {
    const cartItems = [...this.cartItems];
    cartItems.splice(index, 1);
    this.cartItems = cartItems;
  }

  setStoreUser(user: any): void {
    this.storeSelectedUser = user;
  }

  getStoreUser(): any {
    return this.storeSelectedUser;
  }

  setOfferCode(offerCode: String): void {
    this.offerCode = offerCode;
  }

  getOfferCode() {
    return this.offerCode;
  }

  setCourseId(courseId) {
    this.courseId = courseId;
  }

  getCourseId() {
    return this.courseId;
  }
}
