import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewLayout } from 'app/main/admin/services/service-list/service-list.component';
import { AddContactDlgComponent } from 'app/main/shared/components/add-contact-dlg/add-contact-dlg.component';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { AppSettingsService } from '../../services/app-settings.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'app/main/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-franchise-list',
  templateUrl: './franchise-list.component.html',
  styleUrls: ['./franchise-list.component.scss']
})
export class FranchiseListComponent implements OnInit, OnDestroy {

  franchises: any;
  franchiseData:any;
  filterText: '';
  tableSearch= new FormControl('');
  franchiseLength: number;
  isLoading: boolean = false;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns = ["orgId", "dateCreatedStr", "action"];
  pageEvent: PageEvent;
  totalData: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  dialogRef: any;
  subs = new SubSink();

  constructor(
    private _settingsService: AppSettingsService,
    public _matDialog: MatDialog,
    public toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadFranchise();
    this.dataSource.paginator = this.paginator;
    this.subs.add(
      this.tableSearch.valueChanges
        .pipe(debounceTime(150), distinctUntilChanged())
        .subscribe((val) => {
          if (!this.dataSource) {
            return;
          }
          this.dataSource.filter = val.trim().toLowerCase();
        })
    );
  }

  loadFranchise() {
    this._settingsService.getFranchises()
    .subscribe((response: any) => {
      this.isLoading = false;
      this.dataSource = new MatTableDataSource<any>(response.data);
      this.totalData = this.dataSource?.filteredData?.length ?? response.length;
      this.dataSource.paginator = this.paginator;
      this.franchiseData = this.dataSource.connect();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  deleteFranchise(franchiseId) {
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: "dlg-contct-div",
      disableClose: true,
      height: "205px",
      width: "450px",
      data: {
        dialogTitle: "Delete Franchise!",
        content: "Are you sure you want to continue?"
      },
    });
    this.subs.add(
      this.dialogRef.afterClosed().subscribe((response: boolean) => {
        if (!response) {
          return;
        }
        this.subs.add(
          this._settingsService.deleteFranchise(franchiseId).subscribe(
            (res: any) => {
              this.toastr.success("Successfully deleted franchise");
              if (res) {
                this.loadFranchise();
              }
            },
            (error: any) => {
              this.toastr.error(error.error.message);
            }
          )
        );
      })
    );
  }

}
