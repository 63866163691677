export class Contact {
  name: string;
  email: string;
  phone: number;
  dateCreated: string;
  dateUpdated: string;
  guId: string;
  picture: string;
  claim: string;
  balance: any;
  balanceType: "+" | "-";
  mailchimpInfo: "subscribed" | "unsubscribed";
  isParent: boolean;
  lastActiveMembershipName: any;
  lastActiveMembershipExpiresOn: any;
  lastActiveMembershipJustPurchased: any;
  leadsource: any;
  mcStatus: any;
  isUnread: any;
  isLead: any;
}
