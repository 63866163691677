<div class="title-header" style="display: block">
  <span class="h2" *ngIf="!isPageHeaderEdit" style="font-weight: normal; color: rgba(0, 0, 0, 0.87)">{{ isLoggedIn ? header == '' ? 'Hero text here' : header: header }}</span>
  <mat-icon
    matChipTrailingIcon
    class="ml-a br-blue pointer"
    *ngIf="!isPageHeaderEdit && isLoggedIn && !isPreviewMode"
    (click)="isPageHeaderEdit = true"
    style="font-size: 16px; line-height: 24px; color: #00afbf;  margin-left: 7px"
    >mode_edit
  </mat-icon>
  <mat-form-field class="external-url" *ngIf="isPageHeaderEdit" style="padding: 10px; padding-bottom: 0; width: 80%">
    <mat-label>Hero Header</mat-label>
    <input matInput class="riuctght-align" type="text" [(ngModel)]="header" (keydown.enter)="editInternalWebsitePageDetail(); isPageHeaderEdit = false" />
    <mat-icon matSuffix class="pointer" (click)="editInternalWebsitePageDetail(); isPageHeaderEdit = false">done </mat-icon>
    <mat-icon matSuffix class="pointer" (click)="isPageHeaderEdit = false">cancel</mat-icon>
  </mat-form-field>
</div>
<div class="title-header" style="margin-top: 12px; margin-bottom: 5px; width: 100%; display: block; white-space: break-spaces;">
  <span class="h3" *ngIf="!isPageSubHeaderEdit" style="color: rgba(0, 0, 0, 0.87)">{{ isLoggedIn ? subHeader == '' ? 'Add Description' : subHeader : subHeader  }}</span>
  <mat-icon
    matChipTrailingIcon
    class="ml-a br-blue pointer"
    *ngIf="!isPageSubHeaderEdit && isLoggedIn && !isPreviewMode"
    (click)="isPageSubHeaderEdit = true"
    style="font-size: 16px; line-height: 24px; color: #00afbf; margin-left: 7px"
    >mode_edit
  </mat-icon>
  <mat-form-field class="external-url" *ngIf="isPageSubHeaderEdit" style="padding: 10px; padding-bottom: 0; width: 80%">
    <mat-label>Description</mat-label>
    <textarea matInput class="riuctght-align" type="text" [(ngModel)]="subHeader" rows="3"></textarea>
    <mat-icon matSuffix class="pointer" (click)="editInternalWebsitePageDetail(); isPageSubHeaderEdit = false">done </mat-icon>
    <mat-icon matSuffix class="pointer" (click)="isPageSubHeaderEdit = false">cancel</mat-icon>
  </mat-form-field>
</div>
