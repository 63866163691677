import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountDetail } from '../models/account-details';
import { WindowRef } from 'app/core/services/winref.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppLoadService } from 'app/core/services/appload.service';
import { LocalstorageService } from 'app/core/services/applocalstorage.service';
import { AppConstants } from 'app/core/settings/appconstants';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  static _cloudName: string = '';
  private _accountDetail: AccountDetail;
  private _detailSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient, private _appLoadService: AppLoadService, private _localStorageService: LocalstorageService) {
    this._detailSubject = new BehaviorSubject(this._accountDetail);
  }

  getAccountDetail() {
    let url = `${environment.apiBaseUrl}/snode/tenant`;
    return this.http.get(url).pipe(
      map(data => {
        this.populateAccountDetail(data);
        return this._accountDetail;
      }),
    );
  }

  get accountDetails(){
    return this._detailSubject.value;
  }

  populateAccountDetail(data: AccountDetail) {
    this._accountDetail = {
      accountOwnerName: data.accountOwnerName,
      companyUrl: data.companyUrl,
      // calendarVisibility: data.calendarVisibility,
      // isDonationPublicCheckout: data.isDonationPublicCheckout,
      aboutOrg: data.aboutOrg,
      accountOwner: data.accountOwner,
      address: data.address,
      appLabel1: data.appLabel1,
      appLabel2: data.appLabel2,
      appLabel3: data.appLabel3,
      appLabel4: data.appLabel4,
      appPrimaryColor: data.appPrimaryColor,
      appWelcomeImage1: data.appWelcomeImage1,
      appWelcomeImage2: data.appWelcomeImage2,
      appWelcomeImage3: data.appWelcomeImage3,
      bankFees: data.bankFees,
      bigLogo: data.bigLogo,
      buyButtonLabel: data.buyButtonLabel,
      businessReferer: data.businessReferer,
      businessSize: data.businessSize,
      businessType: data.businessType,
      cardFees: data.cardFees,
      clanMeetingDomain: data.clanMeetingDomain,
      clanMeetingSecret: data.clanMeetingSecret,
      cloudinaryCloudName: data.cloudinaryCloudName,
      cloudinaryPreset: data.cloudinaryPreset,
      cloudName: data.cloudName,
      csymbol: data.csymbol,
      customDomain: data.customDomain,
      dateFormat: data.dateFormat,
      defaultClassName: data.defaultClassName,
      defaultMaxAttendees: data.defaultMaxAttendees,
      email: data.email,
      environmentName: data.environmentName,
      fromEmail: data.fromEmail,
      fromEmailName: data.fromEmailName,
      instamojo: data.instamojo,
      introMembershipPeriod: data.introMembershipPeriod,
      isAddressNeededForEvent: data.isAddressNeededForEvent,
      isAllowCreateCourses: data.isAllowCreateCourses,
      isAllowValidAttendeesOnly: data.isAllowValidAttendeesOnly,
      isAssetDownloadable: data.isAssetDownloadable,
      isAuthValid: data.isAuthValid,
      isEnableCourses: data.isEnableCourses,
      isEnableOnlineCourses: data.isEnableOnlineCourses,
      isExternalWebsite: data.isExternalWebsite,
      isMasterFranchise: data.isMasterFranchise,
      isNewClient: data.isNewClient,
      isNewTenant: data.isNewTenant,
      isOtpAllowed: data.isOtpAllowed,
      isRegAndPurchaseOnSamePage: data.isRegAndPurchaseOnSamePage,
      isSendReminder: data.isSendReminder,
      isSendSmsReminder: data.isSendSmsReminder,
      isShowCalenderView: data.isShowCalenderView,
      isShowCampaignsMenu: data.isShowCampaignsMenu,
      isShowAssessment: data.isShowAssessment,
      isShowCourses: data.isShowCourses,
      isShowDonationMenu: data.isShowDonationMenu,
      isShowEvents: data.isShowEvents,
      isShowEventsMenu: data.isShowEventsMenu,
      isShowFormsMenu: data.isShowFormsMenu,
      isShowGuardian: data.isShowGuardian,
      isShowMerchandiseMenu: data.isShowMerchandiseMenu,
      isShowRegistrationLink: data.isShowRegistrationLink,
      isShowRoomName: data.isShowRoomName,
      isShowSchedule: data.isShowSchedule,
      isShowScheduleMenu: data.isShowScheduleMenu,
      isShowSidebar: data.isShowSidebar,
      isShowStoreMenu: data.isShowStoreMenu,
      isShowStudentSchedule: data.isShowStudentSchedule,
      isShowWorkshops: data.isShowWorkshops,
      isSupportGrn: data.isSupportGrn,
      isTestMode: data.isTestMode,
      isWaiverFormToBeSigned: data.isWaiverFormToBeSigned,
      itemExpiresAfter: data.itemExpiresAfter,
      logo: data.logo,
      logoUrl: data.logoUrl,
      masterOrgId: data.masterOrgId,
      name: data.name,
      oauthProviderList: data.oauthProviderList,
      orgId: data.orgId,
      pagelinks: data.pagelinks,
      paymentProvider: data.paymentProvider,
      paytm: data.paytm,
      phone: data.phone,
      plantype: data.plantype,
      primaryGuardianLabel: data.primaryGuardianLabel,
      privacyPolicyLink: data.privacyPolicyLink,
      promotionLabel: data.promotionLabel,
      pspringPrivateKey: data.pspringPrivateKey,
      pspringPrivateKeyTest: data.pspringPrivateKeyTest,
      pspringPublicKey: data.pspringPublicKey,
      pspringPublicKeyTest: data.pspringPublicKeyTest,
      registerButtonLabel: data.registerButtonLabel,
      registerSuccessMessage: data.registerSuccessMessage,
      registrationLinkName: data.registrationLinkName,
      reminderTime: data.reminderTime,
      restrictWeeklyCalendar: data.restrictWeeklyCalendar,
      salesEmailList: data.salesEmailList,
      scheduleLabel: data.scheduleLabel,
      secondaryGuardianLabel: data.secondaryGuardianLabel,
      seoDescription: data.seoDescription,
      seoKeywords: data.seoKeywords,
      smallLogo: data.smallLogo,
      stripeApiKey: data.stripeApiKey,
      stripeAuthCode: data.stripeAuthCode,
      stripeClientId: data.stripeClientId,
      stripePublishableKey: data.stripePublishableKey,
      stripeRedirectUrl: data.stripeRedirectUrl,
      subscriptionRegistrationFees: data.subscriptionRegistrationFees,
      tabLabelName: data.tabLabelName,
      tenantId: data.tenantId,
      taxPercent: data.taxPercent,
      tenantAuthViewCmd: data.tenantAuthViewCmd,
      tenantRoles: data.tenantRoles,
      termsOfServiceLink: data.termsOfServiceLink,
      timezone: data.timezone,
      version: data.version,
      waiverFormLink: data.waiverFormLink,
      website: data.website,
      workshopLabel: data.workshopLabel,
      zoomApi: data.zoomApi,
      zoomAuthCode: data.zoomAuthCode,
      zoomClientId: data.zoomClientId,
      zoomSecret: data.zoomSecret,
      org:data.org,
      onBoardingStep: data.onBoardingStep || 0,
    };
    this._detailSubject.next(this._accountDetail);
  }

  updateSettings(tenantDetail: AccountDetail) {
    return this.http.put(`${environment.apiBaseUrl}/snode/tenant`, tenantDetail);
  }

  getStaffUsers() {
    return this.http.get(`${environment.apiBaseUrl}/rest/contact?userType=staff`);
  }

  getCustomSettings() {
    return this.http.get(`${environment.apiBaseUrl}/rest/customField`);
  }

  getPluginTenant(guId) {
    return this.http.get(`${environment.apiBaseUrl}/plugin/tenant/${guId}`);
  }

  getPluginIntTenant() {
    return this.http.get(`${environment.apiBaseUrl}/plugin/tenant`);
  }

  getInternalWebsitePublicPage(pageName, guId) {
    return this.http.get(`${environment.apiBaseUrl}/stemplate/public/web/${guId}/${pageName}`);
  }
  getApiKey() {
    return this.http.post(`${environment.apiBaseUrl}/plugin/tenant/apikey`, {});
  }

  updatePluginTenant(payload) {
    return this.http.put(`${environment.apiBaseUrl}/plugin/tenant/`, payload);
  }

  getAppVersion() {
    return this.http.get(`${environment.apiBaseUrl}/plugin/app/version`);
  }

  getCustomSettingsById(customForm) {
    return this.http.get(`${environment.apiBaseUrl}/rest/customField?customForm=` + customForm);
  }

  addCustomField(customField: any) {
    let url = `${environment.apiBaseUrl}/rest/customField`;
    return this.http.post(url, customField);
  }

  updateCustomField(guId, customField: any) {
    let url = `${environment.apiBaseUrl}/rest/customField/${guId}`;
    return this.http.put(url, customField);
  }

  deleteCustomField(guId: any) {
    return this.http.delete(`${environment.apiBaseUrl}/rest/customField/${guId}`);
  }

  getCustomForms() {
    return this.http.get(`${environment.apiBaseUrl}/rest/customForm`);
  }

  getCustomFormDetails(guId: any) {
    return this.http.get(`${environment.apiBaseUrl}/rest/customForm/${guId}`).pipe(
      map((response: any) => {
        if (response.customFields && Array.isArray(response.customFields)) {
          response.customFields.sort((a: any, b: any) => a.sequence - b.sequence);
        }
        return response;
      }),
    );
  }

  getCustomFormDetailsByType(type: string): any {
    return this.http.get(`${environment.apiBaseUrl}/rest/customForm?type=${type}`);
  }

  updateCustomForm(payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/customForm/${payload.guId}`, payload);
  }

  saveCustomForm(payload) {
    return this.http.post(`${environment.apiBaseUrl}/rest/customForm`, payload);
  }

  updateTenant(tenantDetail) {
    return this.http.put(`${environment.apiBaseUrl}/snode/tenant`, tenantDetail);
  }

  registerForm(payload) {
    let url = `${environment.apiBaseUrl}/studentreg/register`;
    return this.http.post(url, payload);
  }

  registerFormEndpoint(payload) {
    let url = `${environment.apiBaseUrl}/cform/regform`;
    return this.http.post(url, payload);
  }

  registerFormNewEndpoint(payload, type) {
    let url = `${environment.apiBaseUrl}/cform/regform/`;
    if (type == 'public') {
      url += 'public';
    }
    return this.http.post(url, payload);
  }

  getDownloadPdfStatus(id: any) {
    let url = `${environment.apiBaseUrl}/cform/regform/${id}`;
    return this.http.get(url);
  }

  getRegistrationFormsById(id: any): any {
    const orgId = this._appLoadService.getOrgId();
    const tenantId = this._appLoadService.tenantConfig.tenantId;
    return this.http.get(`${environment.apiBaseUrl}/public/customForm/${id}?tid=${tenantId}`);
  }

  getRegistrationForms(id: any): any {
    return this.http.get(`${environment.apiBaseUrl}/rest/customForm/${id}`).pipe(
      map((response: any) => {
        if (response.customFields && Array.isArray(response.customFields)) {
          response.customFields.sort((a: any, b: any) => a.sequence - b.sequence);
        }
        return response;
      }),
    );
  }

  getAsset(type) {
    return this.http.get(`${environment.apiBaseUrl}/edcourse/assets?fileType=${type}`);
  }

  getFranchises() {
    return this.http.get(`${environment.apiBaseUrl}/rest/franchise`);
  }

  getFranchise(id) {
    return this.http.get(`${environment.apiBaseUrl}/rest/franchise/${id}`);
  }

  deleteFranchise(id) {
    return this.http.delete(`${environment.apiBaseUrl}/rest/franchise/${id}`);
  }

  updateFranchise(id, payload) {
    return this.http.put(`${environment.apiBaseUrl}/rest/franchise/${id}`, payload);
  }

  postFranchise(payload) {
    return this.http.post(`${environment.apiBaseUrl}/rest/franchise`, payload);
  }

  get accountDetail(): AccountDetail {
    return this._accountDetail;
  }

  get accountDetails$() : Observable<AccountDetail>{
    return this._detailSubject as Observable<AccountDetail>;
  }

  getTenantDetail() {
    let url = `${environment.apiBaseUrl}/item/tenant`;
    return this.http.get(url);
  }

  updateTenantSettings(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant`, payload);
  }

  updatePspringProvider(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant/pspring`, payload);
  }

  updateStripeProvider(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant/stripe`, payload);
  }

  updateRazorpayProvider(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant/razorpay`, payload);
  }

  updatePhonepeProvider(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant/phonepe`, payload);
  }

  updatePaytmProvider(payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/item/tenant/paytm`, payload);
  }

  deleteProvider(type: any) {
    return this.http.delete(`${environment.apiBaseUrl}/item/tenant?type=` + type);
  }

  deleteProvide(type: any) {
    return this.http.delete(`${environment.apiBaseUrl}/item/tenant?type=${type}`);
  }

  getConnectedStripeAccount() {
    return this.http.get(`${environment.apiBaseUrl}/item/stripe/account`);
  }

  getStripeLoginlinkAccount() {
    return this.http.get(`${environment.apiBaseUrl}/item/stripe/loginlink`);
  }

  getStripePaymentKeys() {
    let url = `${environment.apiBaseUrl}/item/payment/key/stripe`;
    return this.http.get(url);
  }

  getCardList(contactId, provider) {
    let url = `${environment.apiBaseUrl}/item/contact/${contactId}/card?provider=${provider}`;
    return this.http.get(url);
  }

  deleteCard(contactId, id) {
    let url = `${environment.apiBaseUrl}/item/contact/${contactId}/card/${id}`;
    return this.http.delete(url);
  }

  getPaymentintentUrl() {
    return `${environment.apiBaseUrl}/item/payment/paymentintent`;
  }

  getRequestToken() {
    return 'Bearer ' + this._localStorageService.getItem(AppConstants.AUTHTOKEN_KEY);
  }

  getSMSTenantDetail() {
    let url = `${environment.apiBaseUrl}/sms/tenant`;
    return this.http.get(url);
  }

  getCampaignDetail() {
    let url = `${environment.apiBaseUrl}/campaign/tenant`;
    return this.http.get(url);
  }

  addEmailTemplate(payload) {
    let url = `${environment.apiBaseUrl}/esend/etemplate`;
    return this.http.post(url, payload);
  }

  deleteEmailTemplate(id){
    let url = `${environment.apiBaseUrl}/esend/etemplate/${id}`;
    return this.http.delete(url);
  }

  updateEmailTemplate(payload) {
    let url = `${environment.apiBaseUrl}/esend/etemplate/${payload.id}`;
    return this.http.put(url, payload);
  }

  getEmailTemplateList() {
    let url = `${environment.apiBaseUrl}/esend/etemplate`;
    return this.http.get(url);
  }

  getEmailTemplateDetails(id: string) {
    let url = `${environment.apiBaseUrl}/esend/etemplate/${id}`;
    return this.http.get(url);
  }

  updateBrevoTenant(payload: any) {
    let url = `${environment.apiBaseUrl}/campaign/tenant/brevo`;
    return this.http.put(url, payload);
  }

  updateMailchimpTenant(payload: any) {
    let url = `${environment.apiBaseUrl}/campaign/tenant/mailchimp`;
    return this.http.put(url, payload);
  }

  updateSMSTenant(payload: any) {
    let url = `${environment.apiBaseUrl}/sms/tenant/twilio`;
    return this.http.put(url, payload);
  }

  updatePlivoSMSTenant(payload: any) {
    let url = `${environment.apiBaseUrl}/sms/tenant/plivo`;
    return this.http.put(url, payload);
  }
}
