import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root"
})
export class NoticeService {
  baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  createNotice(payload: any) {
    let apiUrl = `${this.baseUrl}/edtest/noticeboard`;
    return this.http.post(apiUrl, payload);
  }

  getNotices(isActive) {
    let apiUrl = `${this.baseUrl}/edtest/noticeboard?isActive=${isActive}`;
    return this.http.get(apiUrl);
  }

  updateNotice(payload: any) {
    let apiUrl = `${this.baseUrl}/edtest/noticeboard/${payload._id}`;
    return this.http.put(apiUrl, payload);
  }

  delete(id) {
    let apiUrl = `${this.baseUrl}/edtest/noticeboard/${id}`;
    return this.http.delete(apiUrl);
  }
}
